import {
  AttackReference,
  BaseMove,
  calcAcrobaticsBuff,
  calcAvalancheBuff,
  calcLastRespectsPower,
  calcRageFistPower,
  calcStoredPowerPower,
  weightRefMoves,
} from './BaseMove'
import { judgeTeraBlastCategory } from './TeraBlast'
import { TypeId, typesId } from './Type'

export type BattleMoveCategory = 'Physical' | 'Special'

export type BattleMove = {
  moveId: number
  attackTimes: number
  category: BattleMoveCategory
  type: TypeId[]
}

export type MoveCustomParams = {
  totalStatStageCount: number
  lastRespectsCount: number
  rageFistCount: number
  acrobaticsBuff: boolean
  knockOffBuff: boolean
  avalancheBuff: boolean
}

export const calculateBattleMoveDisplayCategory = (args: {
  attackerIsTerastal: boolean
  stats: {
    attack: number
    specialAttack: number
  }
  statStages: {
    attack: number
    specialAttack: number
  }
  baseDisplayCategory: string
  attackReference: AttackReference
}) => {
  if (args.attackReference === 'teraBlast') {
    const categoryEng = judgeTeraBlastCategory({
      isTerastal: args.attackerIsTerastal,
      attackStat: args.stats.attack,
      specialAttackStat: args.stats.specialAttack,
      attackStage: args.statStages.attack,
      specialAttackStage: args.statStages.specialAttack,
    })
    if (categoryEng === 'Physical') {
      return '物理'
    } else {
      return '特殊'
    }
  } else {
    return args.baseDisplayCategory
  }
}

export const calculateBattleMoveType = (args: {
  attackerIsTerastal: boolean
  attackerTeraType: TypeId
  attackerAbilityName: string
  baseMoveTypeId: TypeId
  baseMoveAttackReference: AttackReference
}): TypeId => {
  if (args.baseMoveAttackReference === 'teraBlast' && args.attackerIsTerastal) {
    return args.attackerTeraType
    //TODO 技のタイプが変わる系統の分岐を実装
  } else if (
    args.attackerAbilityName === 'エレキスキン' &&
    args.baseMoveTypeId === typesId.NORMAL
  ) {
    // エレキスキンはノーマル技を電気技にする
    return typesId.ELECTRIC
  } else if (
    args.attackerAbilityName === 'スカイスキン' &&
    args.baseMoveTypeId === typesId.NORMAL
  ) {
    // スカイスキンはノーマル技を飛行技にする
    return typesId.FLYING
  } else if (args.attackerAbilityName === 'ノーマルスキン') {
    // ノーマルスキンはすべての技をノーマル技にする
    return typesId.NORMAL
  } else if (
    args.attackerAbilityName === 'フェアリースキン' &&
    args.baseMoveTypeId === typesId.NORMAL
  ) {
    // フェアリースキンはノーマル技をフェアリー技にする
    return typesId.FAIRY
  } else if (
    args.attackerAbilityName === 'フリーズスキン' &&
    args.baseMoveTypeId === typesId.NORMAL
  ) {
    // フリーズスキンはノーマル技を氷技にする
    return typesId.ICE
  } else {
    return args.baseMoveTypeId
  }
}

export const calculateDisplayMovePower = (args: {
  baseMove: BaseMove
  moveCustomParams: MoveCustomParams
}): string => {
  if (args.baseMove.name === 'アシストパワー') {
    return String(
      calcStoredPowerPower(args.moveCustomParams.totalStatStageCount)
    )
  } else if (weightRefMoves.includes(args.baseMove.name)) {
    return '-'
  } else if (args.baseMove.name === 'おはかまいり') {
    return String(
      calcLastRespectsPower(args.moveCustomParams.lastRespectsCount)
    )
  } else if (args.baseMove.name === 'ふんどのこぶし') {
    return String(calcRageFistPower(args.moveCustomParams.rageFistCount))
  } else if (args.baseMove.name === 'アクロバット') {
    return String(calcAcrobaticsBuff(args.moveCustomParams.acrobaticsBuff))
  } else if (args.baseMove.name === 'ゆきなだれ') {
    return String(calcAvalancheBuff(args.moveCustomParams.avalancheBuff))
  } else {
    return String(args.baseMove.power)
  }
}
