export type Ability = {
  id: number
  name: string
  offensive: boolean
  defensive: boolean
  isExistsInPaldia: boolean
}

export const ignoreBurnDebuffAbilities = ['こんじょう', 'ねつぼうそう']

export const abilities: Ability[] = [
  {
    id: 0,
    name: 'なし',
    offensive: true,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 1,
    name: 'オーラブレイク',
    offensive: true,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 2,
    name: 'とうそうしん弱化',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 3,
    name: 'そうだいしょう1',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 4,
    name: 'エレキスキン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 5,
    name: 'スカイスキン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 6,
    name: 'ノーマルスキン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 7,
    name: 'フェアリースキン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 8,
    name: 'フリーズスキン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 9,
    name: 'てつのこぶし',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 10,
    name: 'すてみ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 11,
    name: 'そうだいしょう2',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 12,
    name: 'とうそうしん強化',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 13,
    name: 'バッテリー',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 14,
    name: 'ちからづく',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 15,
    name: 'すなのちから',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 16,
    name: 'アナライズ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 17,
    name: 'かたいツメ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 18,
    name: 'そうだいしょう3',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 19,
    name: 'パンクロック',
    offensive: true,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 20,
    name: 'パワースポット',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 21,
    name: 'フェアリーオーラ',
    offensive: true,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 22,
    name: 'ダークオーラ',
    offensive: true,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 23,
    name: 'きれあじ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 24,
    name: 'テクニシャン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 25,
    name: 'ねつぼうそう',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 26,
    name: 'どくぼうそう',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 27,
    name: 'がんじょうあご',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 28,
    name: 'メガランチャー',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 29,
    name: 'はがねのせいしん',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 30,
    name: 'たいねつ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 31,
    name: 'かんそうはだ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 32,
    name: 'スロースタート',
    offensive: false,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 33,
    name: 'よわき',
    offensive: false,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 34,
    name: 'わざわいのうつわ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 35,
    name: 'わざわいのおふだ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 37,
    name: 'クォークチャージ(攻撃)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 38,
    name: 'クォークチャージ(防御)',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 39,
    name: 'クォークチャージ(特攻)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 40,
    name: 'クォークチャージ(特防)',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 41,
    name: 'こだいかっせい(攻撃)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 42,
    name: 'こだいかっせい(防御)',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 43,
    name: 'こだいかっせい(特攻)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 44,
    name: 'こだいかっせい(特防)',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 45,
    name: 'ハドロンエンジン',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 46,
    name: 'ひひいろのこどう',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 47,
    name: 'フラワーギフト',
    offensive: true,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 48,
    name: 'こんじょう',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 49,
    name: 'しんりょく',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 50,
    name: 'もうか',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 51,
    name: 'げきりゅう',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 52,
    name: 'むしのしらせ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 53,
    name: 'もらいび',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 54,
    name: 'サンパワー',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 55,
    name: 'プラス',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 55,
    name: 'マイナス',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 56,
    name: 'いわはこび',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 57,
    name: 'はがねつかい',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 58,
    name: 'ごりむちゅう',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 59,
    name: 'トランジスタ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 60,
    name: 'りゅうのあぎと',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 61,
    name: 'ちからもち',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 62,
    name: 'ヨガパワー',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 63,
    name: 'すいほう(攻撃側)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 64,
    name: 'すいほう(防御側)',
    offensive: false,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 65,
    name: 'はりこみ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 66,
    name: 'あついしぼう',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 67,
    name: 'きよめのしお',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 68,
    name: 'はりきり',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 69,
    name: 'わざわいのたま',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 70,
    name: 'わざわいのつるぎ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 71,
    name: 'ふしぎなうろこ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 72,
    name: 'くさのけがわ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 73,
    name: 'ファーコート',
    offensive: false,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 74,
    name: 'ブレインフォース',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 75,
    name: 'スナイパー',
    offensive: true,
    defensive: false,
    isExistsInPaldia: false,
  },
  {
    id: 76,
    name: 'いろめがね',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 77,
    name: 'もふもふ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 78,
    name: 'マルチスケイル',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 79,
    name: 'ファントムガード',
    offensive: false,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 80,
    name: 'こおりのりんぷん',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 81,
    name: 'ハードロック',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 82,
    name: 'フィルター',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 83,
    name: 'プリズムアーマー',
    offensive: false,
    defensive: true,
    isExistsInPaldia: false,
  },
  {
    id: 84,
    name: 'へんげんじざい',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
]

export const getAbility = (id: number): Ability => {
  const ability = abilities.find((ability) => ability.id === id)
  if (typeof ability === 'undefined') {
    throw new Error()
  }
  return ability
}
