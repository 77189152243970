import { Box } from '@chakra-ui/react'

type DamageBarProps = {
  lowHpRatio: number
  highHpRatio: number
  height?: string
}

export const DamageBar = ({
  lowHpRatio,
  highHpRatio,
  height = '15px',
}: DamageBarProps) => {
  const color = getColor(lowHpRatio, highHpRatio)

  return (
    <Box position="relative">
      <Box
        position="absolute"
        width="100%"
        zIndex={1}
        backgroundColor="#f3f6f9"
        height={height}
      />
      <Box
        position="absolute"
        width={String(highHpRatio * 100) + '%'}
        zIndex={2}
        backgroundColor={color.higherHp}
        height={height}
      />
      <Box
        position="absolute"
        width={String(lowHpRatio * 100) + '%'}
        zIndex={3}
        backgroundColor={color.lowerHp}
        height={height}
      />
    </Box>
  )
}

const getColor = (lowHpRatio: number, highHpRatio: number) => {
  const avg = (lowHpRatio + highHpRatio) / 2
  if (avg > 0.5) {
    return {
      lowerHp: 'rgb(46, 125, 50)',
      higherHp: 'rgb(175, 205, 177)',
    }
  } else if (avg > 0.25) {
    return {
      lowerHp: 'rgb(237, 108, 2)',
      higherHp: 'rgb(248, 199, 158)',
    }
  } else {
    return {
      lowerHp: 'rgb(211, 47, 47)',
      higherHp: 'rgb(238, 175, 175)',
    }
  }
}
