import { getRankCorrection } from './StatStage'

export const judgeTeraBlastCategory = ({
  isTerastal,
  attackStat,
  specialAttackStat,
  attackStage,
  specialAttackStage,
}: {
  isTerastal: boolean
  attackStat: number
  specialAttackStat: number
  attackStage: number
  specialAttackStage: number
}): 'Physical' | 'Special' => {
  if (isTerastal) {
    const attack = attackStat * getRankCorrection(attackStage)
    const specialAttack =
      specialAttackStat * getRankCorrection(specialAttackStage)
    if (attack > specialAttack) {
      return 'Physical'
    } else {
      return 'Special'
    }
  } else {
    return 'Special'
  }
}
