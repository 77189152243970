import { Box, BoxProps } from '@chakra-ui/react'

type StatsBoxProps = BoxProps
export const StatsBox = (props: StatsBoxProps) => {
  return (
    <Box width="50px" display="flex" justifyContent="center" {...props}>
      {props.children}
    </Box>
  )
}
