import {
  calcAvalancheBuff,
  calcGrassKnot,
  calcLastRespectsPower,
  calcLowKick,
  calcRageFistPower,
  calcStoredPowerPower,
  calcWeightPower,
} from './BaseMove'
import { PokeMath } from './PokeMath'
import { getRankCorrection } from './StatStage'

export type 技の威力を計算する引数 = {
  表記威力: number
  エラがみ: boolean
  アクロバット: boolean
  でんげきくちばし: boolean
  ダメおし: boolean
  たたりめ: boolean
  アシストパワー: boolean
  合計能力ランクアップ数: number
  ヘビーボンバー: boolean
  ヒートスタンプ: boolean
  攻撃側の重さ: number
  防御側の重さ: number
  おはかまいり: boolean
  倒された味方の数: number
  ふんどのこぶし: boolean
  攻撃を受けた回数: number
  くさむすび: boolean
  けたぐり: boolean
  ゆきなだれ: boolean
}

export const 技の威力を計算する = (args: 技の威力を計算する引数) => {
  let value = args.表記威力
  if (
    args.エラがみ ||
    args.アクロバット ||
    args.でんげきくちばし ||
    args.ダメおし ||
    args.たたりめ
  ) {
    value = value * 2
  }
  if (args.アシストパワー && args.合計能力ランクアップ数 > 0) {
    value = calcStoredPowerPower(args.合計能力ランクアップ数)
  }

  if (args.ヘビーボンバー || args.ヒートスタンプ) {
    value = calcWeightPower(args.攻撃側の重さ, args.防御側の重さ)
  }

  if (args.おはかまいり) {
    value = calcLastRespectsPower(args.倒された味方の数)
  }

  if (args.ふんどのこぶし) {
    value = calcRageFistPower(args.攻撃を受けた回数)
  }

  if (args.くさむすび) {
    value = calcGrassKnot(args.防御側の重さ)
  }

  if (args.けたぐり) {
    value = calcLowKick(args.防御側の重さ)
  }

  if (args.ゆきなだれ) {
    value = calcAvalancheBuff(true)
  }

  return value
}

export type 威力の補正値を計算する引数 = {
  // 特性系
  フェアリーオーラブレイク: boolean
  ダークオーラブレイク: boolean
  とうそうしん弱化: boolean
  そうだいしょう1: boolean
  エレキスキン: boolean
  スカイスキン: boolean
  ノーマルスキン: boolean
  フェアリースキン: boolean
  フリーズスキン: boolean
  てつのこぶし: boolean
  すてみ: boolean
  そうだいしょう2: boolean
  とうそうしん強化: boolean
  バッテリー: boolean
  ちからずく: boolean
  すなのちから: boolean
  アナライズ: boolean
  かたいツメ: boolean
  そうだいしょう3: boolean
  パンクロック: boolean
  パワースポット: boolean
  フェアリーオーラ: boolean
  ダークオーラ: boolean
  きれあじ: boolean
  テクニシャン: boolean
  ねつぼうそう: boolean
  どくぼうそう: boolean
  がんじょうあご: boolean
  メガランチャー: boolean
  はがねのせいしん: boolean
  たいねつ: boolean
  かんそうはだ: boolean
  // アイテム系
  ちからのハチマキ: boolean
  ものしりメガネ: boolean
  パンチグローブ: boolean
  タイプ強化系持ち物: boolean
  こんごうだま: boolean
  しらたま: boolean
  はっきんだま: boolean
  こころのしずく: boolean
  ジュエル系持ち物: boolean
  技の性質で天候による半減: boolean
  // 技の効果
  さきどり: boolean
  はたきおとす: boolean
  てだすけ: boolean
  重力下のGのちから: boolean
  ワイドフォース: boolean
  じゅうでん: boolean
  からげんき: boolean
  しおみず: boolean
  ベノムショック: boolean
  かたきうち: boolean
  クロスサンダークロスフレイム: boolean
  ライジングボルト: boolean
  // フィールド補正
  フィールド弱化: boolean
  フィールド強化: boolean
  みずあそび: boolean
  どろあそび: boolean
}
const BASE = 4096
export const 威力の補正値を計算する = (args: 威力の補正値を計算する引数) => {
  let value = BASE

  if (
    args.フェアリーオーラブレイク ||
    args.ダークオーラブレイク ||
    args.とうそうしん弱化
  ) {
    value = PokeMath.round((value * 3072) / BASE)
  }

  if (args.そうだいしょう1) {
    value = PokeMath.round((value * 4505) / BASE)
  }

  if (
    args.エレキスキン ||
    args.スカイスキン ||
    args.ノーマルスキン ||
    args.フェアリースキン ||
    args.フリーズスキン ||
    args.てつのこぶし ||
    args.すてみ ||
    args.そうだいしょう2
  ) {
    value = PokeMath.round((value * 4915) / BASE)
  }

  if (args.とうそうしん強化) {
    value = PokeMath.round((value * 5120) / BASE)
  }

  if (
    args.バッテリー ||
    args.ちからずく ||
    args.すなのちから ||
    args.アナライズ ||
    args.かたいツメ ||
    args.そうだいしょう3 ||
    args.パンクロック ||
    args.パワースポット
  ) {
    value = PokeMath.round((value * 5325) / BASE)
  }

  if (args.フェアリーオーラ || args.ダークオーラ) {
    value = PokeMath.round((value * 5448) / BASE)
  }

  if (
    args.きれあじ ||
    args.テクニシャン ||
    args.ねつぼうそう ||
    args.どくぼうそう ||
    args.がんじょうあご ||
    args.メガランチャー ||
    args.はがねのせいしん
  ) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (args.たいねつ) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (args.かんそうはだ) {
    value = PokeMath.round((value * 5120) / BASE)
  }

  if (args.ちからのハチマキ || args.ものしりメガネ) {
    value = PokeMath.round((value * 4505) / BASE)
  }

  if (args.パンチグローブ) {
    value = PokeMath.round((value * 4505) / BASE)
  }

  if (
    args.タイプ強化系持ち物 ||
    args.こんごうだま ||
    args.しらたま ||
    args.はっきんだま ||
    args.こころのしずく
  ) {
    value = PokeMath.round((value * 4915) / BASE)
  }

  if (args.ジュエル系持ち物) {
    value = PokeMath.round((value * 5325) / BASE)
  }

  if (args.技の性質で天候による半減) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (
    args.さきどり ||
    args.はたきおとす ||
    args.てだすけ ||
    args.重力下のGのちから ||
    args.ワイドフォース
  ) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (
    args.じゅうでん ||
    args.からげんき ||
    args.しおみず ||
    args.ベノムショック ||
    args.かたきうち ||
    args.クロスサンダークロスフレイム ||
    args.ライジングボルト
  ) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  if (args.フィールド弱化) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (args.フィールド強化) {
    value = PokeMath.round((value * 5325) / BASE)
  }

  if (args.みずあそび || args.どろあそび) {
    value = PokeMath.round((value * 1352) / BASE)
  }

  return value
}

export type 最終威力を計算する引数 = {
  v技の威力を計算する引数: 技の威力を計算する引数
  v威力の補正値を計算する引数: 威力の補正値を計算する引数
  連続技: boolean
  先制技: boolean
  テラスタイプと技のタイプが同じ: boolean
}
export const 最終威力を計算する = (args: 最終威力を計算する引数) => {
  const 技の威力 = 技の威力を計算する(args.v技の威力を計算する引数)
  const 威力の補正値 = 威力の補正値を計算する(args.v威力の補正値を計算する引数)
  let value = PokeMath.specialRound((技の威力 * 威力の補正値) / BASE)

  if (value < 1) {
    return 1
  }
  //TODO 仕様を確認して追記
  // if (!args.連続技 && !args.先制技 && value < 40) {
  //   return 60;
  // }
  return value
}

export type 攻撃の補正値を計算する引数 = {
  スロースタート: boolean
  よわき: boolean
  わざわいのうつわ: boolean
  わざわいのおふだ: boolean
  クォークチャージ: boolean
  こだいかっせい: boolean
  ハドロンエンジン: boolean
  ひひいろのこどう: boolean
  フラワーギフト: boolean
  こんじょう: boolean
  しんりょく: boolean
  もうか: boolean
  げきりゅう: boolean
  むしのしらせ: boolean
  もらいび: boolean
  サンパワー: boolean
  プラス: boolean
  マイナス: boolean
  いわはこび: boolean
  はがねつかい: boolean
  ごりむちゅう: boolean
  トランジスタ: boolean
  りゅうのあぎと: boolean
  ちからもち: boolean
  ヨガパワー: boolean
  すいほう水技: boolean
  はりこみ: boolean
  あついしぼう: boolean
  すいほう炎技: boolean
  きよめのしお: boolean
  こだわりハチマキ: boolean
  こだわりメガネ: boolean
  ふといホネ: boolean
  しんかいのキバ: boolean
  でんきだま: boolean
}

export const 攻撃の補正値を計算する = (args: 攻撃の補正値を計算する引数) => {
  let value = BASE

  if (args.スロースタート || args.よわき) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (args.わざわいのうつわ || args.わざわいのおふだ) {
    value = PokeMath.round((value * 3072) / BASE)
  }

  if (args.クォークチャージ || args.こだいかっせい) {
    value = PokeMath.round((value * 5325) / BASE)
  }

  if (args.ハドロンエンジン || args.ひひいろのこどう) {
    value = PokeMath.round((value * 5461) / BASE)
  }

  if (
    args.フラワーギフト ||
    args.こんじょう ||
    args.しんりょく ||
    args.もうか ||
    args.げきりゅう ||
    args.むしのしらせ ||
    args.もらいび ||
    args.サンパワー ||
    args.プラス ||
    args.マイナス ||
    args.いわはこび ||
    args.はがねつかい ||
    args.ごりむちゅう ||
    args.トランジスタ ||
    args.りゅうのあぎと
  ) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (
    args.ちからもち ||
    args.ヨガパワー ||
    args.すいほう水技 ||
    args.はりこみ
  ) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  if (args.あついしぼう || args.すいほう炎技 || args.きよめのしお) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (args.こだわりハチマキ || args.こだわりメガネ) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (args.ふといホネ || args.しんかいのキバ || args.でんきだま) {
    value = PokeMath.round((value * 8192) / BASE)
  }
  return value
}

export type 最終攻撃を計算する引数 = {
  参照値: number
  ランク: number
  はりきり: boolean
  v攻撃の補正値を計算する引数: 攻撃の補正値を計算する引数
}

export const 最終攻撃を計算する = (args: 最終攻撃を計算する引数) => {
  const 攻撃の補正値 = 攻撃の補正値を計算する(args.v攻撃の補正値を計算する引数)

  let value = 0
  const ランク補正 = getRankCorrection(args.ランク)
  value = PokeMath.floor(args.参照値 * ランク補正)

  if (args.はりきり) {
    value = PokeMath.floor((value * 6144) / BASE)
  }

  value = PokeMath.specialRound((value * 攻撃の補正値) / BASE)

  if (value < 1) {
    return 1
  }

  return value
}

export type 防御の補正値を計算する引数 = {
  わざわいのたま: boolean
  わざわいのつるぎ: boolean
  クォークチャージ: boolean
  こだいかっせい: boolean
  フラワーギフト: boolean
  ふしぎなうろこ: boolean
  くさのけがわ: boolean
  ファーコート: boolean
  しんかのきせき: boolean
  とつげきチョッキ: boolean
  しんかいのウロコ: boolean
  メタルパウダー: boolean
}

export const 防御の補正値を計算する = (args: 防御の補正値を計算する引数) => {
  let value = 4096
  if (args.わざわいのたま || args.わざわいのつるぎ) {
    value = PokeMath.round((value * 3072) / BASE)
  }

  if (args.クォークチャージ || args.こだいかっせい) {
    value = PokeMath.round((value * 5325) / BASE)
  }

  if (args.フラワーギフト || args.ふしぎなうろこ || args.くさのけがわ) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (args.ファーコート) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  if (args.しんかのきせき || args.とつげきチョッキ) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (args.しんかいのウロコ || args.メタルパウダー) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  return value
}

export type 最終防御を計算する引数 = {
  参照値: number
  ランク: number
  砂嵐による特防強化: boolean
  雪による防御強化: boolean
  v防御の補正値を計算する引数: 防御の補正値を計算する引数
}

export const 最終防御を計算する = (args: 最終防御を計算する引数) => {
  const 防御の補正値 = 防御の補正値を計算する(args.v防御の補正値を計算する引数)

  const ランク補正 = getRankCorrection(args.ランク)
  let value = PokeMath.floor(args.参照値 * ランク補正)

  if (args.砂嵐による特防強化) {
    value = PokeMath.floor((value * 6144) / BASE)
  }

  if (args.雪による防御強化) {
    value = PokeMath.floor((value * 6144) / BASE)
  }

  value = PokeMath.specialRound((value * 防御の補正値) / BASE)
  if (value < 1) {
    return 1
  }
  return value
}

export type ダメージの補正値を計算する引数 = {
  リフレクター: boolean
  ひかりのかべ: boolean
  オーロラベール: boolean
  ブレインフォース: boolean
  アクセルブレイク: boolean
  イナズマドライブ: boolean
  スナイパー: boolean
  いろめがね: boolean
  もふもふに炎技: boolean
  マルチスケイル: boolean
  ファントムガード: boolean
  もふもふに直接攻撃: boolean
  パンクロック: boolean
  こおりのりんぷん: boolean
  フレンドガード: boolean
  ハードロック: boolean
  フィルター: boolean
  プリズムアーマー: boolean
  メトロノーム2: boolean
  メトロノーム3: boolean
  メトロノーム4: boolean
  メトロノーム5: boolean
  メトロノーム6: boolean
  たつじんのおび: boolean
  いのちのたま: boolean
  半減きのみ: boolean
  ちいさくなる2倍: boolean
}

export const ダメージの補正値を計算する = (
  args: ダメージの補正値を計算する引数
) => {
  let value = 4096

  if (args.リフレクター || args.ひかりのかべ || args.オーロラベール) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (args.ブレインフォース) {
    value = PokeMath.round((value * 5120) / BASE)
  }

  if (args.アクセルブレイク || args.イナズマドライブ) {
    value = PokeMath.round((value * 5461) / BASE)
  }

  if (args.スナイパー) {
    value = PokeMath.round((value * 6144) / BASE)
  }

  if (args.いろめがね || args.もふもふに炎技) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  if (
    args.マルチスケイル ||
    args.ファントムガード ||
    args.もふもふに直接攻撃 ||
    args.パンクロック ||
    args.こおりのりんぷん
  ) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (
    args.フレンドガード ||
    args.ハードロック ||
    args.フィルター ||
    args.プリズムアーマー
  ) {
    value = PokeMath.round((value * 3072) / BASE)
  }

  if (args.メトロノーム2) {
    value = PokeMath.round((value * 4915) / BASE)
  } else if (args.メトロノーム3) {
    value = PokeMath.round((value * 5734) / BASE)
  } else if (args.メトロノーム4) {
    value = PokeMath.round((value * 6553) / BASE)
  } else if (args.メトロノーム5) {
    value = PokeMath.round((value * 7372) / BASE)
  } else if (args.メトロノーム6) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  if (args.たつじんのおび) {
    value = PokeMath.round((value * 4915) / BASE)
  }

  if (args.いのちのたま) {
    value = PokeMath.round((value * 5324) / BASE)
  }

  if (args.半減きのみ) {
    value = PokeMath.round((value * 2048) / BASE)
  }

  if (args.ちいさくなる2倍) {
    value = PokeMath.round((value * 8192) / BASE)
  }

  return value
}

export type calcProps = {
  level: number
  元の威力: number
}
export const randomNumbers = [
  0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97,
  0.98, 0.99, 1,
]
export type 最終ダメージを計算する引数 = {
  レベル: number
  v最終威力を計算する引数: 最終威力を計算する引数
  v最終攻撃を計算する引数: 最終攻撃を計算する引数
  v最終防御を計算する引数: 最終防御を計算する引数
  vダメージの補正値を計算する引数: ダメージの補正値を計算する引数
  天気弱化: boolean
  天気強化: boolean
  きょけんとつげき被ダメ増加: boolean
  急所: boolean
  タイプ一致: boolean
  適応力: boolean
  タイプ一致テラスタル: boolean
  適応力テラスタル: boolean
  タイプ相性2倍の数: number
  タイプ相性半減の数: number
  タイプ相性無効: boolean
  やけど: boolean
}

export type 最終ダメージ計算結果 = {
  damages: number[]
  lowestDamage: number
  highestDamage: number
}

export const 最終ダメージを計算する = (
  args: 最終ダメージを計算する引数
): 最終ダメージ計算結果 => {
  const 最終威力 = 最終威力を計算する(args.v最終威力を計算する引数)
  const 最終攻撃 = 最終攻撃を計算する(args.v最終攻撃を計算する引数)
  const 最終防御 = 最終防御を計算する(args.v最終防御を計算する引数)
  const ダメージの補正値 = ダメージの補正値を計算する(
    args.vダメージの補正値を計算する引数
  )

  let value = PokeMath.floor((args.レベル * 2) / 5 + 2)
  value = PokeMath.floor((value * 最終威力 * 最終攻撃) / 最終防御)
  value = PokeMath.floor(value / 50 + 2)

  if (args.天気弱化) {
    value = PokeMath.specialRound((value * 2048) / BASE)
  }

  if (args.天気強化) {
    value = PokeMath.specialRound((value * 6144) / BASE)
  }

  if (args.きょけんとつげき被ダメ増加) {
    value = PokeMath.specialRound((value * 8192) / BASE)
  }

  if (args.急所) {
    value = PokeMath.specialRound((value * 6144) / BASE)
  }

  let damages = randomNumbers.map((r) => PokeMath.floor(value * r))

  if (args.適応力テラスタル) {
    damages = damages.map((damage) =>
      PokeMath.specialRound((damage * 9216) / BASE)
    )
  } else if (args.適応力 || args.タイプ一致テラスタル) {
    damages = damages.map((damage) =>
      PokeMath.specialRound((damage * 8192) / BASE)
    )
  } else if (args.タイプ一致) {
    damages = damages.map((damage) =>
      PokeMath.specialRound((damage * 6144) / BASE)
    )
  }
  if (args.タイプ相性無効) {
    damages = damages.map((damage) => damage * 0)
  } else if (args.タイプ相性2倍の数 > args.タイプ相性半減の数) {
    const diff = args.タイプ相性2倍の数 - args.タイプ相性半減の数
    const c = BASE * 2 * diff
    damages = damages.map((damage) => PokeMath.floor((damage * c) / BASE))
  } else if (args.タイプ相性半減の数 > args.タイプ相性2倍の数) {
    const diff = args.タイプ相性半減の数 - args.タイプ相性2倍の数
    const c = BASE * (1 / (2 * diff))
    damages = damages.map((damage) => PokeMath.floor((damage * c) / BASE))
  }

  if (args.やけど) {
    damages = damages.map((damage) =>
      PokeMath.specialRound((damage * 2048) / BASE)
    )
  }

  damages = damages.map((damage) =>
    PokeMath.specialRound((damage * ダメージの補正値) / BASE)
  )

  return { damages, lowestDamage: damages[0], highestDamage: damages[15] }
}
