import React, { Dispatch, memo, useCallback } from 'react'
import {
  Card,
  CardBody,
  Box,
  Divider,
  Stack,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { Attacker, AttackersAction } from './useAttackersState'
import { AttackerStatsSection } from './AttackerStatsSection'
import { AbilityPicker } from '../../common/AbilityPicker'
import { HeldItemPicker } from '../../common/HeldItemPicker'
import { Checkbox } from '../../common/Checkbox'
import { WeatherPicker } from '../../common/WeatherPicker'
import { TerrainPicker } from '../../common/TerrainPicker'
import { TeraTypePicker } from '../common/TeraTypePicker'
import { TypeId } from '../../../domain/Type'
import { StatusConditions } from '../../../domain/StatusCondition'
import { StatusConditionSection } from '../common/StatusConditionSection'
import { AttackerPokemonSection } from './AttackerPokemonSection'
import { MoveSection } from './MoveForm/MoveSection'
import { Pokemon } from '../../../domain/Pokemon'
import { BaseStats } from '../../../domain/Stat'
import { BaseMove } from '../../../domain/BaseMove'
import { TriangleUpIcon } from '@chakra-ui/icons'

type AttackerFormProps = {
  removable: boolean
  id: number
  attacker: Attacker
  dispatch: Dispatch<AttackersAction>
  pokemon: Pokemon
  baseStats: BaseStats
  move: BaseMove
  movePower: string
  moveTypeId: number
  moveDisplayCategory: string
  onCloseClick: () => void
}

export const AttackerForm = memo(
  ({
    removable,
    id,
    attacker,
    dispatch,
    pokemon,
    baseStats,
    move,
    movePower,
    moveTypeId,
    moveDisplayCategory,
    onCloseClick,
  }: AttackerFormProps) => {
    const handleChangePokemonId = useCallback(
      (value: string) => {
        dispatch({
          type: 'changePokemonId',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onClickRemove = useCallback(() => {
      dispatch({
        type: 'remove',
        payload: { id },
      })
    }, [dispatch, id])
    const onChangeAbility = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeAbility',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeHeldItem = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeHeldItem',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeCriticalHitIsEnabled = useCallback(
      (value: boolean) => {
        dispatch({
          type: 'changeCriticalHitIsEnabled',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeWeatherId = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeWeatherId',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeTerrainId = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeTerrainId',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const handleChangeStatusCondition = useCallback(
      (key: keyof StatusConditions, value: boolean) => {
        dispatch({
          type: 'changeStatusCondition',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )

    const onChangeTeraType = useCallback(
      (value?: TypeId) => {
        dispatch({
          type: 'changeTeraType',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeIsBatteryCharging = useCallback(
      (value: boolean) => {
        dispatch({
          type: 'changeIsBatteryCharging',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    return (
      <Card>
        <CardBody>
          <Stack spacing={3}>
            <AttackerPokemonSection
              pokemonId={attacker.pokemonId}
              name={pokemon.name}
              types={pokemon.types}
              baseStats={baseStats}
              weight={pokemon.weight}
              removable={removable}
              onClickRemove={onClickRemove}
              onChangePokemonId={handleChangePokemonId}
              dataTestIdPokemonPicker={'attacker-pokemon-picker-' + String(id)}
            />
            <TeraTypePicker
              value={attacker.teraType}
              onChange={onChangeTeraType}
              dataTestIdPrefix={`attacker-${id}-`}
            />
            <Divider />
            <MoveSection
              id={attacker.id}
              moveId={attacker.moveId}
              moveName={move.name}
              power={movePower}
              typeId={moveTypeId}
              categoryName={moveDisplayCategory}
              attackTimes={attacker.attackTime}
              attackTimesOptions={move.attackTimes}
              attackerTeraType={attacker.teraType}
              moveCustomParams={attacker.moveCustomParams}
              dispatch={dispatch}
              dataTestIdMovePicker={'move-picker-' + String(id)}
            />
            <Divider />
            <AttackerStatsSection
              id={attacker.id}
              level={attacker.level}
              baseStats={baseStats}
              indivisualValues={attacker.indivisualValues}
              effortValues={attacker.effortValues}
              natureCorrections={attacker.natureCorrections}
              statStages={attacker.statStages}
              attackReference={move.attackReference}
              dispatch={dispatch}
            />
            <Flex gap="10px" alignItems="center">
              <Box flex="1">
                <AbilityPicker
                  value={attacker.abilityId}
                  onChange={onChangeAbility}
                  category="offensive"
                  defaultAbilityNames={pokemon.abilities}
                  dataTestId={`attacker-${id}-abilityPicker`}
                />
              </Box>
              <Box flex="1">
                <HeldItemPicker
                  value={attacker.heldItemId}
                  onChange={onChangeHeldItem}
                  category="offensive"
                  dataTestId={`attacker-${id}-heldItemPicker`}
                />
              </Box>
            </Flex>
            <Flex gap="30px">
              <Checkbox
                value={attacker.criticalHitIsEnabled}
                onChange={onChangeCriticalHitIsEnabled}
                label="急所"
              />
              <Checkbox
                value={attacker.isBatteryCharging}
                onChange={onChangeIsBatteryCharging}
                label="充電"
              />
            </Flex>
            <StatusConditionSection
              displayBurn
              displayPoison
              statusConditions={attacker.statusConditions}
              onChange={handleChangeStatusCondition}
            />
            <Flex gap="10px" alignItems="center">
              <Box flex="1">
                <WeatherPicker
                  value={attacker.weatherId}
                  onChange={onChangeWeatherId}
                />
              </Box>
              <Box flex="1">
                <TerrainPicker
                  value={attacker.terrainId}
                  onChange={onChangeTerrainId}
                />
              </Box>
            </Flex>
            <IconButton
              onClick={onCloseClick}
              aria-label="close"
              width="100%"
              size="xs"
              icon={<TriangleUpIcon />}
            />
          </Stack>
        </CardBody>
      </Card>
    )
  }
)
