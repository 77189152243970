export type Weather = {
  id: number
  name: string
}

export const WEATHERS_ID = {
  NONE: 0,
  HARSH_SUNLIGHT: 1,
  RAIN: 2,
  SNOW: 3,
  SANDSTORM: 4,
}

export const WEATHERS: Weather[] = [
  {
    id: WEATHERS_ID.NONE,
    name: '天候なし',
  },
  {
    id: WEATHERS_ID.HARSH_SUNLIGHT,
    name: 'はれ',
  },
  {
    id: WEATHERS_ID.RAIN,
    name: 'あめ',
  },
  {
    id: WEATHERS_ID.SNOW,
    name: 'ゆき',
  },
  {
    id: WEATHERS_ID.SANDSTORM,
    name: 'すなあらし',
  },
]

export const getWeather = (id: number) => {
  return WEATHERS[id]
}
