import { DeleteIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'

type RemoveButtonProps = {
  onClick: () => void
}
export const RemoveButton = ({ onClick }: RemoveButtonProps) => {
  return (
    <IconButton
      aria-label="delete-button"
      icon={<DeleteIcon color="red.500" />}
      onClick={onClick}
    />
  )
}
