import { TriangleDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Card,
  CardBody,
  Divider,
  Flex,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { memo } from 'react'
import { Ability } from '../../../domain/Ability'
import { 最終ダメージ計算結果 } from '../../../domain/DamageFormula'
import { Pokemon } from '../../../domain/Pokemon'
import { Stats } from '../../../domain/Stat'
import { calcHPStat } from '../../../domain/StatsFormula'
import { getTerrain, TERRAINS_ID } from '../../../domain/Terrain'
import { getType } from '../../../domain/Type'
import { getWeather, WEATHERS_ID } from '../../../domain/Weather'
import { DamageCalcResult } from '../../common/DamageCalcResult/DamageCalcResult'
import { Defender } from '../DefendersForm/useDefendersState'
import { PokemonWithHeldItemIcon } from '../DisplayParts/PokemonWithHeldItemIcon'
import { DefendersStatsDisplay } from './DefenderStatsDisplay'

type DefenderDisplayProps = {
  id: number
  defender: Defender
  pokemon: Pokemon
  ability: Ability
  stats: Stats
  onOpenClick: () => void
  isIncludeDefenderAttackReference: boolean
  isIncludeDefenderDefenseReference: boolean
  isIncludeDefenderSpecialDefenseReference: boolean
  result: 最終ダメージ計算結果
}

export const DefenderDisplay = memo(
  ({
    id,
    defender,
    pokemon,
    ability,
    stats,
    onOpenClick,
    isIncludeDefenderAttackReference,
    isIncludeDefenderDefenseReference,
    isIncludeDefenderSpecialDefenseReference,
    result,
  }: DefenderDisplayProps) => {
    return (
      <Card>
        <CardBody>
          <Flex alignItems="center">
            <PokemonWithHeldItemIcon
              pokemonId={defender.pokemonId}
              heldItemId={defender.heldItemId}
            />
            <Box>
              <HStack>
                <Box>{pokemon.name}</Box>
                {ability.name !== 'なし' && <Box>特性:{ability.name}</Box>}
                {typeof defender.teraType !== 'undefined' && (
                  <Box>{getType(defender.teraType).name}テラスタル</Box>
                )}
              </HStack>
              <DefendersStatsDisplay
                stats={stats}
                defender={defender}
                isIncludeDefenderAttackReference={
                  isIncludeDefenderAttackReference
                }
                isIncludeDefenderDefenseReference={
                  isIncludeDefenderDefenseReference
                }
                isIncludeDefenderSpecialDefenseReference={
                  isIncludeDefenderSpecialDefenseReference
                }
              />
              <HStack>
                {defender.hasReflect && <Box>リフレクター</Box>}
                {defender.hasLightScreen && <Box>ひかりのかべ</Box>}
                {defender.statusConditions.burn && <Box>やけど</Box>}
                {defender.statusConditions.poison && <Box>どく</Box>}
                {defender.weatherId !== WEATHERS_ID.NONE && (
                  <Box>天候{getWeather(defender.weatherId).name}</Box>
                )}
                {defender.terrainId !== TERRAINS_ID.NONE && (
                  <Box>{getTerrain(defender.terrainId).name}</Box>
                )}
              </HStack>
            </Box>
          </Flex>
          <Divider />
          <DamageCalcResult
            result={result}
            deffenseHp={calcHPStat({
              level: 50,
              baseStat: pokemon.hp,
              indivisualValue: defender.indivisualValues.hp,
              effortValue: defender.effortValues.hp,
            })}
            dataTestIdMinDamage={'min-damage-' + String(id)}
            dataTestIdMaxDamage={'max-damage-' + String(id)}
            dataTestIdPrefix={`defender-${id}`}
          />
          <IconButton
            onClick={onOpenClick}
            aria-label="close"
            width="100%"
            size="xs"
            icon={<TriangleDownIcon />}
          />
        </CardBody>
      </Card>
    )
  }
)
