import { Box, Card } from '@chakra-ui/react'
import { forwardRef } from 'react'

export type OptionsBoxProps = {
  isOpen: boolean
  children: React.ReactNode
}
export const OptionsBox = forwardRef<HTMLDivElement, OptionsBoxProps>(
  ({ isOpen, children }: OptionsBoxProps, ref) => {
    return (
      <Box
        display={isOpen ? 'block' : 'none'}
        position="relative"
        userSelect="none"
      >
        <Card
          ref={ref}
          position="absolute"
          backgroundColor="white"
          width="100%"
          zIndex={10}
          maxHeight="200px"
          overflow="auto"
        >
          {children}
        </Card>
      </Box>
    )
  }
)
