import React, { useState } from 'react'
import { Box, Divider, Flex } from '@chakra-ui/react'
import { calcDamages } from './common/generateConditions'
import { useAttackers } from './AttackersForm/useAttackersState'
import { useDefenders } from './DefendersForm/useDefendersState'
import { Header } from './Header'
import { useCalcHistory } from './CalcHistories/useCalcHistory'
import { CalcHistorySection } from './CalcHistories/CalcHistorySection'
import { CalculateSection } from './CalculateSection'
import { ScrollBox } from '../common/ScrollBox'
import { ToolBar } from './ToolBar'

export const SimpleCalc = () => {
  const [id, setId] = useState<number | undefined>(undefined)
  const { attackersState, attackersDispatch } = useAttackers()
  const { defendersState, defendersDispatch } = useDefenders()
  const { calcHistory, add, update, remove, move } = useCalcHistory()

  const results = calcDamages({
    attackers: attackersState,
    defenders: defendersState,
  })

  const handleClickCalcHistoryRecord = (id: number) => {
    setId(id)
    const target = calcHistory.data.find((c) => c.id === id)
    if (typeof target === 'undefined') {
      throw new Error()
    }
    attackersDispatch({
      type: 'init',
      payload: { attackersState: target.attackersState },
    })
    defendersDispatch({
      type: 'init',
      payload: { defendersState: target.defendersState },
    })
  }
  const handleClickRemoveHistoryRecord = (id: number) => {
    remove(id)
  }
  const handleClickAdd = () => {
    add({ attackersState, defendersState })
  }
  const handleClickUpdate = (id: number) => {
    update({ id, attackersState, defendersState })
  }
  const handleClearClick = () => {
    attackersDispatch({
      type: 'default',
    })
    defendersDispatch({
      type: 'default',
    })
    setId(undefined)
  }
  const handleDragAndDrop = (srcId: number, destId: number) => {
    move(srcId, destId)
  }
  return (
    <Box minWidth="1630px" maxHeight="100%" height="100%">
      <Header height="56px" />
      <Flex maxHeight="calc(100% - 57px)" height="calc(100% - 57px)">
        <ScrollBox
          width="300px"
          minWidth="300px"
          maxHeight="100%"
          height="100%"
        >
          <CalcHistorySection
            selectedId={id}
            calcHistory={calcHistory}
            onClickCalcHistoryRecord={handleClickCalcHistoryRecord}
            onClickRemove={handleClickRemoveHistoryRecord}
            onDragAndDrop={handleDragAndDrop}
          />
        </ScrollBox>
        <Box flex="1">
          <ToolBar
            selectedId={id}
            onAddClick={handleClickAdd}
            onSaveClick={handleClickUpdate}
            onClearClick={handleClearClick}
            height="56px"
          />
          <Divider />
          <ScrollBox
            maxHeight="calc(100% - 57px)"
            height="calc(100% - 57px)"
            alwaysDisplayScrollBar
          >
            <CalculateSection
              attackersState={attackersState}
              attackersDispatch={attackersDispatch}
              defendersState={defendersState}
              defendersDispatch={defendersDispatch}
              results={results}
            />
          </ScrollBox>
        </Box>
      </Flex>
    </Box>
  )
}
