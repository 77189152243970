import React, { memo, useCallback } from 'react'
import { Button } from '@chakra-ui/react'
import { NatureCorrection } from '../../domain/Nature'

type NatureCorrectionPickerProps = {
  value: NatureCorrection
  onChange: (value: NatureCorrection) => void
  dataTestIdPrefix: string
}
export const NatureCorrectionPicker = memo(
  ({ value, onChange, dataTestIdPrefix }: NatureCorrectionPickerProps) => {
    const onClickUp = useCallback(() => {
      onChange('上昇')
    }, [onChange])
    const onClickNoCorrection = useCallback(() => {
      onChange('無し')
    }, [onChange])
    const onClickDown = useCallback(() => {
      onChange('下降')
    }, [onChange])
    return (
      <div>
        <Button
          isActive={value === '下降'}
          onClick={onClickDown}
          marginRight={1}
          size="sm"
          data-testid={`${dataTestIdPrefix}-down`}
        >
          x0.9
        </Button>
        <Button
          isActive={value === '無し'}
          onClick={onClickNoCorrection}
          marginRight={1}
          size="sm"
          data-testid={`${dataTestIdPrefix}-none`}
        >
          x1.0
        </Button>
        <Button
          isActive={value === '上昇'}
          onClick={onClickUp}
          size="sm"
          data-testid={`${dataTestIdPrefix}-up`}
        >
          x1.1
        </Button>
      </div>
    )
  }
)
