import { Defender } from '../DefendersForm/useDefendersState'
import { PokemonWithHeldItemIcon } from './PokemonWithHeldItemIcon'

type DefenderSectionProps = {
  defender: Defender
}

export const DefenderSection = ({ defender }: DefenderSectionProps) => {
  return (
    <PokemonWithHeldItemIcon
      pokemonId={defender.pokemonId}
      heldItemId={defender.heldItemId}
    />
  )
}
