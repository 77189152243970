import { Box, Flex } from '@chakra-ui/react'
import { memo } from 'react'
import { BaseStats } from '../../../domain/Stat'
import { TypeId } from '../../../domain/Type'
import { PokemonsPicker } from '../../common/PokemonPicker'
import { RemoveButton } from '../../common/RemoveButton'
import { SpeciesSection } from '../common/SpeciesSection/SpeciesSection'

type AttackerPokemonSectionProps = {
  pokemonId: string
  name: string
  types: TypeId[]
  baseStats: BaseStats
  weight: number
  removable: boolean
  onClickRemove: () => void
  onChangePokemonId: (value: string) => void
  dataTestIdPokemonPicker?: string
}

export const AttackerPokemonSection = memo(
  ({
    pokemonId,
    name,
    types,
    baseStats,
    weight,
    removable,
    onClickRemove,
    onChangePokemonId,
    dataTestIdPokemonPicker,
  }: AttackerPokemonSectionProps) => {
    return (
      <>
        <Flex justifyContent="space-between">
          <SpeciesSection
            pokemonId={pokemonId}
            types={types}
            baseStats={baseStats}
            weight={weight}
          />
          {removable && <RemoveButton onClick={onClickRemove} />}
        </Flex>
        <Box>
          <PokemonsPicker
            value={pokemonId}
            onChange={onChangePokemonId}
            dataTestId={dataTestIdPokemonPicker}
          />
        </Box>
      </>
    )
  }
)
