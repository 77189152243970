import { Box, Flex } from '@chakra-ui/react'
import { BaseMove } from '../../../domain/BaseMove'

type MoveDisplayProps = {
  move: BaseMove
  movePower: string
  moveDisplayCategory: string
  attackTimes: number
}

export const MoveDisplay = ({
  move,
  movePower,
  moveDisplayCategory,
  attackTimes,
}: MoveDisplayProps) => {
  return (
    <Flex gap="10px">
      <Box>{move.name}</Box>
      {attackTimes !== 1 && <Box>{attackTimes}回</Box>}
      <Box>威力{movePower}</Box>
      <Box>{moveDisplayCategory}</Box>
    </Flex>
  )
}
