import { Box } from '@chakra-ui/react'
import { memo } from 'react'
import { BaseStats } from '../../../../domain/Stat'
import { TypeId } from '../../../../domain/Type'
import { PokeIcon } from '../../../common/PokeIcon'

import { BaseStatsSection } from './BaseStatsSection'
import { TypeSection } from './TypeSection'

type SpeciesSectionProps = {
  pokemonId: string
  types: TypeId[]
  baseStats: BaseStats
  weight: number
}
export const SpeciesSection = memo(
  ({ pokemonId, types, baseStats, weight }: SpeciesSectionProps) => {
    return (
      <Box display="flex">
        <PokeIcon pokemonId={pokemonId} width="70px" />
        <div>
          <TypeSection types={types} />
          <BaseStatsSection
            hp={baseStats.hp}
            attack={baseStats.attack}
            deffense={baseStats.defense}
            specialAttack={baseStats.specialAttack}
            specialDeffense={baseStats.specialDefense}
            speed={baseStats.speed}
            weight={weight}
          />
        </div>
      </Box>
    )
  }
)
