import { Flex } from '@chakra-ui/react'
import React, { memo, useCallback } from 'react'

export const OptionBox = memo(
  ({
    children,
    value,
    isHovered,
    isSelected,
    onClick,
  }: {
    children: React.ReactNode
    value: string
    isHovered: boolean
    isSelected: boolean
    onClick: (value: string) => void
  }) => {
    const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
      (e) => {
        onClick(value)
        e.stopPropagation()
      },
      [onClick, value]
    )

    const bgColor = isSelected
      ? 'rgb(175, 205, 177)'
      : isHovered
      ? 'LightGray'
      : 'inherit'

    return (
      <Flex
        cursor="pointer"
        paddingLeft="10px"
        minHeight="35px"
        height="35px"
        alignItems="center"
        backgroundColor={bgColor}
        _hover={{ backgroundColor: '#f3f6f9' }}
        onMouseDown={handleClick}
      >
        {children}
      </Flex>
    )
  }
)
