import { BaseMove as Move } from '../BaseMove'
import { typesId } from '../Type'

export const moves: Move[] = [
  {
    id: 858,
    name: '3ぼんのや',
    power: 90,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 905,
    name: 'アーマーキャノン',
    power: 120,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 359,
    name: 'アームハンマー',
    power: 100,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 231,
    name: 'アイアンテール',
    power: 100,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 442,
    name: 'アイアンヘッド',
    power: 80,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 813,
    name: 'アイアンローラー',
    power: 130,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 876,
    name: 'アイススピナー',
    power: 80,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 641,
    name: 'アイスハンマー',
    power: 100,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 910,
    name: 'アクアカッター',
    power: 70,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 453,
    name: 'アクアジェット',
    power: 40,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 887,
    name: 'アクアステップ',
    power: 80,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 401,
    name: 'アクアテール',
    power: 90,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 664,
    name: 'アクアブレイク',
    power: 85,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 460,
    name: 'あくうせつだん',
    power: 100,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 893,
    name: 'アクセルブレイク',
    power: 100,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 626,
    name: 'アクセルロック',
    power: 40,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 399,
    name: 'あくのはどう',
    power: 80,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 512,
    name: 'アクロバット',
    power: 55,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 500,
    name: 'アシストパワー',
    power: 20,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 491,
    name: 'アシッドボム',
    power: 40,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 840,
    name: 'アストラルビット',
    power: 120,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 91,
    name: 'あなをほる',
    power: 80,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 37,
    name: 'あばれる',
    power: 120,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 832,
    name: 'あんこくきょうだ',
    power: 75,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 15,
    name: 'いあいぎり',
    power: 50,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 492,
    name: 'イカサマ',
    power: 95,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'defenderAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 615,
    name: 'いじげんホール',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 616,
    name: 'いじげんラッシュ',
    power: 100,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 871,
    name: 'いっちょうあがり',
    power: 80,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 836,
    name: 'いてつくしせん',
    power: 90,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 894,
    name: 'イナズマドライブ',
    power: 100,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 547,
    name: 'いにしえのうた',
    power: 75,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 173,
    name: 'いびき',
    power: 50,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 88,
    name: 'いわおとし',
    power: 50,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 249,
    name: 'いわくだき',
    power: 40,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 157,
    name: 'いわなだれ',
    power: 75,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 370,
    name: 'インファイト',
    power: 120,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 849,
    name: 'ウェーブタックル',
    power: 120,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 311,
    name: 'ウェザーボール',
    power: 50,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 250,
    name: 'うずしお',
    power: 35,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 479,
    name: 'うちおとす',
    power: 50,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 452,
    name: 'ウッドハンマー',
    power: 120,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 532,
    name: 'ウッドホーン',
    power: 75,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 823,
    name: 'うっぷんばらし',
    power: 75,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 856,
    name: 'うらみつらみ',
    power: 75,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 314,
    name: 'エアカッター',
    power: 60,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 403,
    name: 'エアスラッシュ',
    power: 75,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: true,
  },
  {
    id: 497,
    name: 'エコーボイス',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 767,
    name: 'えだづき',
    power: 40,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 412,
    name: 'エナジーボール',
    power: 90,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 527,
    name: 'エレキネット',
    power: 55,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 768,
    name: 'オーバードライブ',
    power: 80,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 315,
    name: 'オーバーヒート',
    power: 130,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 855,
    name: 'オーラウイング',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 62,
    name: 'オーロラビーム',
    power: 65,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 310,
    name: 'おどろかす',
    power: 30,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 869,
    name: 'おはかまいり',
    power: 50,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 70,
    name: 'かいりき',
    power: 80,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 172,
    name: 'かえんぐるま',
    power: 60,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 53,
    name: 'かえんほうしゃ',
    power: 90,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 762,
    name: 'かえんボール',
    power: 120,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 868,
    name: 'かかとおとし',
    power: 120,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 425,
    name: 'かげうち',
    power: 40,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 622,
    name: 'かげぬい',
    power: 80,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 16,
    name: 'かぜおこし',
    power: 40,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 514,
    name: 'かたきうち',
    power: 70,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 642,
    name: 'かふんだんご',
    power: 90,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 242,
    name: 'かみくだく',
    power: 80,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 44,
    name: 'かみつく',
    power: 60,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 87,
    name: 'かみなり',
    power: 110,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 862,
    name: 'かみなりあらし',
    power: 100,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 422,
    name: 'かみなりのキバ',
    power: 65,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 9,
    name: 'かみなりパンチ',
    power: 75,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 263,
    name: 'からげんき',
    power: 70,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 280,
    name: 'かわらわり',
    power: 75,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 614,
    name: 'ガリョウテンセイ',
    power: 120,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 845,
    name: 'がんせきアックス',
    power: 65,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 317,
    name: 'がんせきふうじ',
    power: 60,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 411,
    name: 'きあいだま',
    power: 120,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 264,
    name: 'きあいパンチ',
    power: 150,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 141,
    name: 'きゅうけつ',
    power: 80,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 877,
    name: 'きょけんとつげき',
    power: 120,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 763,
    name: 'きょじゅうざん',
    power: 100,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 764,
    name: 'きょじゅうだん',
    power: 100,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 881,
    name: 'キラースピン',
    power: 30,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 163,
    name: 'きりさく',
    power: 70,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 416,
    name: 'ギガインパクト',
    power: 150,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 202,
    name: 'ギガドレイン',
    power: 75,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 827,
    name: 'クイックターン',
    power: 60,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 520,
    name: 'くさのちかい',
    power: 80,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 447,
    name: 'くさむすび',
    power: 0,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 900,
    name: 'くさわけ',
    power: 50,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 728,
    name: 'くらいつく',
    power: 80,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 152,
    name: 'クラブハンマー',
    power: 100,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 499,
    name: 'クリアスモッグ',
    power: 50,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 238,
    name: 'クロスチョップ',
    power: 100,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 440,
    name: 'クロスポイズン',
    power: 70,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: true,
  },
  {
    id: 850,
    name: 'クロロブラスト',
    power: 150,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 818,
    name: 'グラススライダー',
    power: 60,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 67,
    name: 'けたぐり',
    power: 0,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 200,
    name: 'げきりん',
    power: 120,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 599,
    name: 'ゲップ',
    power: 120,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 246,
    name: 'げんしのちから',
    power: 60,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 454,
    name: 'こうげきしれい',
    power: 90,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 229,
    name: 'こうそくスピン',
    power: 50,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 524,
    name: 'こおりのいぶき',
    power: 60,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 423,
    name: 'こおりのキバ',
    power: 65,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 420,
    name: 'こおりのつぶて',
    power: 40,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 861,
    name: 'こがらしあらし',
    power: 100,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 196,
    name: 'こごえるかぜ',
    power: 55,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 181,
    name: 'こなゆき',
    power: 40,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 621,
    name: 'このは',
    power: 40,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 309,
    name: 'コメットパンチ',
    power: 90,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 205,
    name: 'ころがる',
    power: 30,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 613,
    name: 'こんげんのはどう',
    power: 110,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 591,
    name: 'ゴーストダイブ',
    power: 90,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 889,
    name: 'ゴールドラッシュ',
    power: 120,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 143,
    name: 'ゴッドバード',
    power: 140,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 60,
    name: 'サイケこうせん',
    power: 65,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 427,
    name: 'サイコカッター',
    power: 70,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 94,
    name: 'サイコキネシス',
    power: 90,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 473,
    name: 'サイコショック',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 666,
    name: 'サイコファング',
    power: 85,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 540,
    name: 'サイコブレイク',
    power: 100,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 449,
    name: 'さばきのつぶて',
    power: 100,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 253,
    name: 'さわぐ',
    power: 90,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 834,
    name: 'サンダープリズン',
    power: 80,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 816,
    name: 'シェルアームズ',
    power: 90,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 534,
    name: 'シェルブレード',
    power: 75,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: true,
  },
  {
    id: 879,
    name: 'しおづけ',
    power: 40,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 323,
    name: 'しおふき',
    power: 150,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 362,
    name: 'しおみず',
    power: 65,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 404,
    name: 'シザークロス',
    power: 80,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 122,
    name: 'したでなめる',
    power: 30,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 822,
    name: 'しっとのほのお',
    power: 70,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 371,
    name: 'しっぺがえし',
    power: 50,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 428,
    name: 'しねんのずつき',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 20,
    name: 'しめつける',
    power: 15,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 421,
    name: 'シャドークロー',
    power: 70,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 467,
    name: 'シャドーダイブ',
    power: 120,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 325,
    name: 'シャドーパンチ',
    power: 60,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 247,
    name: 'シャドーボール',
    power: 80,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 410,
    name: 'しんくうは',
    power: 40,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 245,
    name: 'しんそく',
    power: 80,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 847,
    name: 'しんぴのちから',
    power: 70,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 770,
    name: 'Gのちから',
    power: 80,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 872,
    name: 'ジェットパンチ',
    power: 60,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 663,
    name: 'じごくづき',
    power: 80,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 89,
    name: 'じしん',
    power: 100,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 655,
    name: 'じだんだ',
    power: 75,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 523,
    name: 'じならし',
    power: 60,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 120,
    name: 'じばく',
    power: 200,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 587,
    name: 'じゃれつく',
    power: 90,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 659,
    name: '10まんばりき',
    power: 95,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 85,
    name: '10まんボルト',
    power: 90,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 326,
    name: 'じんつうりき',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 541,
    name: 'スイープビンタ',
    power: 25,
    type: typesId.NORMAL,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 71,
    name: 'すいとる',
    power: 20,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 833,
    name: 'すいりゅうれんだ',
    power: 25,
    type: typesId.WATER,
    attackTimes: [3],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 814,
    name: 'スケイルショット',
    power: 25,
    type: typesId.DRAGON,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 617,
    name: 'スチームバースト',
    power: 110,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 38,
    name: 'すてみタックル',
    power: 120,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 444,
    name: 'ストーンエッジ',
    power: 100,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 328,
    name: 'すなじごく',
    power: 35,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 209,
    name: 'スパーク',
    power: 65,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 129,
    name: 'スピードスター',
    power: 60,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 653,
    name: 'スマートホーン',
    power: 70,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 123,
    name: 'スモッグ',
    power: 30,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 29,
    name: 'ずつき',
    power: 70,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 533,
    name: 'せいなるつるぎ',
    power: 90,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 76,
    name: 'ソーラービーム',
    power: 120,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 627,
    name: 'ソーラーブレード',
    power: 125,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 771,
    name: 'ソウルクラッシュ',
    power: 75,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 19,
    name: 'そらをとぶ',
    power: 90,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 33,
    name: 'たいあたり',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 127,
    name: 'たきのぼり',
    power: 80,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 21,
    name: 'たたきつける',
    power: 80,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 506,
    name: 'たたりめ',
    power: 65,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 239,
    name: 'たつまき',
    power: 40,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 402,
    name: 'タネばくだん',
    power: 80,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 331,
    name: 'タネマシンガン',
    power: 25,
    type: typesId.GRASS,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 414,
    name: 'だいちのちから',
    power: 90,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 820,
    name: 'だいちのはどう',
    power: 50,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 153,
    name: 'だいばくはつ',
    power: 250,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 291,
    name: 'ダイビング',
    power: 80,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 848,
    name: 'だいふんげき',
    power: 120,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 726,
    name: 'ダイマックスほう',
    power: 100,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 126,
    name: 'だいもんじ',
    power: 110,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 611,
    name: 'ダイヤストーム',
    power: 100,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 330,
    name: 'だくりゅう',
    power: 90,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 441,
    name: 'ダストシュート',
    power: 120,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 458,
    name: 'ダブルアタック',
    power: 35,
    type: typesId.NORMAL,
    attackTimes: [2],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 829,
    name: 'ダブルウイング',
    power: 40,
    type: typesId.FLYING,
    attackTimes: [2],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 372,
    name: 'ダメおし',
    power: 60,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 612,
    name: 'だんがいのつるぎ',
    power: 120,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 451,
    name: 'チャージビーム',
    power: 50,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 566,
    name: 'チャームボイス',
    power: 40,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 365,
    name: 'ついばむ',
    power: 60,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 903,
    name: 'ツインビーム',
    power: 40,
    type: typesId.PSYCHIC,
    attackTimes: [2],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 656,
    name: 'つけあがる',
    power: 20,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 400,
    name: 'つじぎり',
    power: 70,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 292,
    name: 'つっぱり',
    power: 15,
    type: typesId.FIGHTING,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 64,
    name: 'つつく',
    power: 35,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 30,
    name: 'つのでつく',
    power: 65,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 17,
    name: 'つばさでうつ',
    power: 60,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 332,
    name: 'つばめがえし',
    power: 60,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 556,
    name: 'つららおとし',
    power: 85,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 333,
    name: 'つららばり',
    power: 25,
    type: typesId.ICE,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 22,
    name: 'つるのムチ',
    power: 45,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 610,
    name: 'てかげん',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 778,
    name: 'てっていこうせん',
    power: 140,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 866,
    name: 'テラバースト',
    power: 80,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'teraBlast',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 646,
    name: 'であいがしら',
    power: 90,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 623,
    name: 'DDラリアット',
    power: 85,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 908,
    name: 'デカハンマー',
    power: 160,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 84,
    name: 'でんきショック',
    power: 40,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 351,
    name: 'でんげきは',
    power: 60,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 98,
    name: 'でんこうせっか',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 907,
    name: 'でんこうそうげき',
    power: 120,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 192,
    name: 'でんじほう',
    power: 120,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 459,
    name: 'ときのほうこう',
    power: 150,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 36,
    name: 'とっしん',
    power: 90,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 387,
    name: 'とっておき',
    power: 140,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 579,
    name: 'とどめばり',
    power: 50,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 665,
    name: 'とびかかる',
    power: 80,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 899,
    name: 'とびつく',
    power: 50,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 340,
    name: 'とびはねる',
    power: 85,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 136,
    name: 'とびひざげり',
    power: 130,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 509,
    name: 'ともえなげ',
    power: 60,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 161,
    name: 'トライアタック',
    power: 80,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 885,
    name: 'トリックフラワー',
    power: 70,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 828,
    name: 'トリプルアクセル',
    power: 20,
    type: typesId.ICE,
    attackTimes: [3],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 880,
    name: 'トリプルダイブ',
    power: 30,
    type: typesId.WATER,
    attackTimes: [3],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 628,
    name: 'トロピカルキック',
    power: 70,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 369,
    name: 'とんぼがえり',
    power: 70,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 398,
    name: 'どくづき',
    power: 80,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 305,
    name: 'どくどくのキバ',
    power: 50,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 40,
    name: 'どくばり',
    power: 15,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 854,
    name: 'どくばりセンボン',
    power: 60,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 775,
    name: 'どげざつき',
    power: 80,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 884,
    name: 'ドゲザン',
    power: 85,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 733,
    name: 'ドラゴンアロー',
    power: 50,
    type: typesId.DRAGON,
    attackTimes: [2],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 835,
    name: 'ドラゴンエナジー',
    power: 150,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 337,
    name: 'ドラゴンクロー',
    power: 80,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 407,
    name: 'ドラゴンダイブ',
    power: 100,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 525,
    name: 'ドラゴンテール',
    power: 60,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 760,
    name: 'ドラムアタック',
    power: 80,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 65,
    name: 'ドリルくちばし',
    power: 80,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 529,
    name: 'ドリルライナー',
    power: 80,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 564,
    name: 'ドレインキッス',
    power: 50,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 409,
    name: 'ドレインパンチ',
    power: 75,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 189,
    name: 'どろかけ',
    power: 20,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 168,
    name: 'どろぼう',
    power: 60,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 539,
    name: 'ナイトバースト',
    power: 85,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 57,
    name: 'なみのり',
    power: 90,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 488,
    name: 'ニトロチャージ',
    power: 50,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 24,
    name: 'にどげり',
    power: 30,
    type: typesId.FIGHTING,
    attackTimes: [2],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 252,
    name: 'ねこだまし',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 6,
    name: 'ネコにこばん',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 875,
    name: 'ネズミざん',
    power: 20,
    type: typesId.NORMAL,
    attackTimes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 863,
    name: 'ねっさのあらし',
    power: 100,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 830,
    name: 'ねっさのだいち',
    power: 70,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 503,
    name: 'ねっとう',
    power: 80,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 257,
    name: 'ねっぷう',
    power: 95,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 727,
    name: 'ねらいうち',
    power: 80,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 93,
    name: 'ねんりき',
    power: 50,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 34,
    name: 'のしかかり',
    power: 85,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 338,
    name: 'ハードプラント',
    power: 150,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 308,
    name: 'ハイドロカノン',
    power: 150,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 56,
    name: 'ハイドロポンプ',
    power: 110,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 902,
    name: 'ハイパードリル',
    power: 100,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 304,
    name: 'ハイパーボイス',
    power: 90,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 821,
    name: 'はいよるいちげき',
    power: 70,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 63,
    name: 'はかいこうせん',
    power: 150,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 211,
    name: 'はがねのつばさ',
    power: 70,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 11,
    name: 'はさむ',
    power: 55,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 282,
    name: 'はたきおとす',
    power: 65,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 1,
    name: 'はたく',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 395,
    name: 'はっけい',
    power: 60,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 75,
    name: 'はっぱカッター',
    power: 55,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 396,
    name: 'はどうだん',
    power: 80,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 80,
    name: 'はなびらのまい',
    power: 120,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 577,
    name: 'はなふぶき',
    power: 90,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 846,
    name: 'はるのあらし',
    power: 100,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 555,
    name: 'バークアウト',
    power: 55,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 276,
    name: 'ばかぢから',
    power: 120,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 605,
    name: 'ばくおんぱ',
    power: 140,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 223,
    name: 'ばくれつパンチ',
    power: 100,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 61,
    name: 'バブルこうせん',
    power: 65,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 843,
    name: 'バリアーラッシュ',
    power: 70,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 418,
    name: 'バレットパンチ',
    power: 40,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 569,
    name: 'パラボラチャージ',
    power: 65,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 438,
    name: 'パワーウィップ',
    power: 120,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 408,
    name: 'パワージェム',
    power: 80,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 535,
    name: 'ヒートスタンプ',
    power: 0,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 860,
    name: 'ひけん・ちえなみ',
    power: 65,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 10,
    name: 'ひっかく',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 52,
    name: 'ひのこ',
    power: 40,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 859,
    name: 'ひゃっきやこう',
    power: 60,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 901,
    name: 'ひやみず',
    power: 50,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 851,
    name: 'ひょうざんおろし',
    power: 100,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 649,
    name: 'びりびりちくちく',
    power: 80,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 389,
    name: 'ふいうち',
    power: 70,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 842,
    name: 'フェイタルクロー',
    power: 80,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 364,
    name: 'フェイント',
    power: 30,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 59,
    name: 'ふぶき',
    power: 110,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 23,
    name: 'ふみつけ',
    power: 65,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 574,
    name: 'フライングプレス',
    power: 100,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 586,
    name: 'フリーズドライ',
    power: 70,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 639,
    name: 'フルールカノン',
    power: 130,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 886,
    name: 'フレアソング',
    power: 80,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 394,
    name: 'フレアドライブ',
    power: 120,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 436,
    name: 'ふんえん',
    power: 80,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 284,
    name: 'ふんか',
    power: 150,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 904,
    name: 'ふんどのこぶし',
    power: 50,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 557,
    name: 'Vジェネレート',
    power: 180,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 841,
    name: 'ぶきみなじゅもん',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 853,
    name: 'ぶちかまし',
    power: 120,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 307,
    name: 'ブラストバーン',
    power: 150,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 839,
    name: 'ブリザードランス',
    power: 120,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 306,
    name: 'ブレイククロー',
    power: 75,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 299,
    name: 'ブレイズキック',
    power: 85,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 413,
    name: 'ブレイブバード',
    power: 120,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 652,
    name: 'ぶんまわす',
    power: 60,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 482,
    name: 'ヘドロウェーブ',
    power: 95,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 124,
    name: 'ヘドロこうげき',
    power: 65,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 188,
    name: 'ヘドロばくだん',
    power: 90,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 484,
    name: 'ヘビーボンバー',
    power: 0,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 474,
    name: 'ベノムショック',
    power: 65,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 874,
    name: 'ホイールスピン',
    power: 100,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 435,
    name: 'ほうでん',
    power: 80,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 343,
    name: 'ほしがる',
    power: 60,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 565,
    name: 'ほっぺすりすり',
    power: 20,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 83,
    name: 'ほのおのうず',
    power: 35,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 424,
    name: 'ほのおのキバ',
    power: 65,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 519,
    name: 'ほのおのちかい',
    power: 80,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 7,
    name: 'ほのおのパンチ',
    power: 75,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 552,
    name: 'ほのおのまい',
    power: 80,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 669,
    name: 'ほのおのムチ',
    power: 80,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 198,
    name: 'ボーンラッシュ',
    power: 25,
    type: typesId.GROUND,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 542,
    name: 'ぼうふう',
    power: 110,
    type: typesId.FLYING,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 758,
    name: 'ボディプレス',
    power: 80,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerDefense',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 344,
    name: 'ボルテッカー',
    power: 120,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 521,
    name: 'ボルトチェンジ',
    power: 70,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 342,
    name: 'ポイズンテール',
    power: 50,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 824,
    name: 'ポルターガイスト',
    power: 110,
    type: typesId.GHOST,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 35,
    name: 'まきつく',
    power: 15,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 463,
    name: 'マグマストーム',
    power: 100,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 580,
    name: 'マジカルシャイン',
    power: 80,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 572,
    name: 'マジカルフレイム',
    power: 75,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 345,
    name: 'マジカルリーフ',
    power: 60,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 341,
    name: 'マッドショット',
    power: 55,
    type: typesId.GROUND,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 183,
    name: 'マッハパンチ',
    power: 40,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 562,
    name: 'まとわりつく',
    power: 20,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 42,
    name: 'ミサイルばり',
    power: 25,
    type: typesId.BUG,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 817,
    name: 'ミストバースト',
    power: 100,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 570,
    name: 'みずしゅりけん',
    power: 15,
    type: typesId.WATER,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 55,
    name: 'みずでっぽう',
    power: 40,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 518,
    name: 'みずのちかい',
    power: 80,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 352,
    name: 'みずのはどう',
    power: 60,
    type: typesId.WATER,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 31,
    name: 'みだれづき',
    power: 15,
    type: typesId.NORMAL,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 154,
    name: 'みだれひっかき',
    power: 18,
    type: typesId.NORMAL,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 206,
    name: 'みねうち',
    power: 40,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 248,
    name: 'みらいよち',
    power: 120,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 578,
    name: 'ムーンフォース',
    power: 95,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 450,
    name: 'むしくい',
    power: 60,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 405,
    name: 'むしのさざめき',
    power: 90,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 522,
    name: 'むしのていこう',
    power: 50,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 906,
    name: 'むねんのつるぎ',
    power: 90,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 25,
    name: 'メガトンキック',
    power: 120,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 5,
    name: 'メガトンパンチ',
    power: 80,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 72,
    name: 'メガドレイン',
    power: 40,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 224,
    name: 'メガホーン',
    power: 120,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 625,
    name: 'めざめるダンス',
    power: 90,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 232,
    name: 'メタルクロー',
    power: 50,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 815,
    name: 'メテオビーム',
    power: 120,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 837,
    name: 'もえあがるいかり',
    power: 90,
    type: typesId.DARK,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 657,
    name: 'もえつきる',
    power: 130,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 457,
    name: 'もろはのずつき',
    power: 150,
    type: typesId.ROCK,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 510,
    name: 'やきつくす',
    power: 60,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 419,
    name: 'ゆきなだれ',
    power: 60,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 138,
    name: 'ゆめくい',
    power: 100,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 51,
    name: 'ようかいえき',
    power: 40,
    type: typesId.POISON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 561,
    name: 'ようせいのかぜ',
    power: 40,
    type: typesId.FAIRY,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 819,
    name: 'ライジングボルト',
    power: 70,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 838,
    name: 'らいめいげり',
    power: 90,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 430,
    name: 'ラスターカノン',
    power: 80,
    type: typesId.STEEL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 437,
    name: 'リーフストーム',
    power: 130,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 348,
    name: 'リーフブレード',
    power: 90,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 434,
    name: 'りゅうせいぐん',
    power: 130,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 225,
    name: 'りゅうのいぶき',
    power: 60,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 406,
    name: 'りゅうのはどう',
    power: 85,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 769,
    name: 'りんごさん',
    power: 80,
    type: typesId.GRASS,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 496,
    name: 'りんしょう',
    power: 60,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: true,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 870,
    name: 'ルミナコリジョン',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 888,
    name: 'レイジングブル',
    power: 90,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 8,
    name: 'れいとうパンチ',
    power: 75,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: true,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 58,
    name: 'れいとうビーム',
    power: 90,
    type: typesId.ICE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 517,
    name: 'れんごく',
    power: 100,
    type: typesId.FIRE,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 210,
    name: 'れんぞくぎり',
    power: 40,
    type: typesId.BUG,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: true,
  },
  {
    id: 490,
    name: 'ローキック',
    power: 65,
    type: typesId.FIGHTING,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 350,
    name: 'ロックブラスト',
    power: 25,
    type: typesId.ROCK,
    attackTimes: [2, 3, 4, 5],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 812,
    name: 'ワイドフォース',
    power: 80,
    type: typesId.PSYCHIC,
    attackTimes: [1],
    attackReference: 'attackerSpecialAttack',
    defenseReference: 'defenderSpecialDefense',
    displayCategory: '特殊',
    isDirect: false,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 766,
    name: 'ワイドブレイカー',
    power: 60,
    type: typesId.DRAGON,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: true,
    isSlashing: false,
  },
  {
    id: 528,
    name: 'ワイルドボルト',
    power: 90,
    type: typesId.ELECTRIC,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: true,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
  {
    id: 165,
    name: 'わるあがき',
    power: 50,
    type: typesId.NORMAL,
    attackTimes: [1],
    attackReference: 'attackerAttack',
    defenseReference: 'defenderDefense',
    displayCategory: '物理',
    isDirect: true,
    isPunch: false,
    hasRecoilDamage: false,
    isSound: false,
    hasAdditionalEffect: false,
    isSlashing: false,
  },
]
