import { Box, Flex } from '@chakra-ui/react'
import { memo } from 'react'
import { BaseStats } from '../../../domain/Stat'
import { TypeId } from '../../../domain/Type'
import { PokemonsPicker } from '../../common/PokemonPicker'
import { TypesPicker } from '../../common/TypesPicker'
import { RemoveButton } from '../../common/RemoveButton'
import { SpeciesSection } from '../common/SpeciesSection/SpeciesSection'

type DefenderPokemonSectionProps = {
  pokemonId: string
  name: string
  baseTypeIds: TypeId[]
  typeIds: TypeId[]
  weight: number
  baseStats: BaseStats
  removable: boolean
  isBaseTypeCustomEnabled?: boolean
  onClickRemove: () => void
  onChangePokemonId: (value: string) => void
  onChangeTypeIds: (value: TypeId[]) => void
  dataTestIdPokemonPicker?: string
}

export const DefenderPokemonSection = memo(
  ({
    pokemonId,
    name,
    baseTypeIds,
    typeIds,
    weight,
    baseStats,
    removable,
    isBaseTypeCustomEnabled = false,
    onClickRemove,
    onChangePokemonId,
    onChangeTypeIds,
    dataTestIdPokemonPicker,
  }: DefenderPokemonSectionProps) => {
    return (
      <>
        <Flex justifyContent="space-between">
          <SpeciesSection
            pokemonId={pokemonId}
            types={baseTypeIds}
            baseStats={baseStats}
            weight={weight}
          />
          {removable && <RemoveButton onClick={onClickRemove} />}
        </Flex>
        {isBaseTypeCustomEnabled && (
          <Box>
            <TypesPicker value={typeIds} onChange={onChangeTypeIds} />
          </Box>
        )}

        <Box>
          <PokemonsPicker
            value={pokemonId}
            onChange={onChangePokemonId}
            dataTestId={dataTestIdPokemonPicker}
          />
        </Box>
      </>
    )
  }
)
