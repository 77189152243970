import { Box } from '@chakra-ui/react'
import { memo } from 'react'

type ScrollBoxProps = {
  children: React.ReactNode
  width?: string
  minWidth?: string
  flex?: string
  height?: string
  maxHeight?: string
  alwaysDisplayScrollBar?: boolean
}

export const ScrollBox = memo(
  ({
    children,
    width,
    minWidth,
    flex,
    height,
    maxHeight,
    alwaysDisplayScrollBar,
  }: ScrollBoxProps) => {
    return (
      <Box
        padding="5px 5px 5px 0"
        height={height}
        maxHeight={maxHeight}
        minWidth={minWidth}
        width={width}
        flex={flex}
      >
        <Box
          width="100%"
          overflow="auto"
          height="100%"
          maxHeight="100%"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: alwaysDisplayScrollBar ? 'Gray' : 'transparent',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              borderRadius: '8px',
              background: 'Gray',
            },
          }}
        >
          {children}
        </Box>
      </Box>
    )
  }
)
