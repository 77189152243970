import {
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react'

type EffortValueSliderProps = {
  value: number
  onChange: (value: number) => void
  indivisualValue: number
  dataTestId: string
}

export const EffortValueSlider = (props: EffortValueSliderProps) => {
  const isIndivisualValueOddNumber = props.indivisualValue % 2 !== 0
  const onChange = (value: number) => {
    if (isIndivisualValueOddNumber) {
      if (value === 0) {
        props.onChange(0)
      } else if (value === 1) {
        props.onChange(4)
      } else {
        props.onChange(4 + (value - 1) * 8)
      }
    } else {
      props.onChange(value * 8)
    }
  }
  return (
    <Slider
      min={0}
      max={isIndivisualValueOddNumber ? 32 : 31}
      step={1}
      value={calcStatsDiff({
        isIndivisualValueOddNumber,
        effortValue: props.value,
      })}
      onChange={onChange}
      data-testid={props.dataTestId}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  )
}

const calcStatsDiff = (args: {
  isIndivisualValueOddNumber: boolean
  effortValue: number
}) => {
  if (args.isIndivisualValueOddNumber) {
    if (args.effortValue === 0) {
      return 0
    } else if (args.effortValue < 5) {
      return 1
    } else {
      return 1 + (args.effortValue - 4) / 8
    }
  } else {
    return args.effortValue / 8
  }
}
