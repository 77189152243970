import { Dispatch, memo, useCallback } from 'react'
import { Defender, DefendersAction } from './useDefendersState'
import {
  Card,
  CardBody,
  Box,
  Divider,
  Stack,
  Flex,
  IconButton,
} from '@chakra-ui/react'
import { DefenderStatsSection } from './DefenderStatsSection'
import { DamageCalcResult } from '../../common/DamageCalcResult/DamageCalcResult'
import { 最終ダメージ計算結果 } from '../../../domain/DamageFormula'
import { calcHPStat } from '../../../domain/StatsFormula'
import { AbilityPicker } from '../../common/AbilityPicker'
import { HeldItemPicker } from '../../common/HeldItemPicker'
import { WeatherPicker } from '../../common/WeatherPicker'
import { TerrainPicker } from '../../common/TerrainPicker'
import { Checkbox } from '../../common/Checkbox'
import { TypeId } from '../../../domain/Type'
import { StatusConditions } from '../../../domain/StatusCondition'
import { StatusConditionSection } from '../common/StatusConditionSection'
import { DefenderPokemonSection } from './DefenderPokemonSection'
import { TeraTypePicker } from '../common/TeraTypePicker'
import { Pokemon } from '../../../domain/Pokemon'
import { BaseStats } from '../../../domain/Stat'
import { TriangleUpIcon } from '@chakra-ui/icons'
import { ConstantDamageSection } from './ConstantDamageSection'

type DefenderFormProps = {
  removable: boolean
  id: number
  isIncludeDefenderAttackReference: boolean

  defender: Defender
  dispatch: Dispatch<DefendersAction>
  result: 最終ダメージ計算結果
  pokemon: Pokemon
  baseStats: BaseStats
  onCloseClick: () => void
}

export const DefenderForm = memo(
  ({
    removable,
    id,
    isIncludeDefenderAttackReference,
    defender,
    dispatch,
    result,
    pokemon,
    baseStats,
    onCloseClick,
  }: DefenderFormProps) => {
    const onChangePokemonId = useCallback(
      (value: string) => {
        dispatch({
          type: 'changePokemonId',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onClickRemove = useCallback(() => {
      dispatch({
        type: 'remove',
        payload: { id },
      })
    }, [dispatch, id])
    const onChangeAbility = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeAbility',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeHeldItem = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeHeldItem',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeWeatherId = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeWeatherId',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeTerrainId = useCallback(
      (value: number) => {
        dispatch({
          type: 'changeTerrainId',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeHasReflect = useCallback(
      (value: boolean) => {
        dispatch({
          type: 'changeHasReflect',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeHasLightScreen = useCallback(
      (value: boolean) => {
        dispatch({
          type: 'changeHasLightScreen',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const onChangeTypeIds = useCallback(
      (value: TypeId[]) => {
        dispatch({
          type: 'changeTypeIds',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    const handleChangeStatusCondition = useCallback(
      (key: keyof StatusConditions, value: boolean) => {
        dispatch({
          type: 'changeStatusCondition',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeTeraType = useCallback(
      (value?: TypeId) => {
        dispatch({
          type: 'changeTeraType',
          payload: { id, value },
        })
      },
      [dispatch, id]
    )
    return (
      <Card>
        <CardBody>
          <Stack spacing={3}>
            <DefenderPokemonSection
              pokemonId={defender.pokemonId}
              name={pokemon.name}
              baseTypeIds={pokemon.types}
              typeIds={defender.typeIds}
              baseStats={baseStats}
              weight={pokemon.weight}
              removable={removable}
              onClickRemove={onClickRemove}
              onChangePokemonId={onChangePokemonId}
              onChangeTypeIds={onChangeTypeIds}
              dataTestIdPokemonPicker={'defender-pokemon-picker-' + String(id)}
            />
            <TeraTypePicker
              value={defender.teraType}
              onChange={handleChangeTeraType}
              dataTestIdPrefix={`defender-${id}-`}
            />
            <Divider />
            <DefenderStatsSection
              id={id}
              level={defender.level}
              baseStats={baseStats}
              indivisualValues={defender.indivisualValues}
              effortValues={defender.effortValues}
              natureCorrections={defender.natureCorrections}
              statStages={defender.statStages}
              isIncludeDefenderAttackReference={
                isIncludeDefenderAttackReference
              }
              dispatch={dispatch}
            />
            <Flex gap="10px" alignItems="center">
              <Box flex="1">
                <AbilityPicker
                  value={defender.abilityId}
                  onChange={onChangeAbility}
                  category="defensive"
                  defaultAbilityNames={pokemon.abilities}
                  dataTestId={`defender-${id}-abilityPicker`}
                />
              </Box>
              <Box flex="1">
                <HeldItemPicker
                  value={defender.heldItemId}
                  onChange={onChangeHeldItem}
                  category="defensive"
                  dataTestId={`defender-${id}-heldItemPicker`}
                />
              </Box>
            </Flex>
            <Flex gap="30px">
              <Checkbox
                value={defender.hasReflect}
                onChange={onChangeHasReflect}
                label="リフレクター"
              />
              <Checkbox
                value={defender.hasReflect}
                onChange={onChangeHasLightScreen}
                label="ひかりのかべ"
              />
            </Flex>
            <StatusConditionSection
              displayBurn
              displayPoison
              statusConditions={defender.statusConditions}
              onChange={handleChangeStatusCondition}
            />
            <ConstantDamageSection
              id={defender.id}
              pokemon={pokemon}
              constantDamages={defender.constantDamages}
              dispatch={dispatch}
            />
            <Flex gap="10px" alignItems="center">
              <Box flex="1">
                <WeatherPicker
                  value={defender.weatherId}
                  onChange={onChangeWeatherId}
                />
              </Box>
              <Box flex="1">
                <TerrainPicker
                  value={defender.terrainId}
                  onChange={onChangeTerrainId}
                />
              </Box>
            </Flex>
            <Divider />
            <Box>
              <DamageCalcResult
                result={result}
                deffenseHp={calcHPStat({
                  level: 50,
                  baseStat: pokemon.hp,
                  indivisualValue: defender.indivisualValues.hp,
                  effortValue: defender.effortValues.hp,
                })}
                dataTestIdMinDamage={'min-damage-' + String(id)}
                dataTestIdMaxDamage={'max-damage-' + String(id)}
                dataTestIdPrefix={`defender-${id}`}
              />
            </Box>
            <IconButton
              onClick={onCloseClick}
              aria-label="close"
              width="100%"
              size="xs"
              icon={<TriangleUpIcon />}
            />
          </Stack>
        </CardBody>
      </Card>
    )
  }
)
