import { Pokemon } from '../Pokemon'
import { typesId } from '../Type'

export const pokemons: Pokemon[] = [
  {
    id: '906',
    nationalNumber: 906,
    name: 'ニャオハ',
    hp: 40,
    attack: 61,
    defense: 54,
    specialAttack: 45,
    specialDefense: 45,
    speed: 65,
    weight: 4.1,
    types: [typesId.GRASS],
    abilities: ['しんりょく', 'へんげんじざい'],
  },
  {
    id: '907',
    nationalNumber: 907,
    name: 'ニャローテ',
    hp: 61,
    attack: 80,
    defense: 63,
    specialAttack: 60,
    specialDefense: 63,
    speed: 83,
    weight: 12.2,
    types: [typesId.GRASS],
    abilities: ['しんりょく', 'へんげんじざい'],
  },
  {
    id: '908',
    nationalNumber: 908,
    name: 'マスカーニャ',
    hp: 76,
    attack: 110,
    defense: 70,
    specialAttack: 81,
    specialDefense: 70,
    speed: 123,
    weight: 31.2,
    types: [typesId.GRASS, typesId.DARK],
    abilities: ['しんりょく', 'へんげんじざい'],
  },
  {
    id: '909',
    nationalNumber: 909,
    name: 'ホゲータ',
    hp: 67,
    attack: 45,
    defense: 59,
    specialAttack: 63,
    specialDefense: 40,
    speed: 36,
    weight: 9.8,
    types: [typesId.FIRE],
    abilities: ['もうか', 'てんねん'],
  },
  {
    id: '910',
    nationalNumber: 910,
    name: 'アチゲータ',
    hp: 81,
    attack: 55,
    defense: 78,
    specialAttack: 90,
    specialDefense: 58,
    speed: 49,
    weight: 30.7,
    types: [typesId.FIRE],
    abilities: ['もうか', 'てんねん'],
  },
  {
    id: '911',
    nationalNumber: 911,
    name: 'ラウドボーン',
    hp: 104,
    attack: 75,
    defense: 100,
    specialAttack: 110,
    specialDefense: 75,
    speed: 66,
    weight: 326.5,
    types: [typesId.FIRE, typesId.GHOST],
    abilities: ['もうか', 'てんねん'],
  },
  {
    id: '912',
    nationalNumber: 912,
    name: 'クワッス',
    hp: 55,
    attack: 65,
    defense: 45,
    specialAttack: 50,
    specialDefense: 45,
    speed: 50,
    weight: 6.1,
    types: [typesId.WATER],
    abilities: ['げきりゅう', 'じしんかじょう'],
  },
  {
    id: '913',
    nationalNumber: 913,
    name: 'ウェルカモ',
    hp: 70,
    attack: 85,
    defense: 65,
    specialAttack: 65,
    specialDefense: 60,
    speed: 65,
    weight: 21.5,
    types: [typesId.WATER],
    abilities: ['げきりゅう', 'じしんかじょう'],
  },
  {
    id: '914',
    nationalNumber: 914,
    name: 'ウェーニバル',
    hp: 85,
    attack: 120,
    defense: 80,
    specialAttack: 85,
    specialDefense: 75,
    speed: 85,
    weight: 61.9,
    types: [typesId.WATER, typesId.FIGHTING],
    abilities: ['げきりゅう', 'じしんかじょう'],
  },
  {
    id: '915',
    nationalNumber: 915,
    name: 'グルトン',
    hp: 54,
    attack: 45,
    defense: 40,
    specialAttack: 35,
    specialDefense: 45,
    speed: 35,
    weight: 10.2,
    types: [typesId.NORMAL],
    abilities: ['アロマベール', 'くいしんぼう', 'あついしぼう'],
  },
  {
    id: '916',
    nationalNumber: 916,
    name: 'パフュートン♂',
    hp: 110,
    attack: 100,
    defense: 75,
    specialAttack: 59,
    specialDefense: 80,
    speed: 65,
    weight: 120,
    types: [typesId.NORMAL],
    abilities: ['とれないにおい', 'くいしんぼう', 'あついしぼう'],
  },
  {
    id: '916f',
    nationalNumber: 916,
    name: 'パフュートン♀',
    hp: 115,
    attack: 90,
    defense: 70,
    specialAttack: 59,
    specialDefense: 90,
    speed: 65,
    weight: 120,
    types: [typesId.NORMAL],
    abilities: ['アロマベール', 'くいしんぼう', 'あついしぼう'],
  },
  {
    id: '918',
    nationalNumber: 917,
    name: 'タマンチュラ',
    hp: 35,
    attack: 41,
    defense: 45,
    specialAttack: 29,
    specialDefense: 40,
    speed: 20,
    weight: 4,
    types: [typesId.BUG],
    abilities: ['ふみん', 'はりこみ'],
  },
  {
    id: '919',
    nationalNumber: 918,
    name: 'ワナイダー',
    hp: 60,
    attack: 79,
    defense: 92,
    specialAttack: 52,
    specialDefense: 86,
    speed: 35,
    weight: 16.5,
    types: [typesId.BUG],
    abilities: ['ふみん', 'はりこみ'],
  },
  {
    id: '920',
    nationalNumber: 919,
    name: 'マメバッタ',
    hp: 33,
    attack: 46,
    defense: 40,
    specialAttack: 21,
    specialDefense: 25,
    speed: 45,
    weight: 1,
    types: [typesId.BUG],
    abilities: ['むしのしらせ', 'いろめがね'],
  },
  {
    id: '921',
    nationalNumber: 920,
    name: 'エクスレッグ',
    hp: 71,
    attack: 102,
    defense: 78,
    specialAttack: 52,
    specialDefense: 55,
    speed: 92,
    weight: 17.5,
    types: [typesId.BUG, typesId.DARK],
    abilities: ['むしのしらせ', 'いろめがね'],
  },
  {
    id: '187',
    nationalNumber: 187,
    name: 'ハネッコ',
    hp: 35,
    attack: 35,
    defense: 40,
    specialAttack: 35,
    specialDefense: 55,
    speed: 50,
    weight: 0.5,
    types: [typesId.GRASS, typesId.FLYING],
    abilities: ['ようりょくそ', 'リーフガード', 'すりぬけ'],
  },
  {
    id: '188',
    nationalNumber: 188,
    name: 'ポポッコ',
    hp: 55,
    attack: 45,
    defense: 50,
    specialAttack: 45,
    specialDefense: 65,
    speed: 80,
    weight: 1,
    types: [typesId.GRASS, typesId.FLYING],
    abilities: ['ようりょくそ', 'リーフガード', 'すりぬけ'],
  },
  {
    id: '189',
    nationalNumber: 189,
    name: 'ワタッコ',
    hp: 75,
    attack: 55,
    defense: 70,
    specialAttack: 55,
    specialDefense: 95,
    speed: 110,
    weight: 3,
    types: [typesId.GRASS, typesId.FLYING],
    abilities: ['ようりょくそ', 'リーフガード', 'すりぬけ'],
  },
  {
    id: '661',
    nationalNumber: 661,
    name: 'ヤヤコマ',
    hp: 45,
    attack: 50,
    defense: 43,
    specialAttack: 40,
    specialDefense: 38,
    speed: 62,
    weight: 1.7,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['はとむね', 'はやてのつばさ'],
  },
  {
    id: '662',
    nationalNumber: 662,
    name: 'ヒノヤコマ',
    hp: 62,
    attack: 73,
    defense: 55,
    specialAttack: 56,
    specialDefense: 52,
    speed: 84,
    weight: 16,
    types: [typesId.FIRE, typesId.FLYING],
    abilities: ['ほのおのからだ', 'はやてのつばさ'],
  },
  {
    id: '663',
    nationalNumber: 663,
    name: 'ファイアロー',
    hp: 78,
    attack: 81,
    defense: 71,
    specialAttack: 74,
    specialDefense: 69,
    speed: 126,
    weight: 24.5,
    types: [typesId.FIRE, typesId.FLYING],
    abilities: ['ほのおのからだ', 'はやてのつばさ'],
  },
  {
    id: '954',
    nationalNumber: 921,
    name: 'パモ',
    hp: 45,
    attack: 50,
    defense: 20,
    specialAttack: 40,
    specialDefense: 25,
    speed: 60,
    weight: 2.5,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'しぜんかいふく', 'てつのこぶし'],
  },
  {
    id: '955',
    nationalNumber: 922,
    name: 'パモット',
    hp: 60,
    attack: 75,
    defense: 40,
    specialAttack: 50,
    specialDefense: 40,
    speed: 85,
    weight: 6.5,
    types: [typesId.ELECTRIC, typesId.FIGHTING],
    abilities: ['ちくでん', 'しぜんかいふく', 'てつのこぶし'],
  },
  {
    id: '956',
    nationalNumber: 923,
    name: 'パーモット',
    hp: 70,
    attack: 115,
    defense: 70,
    specialAttack: 70,
    specialDefense: 60,
    speed: 105,
    weight: 41,
    types: [typesId.ELECTRIC, typesId.FIGHTING],
    abilities: ['ちくでん', 'しぜんかいふく', 'てつのこぶし'],
  },
  {
    id: '228',
    nationalNumber: 228,
    name: 'デルビル',
    hp: 45,
    attack: 60,
    defense: 30,
    specialAttack: 80,
    specialDefense: 50,
    speed: 65,
    weight: 10.8,
    types: [typesId.DARK, typesId.FIRE],
    abilities: ['はやおき', 'もらいび', 'きんちょうかん'],
  },
  {
    id: '229',
    nationalNumber: 229,
    name: 'ヘルガー',
    hp: 75,
    attack: 90,
    defense: 50,
    specialAttack: 110,
    specialDefense: 80,
    speed: 95,
    weight: 35,
    types: [typesId.DARK, typesId.FIRE],
    abilities: ['はやおき', 'もらいび', 'きんちょうかん'],
  },
  {
    id: '734',
    nationalNumber: 734,
    name: 'ヤングース',
    hp: 48,
    attack: 70,
    defense: 30,
    specialAttack: 30,
    specialDefense: 30,
    speed: 45,
    weight: 6,
    types: [typesId.NORMAL],
    abilities: ['はりこみ', 'がんじょうあご', 'てきおうりょく'],
  },
  {
    id: '735',
    nationalNumber: 735,
    name: 'デカグース',
    hp: 88,
    attack: 110,
    defense: 60,
    specialAttack: 55,
    specialDefense: 60,
    speed: 45,
    weight: 14.2,
    types: [typesId.NORMAL],
    abilities: ['はりこみ', 'がんじょうあご', 'てきおうりょく'],
  },
  {
    id: '819',
    nationalNumber: 819,
    name: 'ホシガリス',
    hp: 70,
    attack: 55,
    defense: 55,
    specialAttack: 35,
    specialDefense: 35,
    speed: 25,
    weight: 2.5,
    types: [typesId.NORMAL],
    abilities: ['ほおぶくろ', 'くいしんぼう'],
  },
  {
    id: '820',
    nationalNumber: 820,
    name: 'ヨクバリス',
    hp: 120,
    attack: 95,
    defense: 95,
    specialAttack: 55,
    specialDefense: 75,
    speed: 20,
    weight: 6,
    types: [typesId.NORMAL],
    abilities: ['ほおぶくろ', 'くいしんぼう'],
  },
  {
    id: '191',
    nationalNumber: 191,
    name: 'ヒマナッツ',
    hp: 30,
    attack: 30,
    defense: 30,
    specialAttack: 30,
    specialDefense: 30,
    speed: 30,
    weight: 1.8,
    types: [typesId.GRASS],
    abilities: ['ようりょくそ', 'サンパワー', 'はやおき'],
  },
  {
    id: '192',
    nationalNumber: 192,
    name: 'キマワリ',
    hp: 75,
    attack: 75,
    defense: 55,
    specialAttack: 105,
    specialDefense: 85,
    speed: 30,
    weight: 8.5,
    types: [typesId.GRASS],
    abilities: ['ようりょくそ', 'サンパワー', 'はやおき'],
  },
  {
    id: '401',
    nationalNumber: 401,
    name: 'コロボーシ',
    hp: 37,
    attack: 25,
    defense: 41,
    specialAttack: 25,
    specialDefense: 41,
    speed: 25,
    weight: 2.2,
    types: [typesId.BUG],
    abilities: ['だっぴ', 'にげあし'],
  },
  {
    id: '402',
    nationalNumber: 402,
    name: 'コロトック',
    hp: 77,
    attack: 85,
    defense: 51,
    specialAttack: 55,
    specialDefense: 51,
    speed: 65,
    weight: 25.5,
    types: [typesId.BUG],
    abilities: ['むしのしらせ', 'テクニシャン'],
  },
  {
    id: '664',
    nationalNumber: 664,
    name: 'コフキムシ',
    hp: 38,
    attack: 35,
    defense: 40,
    specialAttack: 27,
    specialDefense: 25,
    speed: 35,
    weight: 2.5,
    types: [typesId.BUG],
    abilities: ['りんぷん', 'ふくがん', 'フレンドガード'],
  },
  {
    id: '665',
    nationalNumber: 665,
    name: 'コフーライ',
    hp: 45,
    attack: 22,
    defense: 60,
    specialAttack: 27,
    specialDefense: 30,
    speed: 29,
    weight: 8.4,
    types: [typesId.BUG],
    abilities: ['だっぴ', 'フレンドガード'],
  },
  {
    id: '666',
    nationalNumber: 666,
    name: 'ビビヨン',
    hp: 80,
    attack: 52,
    defense: 50,
    specialAttack: 90,
    specialDefense: 50,
    speed: 89,
    weight: 17,
    types: [typesId.BUG, typesId.FLYING],
    abilities: ['りんぷん', 'ふくがん', 'フレンドガード'],
  },
  {
    id: '415',
    nationalNumber: 415,
    name: 'ミツハニー',
    hp: 30,
    attack: 30,
    defense: 42,
    specialAttack: 30,
    specialDefense: 42,
    speed: 70,
    weight: 5.5,
    types: [typesId.BUG, typesId.FLYING],
    abilities: ['みつあつめ', 'はりきり'],
  },
  {
    id: '416',
    nationalNumber: 416,
    name: 'ビークイン',
    hp: 70,
    attack: 80,
    defense: 102,
    specialAttack: 80,
    specialDefense: 102,
    speed: 40,
    weight: 38.5,
    types: [typesId.BUG, typesId.FLYING],
    abilities: ['プレッシャー', 'きんちょうかん'],
  },
  {
    id: '821',
    nationalNumber: 821,
    name: 'ココガラ',
    hp: 38,
    attack: 47,
    defense: 35,
    specialAttack: 33,
    specialDefense: 35,
    speed: 57,
    weight: 1.8,
    types: [typesId.FLYING],
    abilities: ['するどいめ', 'きんちょうかん', 'はとむね'],
  },
  {
    id: '822',
    nationalNumber: 822,
    name: 'アオガラス',
    hp: 68,
    attack: 67,
    defense: 55,
    specialAttack: 43,
    specialDefense: 55,
    speed: 77,
    weight: 16,
    types: [typesId.FLYING],
    abilities: ['するどいめ', 'きんちょうかん', 'はとむね'],
  },
  {
    id: '823',
    nationalNumber: 823,
    name: 'アーマーガア',
    hp: 98,
    attack: 87,
    defense: 105,
    specialAttack: 53,
    specialDefense: 85,
    speed: 67,
    weight: 75,
    types: [typesId.FLYING, typesId.STEEL],
    abilities: ['プレッシャー', 'きんちょうかん', 'ミラーアーマー'],
  },
  {
    id: '440',
    nationalNumber: 440,
    name: 'ピンプク',
    hp: 100,
    attack: 5,
    defense: 5,
    specialAttack: 15,
    specialDefense: 65,
    speed: 30,
    weight: 24.4,
    types: [typesId.NORMAL],
    abilities: ['しぜんかいふく', 'てんのめぐみ', 'フレンドガード'],
  },
  {
    id: '113',
    nationalNumber: 113,
    name: 'ラッキー',
    hp: 250,
    attack: 5,
    defense: 5,
    specialAttack: 35,
    specialDefense: 105,
    speed: 50,
    weight: 34.6,
    types: [typesId.NORMAL],
    abilities: ['しぜんかいふく', 'てんのめぐみ', 'いやしのこころ'],
  },
  {
    id: '242',
    nationalNumber: 242,
    name: 'ハピナス',
    hp: 255,
    attack: 10,
    defense: 10,
    specialAttack: 75,
    specialDefense: 135,
    speed: 55,
    weight: 46.8,
    types: [typesId.NORMAL],
    abilities: ['しぜんかいふく', 'てんのめぐみ', 'いやしのこころ'],
  },
  {
    id: '298',
    nationalNumber: 298,
    name: 'ルリリ',
    hp: 50,
    attack: 20,
    defense: 40,
    specialAttack: 20,
    specialDefense: 40,
    speed: 20,
    weight: 2,
    types: [typesId.NORMAL, typesId.FAIRY],
    abilities: ['あついしぼう', 'ちからもち', 'そうしょく'],
  },
  {
    id: '183',
    nationalNumber: 183,
    name: 'マリル',
    hp: 70,
    attack: 20,
    defense: 50,
    specialAttack: 20,
    specialDefense: 50,
    speed: 40,
    weight: 8.5,
    types: [typesId.WATER, typesId.FAIRY],
    abilities: ['あついしぼう', 'ちからもち', 'そうしょく'],
  },
  {
    id: '184',
    nationalNumber: 184,
    name: 'マリルリ',
    hp: 100,
    attack: 50,
    defense: 80,
    specialAttack: 60,
    specialDefense: 80,
    speed: 50,
    weight: 28.5,
    types: [typesId.WATER, typesId.FAIRY],
    abilities: ['あついしぼう', 'ちからもち', 'そうしょく'],
  },
  {
    id: '283',
    nationalNumber: 283,
    name: 'アメタマ',
    hp: 40,
    attack: 30,
    defense: 32,
    specialAttack: 50,
    specialDefense: 52,
    speed: 65,
    weight: 1.7,
    types: [typesId.BUG, typesId.WATER],
    abilities: ['すいすい', 'あめうけざら'],
  },
  {
    id: '284',
    nationalNumber: 284,
    name: 'アメモース',
    hp: 70,
    attack: 60,
    defense: 62,
    specialAttack: 100,
    specialDefense: 82,
    speed: 80,
    weight: 3.6,
    types: [typesId.BUG, typesId.FLYING],
    abilities: ['いかく', 'きんちょうかん'],
  },
  {
    id: '418',
    nationalNumber: 418,
    name: 'ブイゼル',
    hp: 55,
    attack: 65,
    defense: 35,
    specialAttack: 60,
    specialDefense: 30,
    speed: 85,
    weight: 29.5,
    types: [typesId.WATER],
    abilities: ['すいすい', 'みずのベール'],
  },
  {
    id: '419',
    nationalNumber: 419,
    name: 'フローゼル',
    hp: 85,
    attack: 105,
    defense: 55,
    specialAttack: 85,
    specialDefense: 50,
    speed: 115,
    weight: 33.5,
    types: [typesId.WATER],
    abilities: ['すいすい', 'みずのベール'],
  },
  {
    id: '194p',
    nationalNumber: 194,
    name: 'ウパー(パルデア)',
    hp: 55,
    attack: 45,
    defense: 45,
    specialAttack: 25,
    specialDefense: 25,
    speed: 15,
    weight: 11,
    types: [typesId.POISON, typesId.GROUND],
    abilities: ['どくのトゲ', 'ちょすい', 'てんねん'],
  },
  {
    id: '1009',
    nationalNumber: 980,
    name: 'ドオー',
    hp: 130,
    attack: 75,
    defense: 60,
    specialAttack: 45,
    specialDefense: 100,
    speed: 20,
    weight: 223,
    types: [typesId.POISON, typesId.GROUND],
    abilities: ['どくのトゲ', 'ちょすい', 'てんねん'],
  },
  {
    id: '54',
    nationalNumber: 54,
    name: 'コダック',
    hp: 50,
    attack: 52,
    defense: 48,
    specialAttack: 65,
    specialDefense: 50,
    speed: 55,
    weight: 19.6,
    types: [typesId.WATER],
    abilities: ['しめりけ', 'ノーてんき', 'すいすい'],
  },
  {
    id: '55',
    nationalNumber: 55,
    name: 'ゴルダック',
    hp: 80,
    attack: 82,
    defense: 78,
    specialAttack: 95,
    specialDefense: 80,
    speed: 85,
    weight: 76.6,
    types: [typesId.WATER],
    abilities: ['しめりけ', 'ノーてんき', 'すいすい'],
  },
  {
    id: '833',
    nationalNumber: 833,
    name: 'カムカメ',
    hp: 50,
    attack: 64,
    defense: 50,
    specialAttack: 38,
    specialDefense: 38,
    speed: 44,
    weight: 8.5,
    types: [typesId.WATER],
    abilities: ['がんじょうあご', 'シェルアーマー', 'すいすい'],
  },
  {
    id: '834',
    nationalNumber: 834,
    name: 'カジリガメ',
    hp: 90,
    attack: 115,
    defense: 90,
    specialAttack: 48,
    specialDefense: 68,
    speed: 74,
    weight: 115.5,
    types: [typesId.WATER, typesId.ROCK],
    abilities: ['がんじょうあご', 'シェルアーマー', 'すいすい'],
  },
  {
    id: '174',
    nationalNumber: 174,
    name: 'ププリン',
    hp: 90,
    attack: 30,
    defense: 15,
    specialAttack: 40,
    specialDefense: 20,
    speed: 15,
    weight: 1,
    types: [typesId.NORMAL, typesId.FAIRY],
    abilities: ['メロメロボディ', 'かちき', 'フレンドガード'],
  },
  {
    id: '39',
    nationalNumber: 39,
    name: 'プリン',
    hp: 115,
    attack: 45,
    defense: 20,
    specialAttack: 45,
    specialDefense: 25,
    speed: 20,
    weight: 5.5,
    types: [typesId.NORMAL, typesId.FAIRY],
    abilities: ['メロメロボディ', 'かちき', 'フレンドガード'],
  },
  {
    id: '40',
    nationalNumber: 40,
    name: 'プクリン',
    hp: 140,
    attack: 70,
    defense: 45,
    specialAttack: 85,
    specialDefense: 50,
    speed: 45,
    weight: 12,
    types: [typesId.NORMAL, typesId.FAIRY],
    abilities: ['メロメロボディ', 'かちき', 'おみとおし'],
  },
  {
    id: '280',
    nationalNumber: 280,
    name: 'ラルトス',
    hp: 28,
    attack: 25,
    defense: 25,
    specialAttack: 45,
    specialDefense: 35,
    speed: 40,
    weight: 6.6,
    types: [typesId.PSYCHIC, typesId.FAIRY],
    abilities: ['シンクロ', 'トレース', 'テレパシー'],
  },
  {
    id: '281',
    nationalNumber: 281,
    name: 'キルリア',
    hp: 38,
    attack: 35,
    defense: 35,
    specialAttack: 65,
    specialDefense: 55,
    speed: 50,
    weight: 20.2,
    types: [typesId.PSYCHIC, typesId.FAIRY],
    abilities: ['シンクロ', 'トレース', 'テレパシー'],
  },
  {
    id: '282',
    nationalNumber: 282,
    name: 'サーナイト',
    hp: 68,
    attack: 65,
    defense: 65,
    specialAttack: 125,
    specialDefense: 115,
    speed: 80,
    weight: 48.4,
    types: [typesId.PSYCHIC, typesId.FAIRY],
    abilities: ['シンクロ', 'トレース', 'テレパシー'],
  },
  {
    id: '475',
    nationalNumber: 475,
    name: 'エルレイド',
    hp: 68,
    attack: 125,
    defense: 65,
    specialAttack: 65,
    specialDefense: 115,
    speed: 80,
    weight: 52,
    types: [typesId.PSYCHIC, typesId.FIGHTING],
    abilities: ['ふくつのこころ', 'きれあじ', 'せいぎのこころ'],
  },
  {
    id: '96',
    nationalNumber: 96,
    name: 'スリープ',
    hp: 60,
    attack: 48,
    defense: 45,
    specialAttack: 43,
    specialDefense: 90,
    speed: 42,
    weight: 32.4,
    types: [typesId.PSYCHIC],
    abilities: ['ふみん', 'よちむ', 'せいしんりょく'],
  },
  {
    id: '97',
    nationalNumber: 97,
    name: 'スリーパー',
    hp: 85,
    attack: 73,
    defense: 70,
    specialAttack: 73,
    specialDefense: 115,
    speed: 67,
    weight: 75.6,
    types: [typesId.PSYCHIC],
    abilities: ['ふみん', 'よちむ', 'せいしんりょく'],
  },
  {
    id: '92',
    nationalNumber: 92,
    name: 'ゴース',
    hp: 30,
    attack: 35,
    defense: 30,
    specialAttack: 100,
    specialDefense: 35,
    speed: 80,
    weight: 0.1,
    types: [typesId.GHOST, typesId.POISON],
    abilities: ['ふゆう'],
  },
  {
    id: '93',
    nationalNumber: 93,
    name: 'ゴースト',
    hp: 45,
    attack: 50,
    defense: 45,
    specialAttack: 115,
    specialDefense: 55,
    speed: 95,
    weight: 0.1,
    types: [typesId.GHOST, typesId.POISON],
    abilities: ['ふゆう'],
  },
  {
    id: '94',
    nationalNumber: 94,
    name: 'ゲンガー',
    hp: 60,
    attack: 65,
    defense: 60,
    specialAttack: 130,
    specialDefense: 75,
    speed: 110,
    weight: 40.5,
    types: [typesId.GHOST, typesId.POISON],
    abilities: ['のろわれボディ'],
  },
  {
    id: '945',
    nationalNumber: 924,
    name: 'ワッカネズミ',
    hp: 50,
    attack: 50,
    defense: 45,
    specialAttack: 40,
    specialDefense: 45,
    speed: 75,
    weight: 1.8,
    types: [typesId.NORMAL],
    abilities: ['にげあし', 'ものひろい', 'マイペース'],
  },
  {
    id: '946',
    nationalNumber: 925,
    name: 'イッカネズミ',
    hp: 74,
    attack: 75,
    defense: 70,
    specialAttack: 65,
    specialDefense: 75,
    speed: 111,
    weight: 2.3,
    types: [typesId.NORMAL],
    abilities: ['フレンドガード', 'ほおぶくろ', 'テクニシャン'],
  },
  {
    id: '172',
    nationalNumber: 172,
    name: 'ピチュー',
    hp: 20,
    attack: 40,
    defense: 15,
    specialAttack: 35,
    specialDefense: 35,
    speed: 60,
    weight: 2,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'ひらいしん'],
  },
  {
    id: '25',
    nationalNumber: 25,
    name: 'ピカチュウ',
    hp: 35,
    attack: 55,
    defense: 40,
    specialAttack: 50,
    specialDefense: 50,
    speed: 90,
    weight: 6,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'ひらいしん'],
  },
  {
    id: '26',
    nationalNumber: 26,
    name: 'ライチュウ',
    hp: 60,
    attack: 90,
    defense: 55,
    specialAttack: 90,
    specialDefense: 80,
    speed: 110,
    weight: 30,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'ひらいしん'],
  },
  {
    id: '970',
    nationalNumber: 926,
    name: 'パピモッチ',
    hp: 37,
    attack: 55,
    defense: 70,
    specialAttack: 30,
    specialDefense: 55,
    speed: 65,
    weight: 10.9,
    types: [typesId.FAIRY],
    abilities: ['マイペース', 'ぶきよう'],
  },
  {
    id: '971',
    nationalNumber: 927,
    name: 'バウッツェル',
    hp: 57,
    attack: 80,
    defense: 115,
    specialAttack: 50,
    specialDefense: 80,
    speed: 95,
    weight: 14.9,
    types: [typesId.FAIRY],
    abilities: ['こんがりボディ', 'アロマベール'],
  },
  {
    id: '287',
    nationalNumber: 287,
    name: 'ナマケロ',
    hp: 60,
    attack: 60,
    defense: 60,
    specialAttack: 35,
    specialDefense: 35,
    speed: 30,
    weight: 24,
    types: [typesId.NORMAL],
    abilities: ['なまけ'],
  },
  {
    id: '288',
    nationalNumber: 288,
    name: 'ヤルキモノ',
    hp: 80,
    attack: 80,
    defense: 80,
    specialAttack: 55,
    specialDefense: 55,
    speed: 90,
    weight: 46.5,
    types: [typesId.NORMAL],
    abilities: ['やるき'],
  },
  {
    id: '289',
    nationalNumber: 289,
    name: 'ケッキング',
    hp: 150,
    attack: 160,
    defense: 100,
    specialAttack: 95,
    specialDefense: 65,
    speed: 100,
    weight: 130.5,
    types: [typesId.NORMAL],
    abilities: ['なまけ'],
  },
  {
    id: '761',
    nationalNumber: 761,
    name: 'アマカジ',
    hp: 42,
    attack: 30,
    defense: 38,
    specialAttack: 30,
    specialDefense: 38,
    speed: 32,
    weight: 3.2,
    types: [typesId.GRASS],
    abilities: ['リーフガード', 'どんかん', 'スイートベール'],
  },
  {
    id: '762',
    nationalNumber: 762,
    name: 'アママイコ',
    hp: 52,
    attack: 40,
    defense: 48,
    specialAttack: 40,
    specialDefense: 48,
    speed: 62,
    weight: 8.2,
    types: [typesId.GRASS],
    abilities: ['リーフガード', 'どんかん', 'スイートベール'],
  },
  {
    id: '763',
    nationalNumber: 763,
    name: 'アマージョ',
    hp: 72,
    attack: 120,
    defense: 98,
    specialAttack: 50,
    specialDefense: 98,
    speed: 72,
    weight: 21.4,
    types: [typesId.GRASS],
    abilities: ['リーフガード', 'じょおうのいげん', 'スイートベール'],
  },
  {
    id: '935',
    nationalNumber: 928,
    name: 'ミニーブ',
    hp: 41,
    attack: 35,
    defense: 45,
    specialAttack: 58,
    specialDefense: 51,
    speed: 30,
    weight: 6.5,
    types: [typesId.GRASS, typesId.NORMAL],
    abilities: ['はやおき', 'しゅうかく'],
  },
  {
    id: '936',
    nationalNumber: 929,
    name: 'オリーニョ',
    hp: 52,
    attack: 53,
    defense: 60,
    specialAttack: 78,
    specialDefense: 78,
    speed: 33,
    weight: 11.9,
    types: [typesId.GRASS, typesId.NORMAL],
    abilities: ['はやおき', 'しゅうかく'],
  },
  {
    id: '937',
    nationalNumber: 930,
    name: 'オリーヴァ',
    hp: 78,
    attack: 69,
    defense: 90,
    specialAttack: 125,
    specialDefense: 109,
    speed: 39,
    weight: 48.2,
    types: [typesId.GRASS, typesId.NORMAL],
    abilities: ['こぼれダネ', 'しゅうかく'],
  },
  {
    id: '438',
    nationalNumber: 438,
    name: 'ウソハチ',
    hp: 50,
    attack: 80,
    defense: 95,
    specialAttack: 10,
    specialDefense: 45,
    speed: 10,
    weight: 15,
    types: [typesId.ROCK],
    abilities: ['がんじょう', 'いしあたま', 'びびり'],
  },
  {
    id: '185',
    nationalNumber: 185,
    name: 'ウソッキー',
    hp: 70,
    attack: 100,
    defense: 115,
    specialAttack: 30,
    specialDefense: 65,
    speed: 30,
    weight: 38,
    types: [typesId.ROCK],
    abilities: ['がんじょう', 'いしあたま', 'びびり'],
  },
  {
    id: '744',
    nationalNumber: 744,
    name: 'イワンコ',
    hp: 45,
    attack: 65,
    defense: 40,
    specialAttack: 30,
    specialDefense: 40,
    speed: 60,
    weight: 9.2,
    types: [typesId.ROCK],
    abilities: ['するどいめ', 'やるき', 'ふくつのこころ', 'マイペース'],
  },
  {
    id: '745',
    nationalNumber: 745,
    name: 'ルガルガン(まひる)',
    hp: 75,
    attack: 115,
    defense: 65,
    specialAttack: 55,
    specialDefense: 65,
    speed: 112,
    weight: 25,
    types: [typesId.ROCK],
    abilities: ['するどいめ', 'すなかき', 'ふくつのこころ'],
  },
  {
    id: '745f',
    nationalNumber: 745,
    name: 'ルガルガン(まよなか)',
    hp: 85,
    attack: 115,
    defense: 75,
    specialAttack: 55,
    specialDefense: 75,
    speed: 82,
    weight: 25,
    types: [typesId.ROCK],
    abilities: ['するどいめ', 'やるき', 'ノーガード'],
  },
  {
    id: '745d',
    nationalNumber: 745,
    name: 'ルガルガン(たそがれ)',
    hp: 75,
    attack: 117,
    defense: 65,
    specialAttack: 55,
    specialDefense: 65,
    speed: 110,
    weight: 25,
    types: [typesId.ROCK],
    abilities: ['かたいツメ'],
  },
  {
    id: '837',
    nationalNumber: 837,
    name: 'タンドン',
    hp: 30,
    attack: 40,
    defense: 50,
    specialAttack: 40,
    specialDefense: 50,
    speed: 30,
    weight: 12,
    types: [typesId.ROCK],
    abilities: ['じょうききかん', 'たいねつ', 'もらいび'],
  },
  {
    id: '838',
    nationalNumber: 838,
    name: 'トロッゴン',
    hp: 80,
    attack: 60,
    defense: 90,
    specialAttack: 60,
    specialDefense: 70,
    speed: 50,
    weight: 78,
    types: [typesId.ROCK, typesId.FIRE],
    abilities: ['じょうききかん', 'ほのおのからだ', 'もらいび'],
  },
  {
    id: '839',
    nationalNumber: 839,
    name: 'セキタンザン',
    hp: 110,
    attack: 80,
    defense: 120,
    specialAttack: 80,
    specialDefense: 90,
    speed: 30,
    weight: 310.5,
    types: [typesId.ROCK, typesId.FIRE],
    abilities: ['じょうききかん', 'ほのおのからだ', 'もらいび'],
  },
  {
    id: '403',
    nationalNumber: 403,
    name: 'コリンク',
    hp: 45,
    attack: 65,
    defense: 34,
    specialAttack: 40,
    specialDefense: 34,
    speed: 45,
    weight: 9.5,
    types: [typesId.ELECTRIC],
    abilities: ['とうそうしん', 'いかく', 'こんじょう'],
  },
  {
    id: '404',
    nationalNumber: 404,
    name: 'ルクシオ',
    hp: 60,
    attack: 85,
    defense: 49,
    specialAttack: 60,
    specialDefense: 49,
    speed: 60,
    weight: 30.5,
    types: [typesId.ELECTRIC],
    abilities: ['とうそうしん', 'いかく', 'こんじょう'],
  },
  {
    id: '405',
    nationalNumber: 405,
    name: 'レントラー',
    hp: 80,
    attack: 120,
    defense: 79,
    specialAttack: 95,
    specialDefense: 79,
    speed: 70,
    weight: 42,
    types: [typesId.ELECTRIC],
    abilities: ['とうそうしん', 'いかく', 'こんじょう'],
  },
  {
    id: '396',
    nationalNumber: 396,
    name: 'ムックル',
    hp: 40,
    attack: 55,
    defense: 30,
    specialAttack: 30,
    specialDefense: 30,
    speed: 60,
    weight: 2,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['するどいめ', 'すてみ'],
  },
  {
    id: '397',
    nationalNumber: 397,
    name: 'ムクバード',
    hp: 55,
    attack: 75,
    defense: 50,
    specialAttack: 40,
    specialDefense: 40,
    speed: 80,
    weight: 15.5,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['いかく', 'すてみ'],
  },
  {
    id: '398',
    nationalNumber: 398,
    name: 'ムクホーク',
    hp: 85,
    attack: 120,
    defense: 70,
    specialAttack: 50,
    specialDefense: 60,
    speed: 100,
    weight: 24.9,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['いかく', 'すてみ'],
  },
  {
    id: '741',
    nationalNumber: 741,
    name: 'オドリドリ(めらめら)',
    hp: 75,
    attack: 70,
    defense: 70,
    specialAttack: 98,
    specialDefense: 70,
    speed: 93,
    weight: 3.4,
    types: [typesId.FIRE, typesId.FLYING],
    abilities: ['おどりこ'],
  },
  {
    id: '741p',
    nationalNumber: 741,
    name: 'オドリドリ(ぱちぱち)',
    hp: 75,
    attack: 70,
    defense: 70,
    specialAttack: 98,
    specialDefense: 70,
    speed: 93,
    weight: 3.4,
    types: [typesId.ELECTRIC, typesId.FLYING],
    abilities: ['おどりこ'],
  },
  {
    id: '741f',
    nationalNumber: 741,
    name: 'オドリドリ(ふらふら)',
    hp: 75,
    attack: 70,
    defense: 70,
    specialAttack: 98,
    specialDefense: 70,
    speed: 93,
    weight: 3.4,
    types: [typesId.PSYCHIC, typesId.FLYING],
    abilities: ['おどりこ'],
  },
  {
    id: '741m',
    nationalNumber: 741,
    name: 'オドリドリ(まいまい)',
    hp: 75,
    attack: 70,
    defense: 70,
    specialAttack: 98,
    specialDefense: 70,
    speed: 93,
    weight: 3.4,
    types: [typesId.GHOST, typesId.FLYING],
    abilities: ['おどりこ'],
  },
  {
    id: '179',
    nationalNumber: 179,
    name: 'メリープ',
    hp: 55,
    attack: 40,
    defense: 40,
    specialAttack: 65,
    specialDefense: 45,
    speed: 35,
    weight: 7.8,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'プラス'],
  },
  {
    id: '180',
    nationalNumber: 180,
    name: 'モココ',
    hp: 70,
    attack: 55,
    defense: 55,
    specialAttack: 80,
    specialDefense: 60,
    speed: 45,
    weight: 13.3,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'プラス'],
  },
  {
    id: '181',
    nationalNumber: 181,
    name: 'デンリュウ',
    hp: 90,
    attack: 75,
    defense: 85,
    specialAttack: 115,
    specialDefense: 90,
    speed: 55,
    weight: 61.5,
    types: [typesId.ELECTRIC],
    abilities: ['せいでんき', 'プラス'],
  },
  {
    id: '548',
    nationalNumber: 548,
    name: 'チュリネ',
    hp: 45,
    attack: 35,
    defense: 50,
    specialAttack: 70,
    specialDefense: 50,
    speed: 30,
    weight: 6.6,
    types: [typesId.GRASS],
    abilities: ['ようりょくそ', 'マイペース', 'リーフガード'],
  },
  {
    id: '549',
    nationalNumber: 549,
    name: 'ドレディア',
    hp: 70,
    attack: 60,
    defense: 75,
    specialAttack: 110,
    specialDefense: 75,
    speed: 90,
    weight: 16.3,
    types: [typesId.GRASS],
    abilities: ['ようりょくそ', 'マイペース', 'リーフガード'],
  },
  {
    id: '285',
    nationalNumber: 285,
    name: 'キノココ',
    hp: 60,
    attack: 40,
    defense: 60,
    specialAttack: 40,
    specialDefense: 60,
    speed: 35,
    weight: 4.5,
    types: [typesId.GRASS],
    abilities: ['ほうし', 'ポイズンヒール', 'はやあし'],
  },
  {
    id: '286',
    nationalNumber: 286,
    name: 'キノガッサ',
    hp: 60,
    attack: 130,
    defense: 80,
    specialAttack: 60,
    specialDefense: 60,
    speed: 70,
    weight: 39.2,
    types: [typesId.GRASS, typesId.FIGHTING],
    abilities: ['ほうし', 'ポイズンヒール', 'テクニシャン'],
  },
  {
    id: '840',
    nationalNumber: 840,
    name: 'カジッチュ',
    hp: 40,
    attack: 40,
    defense: 80,
    specialAttack: 40,
    specialDefense: 40,
    speed: 20,
    weight: 0.5,
    types: [typesId.GRASS, typesId.DRAGON],
    abilities: ['じゅくせい', 'くいしんぼう', 'ぼうだん'],
  },
  {
    id: '841',
    nationalNumber: 841,
    name: 'アップリュー',
    hp: 70,
    attack: 110,
    defense: 80,
    specialAttack: 95,
    specialDefense: 60,
    speed: 70,
    weight: 1,
    types: [typesId.GRASS, typesId.DRAGON],
    abilities: ['じゅくせい', 'くいしんぼう', 'はりきり'],
  },
  {
    id: '842',
    nationalNumber: 842,
    name: 'タルップル',
    hp: 110,
    attack: 85,
    defense: 80,
    specialAttack: 100,
    specialDefense: 80,
    speed: 30,
    weight: 13,
    types: [typesId.GRASS, typesId.DRAGON],
    abilities: ['じゅくせい', 'くいしんぼう', 'あついしぼう'],
  },
  {
    id: '325',
    nationalNumber: 325,
    name: 'バネブー',
    hp: 60,
    attack: 25,
    defense: 35,
    specialAttack: 70,
    specialDefense: 80,
    speed: 60,
    weight: 30.6,
    types: [typesId.PSYCHIC],
    abilities: ['あついしぼう', 'マイペース', 'くいしんぼう'],
  },
  {
    id: '326',
    nationalNumber: 326,
    name: 'ブーピッグ',
    hp: 80,
    attack: 45,
    defense: 65,
    specialAttack: 90,
    specialDefense: 110,
    speed: 80,
    weight: 71.5,
    types: [typesId.PSYCHIC],
    abilities: ['あついしぼう', 'マイペース', 'くいしんぼう'],
  },
  {
    id: '960',
    nationalNumber: 931,
    name: 'イキリンコ',
    hp: 82,
    attack: 96,
    defense: 51,
    specialAttack: 45,
    specialDefense: 51,
    speed: 92,
    weight: 2.4,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['いかく', 'はりきり', 'こんじょう', 'ちからずく'],
  },
  {
    id: '200',
    nationalNumber: 200,
    name: 'ムウマ',
    hp: 60,
    attack: 60,
    defense: 60,
    specialAttack: 85,
    specialDefense: 85,
    speed: 85,
    weight: 1,
    types: [typesId.GHOST],
    abilities: ['ふゆう'],
  },
  {
    id: '429',
    nationalNumber: 429,
    name: 'ムウマージ',
    hp: 60,
    attack: 60,
    defense: 60,
    specialAttack: 105,
    specialDefense: 105,
    speed: 105,
    weight: 4.4,
    types: [typesId.GHOST],
    abilities: ['ふゆう'],
  },
  {
    id: '296',
    nationalNumber: 296,
    name: 'マクノシタ',
    hp: 72,
    attack: 60,
    defense: 30,
    specialAttack: 20,
    specialDefense: 30,
    speed: 25,
    weight: 86.4,
    types: [typesId.FIGHTING],
    abilities: ['あついしぼう', 'こんじょう', 'ちからずく'],
  },
  {
    id: '297',
    nationalNumber: 297,
    name: 'ハリテヤマ',
    hp: 144,
    attack: 120,
    defense: 60,
    specialAttack: 40,
    specialDefense: 60,
    speed: 50,
    weight: 253.8,
    types: [typesId.FIGHTING],
    abilities: ['あついしぼう', 'こんじょう', 'ちからずく'],
  },
  {
    id: '739',
    nationalNumber: 739,
    name: 'マケンカニ',
    hp: 47,
    attack: 82,
    defense: 57,
    specialAttack: 42,
    specialDefense: 47,
    speed: 63,
    weight: 7,
    types: [typesId.FIGHTING],
    abilities: ['かいりきバサミ', 'てつのこぶし', 'いかりのつぼ'],
  },
  {
    id: '740',
    nationalNumber: 740,
    name: 'ケケンカニ',
    hp: 97,
    attack: 132,
    defense: 77,
    specialAttack: 62,
    specialDefense: 67,
    speed: 43,
    weight: 180,
    types: [typesId.FIGHTING, typesId.ICE],
    abilities: ['かいりきバサミ', 'てつのこぶし', 'いかりのつぼ'],
  },
  {
    id: '757',
    nationalNumber: 757,
    name: 'ヤトウモリ',
    hp: 48,
    attack: 44,
    defense: 40,
    specialAttack: 71,
    specialDefense: 40,
    speed: 77,
    weight: 4.8,
    types: [typesId.POISON, typesId.FIRE],
    abilities: ['ふしょく', 'どんかん'],
  },
  {
    id: '758',
    nationalNumber: 758,
    name: 'エンニュート',
    hp: 68,
    attack: 64,
    defense: 60,
    specialAttack: 111,
    specialDefense: 60,
    speed: 117,
    weight: 22.2,
    types: [typesId.POISON, typesId.FIRE],
    abilities: ['ふしょく', 'どんかん'],
  },
  {
    id: '231',
    nationalNumber: 231,
    name: 'ゴマゾウ',
    hp: 90,
    attack: 60,
    defense: 60,
    specialAttack: 40,
    specialDefense: 40,
    speed: 40,
    weight: 33.5,
    types: [typesId.GROUND],
    abilities: ['ものひろい', 'すながくれ'],
  },
  {
    id: '232',
    nationalNumber: 232,
    name: 'ドンファン',
    hp: 90,
    attack: 120,
    defense: 120,
    specialAttack: 60,
    specialDefense: 60,
    speed: 50,
    weight: 120,
    types: [typesId.GROUND],
    abilities: ['がんじょう', 'すながくれ'],
  },
  {
    id: '878',
    nationalNumber: 878,
    name: 'ゾウドウ',
    hp: 72,
    attack: 80,
    defense: 49,
    specialAttack: 40,
    specialDefense: 49,
    speed: 40,
    weight: 100,
    types: [typesId.STEEL],
    abilities: ['ちからずく', 'ヘヴィメタル'],
  },
  {
    id: '879',
    nationalNumber: 879,
    name: 'ダイオウドウ',
    hp: 122,
    attack: 130,
    defense: 69,
    specialAttack: 80,
    specialDefense: 69,
    speed: 30,
    weight: 650,
    types: [typesId.STEEL],
    abilities: ['ちからずく', 'ヘヴィメタル'],
  },
  {
    id: '443',
    nationalNumber: 443,
    name: 'フカマル',
    hp: 58,
    attack: 70,
    defense: 45,
    specialAttack: 40,
    specialDefense: 45,
    speed: 42,
    weight: 20.5,
    types: [typesId.DRAGON, typesId.GROUND],
    abilities: ['すながくれ', 'さめはだ'],
  },
  {
    id: '444',
    nationalNumber: 444,
    name: 'ガバイト',
    hp: 68,
    attack: 90,
    defense: 65,
    specialAttack: 50,
    specialDefense: 55,
    speed: 82,
    weight: 56,
    types: [typesId.DRAGON, typesId.GROUND],
    abilities: ['すながくれ', 'さめはだ'],
  },
  {
    id: '445',
    nationalNumber: 445,
    name: 'ガブリアス',
    hp: 108,
    attack: 130,
    defense: 95,
    specialAttack: 80,
    specialDefense: 85,
    speed: 102,
    weight: 95,
    types: [typesId.DRAGON, typesId.GROUND],
    abilities: ['すながくれ', 'さめはだ'],
  },
  {
    id: '963',
    nationalNumber: 932,
    name: 'コジオ',
    hp: 55,
    attack: 55,
    defense: 75,
    specialAttack: 35,
    specialDefense: 35,
    speed: 25,
    weight: 16,
    types: [typesId.ROCK],
    abilities: ['きよめのしお', 'がんじょう', 'クリアボディ'],
  },
  {
    id: '964',
    nationalNumber: 933,
    name: 'ジオヅム',
    hp: 60,
    attack: 60,
    defense: 100,
    specialAttack: 35,
    specialDefense: 65,
    speed: 35,
    weight: 105,
    types: [typesId.ROCK],
    abilities: ['きよめのしお', 'がんじょう', 'クリアボディ'],
  },
  {
    id: '965',
    nationalNumber: 934,
    name: 'キョジオーン',
    hp: 100,
    attack: 100,
    defense: 130,
    specialAttack: 45,
    specialDefense: 90,
    speed: 35,
    weight: 240,
    types: [typesId.ROCK],
    abilities: ['きよめのしお', 'がんじょう', 'クリアボディ'],
  },
  {
    id: '278',
    nationalNumber: 278,
    name: 'キャモメ',
    hp: 40,
    attack: 30,
    defense: 30,
    specialAttack: 55,
    specialDefense: 30,
    speed: 85,
    weight: 9.5,
    types: [typesId.WATER, typesId.FLYING],
    abilities: ['するどいめ', 'うるおいボディ', 'あめうけざら'],
  },
  {
    id: '279',
    nationalNumber: 279,
    name: 'ペリッパー',
    hp: 60,
    attack: 50,
    defense: 100,
    specialAttack: 95,
    specialDefense: 70,
    speed: 65,
    weight: 28,
    types: [typesId.WATER, typesId.FLYING],
    abilities: ['するどいめ', 'あめふらし', 'あめうけざら'],
  },
  {
    id: '129',
    nationalNumber: 129,
    name: 'コイキング',
    hp: 20,
    attack: 10,
    defense: 55,
    specialAttack: 15,
    specialDefense: 20,
    speed: 80,
    weight: 10,
    types: [typesId.WATER],
    abilities: ['すいすい', 'びびり'],
  },
  {
    id: '130',
    nationalNumber: 130,
    name: 'ギャラドス',
    hp: 95,
    attack: 125,
    defense: 79,
    specialAttack: 60,
    specialDefense: 100,
    speed: 81,
    weight: 235,
    types: [typesId.WATER, typesId.FLYING],
    abilities: ['いかく', 'じしんかじょう'],
  },
  {
    id: '846',
    nationalNumber: 846,
    name: 'サシカマス',
    hp: 41,
    attack: 63,
    defense: 40,
    specialAttack: 40,
    specialDefense: 30,
    speed: 66,
    weight: 1,
    types: [typesId.WATER],
    abilities: ['すいすい', 'スクリューおびれ'],
  },
  {
    id: '847',
    nationalNumber: 847,
    name: 'カマスジョー',
    hp: 61,
    attack: 123,
    defense: 60,
    specialAttack: 60,
    specialDefense: 50,
    speed: 136,
    weight: 30,
    types: [typesId.WATER],
    abilities: ['すいすい', 'スクリューおびれ'],
  },
  {
    id: '550',
    nationalNumber: 550,
    name: 'バスラオ(赤)',
    hp: 70,
    attack: 92,
    defense: 65,
    specialAttack: 80,
    specialDefense: 55,
    speed: 98,
    weight: 18,
    types: [typesId.WATER],
    abilities: ['すてみ', 'てきおうりょく', 'かたやぶり'],
  },
  {
    id: '316',
    nationalNumber: 316,
    name: 'ゴクリン',
    hp: 70,
    attack: 43,
    defense: 53,
    specialAttack: 43,
    specialDefense: 53,
    speed: 40,
    weight: 10.3,
    types: [typesId.POISON],
    abilities: ['ヘドロえき', 'ねんちゃく', 'くいしんぼう'],
  },
  {
    id: '317',
    nationalNumber: 317,
    name: 'マルノーム',
    hp: 100,
    attack: 73,
    defense: 83,
    specialAttack: 73,
    specialDefense: 83,
    speed: 55,
    weight: 80,
    types: [typesId.POISON],
    abilities: ['ヘドロえき', 'ねんちゃく', 'くいしんぼう'],
  },
  {
    id: '52',
    nationalNumber: 52,
    name: 'ニャース',
    hp: 40,
    attack: 45,
    defense: 35,
    specialAttack: 40,
    specialDefense: 40,
    speed: 90,
    weight: 4.2,
    types: [typesId.NORMAL],
    abilities: ['ものひろい', 'テクニシャン', 'きんちょうかん'],
  },
  {
    id: '53',
    nationalNumber: 53,
    name: 'ペルシアン',
    hp: 65,
    attack: 70,
    defense: 60,
    specialAttack: 65,
    specialDefense: 65,
    speed: 115,
    weight: 32,
    types: [typesId.NORMAL],
    abilities: ['じゅうなん', 'テクニシャン', 'きんちょうかん'],
  },
  {
    id: '425',
    nationalNumber: 425,
    name: 'フワンテ',
    hp: 90,
    attack: 50,
    defense: 34,
    specialAttack: 60,
    specialDefense: 44,
    speed: 70,
    weight: 1.2,
    types: [typesId.GHOST, typesId.FLYING],
    abilities: ['ゆうばく', 'かるわざ', 'ねつぼうそう'],
  },
  {
    id: '426',
    nationalNumber: 426,
    name: 'フワライド',
    hp: 150,
    attack: 80,
    defense: 44,
    specialAttack: 90,
    specialDefense: 54,
    speed: 80,
    weight: 15,
    types: [typesId.GHOST, typesId.FLYING],
    abilities: ['ゆうばく', 'かるわざ', 'ねつぼうそう'],
  },
  {
    id: '669',
    nationalNumber: 669,
    name: 'フラベベ',
    hp: 44,
    attack: 38,
    defense: 39,
    specialAttack: 61,
    specialDefense: 79,
    speed: 42,
    weight: 0.1,
    types: [typesId.FAIRY],
    abilities: ['フラワーベール', 'きょうせい'],
  },
  {
    id: '670',
    nationalNumber: 670,
    name: 'フラエッテ',
    hp: 54,
    attack: 45,
    defense: 47,
    specialAttack: 75,
    specialDefense: 98,
    speed: 52,
    weight: 0.9,
    types: [typesId.FAIRY],
    abilities: ['フラワーベール', 'きょうせい'],
  },
  {
    id: '671',
    nationalNumber: 671,
    name: 'フラージェス',
    hp: 78,
    attack: 65,
    defense: 68,
    specialAttack: 112,
    specialDefense: 154,
    speed: 75,
    weight: 10,
    types: [typesId.FAIRY],
    abilities: ['フラワーベール', 'きょうせい'],
  },
  {
    id: '50',
    nationalNumber: 50,
    name: 'ディグダ',
    hp: 10,
    attack: 55,
    defense: 25,
    specialAttack: 35,
    specialDefense: 45,
    speed: 95,
    weight: 0.8,
    types: [typesId.GROUND],
    abilities: ['すながくれ', 'ありじごく', 'すなのちから'],
  },
  {
    id: '51',
    nationalNumber: 51,
    name: 'ダグトリオ',
    hp: 35,
    attack: 100,
    defense: 50,
    specialAttack: 50,
    specialDefense: 70,
    speed: 120,
    weight: 33.3,
    types: [typesId.GROUND],
    abilities: ['すながくれ', 'ありじごく', 'すなのちから'],
  },
  {
    id: '324',
    nationalNumber: 324,
    name: 'コータス',
    hp: 70,
    attack: 85,
    defense: 140,
    specialAttack: 85,
    specialDefense: 70,
    speed: 20,
    weight: 80.4,
    types: [typesId.FIRE],
    abilities: ['しろいけむり', 'ひでり', 'シェルアーマー'],
  },
  {
    id: '322',
    nationalNumber: 322,
    name: 'ドンメル',
    hp: 60,
    attack: 60,
    defense: 40,
    specialAttack: 65,
    specialDefense: 45,
    speed: 35,
    weight: 24,
    types: [typesId.FIRE, typesId.GROUND],
    abilities: ['どんかん', 'たんじゅん', 'マイペース'],
  },
  {
    id: '323',
    nationalNumber: 323,
    name: 'バクーダ',
    hp: 70,
    attack: 100,
    defense: 70,
    specialAttack: 105,
    specialDefense: 75,
    speed: 40,
    weight: 220,
    types: [typesId.FIRE, typesId.GROUND],
    abilities: ['マグマのよろい', 'ハードロック', 'いかりのつぼ'],
  },
  {
    id: '436',
    nationalNumber: 436,
    name: 'ドーミラー',
    hp: 57,
    attack: 24,
    defense: 86,
    specialAttack: 24,
    specialDefense: 86,
    speed: 23,
    weight: 60.5,
    types: [typesId.STEEL, typesId.PSYCHIC],
    abilities: ['ふゆう', 'たいねつ', 'ヘヴィメタル'],
  },
  {
    id: '437',
    nationalNumber: 437,
    name: 'ドータクン',
    hp: 67,
    attack: 89,
    defense: 116,
    specialAttack: 79,
    specialDefense: 116,
    speed: 33,
    weight: 187,
    types: [typesId.STEEL, typesId.PSYCHIC],
    abilities: ['ふゆう', 'たいねつ', 'ヘヴィメタル'],
  },
  {
    id: '610',
    nationalNumber: 610,
    name: 'キバゴ',
    hp: 46,
    attack: 87,
    defense: 60,
    specialAttack: 30,
    specialDefense: 40,
    speed: 57,
    weight: 18,
    types: [typesId.DRAGON],
    abilities: ['とうそうしん', 'かたやぶり', 'きんちょうかん'],
  },
  {
    id: '611',
    nationalNumber: 611,
    name: 'オノンド',
    hp: 66,
    attack: 117,
    defense: 70,
    specialAttack: 40,
    specialDefense: 50,
    speed: 67,
    weight: 36,
    types: [typesId.DRAGON],
    abilities: ['とうそうしん', 'かたやぶり', 'きんちょうかん'],
  },
  {
    id: '612',
    nationalNumber: 612,
    name: 'オノノクス',
    hp: 76,
    attack: 147,
    defense: 90,
    specialAttack: 60,
    specialDefense: 70,
    speed: 97,
    weight: 105.5,
    types: [typesId.DRAGON],
    abilities: ['とうそうしん', 'かたやぶり', 'きんちょうかん'],
  },
  {
    id: '56',
    nationalNumber: 56,
    name: 'マンキー',
    hp: 40,
    attack: 80,
    defense: 35,
    specialAttack: 35,
    specialDefense: 45,
    speed: 70,
    weight: 28,
    types: [typesId.FIGHTING],
    abilities: ['やるき', 'いかりのつぼ', 'まけんき'],
  },
  {
    id: '57',
    nationalNumber: 57,
    name: 'オコリザル',
    hp: 65,
    attack: 105,
    defense: 60,
    specialAttack: 60,
    specialDefense: 70,
    speed: 95,
    weight: 32,
    types: [typesId.FIGHTING],
    abilities: ['やるき', 'いかりのつぼ', 'まけんき'],
  },
  {
    id: '1010',
    nationalNumber: 979,
    name: 'コノヨザル',
    hp: 110,
    attack: 115,
    defense: 80,
    specialAttack: 50,
    specialDefense: 90,
    speed: 90,
    weight: 56,
    types: [typesId.FIGHTING, typesId.GHOST],
    abilities: ['やるき', 'せいしんりょく', 'まけんき'],
  },
  {
    id: '307',
    nationalNumber: 307,
    name: 'アサナン',
    hp: 30,
    attack: 40,
    defense: 55,
    specialAttack: 40,
    specialDefense: 55,
    speed: 60,
    weight: 11.2,
    types: [typesId.FIGHTING, typesId.PSYCHIC],
    abilities: ['ヨガパワー', 'テレパシー'],
  },
  {
    id: '308',
    nationalNumber: 308,
    name: 'チャーレム',
    hp: 60,
    attack: 60,
    defense: 75,
    specialAttack: 60,
    specialDefense: 75,
    speed: 80,
    weight: 31.5,
    types: [typesId.FIGHTING, typesId.PSYCHIC],
    abilities: ['ヨガパワー', 'テレパシー'],
  },
  {
    id: '447',
    nationalNumber: 447,
    name: 'リオル',
    hp: 40,
    attack: 70,
    defense: 40,
    specialAttack: 35,
    specialDefense: 40,
    speed: 60,
    weight: 20.2,
    types: [typesId.FIGHTING],
    abilities: ['ふくつのこころ', 'せいしんりょく', 'いたずらごころ'],
  },
  {
    id: '448',
    nationalNumber: 448,
    name: 'ルカリオ',
    hp: 70,
    attack: 110,
    defense: 70,
    specialAttack: 115,
    specialDefense: 70,
    speed: 90,
    weight: 54,
    types: [typesId.FIGHTING, typesId.STEEL],
    abilities: ['ふくつのこころ', 'せいしんりょく', 'せいぎのこころ'],
  },
  {
    id: '1003',
    nationalNumber: 935,
    name: 'カルボウ',
    hp: 40,
    attack: 50,
    defense: 40,
    specialAttack: 50,
    specialDefense: 40,
    speed: 35,
    weight: 10.5,
    types: [typesId.FIRE],
    abilities: ['もらいび', 'ほのおのからだ'],
  },
  {
    id: '1004',
    nationalNumber: 936,
    name: 'グレンアルマ',
    hp: 85,
    attack: 60,
    defense: 100,
    specialAttack: 125,
    specialDefense: 80,
    speed: 75,
    weight: 85,
    types: [typesId.FIRE, typesId.PSYCHIC],
    abilities: ['もらいび', 'くだけるよろい'],
  },
  {
    id: '1005',
    nationalNumber: 937,
    name: 'ソウブレイズ',
    hp: 75,
    attack: 125,
    defense: 80,
    specialAttack: 60,
    specialDefense: 100,
    speed: 85,
    weight: 62,
    types: [typesId.FIRE, typesId.GHOST],
    abilities: ['もらいび', 'くだけるよろい'],
  },
  {
    id: '339',
    nationalNumber: 339,
    name: 'ドジョッチ',
    hp: 50,
    attack: 48,
    defense: 43,
    specialAttack: 46,
    specialDefense: 41,
    speed: 60,
    weight: 1.9,
    types: [typesId.WATER, typesId.GROUND],
    abilities: ['どんかん', 'きけんよち', 'うるおいボディ'],
  },
  {
    id: '340',
    nationalNumber: 340,
    name: 'ナマズン',
    hp: 110,
    attack: 78,
    defense: 73,
    specialAttack: 76,
    specialDefense: 71,
    speed: 60,
    weight: 23.6,
    types: [typesId.WATER, typesId.GROUND],
    abilities: ['どんかん', 'きけんよち', 'うるおいボディ'],
  },
  {
    id: '940',
    nationalNumber: 938,
    name: 'ズピカ',
    hp: 61,
    attack: 31,
    defense: 41,
    specialAttack: 59,
    specialDefense: 35,
    speed: 45,
    weight: 0.4,
    types: [typesId.ELECTRIC],
    abilities: ['マイペース', 'せいでんき', 'しめりけ'],
  },
  {
    id: '941',
    nationalNumber: 939,
    name: 'ハラバリー',
    hp: 109,
    attack: 64,
    defense: 91,
    specialAttack: 103,
    specialDefense: 83,
    speed: 45,
    weight: 113,
    types: [typesId.ELECTRIC],
    abilities: ['でんきにかえる', 'せいでんき', 'しめりけ'],
  },
  {
    id: '704',
    nationalNumber: 704,
    name: 'ヌメラ',
    hp: 45,
    attack: 50,
    defense: 35,
    specialAttack: 55,
    specialDefense: 75,
    speed: 40,
    weight: 2.8,
    types: [typesId.DRAGON],
    abilities: ['そうしょく', 'うるおいボディ', 'ぬめぬめ'],
  },
  {
    id: '705',
    nationalNumber: 705,
    name: 'ヌメイル',
    hp: 68,
    attack: 75,
    defense: 53,
    specialAttack: 83,
    specialDefense: 113,
    speed: 60,
    weight: 17.5,
    types: [typesId.DRAGON],
    abilities: ['そうしょく', 'うるおいボディ', 'ぬめぬめ'],
  },
  {
    id: '706',
    nationalNumber: 706,
    name: 'ヌメルゴン',
    hp: 90,
    attack: 100,
    defense: 70,
    specialAttack: 110,
    specialDefense: 150,
    speed: 80,
    weight: 150.5,
    types: [typesId.DRAGON],
    abilities: ['そうしょく', 'うるおいボディ', 'ぬめぬめ'],
  },
  {
    id: '453',
    nationalNumber: 453,
    name: 'グレッグル',
    hp: 48,
    attack: 61,
    defense: 40,
    specialAttack: 61,
    specialDefense: 40,
    speed: 50,
    weight: 23,
    types: [typesId.POISON, typesId.FIGHTING],
    abilities: ['きけんよち', 'かんそうはだ', 'どくしゅ'],
  },
  {
    id: '454',
    nationalNumber: 454,
    name: 'ドクロッグ',
    hp: 83,
    attack: 106,
    defense: 65,
    specialAttack: 86,
    specialDefense: 65,
    speed: 85,
    weight: 44.4,
    types: [typesId.POISON, typesId.FIGHTING],
    abilities: ['きけんよち', 'かんそうはだ', 'どくしゅ'],
  },
  {
    id: '957',
    nationalNumber: 940,
    name: 'カイデン',
    hp: 40,
    attack: 40,
    defense: 35,
    specialAttack: 55,
    specialDefense: 40,
    speed: 70,
    weight: 3.6,
    types: [typesId.ELECTRIC, typesId.FLYING],
    abilities: ['ふうりょくでんき', 'ちくでん', 'かちき'],
  },
  {
    id: '958',
    nationalNumber: 941,
    name: 'タイカイデン',
    hp: 70,
    attack: 70,
    defense: 60,
    specialAttack: 105,
    specialDefense: 60,
    speed: 125,
    weight: 38.6,
    types: [typesId.ELECTRIC, typesId.FLYING],
    abilities: ['ふうりょくでんき', 'ちくでん', 'かちき'],
  },
  {
    id: '133',
    nationalNumber: 133,
    name: 'イーブイ',
    hp: 55,
    attack: 55,
    defense: 50,
    specialAttack: 45,
    specialDefense: 65,
    speed: 55,
    weight: 6.5,
    types: [typesId.NORMAL],
    abilities: ['にげあし', 'てきおうりょく', 'きけんよち'],
  },
  {
    id: '134',
    nationalNumber: 134,
    name: 'シャワーズ',
    hp: 130,
    attack: 65,
    defense: 60,
    specialAttack: 110,
    specialDefense: 95,
    speed: 65,
    weight: 29,
    types: [typesId.WATER],
    abilities: ['ちょすい', 'うるおいボディ'],
  },
  {
    id: '135',
    nationalNumber: 135,
    name: 'サンダース',
    hp: 65,
    attack: 65,
    defense: 60,
    specialAttack: 110,
    specialDefense: 95,
    speed: 130,
    weight: 24.5,
    types: [typesId.ELECTRIC],
    abilities: ['ちくでん', 'はやあし'],
  },
  {
    id: '136',
    nationalNumber: 136,
    name: 'ブースター',
    hp: 65,
    attack: 130,
    defense: 60,
    specialAttack: 95,
    specialDefense: 110,
    speed: 65,
    weight: 25,
    types: [typesId.FIRE],
    abilities: ['もらいび', 'こんじょう'],
  },
  {
    id: '196',
    nationalNumber: 196,
    name: 'エーフィ',
    hp: 65,
    attack: 65,
    defense: 60,
    specialAttack: 130,
    specialDefense: 95,
    speed: 110,
    weight: 26.5,
    types: [typesId.PSYCHIC],
    abilities: ['シンクロ', 'マジックミラー'],
  },
  {
    id: '197',
    nationalNumber: 197,
    name: 'ブラッキー',
    hp: 95,
    attack: 65,
    defense: 110,
    specialAttack: 60,
    specialDefense: 130,
    speed: 65,
    weight: 27,
    types: [typesId.DARK],
    abilities: ['シンクロ', 'せいしんりょく'],
  },
  {
    id: '470',
    nationalNumber: 470,
    name: 'リーフィア',
    hp: 65,
    attack: 110,
    defense: 130,
    specialAttack: 60,
    specialDefense: 65,
    speed: 95,
    weight: 25.5,
    types: [typesId.GRASS],
    abilities: ['リーフガード', 'ようりょくそ'],
  },
  {
    id: '471',
    nationalNumber: 471,
    name: 'グレイシア',
    hp: 65,
    attack: 60,
    defense: 110,
    specialAttack: 130,
    specialDefense: 95,
    speed: 65,
    weight: 25.9,
    types: [typesId.ICE],
    abilities: ['ゆきがくれ', 'アイスボディ'],
  },
  {
    id: '700',
    nationalNumber: 700,
    name: 'ニンフィア',
    hp: 95,
    attack: 65,
    defense: 65,
    specialAttack: 110,
    specialDefense: 130,
    speed: 60,
    weight: 23.5,
    types: [typesId.FAIRY],
    abilities: ['メロメロボディ', 'フェアリースキン'],
  },
  {
    id: '206',
    nationalNumber: 206,
    name: 'ノコッチ',
    hp: 100,
    attack: 70,
    defense: 70,
    specialAttack: 65,
    specialDefense: 65,
    speed: 45,
    weight: 14,
    types: [typesId.NORMAL],
    abilities: ['てんのめぐみ', 'にげあし', 'びびり'],
  },
  {
    id: '917',
    nationalNumber: 982,
    name: 'ノココッチ',
    hp: 125,
    attack: 100,
    defense: 80,
    specialAttack: 85,
    specialDefense: 75,
    speed: 55,
    weight: 39.2,
    types: [typesId.NORMAL],
    abilities: ['てんのめぐみ', 'にげあし', 'びびり'],
  },
  {
    id: '585',
    nationalNumber: 585,
    name: 'シキジカ',
    hp: 60,
    attack: 60,
    defense: 50,
    specialAttack: 40,
    specialDefense: 50,
    speed: 75,
    weight: 19.5,
    types: [typesId.NORMAL, typesId.GRASS],
    abilities: ['ようりょくそ', 'そうしょく', 'てんのめぐみ'],
  },
  {
    id: '586',
    nationalNumber: 586,
    name: 'メブキジカ',
    hp: 80,
    attack: 100,
    defense: 70,
    specialAttack: 60,
    specialDefense: 70,
    speed: 95,
    weight: 92.5,
    types: [typesId.NORMAL, typesId.GRASS],
    abilities: ['ようりょくそ', 'そうしょく', 'てんのめぐみ'],
  },
  {
    id: '203',
    nationalNumber: 203,
    name: 'キリンリキ',
    hp: 70,
    attack: 80,
    defense: 65,
    specialAttack: 90,
    specialDefense: 65,
    speed: 85,
    weight: 41.5,
    types: [typesId.NORMAL, typesId.PSYCHIC],
    abilities: ['せいしんりょく', 'はやおき', 'そうしょく'],
  },
  {
    id: '928',
    nationalNumber: 981,
    name: 'リキキリン',
    hp: 120,
    attack: 90,
    defense: 70,
    specialAttack: 110,
    specialDefense: 70,
    speed: 60,
    weight: 160,
    types: [typesId.NORMAL, typesId.PSYCHIC],
    abilities: ['はんすう', 'テイルアーマー', 'そうしょく'],
  },
  {
    id: '88',
    nationalNumber: 88,
    name: 'ベトベター',
    hp: 80,
    attack: 80,
    defense: 50,
    specialAttack: 40,
    specialDefense: 50,
    speed: 25,
    weight: 30,
    types: [typesId.POISON],
    abilities: ['あくしゅう', 'ねんちゃく', 'どくしゅ'],
  },
  {
    id: '89',
    nationalNumber: 89,
    name: 'ベトベトン',
    hp: 105,
    attack: 105,
    defense: 75,
    specialAttack: 65,
    specialDefense: 100,
    speed: 50,
    weight: 30,
    types: [typesId.POISON],
    abilities: ['あくしゅう', 'ねんちゃく', 'どくしゅ'],
  },
  {
    id: '972',
    nationalNumber: 942,
    name: 'オラチフ',
    hp: 60,
    attack: 78,
    defense: 60,
    specialAttack: 40,
    specialDefense: 51,
    speed: 51,
    weight: 16,
    types: [typesId.DARK],
    abilities: ['いかく', 'にげあし', 'はりこみ'],
  },
  {
    id: '973',
    nationalNumber: 943,
    name: 'マフィティフ',
    hp: 80,
    attack: 120,
    defense: 90,
    specialAttack: 60,
    specialDefense: 70,
    speed: 85,
    weight: 61,
    types: [typesId.DARK],
    abilities: ['いかく', 'ばんけん', 'はりこみ'],
  },
  {
    id: '848',
    nationalNumber: 848,
    name: 'エレズン',
    hp: 40,
    attack: 38,
    defense: 35,
    specialAttack: 54,
    specialDefense: 35,
    speed: 40,
    weight: 11,
    types: [typesId.ELECTRIC, typesId.POISON],
    abilities: ['びびり', 'せいでんき', 'ぶきよう'],
  },
  {
    id: '849',
    nationalNumber: 849,
    name: 'ストリンダー(ハイ)',
    hp: 75,
    attack: 98,
    defense: 70,
    specialAttack: 114,
    specialDefense: 70,
    speed: 75,
    weight: 40,
    types: [typesId.ELECTRIC, typesId.POISON],
    abilities: ['パンクロック', 'プラス', 'テクニシャン'],
  },
  {
    id: '849f',
    nationalNumber: 849,
    name: 'ストリンダー(ロー)',
    hp: 75,
    attack: 98,
    defense: 70,
    specialAttack: 114,
    specialDefense: 70,
    speed: 75,
    weight: 40,
    types: [typesId.ELECTRIC, typesId.POISON],
    abilities: ['パンクロック', 'マイナス', 'テクニシャン'],
  },
  {
    id: '702',
    nationalNumber: 702,
    name: 'デデンネ',
    hp: 67,
    attack: 58,
    defense: 57,
    specialAttack: 81,
    specialDefense: 67,
    speed: 101,
    weight: 2.2,
    types: [typesId.ELECTRIC, typesId.FAIRY],
    abilities: ['ほおぶくろ', 'ものひろい', 'プラス'],
  },
  {
    id: '417',
    nationalNumber: 417,
    name: 'パチリス',
    hp: 60,
    attack: 45,
    defense: 70,
    specialAttack: 45,
    specialDefense: 90,
    speed: 95,
    weight: 3.9,
    types: [typesId.ELECTRIC],
    abilities: ['にげあし', 'ものひろい', 'ちくでん'],
  },
  {
    id: '968',
    nationalNumber: 944,
    name: 'シルシュルー',
    hp: 40,
    attack: 65,
    defense: 35,
    specialAttack: 40,
    specialDefense: 35,
    speed: 75,
    weight: 0.7,
    types: [typesId.POISON, typesId.NORMAL],
    abilities: ['かるわざ', 'わるいてぐせ', 'いたずらごころ'],
  },
  {
    id: '969',
    nationalNumber: 945,
    name: 'タギングル',
    hp: 63,
    attack: 95,
    defense: 65,
    specialAttack: 80,
    specialDefense: 72,
    speed: 110,
    weight: 27.2,
    types: [typesId.POISON, typesId.NORMAL],
    abilities: ['かるわざ', 'どくしゅ', 'いたずらごころ'],
  },
  {
    id: '234',
    nationalNumber: 234,
    name: 'オドシシ',
    hp: 73,
    attack: 95,
    defense: 62,
    specialAttack: 85,
    specialDefense: 65,
    speed: 85,
    weight: 71.2,
    types: [typesId.NORMAL],
    abilities: ['いかく', 'おみとおし', 'そうしょく'],
  },
  {
    id: '590',
    nationalNumber: 590,
    name: 'タマゲタケ',
    hp: 69,
    attack: 55,
    defense: 45,
    specialAttack: 55,
    specialDefense: 55,
    speed: 15,
    weight: 1,
    types: [typesId.GRASS, typesId.POISON],
    abilities: ['ほうし', 'さいせいりょく'],
  },
  {
    id: '591',
    nationalNumber: 591,
    name: 'モロバレル',
    hp: 114,
    attack: 85,
    defense: 70,
    specialAttack: 85,
    specialDefense: 80,
    speed: 30,
    weight: 10.5,
    types: [typesId.GRASS, typesId.POISON],
    abilities: ['ほうし', 'さいせいりょく'],
  },
  {
    id: '100',
    nationalNumber: 100,
    name: 'ビリリダマ',
    hp: 40,
    attack: 30,
    defense: 50,
    specialAttack: 55,
    specialDefense: 55,
    speed: 100,
    weight: 10.4,
    types: [typesId.ELECTRIC],
    abilities: ['ぼうおん', 'せいでんき', 'ゆうばく'],
  },
  {
    id: '101',
    nationalNumber: 101,
    name: 'マルマイン',
    hp: 60,
    attack: 50,
    defense: 70,
    specialAttack: 80,
    specialDefense: 80,
    speed: 150,
    weight: 66.6,
    types: [typesId.ELECTRIC],
    abilities: ['ぼうおん', 'せいでんき', 'ゆうばく'],
  },
  {
    id: '81',
    nationalNumber: 81,
    name: 'コイル',
    hp: 25,
    attack: 35,
    defense: 70,
    specialAttack: 95,
    specialDefense: 55,
    speed: 45,
    weight: 6,
    types: [typesId.ELECTRIC, typesId.STEEL],
    abilities: ['じりょく', 'がんじょう', 'アナライズ'],
  },
  {
    id: '82',
    nationalNumber: 82,
    name: 'レアコイル',
    hp: 50,
    attack: 60,
    defense: 95,
    specialAttack: 120,
    specialDefense: 70,
    speed: 70,
    weight: 60,
    types: [typesId.ELECTRIC, typesId.STEEL],
    abilities: ['じりょく', 'がんじょう', 'アナライズ'],
  },
  {
    id: '462',
    nationalNumber: 462,
    name: 'ジバコイル',
    hp: 70,
    attack: 70,
    defense: 115,
    specialAttack: 130,
    specialDefense: 90,
    speed: 60,
    weight: 180,
    types: [typesId.ELECTRIC, typesId.STEEL],
    abilities: ['じりょく', 'がんじょう', 'アナライズ'],
  },
  {
    id: '132',
    nationalNumber: 132,
    name: 'メタモン',
    hp: 48,
    attack: 48,
    defense: 48,
    specialAttack: 48,
    specialDefense: 48,
    speed: 48,
    weight: 4,
    types: [typesId.NORMAL],
    abilities: ['じゅうなん', 'かわりもの'],
  },
  {
    id: '58',
    nationalNumber: 58,
    name: 'ガーディ',
    hp: 55,
    attack: 70,
    defense: 45,
    specialAttack: 70,
    specialDefense: 50,
    speed: 60,
    weight: 19,
    types: [typesId.FIRE],
    abilities: ['いかく', 'もらいび', 'せいぎのこころ'],
  },
  {
    id: '59',
    nationalNumber: 59,
    name: 'ウインディ',
    hp: 90,
    attack: 110,
    defense: 80,
    specialAttack: 100,
    specialDefense: 80,
    speed: 95,
    weight: 155,
    types: [typesId.FIRE],
    abilities: ['いかく', 'もらいび', 'せいぎのこころ'],
  },
  {
    id: '216',
    nationalNumber: 216,
    name: 'ヒメグマ',
    hp: 60,
    attack: 80,
    defense: 50,
    specialAttack: 50,
    specialDefense: 50,
    speed: 40,
    weight: 8.8,
    types: [typesId.NORMAL],
    abilities: ['ものひろい', 'はやあし', 'みつあつめ'],
  },
  {
    id: '217',
    nationalNumber: 217,
    name: 'リングマ',
    hp: 90,
    attack: 130,
    defense: 75,
    specialAttack: 75,
    specialDefense: 75,
    speed: 55,
    weight: 125.8,
    types: [typesId.NORMAL],
    abilities: ['こんじょう', 'はやあし', 'きんちょうかん'],
  },
  {
    id: '335',
    nationalNumber: 335,
    name: 'ザングース',
    hp: 73,
    attack: 115,
    defense: 60,
    specialAttack: 60,
    specialDefense: 60,
    speed: 90,
    weight: 40.3,
    types: [typesId.NORMAL],
    abilities: ['めんえき', 'どくぼうそう'],
  },
  {
    id: '336',
    nationalNumber: 336,
    name: 'ハブネーク',
    hp: 73,
    attack: 100,
    defense: 60,
    specialAttack: 100,
    specialDefense: 60,
    speed: 65,
    weight: 52.5,
    types: [typesId.POISON],
    abilities: ['だっぴ', 'すりぬけ'],
  },
  {
    id: '333',
    nationalNumber: 333,
    name: 'チルット',
    hp: 45,
    attack: 40,
    defense: 60,
    specialAttack: 40,
    specialDefense: 75,
    speed: 50,
    weight: 1.2,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['しぜんかいふく', 'ノーてんき'],
  },
  {
    id: '334',
    nationalNumber: 334,
    name: 'チルタリス',
    hp: 75,
    attack: 70,
    defense: 90,
    specialAttack: 70,
    specialDefense: 105,
    speed: 80,
    weight: 20.6,
    types: [typesId.DRAGON, typesId.FLYING],
    abilities: ['しぜんかいふく', 'ノーてんき'],
  },
  {
    id: '672',
    nationalNumber: 672,
    name: 'メェークル',
    hp: 66,
    attack: 65,
    defense: 48,
    specialAttack: 62,
    specialDefense: 57,
    speed: 52,
    weight: 31,
    types: [typesId.GRASS],
    abilities: ['そうしょく', 'くさのけがわ'],
  },
  {
    id: '673',
    nationalNumber: 673,
    name: 'ゴーゴート',
    hp: 123,
    attack: 100,
    defense: 62,
    specialAttack: 97,
    specialDefense: 81,
    speed: 68,
    weight: 91,
    types: [typesId.GRASS],
    abilities: ['そうしょく', 'くさのけがわ'],
  },
  {
    id: '128a',
    nationalNumber: 128,
    name: 'ケンタロス(パルデア単)',
    hp: 75,
    attack: 110,
    defense: 105,
    specialAttack: 30,
    specialDefense: 70,
    speed: 100,
    weight: 115,
    types: [typesId.FIGHTING],
    abilities: ['いかく', 'いかりのつぼ', 'はんすう'],
  },
  {
    id: '128b',
    nationalNumber: 128,
    name: 'ケンタロス(パルデア炎)',
    hp: 75,
    attack: 110,
    defense: 105,
    specialAttack: 30,
    specialDefense: 70,
    speed: 100,
    weight: 85,
    types: [typesId.FIGHTING, typesId.FIRE],
    abilities: ['いかく', 'いかりのつぼ', 'はんすう'],
  },
  {
    id: '128c',
    nationalNumber: 128,
    name: 'ケンタロス(パルデア水)',
    hp: 75,
    attack: 110,
    defense: 105,
    specialAttack: 30,
    specialDefense: 70,
    speed: 100,
    weight: 110,
    types: [typesId.FIGHTING, typesId.WATER],
    abilities: ['いかく', 'いかりのつぼ', 'はんすう'],
  },
  {
    id: '667',
    nationalNumber: 667,
    name: 'シシコ',
    hp: 62,
    attack: 50,
    defense: 58,
    specialAttack: 73,
    specialDefense: 54,
    speed: 72,
    weight: 13.5,
    types: [typesId.FIRE, typesId.NORMAL],
    abilities: ['とうそうしん', 'きんちょうかん', 'じしんかじょう'],
  },
  {
    id: '668',
    nationalNumber: 668,
    name: 'カエンジシ',
    hp: 86,
    attack: 68,
    defense: 72,
    specialAttack: 109,
    specialDefense: 66,
    speed: 106,
    weight: 81.5,
    types: [typesId.FIRE, typesId.NORMAL],
    abilities: ['とうそうしん', 'きんちょうかん', 'じしんかじょう'],
  },
  {
    id: '434',
    nationalNumber: 434,
    name: 'スカンプー',
    hp: 63,
    attack: 63,
    defense: 47,
    specialAttack: 41,
    specialDefense: 41,
    speed: 74,
    weight: 19.2,
    types: [typesId.POISON, typesId.DARK],
    abilities: ['あくしゅう', 'ゆうばく', 'するどいめ'],
  },
  {
    id: '435',
    nationalNumber: 435,
    name: 'スカタンク',
    hp: 103,
    attack: 93,
    defense: 67,
    specialAttack: 71,
    specialDefense: 61,
    speed: 84,
    weight: 38,
    types: [typesId.POISON, typesId.DARK],
    abilities: ['あくしゅう', 'ゆうばく', 'するどいめ'],
  },
  {
    id: '570',
    nationalNumber: 570,
    name: 'ゾロア',
    hp: 40,
    attack: 65,
    defense: 40,
    specialAttack: 80,
    specialDefense: 40,
    speed: 65,
    weight: 12.5,
    types: [typesId.DARK],
    abilities: ['イリュージョン'],
  },
  {
    id: '571',
    nationalNumber: 571,
    name: 'ゾロアーク',
    hp: 60,
    attack: 105,
    defense: 60,
    specialAttack: 120,
    specialDefense: 60,
    speed: 105,
    weight: 81.1,
    types: [typesId.DARK],
    abilities: ['イリュージョン'],
  },
  {
    id: '215',
    nationalNumber: 215,
    name: 'ニューラ',
    hp: 55,
    attack: 95,
    defense: 55,
    specialAttack: 35,
    specialDefense: 75,
    speed: 115,
    weight: 28,
    types: [typesId.DARK, typesId.ICE],
    abilities: ['せいしんりょく', 'するどいめ', 'わるいてぐせ'],
  },
  {
    id: '461',
    nationalNumber: 461,
    name: 'マニューラ',
    hp: 70,
    attack: 120,
    defense: 65,
    specialAttack: 45,
    specialDefense: 85,
    speed: 125,
    weight: 34,
    types: [typesId.DARK, typesId.ICE],
    abilities: ['プレッシャー', 'わるいてぐせ'],
  },
  {
    id: '198',
    nationalNumber: 198,
    name: 'ヤミカラス',
    hp: 60,
    attack: 85,
    defense: 42,
    specialAttack: 85,
    specialDefense: 42,
    speed: 91,
    weight: 2.1,
    types: [typesId.DARK, typesId.FLYING],
    abilities: ['ふみん', 'きょううん', 'いたずらごころ'],
  },
  {
    id: '430',
    nationalNumber: 430,
    name: 'ドンカラス',
    hp: 100,
    attack: 125,
    defense: 52,
    specialAttack: 105,
    specialDefense: 52,
    speed: 71,
    weight: 27.3,
    types: [typesId.DARK, typesId.FLYING],
    abilities: ['ふみん', 'きょううん', 'じしんかじょう'],
  },
  {
    id: '574',
    nationalNumber: 574,
    name: 'ゴチム',
    hp: 45,
    attack: 30,
    defense: 50,
    specialAttack: 55,
    specialDefense: 65,
    speed: 45,
    weight: 5.8,
    types: [typesId.PSYCHIC],
    abilities: ['おみとおし', 'かちき', 'かげふみ'],
  },
  {
    id: '575',
    nationalNumber: 575,
    name: 'ゴチミル',
    hp: 60,
    attack: 45,
    defense: 70,
    specialAttack: 75,
    specialDefense: 85,
    speed: 55,
    weight: 18,
    types: [typesId.PSYCHIC],
    abilities: ['おみとおし', 'かちき', 'かげふみ'],
  },
  {
    id: '576',
    nationalNumber: 576,
    name: 'ゴチルゼル',
    hp: 70,
    attack: 55,
    defense: 95,
    specialAttack: 95,
    specialDefense: 110,
    speed: 65,
    weight: 44,
    types: [typesId.PSYCHIC],
    abilities: ['おみとおし', 'かちき', 'かげふみ'],
  },
  {
    id: '854',
    nationalNumber: 854,
    name: 'ヤバチャ',
    hp: 40,
    attack: 45,
    defense: 45,
    specialAttack: 74,
    specialDefense: 54,
    speed: 50,
    weight: 0.2,
    types: [typesId.GHOST],
    abilities: ['くだけるよろい', 'のろわれボディ'],
  },
  {
    id: '855',
    nationalNumber: 855,
    name: 'ポットデス',
    hp: 60,
    attack: 65,
    defense: 65,
    specialAttack: 134,
    specialDefense: 114,
    speed: 70,
    weight: 0.4,
    types: [typesId.GHOST],
    abilities: ['くだけるよろい', 'のろわれボディ'],
  },
  {
    id: '778',
    nationalNumber: 778,
    name: 'ミミッキュ',
    hp: 55,
    attack: 90,
    defense: 80,
    specialAttack: 50,
    specialDefense: 105,
    speed: 96,
    weight: 0.7,
    types: [typesId.GHOST, typesId.FAIRY],
    abilities: ['ばけのかわ'],
  },
  {
    id: '707',
    nationalNumber: 707,
    name: 'クレッフィ',
    hp: 57,
    attack: 80,
    defense: 91,
    specialAttack: 80,
    specialDefense: 87,
    speed: 75,
    weight: 3,
    types: [typesId.STEEL, typesId.FAIRY],
    abilities: ['いたずらごころ', 'マジシャン'],
  },
  {
    id: '876',
    nationalNumber: 876,
    name: 'イエッサン♂',
    hp: 60,
    attack: 65,
    defense: 55,
    specialAttack: 105,
    specialDefense: 95,
    speed: 95,
    weight: 28,
    types: [typesId.PSYCHIC, typesId.NORMAL],
    abilities: ['せいしんりょく', 'シンクロ', 'サイコメイカー'],
  },
  {
    id: '876f',
    nationalNumber: 876,
    name: 'イエッサン♀',
    hp: 70,
    attack: 55,
    defense: 65,
    specialAttack: 95,
    specialDefense: 105,
    speed: 85,
    weight: 28,
    types: [typesId.PSYCHIC, typesId.NORMAL],
    abilities: ['マイペース', 'シンクロ', 'サイコメイカー'],
  },
  {
    id: '974',
    nationalNumber: 946,
    name: 'アノクサ',
    hp: 40,
    attack: 65,
    defense: 30,
    specialAttack: 45,
    specialDefense: 35,
    speed: 60,
    weight: 0.6,
    types: [typesId.GRASS, typesId.GHOST],
    abilities: ['かぜのり', 'すりぬけ'],
  },
  {
    id: '975',
    nationalNumber: 947,
    name: 'アノホラグサ',
    hp: 55,
    attack: 115,
    defense: 70,
    specialAttack: 80,
    specialDefense: 70,
    speed: 90,
    weight: 6,
    types: [typesId.GRASS, typesId.GHOST],
    abilities: ['かぜのり', 'すりぬけ'],
  },
  {
    id: '1006',
    nationalNumber: 948,
    name: 'ノノクラゲ',
    hp: 40,
    attack: 40,
    defense: 35,
    specialAttack: 50,
    specialDefense: 100,
    speed: 70,
    weight: 33,
    types: [typesId.GROUND, typesId.GRASS],
    abilities: ['きんしのちから'],
  },
  {
    id: '1007',
    nationalNumber: 949,
    name: 'リククラゲ',
    hp: 80,
    attack: 70,
    defense: 65,
    specialAttack: 80,
    specialDefense: 120,
    speed: 100,
    weight: 58,
    types: [typesId.GROUND, typesId.GRASS],
    abilities: ['きんしのちから'],
  },
  {
    id: '357',
    nationalNumber: 357,
    name: 'トロピウス',
    hp: 99,
    attack: 68,
    defense: 83,
    specialAttack: 72,
    specialDefense: 87,
    speed: 51,
    weight: 100,
    types: [typesId.GRASS, typesId.FLYING],
    abilities: ['ようりょくそ', 'サンパワー', 'しゅうかく'],
  },
  {
    id: '753',
    nationalNumber: 753,
    name: 'カリキリ',
    hp: 40,
    attack: 55,
    defense: 35,
    specialAttack: 50,
    specialDefense: 35,
    speed: 35,
    weight: 1.5,
    types: [typesId.GRASS],
    abilities: ['リーフガード', 'あまのじゃく'],
  },
  {
    id: '754',
    nationalNumber: 754,
    name: 'ラランテス',
    hp: 70,
    attack: 105,
    defense: 90,
    specialAttack: 80,
    specialDefense: 90,
    speed: 45,
    weight: 18.5,
    types: [typesId.GRASS],
    abilities: ['リーフガード', 'あまのじゃく'],
  },
  {
    id: '962',
    nationalNumber: 950,
    name: 'ガケガニ',
    hp: 70,
    attack: 100,
    defense: 115,
    specialAttack: 35,
    specialDefense: 55,
    speed: 75,
    weight: 79,
    types: [typesId.ROCK],
    abilities: ['いかりのこうら', 'シェルアーマー', 'さいせいりょく'],
  },
  {
    id: '938',
    nationalNumber: 951,
    name: 'カプサイジ',
    hp: 50,
    attack: 62,
    defense: 40,
    specialAttack: 62,
    specialDefense: 40,
    speed: 50,
    weight: 3,
    types: [typesId.GRASS],
    abilities: ['ようりょくそ', 'ふみん', 'ぶきよう'],
  },
  {
    id: '939',
    nationalNumber: 952,
    name: 'スコヴィラン',
    hp: 65,
    attack: 108,
    defense: 65,
    specialAttack: 108,
    specialDefense: 65,
    speed: 75,
    weight: 15,
    types: [typesId.GRASS, typesId.FIRE],
    abilities: ['ようりょくそ', 'ふみん', 'ムラっけ'],
  },
  {
    id: '331',
    nationalNumber: 331,
    name: 'サボネア',
    hp: 50,
    attack: 85,
    defense: 40,
    specialAttack: 85,
    specialDefense: 40,
    speed: 35,
    weight: 51.3,
    types: [typesId.GRASS],
    abilities: ['すながくれ', 'ちょすい'],
  },
  {
    id: '332',
    nationalNumber: 332,
    name: 'ノクタス',
    hp: 70,
    attack: 115,
    defense: 60,
    specialAttack: 115,
    specialDefense: 60,
    speed: 55,
    weight: 77.4,
    types: [typesId.GRASS, typesId.DARK],
    abilities: ['すながくれ', 'ちょすい'],
  },
  {
    id: '922',
    nationalNumber: 953,
    name: 'シガロコ',
    hp: 41,
    attack: 50,
    defense: 60,
    specialAttack: 31,
    specialDefense: 58,
    speed: 30,
    weight: 1,
    types: [typesId.BUG],
    abilities: ['ふくがん', 'だっぴ'],
  },
  {
    id: '923',
    nationalNumber: 954,
    name: 'ベラカス',
    hp: 75,
    attack: 50,
    defense: 85,
    specialAttack: 115,
    specialDefense: 100,
    speed: 45,
    weight: 3.5,
    types: [typesId.BUG, typesId.PSYCHIC],
    abilities: ['シンクロ', 'テレパシー'],
  },
  {
    id: '48',
    nationalNumber: 48,
    name: 'コンパン',
    hp: 60,
    attack: 55,
    defense: 50,
    specialAttack: 40,
    specialDefense: 55,
    speed: 45,
    weight: 30,
    types: [typesId.BUG, typesId.POISON],
    abilities: ['ふくがん', 'いろめがね', 'にげあし'],
  },
  {
    id: '49',
    nationalNumber: 49,
    name: 'モルフォン',
    hp: 70,
    attack: 65,
    defense: 60,
    specialAttack: 90,
    specialDefense: 75,
    speed: 90,
    weight: 12.5,
    types: [typesId.BUG, typesId.POISON],
    abilities: ['りんぷん', 'いろめがね', 'ミラクルスキン'],
  },
  {
    id: '204',
    nationalNumber: 204,
    name: 'クヌギダマ',
    hp: 50,
    attack: 65,
    defense: 90,
    specialAttack: 35,
    specialDefense: 35,
    speed: 15,
    weight: 7.2,
    types: [typesId.BUG],
    abilities: ['がんじょう', 'ぼうじん'],
  },
  {
    id: '205',
    nationalNumber: 205,
    name: 'フォレトス',
    hp: 75,
    attack: 90,
    defense: 140,
    specialAttack: 60,
    specialDefense: 60,
    speed: 40,
    weight: 125.8,
    types: [typesId.BUG, typesId.STEEL],
    abilities: ['がんじょう', 'ぼうじん'],
  },
  {
    id: '123',
    nationalNumber: 123,
    name: 'ストライク',
    hp: 70,
    attack: 110,
    defense: 80,
    specialAttack: 55,
    specialDefense: 80,
    speed: 105,
    weight: 56,
    types: [typesId.BUG, typesId.FLYING],
    abilities: ['むしのしらせ', 'テクニシャン', 'ふくつのこころ'],
  },
  {
    id: '212',
    nationalNumber: 212,
    name: 'ハッサム',
    hp: 70,
    attack: 130,
    defense: 100,
    specialAttack: 55,
    specialDefense: 80,
    speed: 65,
    weight: 118,
    types: [typesId.BUG, typesId.STEEL],
    abilities: ['むしのしらせ', 'テクニシャン', 'ライトメタル'],
  },
  {
    id: '214',
    nationalNumber: 214,
    name: 'ヘラクロス',
    hp: 80,
    attack: 125,
    defense: 75,
    specialAttack: 40,
    specialDefense: 95,
    speed: 85,
    weight: 54,
    types: [typesId.BUG, typesId.FIGHTING],
    abilities: ['むしのしらせ', 'こんじょう', 'じしんかじょう'],
  },
  {
    id: '926',
    nationalNumber: 955,
    name: 'ヒラヒナ',
    hp: 30,
    attack: 35,
    defense: 30,
    specialAttack: 55,
    specialDefense: 30,
    speed: 75,
    weight: 1.5,
    types: [typesId.PSYCHIC],
    abilities: ['きけんよち', 'おみとおし', 'かそく'],
  },
  {
    id: '927',
    nationalNumber: 956,
    name: 'クエスパトラ',
    hp: 95,
    attack: 60,
    defense: 60,
    specialAttack: 101,
    specialDefense: 60,
    speed: 105,
    weight: 90,
    types: [typesId.PSYCHIC],
    abilities: ['びんじょう', 'おみとおし', 'かそく'],
  },
  {
    id: '449',
    nationalNumber: 449,
    name: 'ヒポポタス',
    hp: 68,
    attack: 72,
    defense: 78,
    specialAttack: 38,
    specialDefense: 42,
    speed: 32,
    weight: 49.5,
    types: [typesId.GROUND],
    abilities: ['すなおこし', 'すなのちから'],
  },
  {
    id: '450',
    nationalNumber: 450,
    name: 'カバルドン',
    hp: 108,
    attack: 112,
    defense: 118,
    specialAttack: 68,
    specialDefense: 72,
    speed: 47,
    weight: 300,
    types: [typesId.GROUND],
    abilities: ['すなおこし', 'すなのちから'],
  },
  {
    id: '551',
    nationalNumber: 551,
    name: 'メグロコ',
    hp: 50,
    attack: 72,
    defense: 35,
    specialAttack: 35,
    specialDefense: 35,
    speed: 65,
    weight: 15.2,
    types: [typesId.GROUND, typesId.DARK],
    abilities: ['いかく', 'じしんかじょう', 'いかりのつぼ'],
  },
  {
    id: '552',
    nationalNumber: 552,
    name: 'ワルビル',
    hp: 60,
    attack: 82,
    defense: 45,
    specialAttack: 45,
    specialDefense: 45,
    speed: 74,
    weight: 33.4,
    types: [typesId.GROUND, typesId.DARK],
    abilities: ['いかく', 'じしんかじょう', 'いかりのつぼ'],
  },
  {
    id: '553',
    nationalNumber: 553,
    name: 'ワルビアル',
    hp: 95,
    attack: 117,
    defense: 80,
    specialAttack: 65,
    specialDefense: 70,
    speed: 92,
    weight: 96.3,
    types: [typesId.GROUND, typesId.DARK],
    abilities: ['いかく', 'じしんかじょう', 'いかりのつぼ'],
  },
  {
    id: '843',
    nationalNumber: 843,
    name: 'スナヘビ',
    hp: 52,
    attack: 57,
    defense: 75,
    specialAttack: 35,
    specialDefense: 50,
    speed: 46,
    weight: 7.6,
    types: [typesId.GROUND],
    abilities: ['すなはき', 'だっぴ', 'すながくれ'],
  },
  {
    id: '844',
    nationalNumber: 844,
    name: 'サダイジャ',
    hp: 72,
    attack: 107,
    defense: 125,
    specialAttack: 65,
    specialDefense: 70,
    speed: 71,
    weight: 65.5,
    types: [typesId.GROUND],
    abilities: ['すなはき', 'だっぴ', 'すながくれ'],
  },
  {
    id: '749',
    nationalNumber: 749,
    name: 'ドロバンコ',
    hp: 70,
    attack: 100,
    defense: 70,
    specialAttack: 45,
    specialDefense: 55,
    speed: 45,
    weight: 110,
    types: [typesId.GROUND],
    abilities: ['マイペース', 'じきゅうりょく', 'せいしんりょく'],
  },
  {
    id: '750',
    nationalNumber: 750,
    name: 'バンバドロ',
    hp: 100,
    attack: 125,
    defense: 100,
    specialAttack: 55,
    specialDefense: 85,
    speed: 35,
    weight: 920,
    types: [typesId.GROUND],
    abilities: ['マイペース', 'じきゅうりょく', 'せいしんりょく'],
  },
  {
    id: '636',
    nationalNumber: 636,
    name: 'メラルバ',
    hp: 55,
    attack: 85,
    defense: 55,
    specialAttack: 50,
    specialDefense: 55,
    speed: 60,
    weight: 28.8,
    types: [typesId.BUG, typesId.FIRE],
    abilities: ['ほのおのからだ', 'むしのしらせ'],
  },
  {
    id: '637',
    nationalNumber: 637,
    name: 'ウルガモス',
    hp: 85,
    attack: 60,
    defense: 65,
    specialAttack: 135,
    specialDefense: 105,
    speed: 100,
    weight: 46,
    types: [typesId.BUG, typesId.FIRE],
    abilities: ['ほのおのからだ', 'むしのしらせ'],
  },
  {
    id: '371',
    nationalNumber: 371,
    name: 'タツベイ',
    hp: 45,
    attack: 75,
    defense: 60,
    specialAttack: 40,
    specialDefense: 30,
    speed: 50,
    weight: 42.1,
    types: [typesId.DRAGON],
    abilities: ['いしあたま', 'ちからずく'],
  },
  {
    id: '372',
    nationalNumber: 372,
    name: 'コモルー',
    hp: 65,
    attack: 95,
    defense: 100,
    specialAttack: 60,
    specialDefense: 50,
    speed: 50,
    weight: 110.5,
    types: [typesId.DRAGON],
    abilities: ['いしあたま', 'ぼうじん'],
  },
  {
    id: '373',
    nationalNumber: 373,
    name: 'ボーマンダ',
    hp: 95,
    attack: 135,
    defense: 80,
    specialAttack: 110,
    specialDefense: 80,
    speed: 100,
    weight: 102.6,
    types: [typesId.DRAGON, typesId.FLYING],
    abilities: ['いかく', 'じしんかじょう'],
  },
  {
    id: '1000',
    nationalNumber: 957,
    name: 'カヌチャン',
    hp: 50,
    attack: 45,
    defense: 45,
    specialAttack: 35,
    specialDefense: 64,
    speed: 58,
    weight: 8.9,
    types: [typesId.FAIRY, typesId.STEEL],
    abilities: ['かたやぶり', 'マイペース', 'わるいてぐせ'],
  },
  {
    id: '1001',
    nationalNumber: 958,
    name: 'ナカヌチャン',
    hp: 65,
    attack: 55,
    defense: 55,
    specialAttack: 45,
    specialDefense: 82,
    speed: 78,
    weight: 59.1,
    types: [typesId.FAIRY, typesId.STEEL],
    abilities: ['かたやぶり', 'マイペース', 'わるいてぐせ'],
  },
  {
    id: '1002',
    nationalNumber: 959,
    name: 'デカヌチャン',
    hp: 85,
    attack: 75,
    defense: 77,
    specialAttack: 70,
    specialDefense: 105,
    speed: 94,
    weight: 112.8,
    types: [typesId.FAIRY, typesId.STEEL],
    abilities: ['かたやぶり', 'マイペース', 'わるいてぐせ'],
  },
  {
    id: '856',
    nationalNumber: 856,
    name: 'ミブリム',
    hp: 42,
    attack: 30,
    defense: 45,
    specialAttack: 56,
    specialDefense: 53,
    speed: 39,
    weight: 3.4,
    types: [typesId.PSYCHIC],
    abilities: ['いやしのこころ', 'きけんよち', 'マジックミラー'],
  },
  {
    id: '857',
    nationalNumber: 857,
    name: 'テブリム',
    hp: 57,
    attack: 40,
    defense: 65,
    specialAttack: 86,
    specialDefense: 73,
    speed: 49,
    weight: 4.8,
    types: [typesId.PSYCHIC],
    abilities: ['いやしのこころ', 'きけんよち', 'マジックミラー'],
  },
  {
    id: '858',
    nationalNumber: 858,
    name: 'ブリムオン',
    hp: 57,
    attack: 90,
    defense: 95,
    specialAttack: 136,
    specialDefense: 103,
    speed: 29,
    weight: 5.1,
    types: [typesId.PSYCHIC, typesId.FAIRY],
    abilities: ['いやしのこころ', 'きけんよち', 'マジックミラー'],
  },
  {
    id: '859',
    nationalNumber: 859,
    name: 'ベロバー',
    hp: 45,
    attack: 45,
    defense: 30,
    specialAttack: 55,
    specialDefense: 40,
    speed: 50,
    weight: 5.5,
    types: [typesId.DARK, typesId.FAIRY],
    abilities: ['いたずらごころ', 'おみとおし', 'わるいてぐせ'],
  },
  {
    id: '860',
    nationalNumber: 860,
    name: 'ギモー',
    hp: 65,
    attack: 60,
    defense: 45,
    specialAttack: 75,
    specialDefense: 55,
    speed: 70,
    weight: 12.5,
    types: [typesId.DARK, typesId.FAIRY],
    abilities: ['いたずらごころ', 'おみとおし', 'わるいてぐせ'],
  },
  {
    id: '861',
    nationalNumber: 861,
    name: 'オーロンゲ',
    hp: 95,
    attack: 120,
    defense: 65,
    specialAttack: 95,
    specialDefense: 75,
    speed: 60,
    weight: 61,
    types: [typesId.DARK, typesId.FAIRY],
    abilities: ['いたずらごころ', 'おみとおし', 'わるいてぐせ'],
  },
  {
    id: '929',
    nationalNumber: 960,
    name: 'ウミディグダ',
    hp: 10,
    attack: 55,
    defense: 25,
    specialAttack: 35,
    specialDefense: 25,
    speed: 95,
    weight: 1.8,
    types: [typesId.WATER],
    abilities: ['ぬめぬめ', 'びびり', 'すながくれ'],
  },
  {
    id: '930',
    nationalNumber: 961,
    name: 'ウミトリオ',
    hp: 35,
    attack: 100,
    defense: 50,
    specialAttack: 50,
    specialDefense: 70,
    speed: 120,
    weight: 5.4,
    types: [typesId.WATER],
    abilities: ['ぬめぬめ', 'びびり', 'すながくれ'],
  },
  {
    id: '959',
    nationalNumber: 962,
    name: 'オトシドリ',
    hp: 70,
    attack: 103,
    defense: 85,
    specialAttack: 60,
    specialDefense: 85,
    speed: 82,
    weight: 42.9,
    types: [typesId.FLYING, typesId.DARK],
    abilities: ['はとむね', 'するどいめ', 'いわはこび'],
  },
  {
    id: '933',
    nationalNumber: 963,
    name: 'ナミイルカ',
    hp: 70,
    attack: 45,
    defense: 40,
    specialAttack: 45,
    specialDefense: 40,
    speed: 75,
    weight: 60.2,
    types: [typesId.WATER],
    abilities: ['みずのベール'],
  },
  {
    id: '934',
    nationalNumber: 964,
    name: 'イルカマン(ナイーブ)',
    hp: 100,
    attack: 70,
    defense: 72,
    specialAttack: 53,
    specialDefense: 62,
    speed: 100,
    weight: 60.2,
    types: [typesId.WATER],
    abilities: ['マイティチェンジ'],
  },
  {
    id: '934f',
    nationalNumber: 964,
    name: 'イルカマン(マイティ)',
    hp: 100,
    attack: 160,
    defense: 97,
    specialAttack: 106,
    specialDefense: 87,
    speed: 100,
    weight: 97.4,
    types: [typesId.WATER],
    abilities: ['マイティチェンジ'],
  },
  {
    id: '942',
    nationalNumber: 965,
    name: 'ブロロン',
    hp: 45,
    attack: 70,
    defense: 63,
    specialAttack: 30,
    specialDefense: 45,
    speed: 47,
    weight: 35,
    types: [typesId.STEEL, typesId.POISON],
    abilities: ['ぼうじん', 'スロースタート'],
  },
  {
    id: '943',
    nationalNumber: 966,
    name: 'ブロロローム',
    hp: 80,
    attack: 119,
    defense: 90,
    specialAttack: 54,
    specialDefense: 67,
    speed: 90,
    weight: 120,
    types: [typesId.STEEL, typesId.POISON],
    abilities: ['ぼうじん', 'フィルター'],
  },
  {
    id: '953',
    nationalNumber: 967,
    name: 'モトトカゲ',
    hp: 70,
    attack: 95,
    defense: 65,
    specialAttack: 85,
    specialDefense: 65,
    speed: 121,
    weight: 63,
    types: [typesId.DRAGON, typesId.NORMAL],
    abilities: ['だっぴ', 'さいせいりょく'],
  },
  {
    id: '944',
    nationalNumber: 968,
    name: 'ミミズズ',
    hp: 70,
    attack: 85,
    defense: 145,
    specialAttack: 60,
    specialDefense: 55,
    speed: 65,
    weight: 310,
    types: [typesId.STEEL],
    abilities: ['どしょく', 'すながくれ'],
  },
  {
    id: '302',
    nationalNumber: 302,
    name: 'ヤミラミ',
    hp: 50,
    attack: 75,
    defense: 75,
    specialAttack: 65,
    specialDefense: 65,
    speed: 50,
    weight: 11,
    types: [typesId.DARK, typesId.GHOST],
    abilities: ['するどいめ', 'あとだし', 'いたずらごころ'],
  },
  {
    id: '353',
    nationalNumber: 353,
    name: 'カゲボウズ',
    hp: 44,
    attack: 75,
    defense: 35,
    specialAttack: 63,
    specialDefense: 33,
    speed: 45,
    weight: 2.3,
    types: [typesId.GHOST],
    abilities: ['ふみん', 'おみとおし', 'のろわれボディ'],
  },
  {
    id: '354',
    nationalNumber: 354,
    name: 'ジュペッタ',
    hp: 64,
    attack: 115,
    defense: 65,
    specialAttack: 83,
    specialDefense: 63,
    speed: 65,
    weight: 12.5,
    types: [typesId.GHOST],
    abilities: ['ふみん', 'おみとおし', 'のろわれボディ'],
  },
  {
    id: '870',
    nationalNumber: 870,
    name: 'タイレーツ',
    hp: 65,
    attack: 100,
    defense: 100,
    specialAttack: 70,
    specialDefense: 60,
    speed: 75,
    weight: 62,
    types: [typesId.FIGHTING],
    abilities: ['カブトアーマー', 'まけんき'],
  },
  {
    id: '701',
    nationalNumber: 701,
    name: 'ルチャブル',
    hp: 78,
    attack: 92,
    defense: 75,
    specialAttack: 74,
    specialDefense: 63,
    speed: 118,
    weight: 21.5,
    types: [typesId.FIGHTING, typesId.FLYING],
    abilities: ['じゅうなん', 'かるわざ', 'かたやぶり'],
  },
  {
    id: '442',
    nationalNumber: 442,
    name: 'ミカルゲ',
    hp: 50,
    attack: 92,
    defense: 108,
    specialAttack: 92,
    specialDefense: 108,
    speed: 35,
    weight: 108,
    types: [typesId.GHOST, typesId.DARK],
    abilities: ['プレッシャー', 'すりぬけ'],
  },
  {
    id: '714',
    nationalNumber: 714,
    name: 'オンバット',
    hp: 40,
    attack: 30,
    defense: 35,
    specialAttack: 45,
    specialDefense: 40,
    speed: 55,
    weight: 8,
    types: [typesId.FLYING, typesId.DRAGON],
    abilities: ['おみとおし', 'すりぬけ', 'テレパシー'],
  },
  {
    id: '715',
    nationalNumber: 715,
    name: 'オンバーン',
    hp: 85,
    attack: 70,
    defense: 80,
    specialAttack: 97,
    specialDefense: 80,
    speed: 123,
    weight: 85,
    types: [typesId.FLYING, typesId.DRAGON],
    abilities: ['おみとおし', 'すりぬけ', 'テレパシー'],
  },
  {
    id: '885',
    nationalNumber: 885,
    name: 'ドラメシヤ',
    hp: 28,
    attack: 60,
    defense: 30,
    specialAttack: 40,
    specialDefense: 30,
    speed: 82,
    weight: 2,
    types: [typesId.DRAGON, typesId.GHOST],
    abilities: ['クリアボディ', 'すりぬけ', 'のろわれボディ'],
  },
  {
    id: '886',
    nationalNumber: 886,
    name: 'ドロンチ',
    hp: 68,
    attack: 80,
    defense: 50,
    specialAttack: 60,
    specialDefense: 50,
    speed: 102,
    weight: 11,
    types: [typesId.DRAGON, typesId.GHOST],
    abilities: ['クリアボディ', 'すりぬけ', 'のろわれボディ'],
  },
  {
    id: '887',
    nationalNumber: 887,
    name: 'ドラパルト',
    hp: 88,
    attack: 120,
    defense: 75,
    specialAttack: 100,
    specialDefense: 75,
    speed: 142,
    weight: 50,
    types: [typesId.DRAGON, typesId.GHOST],
    abilities: ['クリアボディ', 'すりぬけ', 'のろわれボディ'],
  },
  {
    id: '966',
    nationalNumber: 969,
    name: 'キラーメ',
    hp: 48,
    attack: 35,
    defense: 42,
    specialAttack: 105,
    specialDefense: 60,
    speed: 60,
    weight: 8,
    types: [typesId.ROCK, typesId.POISON],
    abilities: ['どくげしょう', 'ふしょく'],
  },
  {
    id: '967',
    nationalNumber: 970,
    name: 'キラフロル',
    hp: 83,
    attack: 55,
    defense: 90,
    specialAttack: 130,
    specialDefense: 81,
    speed: 86,
    weight: 45,
    types: [typesId.ROCK, typesId.POISON],
    abilities: ['どくげしょう', 'ふしょく'],
  },
  {
    id: '479',
    nationalNumber: 479,
    name: 'ロトム',
    hp: 50,
    attack: 50,
    defense: 77,
    specialAttack: 95,
    specialDefense: 77,
    speed: 91,
    weight: 0.3,
    types: [typesId.ELECTRIC, typesId.GHOST],
    abilities: ['ふゆう'],
  },
  {
    id: '479h',
    nationalNumber: 479,
    name: 'ヒートロトム',
    hp: 50,
    attack: 65,
    defense: 107,
    specialAttack: 105,
    specialDefense: 107,
    speed: 86,
    weight: 0.3,
    types: [typesId.ELECTRIC, typesId.FIRE],
    abilities: ['ふゆう'],
  },
  {
    id: '479w',
    nationalNumber: 479,
    name: 'ウォッシュロトム',
    hp: 50,
    attack: 65,
    defense: 107,
    specialAttack: 105,
    specialDefense: 107,
    speed: 86,
    weight: 0.3,
    types: [typesId.ELECTRIC, typesId.WATER],
    abilities: ['ふゆう'],
  },
  {
    id: '479f',
    nationalNumber: 479,
    name: 'フロストロトム',
    hp: 50,
    attack: 65,
    defense: 107,
    specialAttack: 105,
    specialDefense: 107,
    speed: 86,
    weight: 0.3,
    types: [typesId.ELECTRIC, typesId.ICE],
    abilities: ['ふゆう'],
  },
  {
    id: '479s',
    nationalNumber: 479,
    name: 'スピンロトム',
    hp: 50,
    attack: 65,
    defense: 107,
    specialAttack: 105,
    specialDefense: 107,
    speed: 86,
    weight: 0.3,
    types: [typesId.ELECTRIC, typesId.FLYING],
    abilities: ['ふゆう'],
  },
  {
    id: '479c',
    nationalNumber: 479,
    name: 'カットロトム',
    hp: 50,
    attack: 65,
    defense: 107,
    specialAttack: 105,
    specialDefense: 107,
    speed: 86,
    weight: 0.3,
    types: [typesId.ELECTRIC, typesId.GRASS],
    abilities: ['ふゆう'],
  },
  {
    id: '924',
    nationalNumber: 971,
    name: 'ボチ',
    hp: 50,
    attack: 61,
    defense: 60,
    specialAttack: 30,
    specialDefense: 55,
    speed: 34,
    weight: 35,
    types: [typesId.GHOST],
    abilities: ['ものひろい', 'もふもふ'],
  },
  {
    id: '925',
    nationalNumber: 972,
    name: 'ハカドッグ',
    hp: 72,
    attack: 101,
    defense: 100,
    specialAttack: 50,
    specialDefense: 97,
    speed: 68,
    weight: 15,
    types: [typesId.GHOST],
    abilities: ['すなかき', 'もふもふ'],
  },
  {
    id: '765',
    nationalNumber: 765,
    name: 'ヤレユータン',
    hp: 90,
    attack: 60,
    defense: 80,
    specialAttack: 90,
    specialDefense: 110,
    speed: 60,
    weight: 76,
    types: [typesId.NORMAL, typesId.PSYCHIC],
    abilities: ['せいしんりょく', 'テレパシー', 'きょうせい'],
  },
  {
    id: '766',
    nationalNumber: 766,
    name: 'ナゲツケサル',
    hp: 100,
    attack: 120,
    defense: 90,
    specialAttack: 40,
    specialDefense: 60,
    speed: 80,
    weight: 82.8,
    types: [typesId.FIGHTING],
    abilities: ['レシーバー', 'まけんき'],
  },
  {
    id: '775',
    nationalNumber: 775,
    name: 'ネッコアラ',
    hp: 65,
    attack: 115,
    defense: 65,
    specialAttack: 75,
    specialDefense: 95,
    speed: 65,
    weight: 19.9,
    types: [typesId.NORMAL],
    abilities: ['ぜったいねむり'],
  },
  {
    id: '246',
    nationalNumber: 246,
    name: 'ヨーギラス',
    hp: 50,
    attack: 64,
    defense: 50,
    specialAttack: 45,
    specialDefense: 50,
    speed: 41,
    weight: 72,
    types: [typesId.ROCK, typesId.GROUND],
    abilities: ['こんじょう', 'すながくれ'],
  },
  {
    id: '247',
    nationalNumber: 247,
    name: 'サナギラス',
    hp: 70,
    attack: 84,
    defense: 70,
    specialAttack: 65,
    specialDefense: 70,
    speed: 51,
    weight: 152,
    types: [typesId.ROCK, typesId.GROUND],
    abilities: ['だっぴ'],
  },
  {
    id: '248',
    nationalNumber: 248,
    name: 'バンギラス',
    hp: 100,
    attack: 134,
    defense: 110,
    specialAttack: 95,
    specialDefense: 100,
    speed: 61,
    weight: 202,
    types: [typesId.ROCK, typesId.DARK],
    abilities: ['すなおこし', 'きんちょうかん'],
  },
  {
    id: '874',
    nationalNumber: 874,
    name: 'イシヘンジン',
    hp: 100,
    attack: 125,
    defense: 135,
    specialAttack: 20,
    specialDefense: 20,
    speed: 70,
    weight: 520,
    types: [typesId.ROCK],
    abilities: ['パワースポット'],
  },
  {
    id: '875',
    nationalNumber: 875,
    name: 'コオリッポ(アイス)',
    hp: 75,
    attack: 80,
    defense: 110,
    specialAttack: 65,
    specialDefense: 90,
    speed: 50,
    weight: 89,
    types: [typesId.ICE],
    abilities: ['アイスフェイス'],
  },
  {
    id: '875f',
    nationalNumber: 875,
    name: 'コオリッポ(ナイス)',
    hp: 75,
    attack: 80,
    defense: 70,
    specialAttack: 65,
    specialDefense: 50,
    speed: 130,
    weight: 89,
    types: [typesId.ICE],
    abilities: ['アイスフェイス'],
  },
  {
    id: '871',
    nationalNumber: 871,
    name: 'バチンウニ',
    hp: 48,
    attack: 101,
    defense: 95,
    specialAttack: 91,
    specialDefense: 85,
    speed: 15,
    weight: 1,
    types: [typesId.ELECTRIC],
    abilities: ['ひらいしん', 'エレキメイカー'],
  },
  {
    id: '769',
    nationalNumber: 769,
    name: 'スナバァ',
    hp: 55,
    attack: 55,
    defense: 80,
    specialAttack: 70,
    specialDefense: 45,
    speed: 15,
    weight: 70,
    types: [typesId.GHOST, typesId.GROUND],
    abilities: ['みずがため', 'すながくれ'],
  },
  {
    id: '770',
    nationalNumber: 770,
    name: 'シロデスナ',
    hp: 85,
    attack: 75,
    defense: 110,
    specialAttack: 100,
    specialDefense: 75,
    speed: 35,
    weight: 250,
    types: [typesId.GHOST, typesId.GROUND],
    abilities: ['みずがため', 'すながくれ'],
  },
  {
    id: '79',
    nationalNumber: 79,
    name: 'ヤドン',
    hp: 90,
    attack: 65,
    defense: 65,
    specialAttack: 40,
    specialDefense: 40,
    speed: 15,
    weight: 36,
    types: [typesId.WATER, typesId.PSYCHIC],
    abilities: ['どんかん', 'マイペース', 'さいせいりょく'],
  },
  {
    id: '80',
    nationalNumber: 80,
    name: 'ヤドラン',
    hp: 95,
    attack: 75,
    defense: 110,
    specialAttack: 100,
    specialDefense: 80,
    speed: 30,
    weight: 78.5,
    types: [typesId.WATER, typesId.PSYCHIC],
    abilities: ['どんかん', 'マイペース', 'さいせいりょく'],
  },
  {
    id: '199',
    nationalNumber: 199,
    name: 'ヤドキング',
    hp: 95,
    attack: 75,
    defense: 80,
    specialAttack: 100,
    specialDefense: 110,
    speed: 30,
    weight: 79.5,
    types: [typesId.WATER, typesId.PSYCHIC],
    abilities: ['どんかん', 'マイペース', 'さいせいりょく'],
  },
  {
    id: '422',
    nationalNumber: 422,
    name: 'カラナクシ',
    hp: 76,
    attack: 48,
    defense: 48,
    specialAttack: 57,
    specialDefense: 62,
    speed: 34,
    weight: 6.3,
    types: [typesId.WATER],
    abilities: ['ねんちゃく', 'よびみず', 'すなのちから'],
  },
  {
    id: '423',
    nationalNumber: 423,
    name: 'トリトドン',
    hp: 111,
    attack: 83,
    defense: 68,
    specialAttack: 92,
    specialDefense: 82,
    speed: 39,
    weight: 29.9,
    types: [typesId.WATER, typesId.GROUND],
    abilities: ['ねんちゃく', 'よびみず', 'すなのちから'],
  },
  {
    id: '90',
    nationalNumber: 90,
    name: 'シェルダー',
    hp: 30,
    attack: 65,
    defense: 100,
    specialAttack: 45,
    specialDefense: 25,
    speed: 40,
    weight: 4,
    types: [typesId.WATER],
    abilities: ['シェルアーマー', 'スキルリンク', 'ぼうじん'],
  },
  {
    id: '91',
    nationalNumber: 91,
    name: 'パルシェン',
    hp: 50,
    attack: 95,
    defense: 180,
    specialAttack: 85,
    specialDefense: 45,
    speed: 70,
    weight: 132.5,
    types: [typesId.WATER, typesId.ICE],
    abilities: ['シェルアーマー', 'スキルリンク', 'ぼうじん'],
  },
  {
    id: '211',
    nationalNumber: 211,
    name: 'ハリーセン',
    hp: 65,
    attack: 95,
    defense: 85,
    specialAttack: 55,
    specialDefense: 55,
    speed: 85,
    weight: 3.9,
    types: [typesId.WATER, typesId.POISON],
    abilities: ['どくのトゲ', 'すいすい', 'いかく'],
  },
  {
    id: '370',
    nationalNumber: 370,
    name: 'ラブカス',
    hp: 43,
    attack: 30,
    defense: 55,
    specialAttack: 40,
    specialDefense: 65,
    speed: 97,
    weight: 8.7,
    types: [typesId.WATER],
    abilities: ['すいすい', 'うるおいボディ'],
  },
  {
    id: '456',
    nationalNumber: 456,
    name: 'ケイコウオ',
    hp: 49,
    attack: 49,
    defense: 56,
    specialAttack: 49,
    specialDefense: 61,
    speed: 66,
    weight: 7,
    types: [typesId.WATER],
    abilities: ['すいすい', 'よびみず', 'みずのベール'],
  },
  {
    id: '457',
    nationalNumber: 457,
    name: 'ネオラント',
    hp: 69,
    attack: 69,
    defense: 76,
    specialAttack: 69,
    specialDefense: 86,
    speed: 91,
    weight: 24,
    types: [typesId.WATER],
    abilities: ['すいすい', 'よびみず', 'みずのベール'],
  },
  {
    id: '779',
    nationalNumber: 779,
    name: 'ハギギシリ',
    hp: 68,
    attack: 105,
    defense: 70,
    specialAttack: 70,
    specialDefense: 70,
    speed: 92,
    weight: 19,
    types: [typesId.WATER, typesId.PSYCHIC],
    abilities: ['ビビッドボディ', 'がんじょうあご', 'ミラクルスキン'],
  },
  {
    id: '594',
    nationalNumber: 594,
    name: 'ママンボウ',
    hp: 165,
    attack: 75,
    defense: 80,
    specialAttack: 40,
    specialDefense: 45,
    speed: 65,
    weight: 31.6,
    types: [typesId.WATER],
    abilities: ['いやしのこころ', 'うるおいボディ', 'さいせいりょく'],
  },
  {
    id: '690',
    nationalNumber: 690,
    name: 'クズモー',
    hp: 50,
    attack: 60,
    defense: 60,
    specialAttack: 60,
    specialDefense: 60,
    speed: 30,
    weight: 7.3,
    types: [typesId.POISON, typesId.WATER],
    abilities: ['どくのトゲ', 'どくしゅ', 'てきおうりょく'],
  },
  {
    id: '691',
    nationalNumber: 691,
    name: 'ドラミドロ',
    hp: 65,
    attack: 75,
    defense: 90,
    specialAttack: 97,
    specialDefense: 123,
    speed: 44,
    weight: 81.5,
    types: [typesId.POISON, typesId.DRAGON],
    abilities: ['どくのトゲ', 'どくしゅ', 'てきおうりょく'],
  },
  {
    id: '692',
    nationalNumber: 692,
    name: 'ウデッポウ',
    hp: 50,
    attack: 53,
    defense: 62,
    specialAttack: 58,
    specialDefense: 63,
    speed: 44,
    weight: 8.3,
    types: [typesId.WATER],
    abilities: ['メガランチャー'],
  },
  {
    id: '693',
    nationalNumber: 693,
    name: 'ブロスター',
    hp: 71,
    attack: 73,
    defense: 88,
    specialAttack: 120,
    specialDefense: 89,
    speed: 59,
    weight: 35.3,
    types: [typesId.WATER],
    abilities: ['メガランチャー'],
  },
  {
    id: '602',
    nationalNumber: 602,
    name: 'シビシラス',
    hp: 35,
    attack: 55,
    defense: 40,
    specialAttack: 45,
    specialDefense: 40,
    speed: 60,
    weight: 0.3,
    types: [typesId.ELECTRIC],
    abilities: ['ふゆう'],
  },
  {
    id: '603',
    nationalNumber: 603,
    name: 'シビビール',
    hp: 65,
    attack: 85,
    defense: 70,
    specialAttack: 75,
    specialDefense: 70,
    speed: 40,
    weight: 22,
    types: [typesId.ELECTRIC],
    abilities: ['ふゆう'],
  },
  {
    id: '604',
    nationalNumber: 604,
    name: 'シビルドン',
    hp: 85,
    attack: 115,
    defense: 80,
    specialAttack: 105,
    specialDefense: 80,
    speed: 50,
    weight: 80.5,
    types: [typesId.ELECTRIC],
    abilities: ['ふゆう'],
  },
  {
    id: '747',
    nationalNumber: 747,
    name: 'ヒドイデ',
    hp: 50,
    attack: 53,
    defense: 62,
    specialAttack: 43,
    specialDefense: 52,
    speed: 45,
    weight: 8,
    types: [typesId.POISON, typesId.WATER],
    abilities: ['ひとでなし', 'じゅうなん', 'さいせいりょく'],
  },
  {
    id: '748',
    nationalNumber: 748,
    name: 'ドヒドイデ',
    hp: 50,
    attack: 63,
    defense: 152,
    specialAttack: 53,
    specialDefense: 142,
    speed: 35,
    weight: 14.5,
    types: [typesId.POISON, typesId.WATER],
    abilities: ['ひとでなし', 'じゅうなん', 'さいせいりょく'],
  },
  {
    id: '961',
    nationalNumber: 973,
    name: 'カラミンゴ',
    hp: 82,
    attack: 115,
    defense: 74,
    specialAttack: 75,
    specialDefense: 64,
    speed: 90,
    weight: 37,
    types: [typesId.FLYING, typesId.FIGHTING],
    abilities: ['きもったま', 'ちどりあし', 'きょうえん'],
  },
  {
    id: '147',
    nationalNumber: 147,
    name: 'ミニリュウ',
    hp: 41,
    attack: 64,
    defense: 45,
    specialAttack: 50,
    specialDefense: 50,
    speed: 50,
    weight: 3.3,
    types: [typesId.DRAGON],
    abilities: ['だっぴ', 'ふしぎなうろこ'],
  },
  {
    id: '148',
    nationalNumber: 148,
    name: 'ハクリュー',
    hp: 61,
    attack: 84,
    defense: 65,
    specialAttack: 70,
    specialDefense: 70,
    speed: 70,
    weight: 16.5,
    types: [typesId.DRAGON],
    abilities: ['だっぴ', 'ふしぎなうろこ'],
  },
  {
    id: '149',
    nationalNumber: 149,
    name: 'カイリュー',
    hp: 91,
    attack: 134,
    defense: 95,
    specialAttack: 100,
    specialDefense: 100,
    speed: 80,
    weight: 210,
    types: [typesId.DRAGON, typesId.FLYING],
    abilities: ['せいしんりょく', 'マルチスケイル'],
  },
  {
    id: '872',
    nationalNumber: 872,
    name: 'ユキハミ',
    hp: 30,
    attack: 25,
    defense: 35,
    specialAttack: 45,
    specialDefense: 30,
    speed: 20,
    weight: 3.8,
    types: [typesId.ICE, typesId.BUG],
    abilities: ['りんぷん', 'こおりのりんぷん'],
  },
  {
    id: '873',
    nationalNumber: 873,
    name: 'モスノウ',
    hp: 70,
    attack: 65,
    defense: 60,
    specialAttack: 125,
    specialDefense: 90,
    speed: 65,
    weight: 42,
    types: [typesId.ICE, typesId.BUG],
    abilities: ['りんぷん', 'こおりのりんぷん'],
  },
  {
    id: '459',
    nationalNumber: 459,
    name: 'ユキカブリ',
    hp: 60,
    attack: 62,
    defense: 50,
    specialAttack: 62,
    specialDefense: 60,
    speed: 40,
    weight: 50.5,
    types: [typesId.GRASS, typesId.ICE],
    abilities: ['ゆきふらし', 'ぼうおん'],
  },
  {
    id: '460',
    nationalNumber: 460,
    name: 'ユキノオー',
    hp: 90,
    attack: 92,
    defense: 75,
    specialAttack: 92,
    specialDefense: 85,
    speed: 60,
    weight: 135.5,
    types: [typesId.GRASS, typesId.ICE],
    abilities: ['ゆきふらし', 'ぼうおん'],
  },
  {
    id: '225',
    nationalNumber: 225,
    name: 'デリバード',
    hp: 45,
    attack: 55,
    defense: 45,
    specialAttack: 65,
    specialDefense: 45,
    speed: 75,
    weight: 16,
    types: [typesId.ICE, typesId.FLYING],
    abilities: ['やるき', 'はりきり', 'ふみん'],
  },
  {
    id: '613',
    nationalNumber: 613,
    name: 'クマシュン',
    hp: 55,
    attack: 70,
    defense: 40,
    specialAttack: 60,
    specialDefense: 40,
    speed: 40,
    weight: 8.5,
    types: [typesId.ICE],
    abilities: ['ゆきがくれ', 'ゆきかき', 'びびり'],
  },
  {
    id: '614',
    nationalNumber: 614,
    name: 'ツンベアー',
    hp: 95,
    attack: 130,
    defense: 80,
    specialAttack: 70,
    specialDefense: 80,
    speed: 50,
    weight: 260,
    types: [typesId.ICE],
    abilities: ['ゆきがくれ', 'ゆきかき', 'すいすい'],
  },
  {
    id: '361',
    nationalNumber: 361,
    name: 'ユキワラシ',
    hp: 50,
    attack: 50,
    defense: 50,
    specialAttack: 50,
    specialDefense: 50,
    speed: 50,
    weight: 16.8,
    types: [typesId.ICE],
    abilities: ['せいしんりょく', 'アイスボディ', 'ムラっけ'],
  },
  {
    id: '362',
    nationalNumber: 362,
    name: 'オニゴーリ',
    hp: 80,
    attack: 80,
    defense: 80,
    specialAttack: 80,
    specialDefense: 80,
    speed: 80,
    weight: 256.5,
    types: [typesId.ICE],
    abilities: ['せいしんりょく', 'アイスボディ', 'ムラっけ'],
  },
  {
    id: '478',
    nationalNumber: 478,
    name: 'ユキメノコ',
    hp: 70,
    attack: 80,
    defense: 70,
    specialAttack: 80,
    specialDefense: 70,
    speed: 110,
    weight: 26.6,
    types: [typesId.ICE, typesId.GHOST],
    abilities: ['ゆきがくれ', 'のろわれボディ'],
  },
  {
    id: '615',
    nationalNumber: 615,
    name: 'フリージオ',
    hp: 80,
    attack: 50,
    defense: 50,
    specialAttack: 95,
    specialDefense: 135,
    speed: 105,
    weight: 148,
    types: [typesId.ICE],
    abilities: ['ふゆう'],
  },
  {
    id: '947',
    nationalNumber: 974,
    name: 'アルクジラ',
    hp: 108,
    attack: 68,
    defense: 45,
    specialAttack: 30,
    specialDefense: 40,
    speed: 43,
    weight: 45,
    types: [typesId.ICE],
    abilities: ['あついしぼう', 'ゆきがくれ', 'ちからずく'],
  },
  {
    id: '948',
    nationalNumber: 975,
    name: 'ハルクジラ',
    hp: 170,
    attack: 113,
    defense: 65,
    specialAttack: 45,
    specialDefense: 55,
    speed: 73,
    weight: 700,
    types: [typesId.ICE],
    abilities: ['あついしぼう', 'ゆきかき', 'ちからずく'],
  },
  {
    id: '712',
    nationalNumber: 712,
    name: 'カチコール',
    hp: 55,
    attack: 69,
    defense: 85,
    specialAttack: 32,
    specialDefense: 35,
    speed: 28,
    weight: 99.5,
    types: [typesId.ICE],
    abilities: ['マイペース', 'アイスボディ', 'がんじょう'],
  },
  {
    id: '713',
    nationalNumber: 713,
    name: 'クレベース',
    hp: 95,
    attack: 117,
    defense: 184,
    specialAttack: 44,
    specialDefense: 46,
    speed: 28,
    weight: 505,
    types: [typesId.ICE],
    abilities: ['マイペース', 'アイスボディ', 'がんじょう'],
  },
  {
    id: '627',
    nationalNumber: 627,
    name: 'ワシボン',
    hp: 70,
    attack: 83,
    defense: 50,
    specialAttack: 37,
    specialDefense: 50,
    speed: 60,
    weight: 10.5,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['するどいめ', 'ちからずく', 'はりきり'],
  },
  {
    id: '628',
    nationalNumber: 628,
    name: 'ウォーグル',
    hp: 100,
    attack: 123,
    defense: 75,
    specialAttack: 57,
    specialDefense: 75,
    speed: 80,
    weight: 41,
    types: [typesId.NORMAL, typesId.FLYING],
    abilities: ['するどいめ', 'ちからずく', 'まけんき'],
  },
  {
    id: '624',
    nationalNumber: 624,
    name: 'コマタナ',
    hp: 45,
    attack: 85,
    defense: 70,
    specialAttack: 40,
    specialDefense: 40,
    speed: 60,
    weight: 10.2,
    types: [typesId.DARK, typesId.STEEL],
    abilities: ['まけんき', 'せいしんりょく', 'プレッシャー'],
  },
  {
    id: '625',
    nationalNumber: 625,
    name: 'キリキザン',
    hp: 65,
    attack: 125,
    defense: 100,
    specialAttack: 60,
    specialDefense: 70,
    speed: 70,
    weight: 70,
    types: [typesId.DARK, typesId.STEEL],
    abilities: ['まけんき', 'せいしんりょく', 'プレッシャー'],
  },
  {
    id: '1008',
    nationalNumber: 983,
    name: 'ドドゲザン',
    hp: 100,
    attack: 135,
    defense: 120,
    specialAttack: 60,
    specialDefense: 85,
    speed: 50,
    weight: 120,
    types: [typesId.DARK, typesId.STEEL],
    abilities: ['まけんき', 'そうだいしょう', 'プレッシャー'],
  },
  {
    id: '633',
    nationalNumber: 633,
    name: 'モノズ',
    hp: 52,
    attack: 65,
    defense: 50,
    specialAttack: 45,
    specialDefense: 50,
    speed: 38,
    weight: 17.3,
    types: [typesId.DARK, typesId.DRAGON],
    abilities: ['はりきり'],
  },
  {
    id: '634',
    nationalNumber: 634,
    name: 'ジヘッド',
    hp: 72,
    attack: 85,
    defense: 70,
    specialAttack: 65,
    specialDefense: 70,
    speed: 58,
    weight: 50,
    types: [typesId.DARK, typesId.DRAGON],
    abilities: ['はりきり'],
  },
  {
    id: '635',
    nationalNumber: 635,
    name: 'サザンドラ',
    hp: 92,
    attack: 105,
    defense: 90,
    specialAttack: 125,
    specialDefense: 90,
    speed: 98,
    weight: 160,
    types: [typesId.DARK, typesId.DRAGON],
    abilities: ['ふゆう'],
  },
  {
    id: '932',
    nationalNumber: 976,
    name: 'ミガルーサ',
    hp: 90,
    attack: 102,
    defense: 73,
    specialAttack: 78,
    specialDefense: 65,
    speed: 70,
    weight: 90,
    types: [typesId.WATER, typesId.PSYCHIC],
    abilities: ['かたやぶり', 'きれあじ'],
  },
  {
    id: '931',
    nationalNumber: 977,
    name: 'ヘイラッシャ',
    hp: 150,
    attack: 100,
    defense: 115,
    specialAttack: 65,
    specialDefense: 65,
    speed: 35,
    weight: 220,
    types: [typesId.WATER],
    abilities: ['てんねん', 'どんかん', 'みずのベール'],
  },
  {
    id: '952',
    nationalNumber: 978,
    name: 'シャリタツ',
    hp: 68,
    attack: 50,
    defense: 60,
    specialAttack: 120,
    specialDefense: 95,
    speed: 82,
    weight: 8,
    types: [typesId.DRAGON, typesId.WATER],
    abilities: ['しれいとう', 'よびみず'],
  },
  {
    id: '978',
    nationalNumber: 984,
    name: 'イダイナキバ',
    hp: 115,
    attack: 131,
    defense: 131,
    specialAttack: 53,
    specialDefense: 53,
    speed: 87,
    weight: 320,
    types: [typesId.GROUND, typesId.FIGHTING],
    abilities: ['こだいかっせい'],
  },
  {
    id: '982',
    nationalNumber: 985,
    name: 'サケブシッポ',
    hp: 115,
    attack: 65,
    defense: 99,
    specialAttack: 65,
    specialDefense: 115,
    speed: 111,
    weight: 8,
    types: [typesId.FAIRY, typesId.PSYCHIC],
    abilities: ['こだいかっせい'],
  },
  {
    id: '979',
    nationalNumber: 986,
    name: 'アラブルタケ',
    hp: 111,
    attack: 127,
    defense: 99,
    specialAttack: 79,
    specialDefense: 99,
    speed: 55,
    weight: 21,
    types: [typesId.GRASS, typesId.DARK],
    abilities: ['こだいかっせい'],
  },
  {
    id: '983',
    nationalNumber: 987,
    name: 'ハバタクカミ',
    hp: 55,
    attack: 55,
    defense: 55,
    specialAttack: 135,
    specialDefense: 135,
    speed: 135,
    weight: 4,
    types: [typesId.GHOST, typesId.FAIRY],
    abilities: ['こだいかっせい'],
  },
  {
    id: '984',
    nationalNumber: 988,
    name: 'チヲハウハネ',
    hp: 85,
    attack: 135,
    defense: 79,
    specialAttack: 85,
    specialDefense: 105,
    speed: 81,
    weight: 92,
    types: [typesId.BUG, typesId.FIGHTING],
    abilities: ['こだいかっせい'],
  },
  {
    id: '981',
    nationalNumber: 989,
    name: 'スナノケガワ',
    hp: 85,
    attack: 81,
    defense: 97,
    specialAttack: 121,
    specialDefense: 85,
    speed: 101,
    weight: 60,
    types: [typesId.ELECTRIC, typesId.GROUND],
    abilities: ['こだいかっせい'],
  },
  {
    id: '986',
    nationalNumber: 990,
    name: 'テツノワダチ',
    hp: 90,
    attack: 112,
    defense: 120,
    specialAttack: 72,
    specialDefense: 70,
    speed: 106,
    weight: 240,
    types: [typesId.GROUND, typesId.STEEL],
    abilities: ['クォークチャージ'],
  },
  {
    id: '992',
    nationalNumber: 991,
    name: 'テツノツツミ',
    hp: 56,
    attack: 80,
    defense: 114,
    specialAttack: 124,
    specialDefense: 60,
    speed: 136,
    weight: 11,
    types: [typesId.ICE, typesId.WATER],
    abilities: ['クォークチャージ'],
  },
  {
    id: '989',
    nationalNumber: 992,
    name: 'テツノカイナ',
    hp: 154,
    attack: 140,
    defense: 108,
    specialAttack: 50,
    specialDefense: 68,
    speed: 50,
    weight: 380.7,
    types: [typesId.FIGHTING, typesId.ELECTRIC],
    abilities: ['クォークチャージ'],
  },
  {
    id: '990',
    nationalNumber: 993,
    name: 'テツノコウベ',
    hp: 94,
    attack: 80,
    defense: 86,
    specialAttack: 122,
    specialDefense: 80,
    speed: 108,
    weight: 111,
    types: [typesId.DARK, typesId.FLYING],
    abilities: ['クォークチャージ'],
  },
  {
    id: '988',
    nationalNumber: 994,
    name: 'テツノドクガ',
    hp: 80,
    attack: 70,
    defense: 60,
    specialAttack: 140,
    specialDefense: 110,
    speed: 110,
    weight: 36,
    types: [typesId.FIRE, typesId.POISON],
    abilities: ['クォークチャージ'],
  },
  {
    id: '991',
    nationalNumber: 995,
    name: 'テツノイバラ',
    hp: 100,
    attack: 134,
    defense: 110,
    specialAttack: 70,
    specialDefense: 84,
    speed: 72,
    weight: 303,
    types: [typesId.ROCK, typesId.ELECTRIC],
    abilities: ['クォークチャージ'],
  },
  {
    id: '949',
    nationalNumber: 996,
    name: 'セビエ',
    hp: 65,
    attack: 75,
    defense: 45,
    specialAttack: 35,
    specialDefense: 45,
    speed: 55,
    weight: 17,
    types: [typesId.DRAGON, typesId.ICE],
    abilities: ['ねつこうかん', 'アイスボディ'],
  },
  {
    id: '950',
    nationalNumber: 997,
    name: 'セゴール',
    hp: 90,
    attack: 95,
    defense: 66,
    specialAttack: 45,
    specialDefense: 65,
    speed: 62,
    weight: 30,
    types: [typesId.DRAGON, typesId.ICE],
    abilities: ['ねつこうかん', 'アイスボディ'],
  },
  {
    id: '951',
    nationalNumber: 998,
    name: 'セグレイブ',
    hp: 115,
    attack: 145,
    defense: 92,
    specialAttack: 75,
    specialDefense: 86,
    speed: 87,
    weight: 210,
    types: [typesId.DRAGON, typesId.ICE],
    abilities: ['ねつこうかん', 'アイスボディ'],
  },
  {
    id: '976',
    nationalNumber: 999,
    name: 'コレクレー(はこ)',
    hp: 45,
    attack: 30,
    defense: 70,
    specialAttack: 75,
    specialDefense: 70,
    speed: 10,
    weight: 5,
    types: [typesId.GHOST],
    abilities: ['びびり'],
  },
  {
    id: '977',
    nationalNumber: 1000,
    name: 'サーフゴー',
    hp: 87,
    attack: 60,
    defense: 95,
    specialAttack: 133,
    specialDefense: 91,
    speed: 84,
    weight: 30,
    types: [typesId.STEEL, typesId.GHOST],
    abilities: ['おうごんのからだ'],
  },
  {
    id: '996',
    nationalNumber: 1001,
    name: 'チオンジェン',
    hp: 85,
    attack: 85,
    defense: 100,
    specialAttack: 95,
    specialDefense: 135,
    speed: 70,
    weight: 74.2,
    types: [typesId.DARK, typesId.GRASS],
    abilities: ['わざわいのおふだ'],
  },
  {
    id: '995',
    nationalNumber: 1002,
    name: 'パオジアン',
    hp: 80,
    attack: 120,
    defense: 80,
    specialAttack: 90,
    specialDefense: 65,
    speed: 135,
    weight: 152.2,
    types: [typesId.DARK, typesId.ICE],
    abilities: ['わざわいのつるぎ'],
  },
  {
    id: '994',
    nationalNumber: 1003,
    name: 'ディンルー',
    hp: 155,
    attack: 110,
    defense: 125,
    specialAttack: 55,
    specialDefense: 80,
    speed: 45,
    weight: 699.7,
    types: [typesId.DARK, typesId.GROUND],
    abilities: ['わざわいのうつわ'],
  },
  {
    id: '997',
    nationalNumber: 1004,
    name: 'イーユイ',
    hp: 55,
    attack: 80,
    defense: 80,
    specialAttack: 135,
    specialDefense: 120,
    speed: 100,
    weight: 4.9,
    types: [typesId.DARK, typesId.FIRE],
    abilities: ['わざわいのたま'],
  },
  {
    id: '985',
    nationalNumber: 1005,
    name: 'トドロクツキ',
    hp: 105,
    attack: 139,
    defense: 71,
    specialAttack: 55,
    specialDefense: 101,
    speed: 119,
    weight: 380,
    types: [typesId.DRAGON, typesId.DARK],
    abilities: ['こだいかっせい'],
  },
  {
    id: '993',
    nationalNumber: 1006,
    name: 'テツノブジン',
    hp: 74,
    attack: 130,
    defense: 90,
    specialAttack: 120,
    specialDefense: 60,
    speed: 116,
    weight: 35,
    types: [typesId.FAIRY, typesId.FIGHTING],
    abilities: ['クォークチャージ'],
  },
  {
    id: '998',
    nationalNumber: 1007,
    name: 'コライドン',
    hp: 100,
    attack: 135,
    defense: 115,
    specialAttack: 85,
    specialDefense: 100,
    speed: 135,
    weight: 303,
    types: [typesId.FIGHTING, typesId.DRAGON],
    abilities: ['ひひいろのこどう'],
  },
  {
    id: '999',
    nationalNumber: 1008,
    name: 'ミライドン',
    hp: 100,
    attack: 85,
    defense: 100,
    specialAttack: 135,
    specialDefense: 115,
    speed: 135,
    weight: 240,
    types: [typesId.ELECTRIC, typesId.DRAGON],
    abilities: ['ハドロンエンジン'],
  },
  {
    id: '4',
    nationalNumber: 4,
    name: 'ヒトカゲ',
    hp: 39,
    attack: 52,
    defense: 43,
    specialAttack: 60,
    specialDefense: 50,
    speed: 65,
    weight: 8.5,
    types: [typesId.FIRE],
    abilities: ['もうか', 'サンパワー'],
  },
  {
    id: '5',
    nationalNumber: 5,
    name: 'リザード',
    hp: 58,
    attack: 64,
    defense: 58,
    specialAttack: 80,
    specialDefense: 65,
    speed: 80,
    weight: 19,
    types: [typesId.FIRE],
    abilities: ['もうか', 'サンパワー'],
  },
  {
    id: '6',
    nationalNumber: 6,
    name: 'リザードン',
    hp: 78,
    attack: 84,
    defense: 78,
    specialAttack: 109,
    specialDefense: 85,
    speed: 100,
    weight: 90.5,
    types: [typesId.FIRE, typesId.FLYING],
    abilities: ['もうか', 'サンパワー'],
  },
  {
    id: '52g',
    nationalNumber: 52,
    name: 'ガラルニャース',
    hp: 50,
    attack: 65,
    defense: 55,
    specialAttack: 40,
    specialDefense: 40,
    speed: 40,
    weight: 7.5,
    types: [typesId.STEEL],
    abilities: ['ものひろい', 'かたいツメ', 'きんちょうかん'],
  },
  {
    id: '194',
    nationalNumber: 194,
    name: 'ウパー',
    hp: 55,
    attack: 45,
    defense: 45,
    specialAttack: 25,
    specialDefense: 25,
    speed: 15,
    weight: 8.5,
    types: [typesId.WATER, typesId.GROUND],
    abilities: ['しめりけ', 'ちょすい', 'てんねん'],
  },
  {
    id: '195',
    nationalNumber: 195,
    name: 'ヌオー',
    hp: 95,
    attack: 85,
    defense: 85,
    specialAttack: 65,
    specialDefense: 65,
    speed: 35,
    weight: 75,
    types: [typesId.WATER, typesId.GROUND],
    abilities: ['しめりけ', 'ちょすい', 'てんねん'],
  },
  {
    id: '813',
    nationalNumber: 813,
    name: 'ヒバニー',
    hp: 50,
    attack: 71,
    defense: 40,
    specialAttack: 40,
    specialDefense: 40,
    speed: 69,
    weight: 4.5,
    types: [typesId.FIRE],
    abilities: ['もうか', 'リベロ'],
  },
  {
    id: '814',
    nationalNumber: 814,
    name: 'ラビフット',
    hp: 65,
    attack: 86,
    defense: 60,
    specialAttack: 55,
    specialDefense: 60,
    speed: 94,
    weight: 9,
    types: [typesId.FIRE],
    abilities: ['もうか', 'リベロ'],
  },
  {
    id: '815',
    nationalNumber: 815,
    name: 'エースバーン',
    hp: 80,
    attack: 116,
    defense: 75,
    specialAttack: 65,
    specialDefense: 75,
    speed: 119,
    weight: 33,
    types: [typesId.FIRE],
    abilities: ['もうか', 'リベロ'],
  },
  {
    id: '863',
    nationalNumber: 863,
    name: 'ニャイキング',
    hp: 70,
    attack: 110,
    defense: 100,
    specialAttack: 50,
    specialDefense: 60,
    speed: 50,
    weight: 28,
    types: [typesId.STEEL],
    abilities: ['カブトアーマー', 'かたいツメ', 'はがねのせいしん'],
  },
]
