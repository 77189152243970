import { 最終ダメージ計算結果 } from '../../../domain/DamageFormula'

export const unifyDamageCalcResult = (
  results: 最終ダメージ計算結果[]
): 最終ダメージ計算結果 => {
  return {
    lowestDamage: results.reduce(
      (prev, current) => prev + current.lowestDamage,
      0
    ),
    highestDamage: results.reduce(
      (prev, current) => prev + current.highestDamage,
      0
    ),
    damages: results[0].damages, // TODO 確率計算しないと使えない
  }
}
