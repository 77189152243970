import { Box } from '@chakra-ui/react'

type BaseStatsBoxProps = {
  children: React.ReactNode
}
export const BaseStatsBox = (props: BaseStatsBoxProps) => {
  return (
    <Box width="50px" display="flex" justifyContent="center">
      {props.children}
    </Box>
  )
}
