import { useReducer } from 'react'
import { StatStage, StatStages } from '../../../domain/StatStage'
import { getBaseMove } from '../../../domain/BaseMove'
import { NatureCorrection, NatureCorrections } from '../../../domain/Nature'
import { calcNewEffortValue } from '../common/calcNewEffortValue'
import { IndivisualValues } from '../../../domain/IndivisualValue'
import { EffortValues } from '../../../domain/EffortValue'
import { TypeId } from '../../../domain/Type'
import { StatusConditions } from '../../../domain/StatusCondition'
import { MoveCustomParams } from '../../../domain/BattleMove'

export type Attacker = {
  id: number
  level: number
  heldItemId: number
  abilityId: number
  pokemonId: string
  indivisualValues: IndivisualValues
  effortValues: EffortValues
  natureCorrections: NatureCorrections
  moveId: number
  attackTime: number
  statStages: StatStages
  criticalHitIsEnabled: boolean
  weatherId: number
  terrainId: number
  statusConditions: StatusConditions
  teraType?: TypeId
  isBatteryCharging: boolean
  moveCustomParams: MoveCustomParams
  isOpen: boolean
}

export type AttackersState = Attacker[]

export type AttackersAction =
  | {
      type: 'init'
      payload: { attackersState: AttackersState }
    }
  | {
      type: 'default'
    }
  | {
      type: 'changeIv'
      payload: { id: number; value: number; key: keyof IndivisualValues }
    }
  | {
      type: 'changeEv'
      payload: { id: number; value: number; key: keyof EffortValues }
    }
  | {
      type: 'changeNatureCorrection'
      payload: {
        id: number
        value: NatureCorrection
        key: keyof NatureCorrections
      }
    }
  | {
      type: 'changeStatStage'
      payload: { id: number; value: StatStage; key: keyof StatStages }
    }
  | {
      type: 'changePokemonId'
      payload: { id: number; value: string }
    }
  | {
      type: 'changeMoveId'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeAttackTime'
      payload: { id: number; value: number }
    }
  | {
      type: 'add'
    }
  | {
      type: 'remove'
      payload: { id: number }
    }
  | {
      type: 'changeAbility'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeHeldItem'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeCriticalHitIsEnabled'
      payload: { id: number; value: boolean }
    }
  | {
      type: 'changeStatusCondition'
      payload: { id: number; value: boolean; key: keyof StatusConditions }
    }
  | {
      type: 'changeWeatherId'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeTerrainId'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeIsTerastal'
      payload: { id: number; value: boolean }
    }
  | {
      type: 'changeTeraType'
      payload: { id: number; value?: number }
    }
  | {
      type: 'changeIsBatteryCharging'
      payload: { id: number; value: boolean }
    }
  | {
      type: 'changeMoveCustomParam'
      payload: {
        id: number
        value: MoveCustomParams[keyof MoveCustomParams]
        key: keyof MoveCustomParams
      }
    }
  | {
      type: 'changeIsOpen'
      payload: {
        id: number
        value: boolean
      }
    }

const replaceState = (
  state: AttackersState,
  id: number,
  changeRecord: (record: Attacker) => Attacker
): AttackersState => {
  const index = state.findIndex((a) => a.id === id)
  return [
    ...state.slice(0, index),
    changeRecord(state[index]),
    ...state.slice(index + 1),
  ]
}
const defaultMoveCustomParams: MoveCustomParams = {
  totalStatStageCount: 0,
  lastRespectsCount: 0,
  rageFistCount: 0,
  acrobaticsBuff: false,
  knockOffBuff: false,
  avalancheBuff: false,
}
const reducer = (
  state: AttackersState,
  action: AttackersAction
): Attacker[] => {
  if (action.type === 'init') {
    return action.payload.attackersState
  } else if (action.type === 'default') {
    return [makeInitial(1)]
  } else if (action.type === 'changeIv') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      indivisualValues: {
        ...record.indivisualValues,
        [key]: value,
      },
      effortValues: {
        ...record.effortValues,
        [key]: calcNewEffortValue(value, record.effortValues[key]),
      },
    }))
  } else if (action.type === 'changeEv') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      effortValues: {
        ...record.effortValues,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeNatureCorrection') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      natureCorrections: {
        ...record.natureCorrections,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeStatStage') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      statStages: {
        ...record.statStages,
        [key]: value,
      },
    }))
  } else if (action.type === 'changePokemonId') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      pokemonId: value,
      abilityId: 0,
    }))
  } else if (action.type === 'changeMoveId') {
    const { id, value } = action.payload
    const move = getBaseMove(value)
    return replaceState(state, id, (record) => ({
      ...record,
      moveId: value,
      attackTime: move.attackTimes[0],
      totalStatStageCount: 0,
    }))
  } else if (action.type === 'changeAttackTime') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      attackTime: value,
    }))
  } else if (action.type === 'add') {
    const maxId = Math.max(...state.map((r) => r.id))
    const index = state.findIndex((r) => r.id === maxId)
    return [
      ...state.slice(0, index),
      { ...state[index], isOpen: false },
      deepCopyAttacker({ ...state[index], isOpen: true }, maxId + 1),
    ]
  } else if (action.type === 'remove') {
    const { id } = action.payload
    const index = state.findIndex((a) => a.id === id)
    return [...state.slice(0, index), ...state.slice(index + 1)]
  } else if (action.type === 'changeAbility') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      abilityId: value,
    }))
  } else if (action.type === 'changeHeldItem') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      heldItemId: value,
    }))
  } else if (action.type === 'changeCriticalHitIsEnabled') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      criticalHitIsEnabled: value,
    }))
  } else if (action.type === 'changeWeatherId') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      weatherId: value,
    }))
  } else if (action.type === 'changeTerrainId') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      terrainId: value,
    }))
  } else if (action.type === 'changeIsTerastal') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      isTerastal: value,
    }))
  } else if (action.type === 'changeStatusCondition') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      statusConditions: {
        ...record.statusConditions,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeTeraType') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      teraType: value,
    }))
  } else if (action.type === 'changeIsBatteryCharging') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      isBatteryCharging: value,
    }))
  } else if (action.type === 'changeMoveCustomParam') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      moveCustomParams: {
        ...record.moveCustomParams,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeIsOpen') {
    const { id, value } = action.payload

    return replaceState(state, id, (record) => ({
      ...record,
      isOpen: value,
    }))
  } else {
    throw new Error()
  }
}

export const deepCopyAttacker = (attacker: Attacker, id: number): Attacker => {
  return {
    ...attacker,
    indivisualValues: {
      ...attacker.indivisualValues,
    },
    effortValues: {
      ...attacker.effortValues,
    },
    natureCorrections: {
      ...attacker.natureCorrections,
    },
    statStages: {
      ...attacker.statStages,
    },
    statusConditions: {
      ...attacker.statusConditions,
    },
    moveCustomParams: {
      ...attacker.moveCustomParams,
    },
    id: id,
  }
}

const makeInitial = (id: number): Attacker => {
  return {
    id: id,
    level: 50,
    heldItemId: 0,
    abilityId: 0,
    pokemonId: '951',
    indivisualValues: {
      hp: 31,
      attack: 31,
      specialAttack: 31,
      defense: 31,
      specialDefense: 31,
      speed: 31,
    },
    effortValues: {
      hp: 0,
      attack: 252,
      defense: 252,
      specialAttack: 252,
      specialDefense: 252,
      speed: 0,
    },
    natureCorrections: {
      attack: '無し',
      defense: '無し',
      specialAttack: '無し',
      specialDefense: '無し',
      speed: '無し',
    },
    attackTime: 1,
    moveId: 877,
    statStages: {
      attack: 0,
      defense: 0,
      specialAttack: 0,
      specialDefense: 0,
      speed: 0,
    },
    statusConditions: {
      burn: false,
      freeze: false,
      paralysis: false,
      poison: false,
    },
    criticalHitIsEnabled: false,
    weatherId: 0,
    terrainId: 0,
    teraType: undefined,
    isBatteryCharging: false,
    moveCustomParams: defaultMoveCustomParams,
    isOpen: true,
  }
}
export const useAttackers = () => {
  const [state, dispatch] = useReducer(reducer, [makeInitial(1)])
  return { attackersState: state, attackersDispatch: dispatch }
}
