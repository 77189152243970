import { Box } from '@chakra-ui/react'
import { getType, TypeId } from '../../../../domain/Type'

type TypeSectionProps = {
  types: TypeId[]
}

export const TypeSection = ({ types }: TypeSectionProps) => {
  const text = getTypeString({ types })
  return <Box>{text}</Box>
}

export const getTypeString = ({ types }: TypeSectionProps) => {
  if (types.length === 1) {
    const type = getType(types[0])
    return type.name
  } else if (types.length === 2) {
    const type1 = getType(types[0])
    const type2 = getType(types[1])
    return type1.name + ' / ' + type2.name
  } else {
    throw new Error()
  }
}
