import { Image, ImageProps } from '@chakra-ui/react'

type HeldItemIconProps = {
  heldItemId: number
  width: string
  position?: ImageProps['position']
  top?: ImageProps['top']
  bottom?: ImageProps['bottom']
  left?: ImageProps['left']
  right?: ImageProps['right']
}
export const HeldItemIcon = (props: HeldItemIconProps) => {
  const src = `/helditem-icons/${props.heldItemId}.png`
  return (
    <Image
      src={src}
      width={props.width}
      position={props.position}
      top={props.top}
      bottom={props.bottom}
      left={props.left}
      right={props.right}
    />
  )
}
