import { Box, Button, Flex } from '@chakra-ui/react'
import { Dispatch, memo } from 'react'
import { MoveCustomParams } from '../../../../domain/BattleMove'
import { getType, TypeId } from '../../../../domain/Type'
import { Checkbox } from '../../../common/Checkbox'
import { MovePicker } from '../../../common/MovePicker'
import { NumberSelect } from '../../../common/NumberSelect'
import { TotalStatStageCountInput } from '../../common/TotalStatStageCountInput'
import { AttackersAction } from '../useAttackersState'
import { InputAttackTimes } from './InputAttackTimes'

type MoveSectionProps = {
  id: number
  moveId: number
  moveName: string
  power: string
  typeId: number
  categoryName: string
  attackTimes: number
  attackTimesOptions: number[]
  attackerTeraType?: TypeId
  moveCustomParams: MoveCustomParams
  dispatch: Dispatch<AttackersAction>
  dataTestIdMovePicker?: string
}

export const MoveSection = memo(
  ({
    id,
    moveId,
    moveName,
    power,
    typeId,
    categoryName,
    attackTimes,
    attackTimesOptions,
    attackerTeraType,
    moveCustomParams,
    dispatch,
    dataTestIdMovePicker,
  }: MoveSectionProps) => {
    const sameMoveAndAttackerTeraType =
      typeof attackerTeraType !== 'undefined' && typeId === attackerTeraType
    const handleChangeMoveId = (value: number) => {
      dispatch({ type: 'changeMoveId', payload: { id, value } })
    }
    const handleChangeAttackTimes = (value: number) => {
      dispatch({ type: 'changeAttackTime', payload: { id, value } })
    }
    const handleClickTerastal = () => {
      if (sameMoveAndAttackerTeraType) {
        dispatch({ type: 'changeTeraType', payload: { id, value: undefined } })
      } else {
        dispatch({ type: 'changeTeraType', payload: { id, value: typeId } })
      }
    }
    const handleChangeTotalStatStageCount = (value: number) => {
      dispatch({
        type: 'changeMoveCustomParam',
        payload: { id, value, key: 'totalStatStageCount' },
      })
    }
    const handleChangeLastRespectsCount = (value: number) => {
      dispatch({
        type: 'changeMoveCustomParam',
        payload: { id, value, key: 'lastRespectsCount' },
      })
    }
    const handleChangeRageFistCount = (value: number) => {
      dispatch({
        type: 'changeMoveCustomParam',
        payload: { id, value, key: 'rageFistCount' },
      })
    }
    const handleChangeAcrobaticsBuff = (value: boolean) => {
      dispatch({
        type: 'changeMoveCustomParam',
        payload: { id, value, key: 'acrobaticsBuff' },
      })
    }
    const handleChangeKnockOffBuff = (value: boolean) => {
      dispatch({
        type: 'changeMoveCustomParam',
        payload: { id, value, key: 'knockOffBuff' },
      })
    }
    const handleChangeAvalancheBuff = (value: boolean) => {
      dispatch({
        type: 'changeMoveCustomParam',
        payload: { id, value, key: 'avalancheBuff' },
      })
    }
    return (
      <>
        <Flex gap="7px" alignItems="center" justifyContent="space-around">
          <Box flex="1">
            <MovePicker
              value={moveId}
              onChange={handleChangeMoveId}
              dataTestId={dataTestIdMovePicker}
            />
          </Box>
          <InputAttackTimes
            display={attackTimesOptions.length > 1}
            value={attackTimes}
            options={attackTimesOptions}
            onChange={handleChangeAttackTimes}
          />
          <Box width="60px">威力{power}</Box>
          <Box width="80px">{getType(typeId).name}</Box>
          <Box width="35px">{categoryName}</Box>
          <Flex width="96px" justifyContent="center">
            <Button
              isActive={sameMoveAndAttackerTeraType}
              onClick={handleClickTerastal}
              size="sm"
              colorScheme="blue"
              variant="outline"
            >
              一致テラス
            </Button>
          </Flex>
        </Flex>
        {moveName === 'アシストパワー' && (
          <Box>
            合計能力ランクアップ数 (技の威力算出にのみ使用)
            <TotalStatStageCountInput
              value={moveCustomParams.totalStatStageCount}
              onChange={handleChangeTotalStatStageCount}
            />
          </Box>
        )}
        {moveName === 'おはかまいり' && (
          <Box>
            瀕死になった味方の数
            <NumberSelect
              value={moveCustomParams.lastRespectsCount}
              onChange={handleChangeLastRespectsCount}
              min={0}
              max={5}
            />
          </Box>
        )}
        {moveName === 'ふんどのこぶし' && (
          <Box>
            攻撃を受けた回数
            <NumberSelect
              value={moveCustomParams.rageFistCount}
              onChange={handleChangeRageFistCount}
              min={0}
              max={6}
            />
          </Box>
        )}
        {moveName === 'アクロバット' && (
          <Box>
            <Checkbox
              label="自分が持ち物を持っていない"
              value={moveCustomParams.acrobaticsBuff}
              onChange={handleChangeAcrobaticsBuff}
            />
          </Box>
        )}
        {moveName === 'はたきおとす' && (
          <Box>
            <Checkbox
              label="相手が持ち物を持っている"
              value={moveCustomParams.knockOffBuff}
              onChange={handleChangeKnockOffBuff}
            />
          </Box>
        )}
        {moveName === 'ゆきなだれ' && (
          <Box>
            <Checkbox
              label="このターンに相手からダメージを受けている"
              value={moveCustomParams.avalancheBuff}
              onChange={handleChangeAvalancheBuff}
            />
          </Box>
        )}
      </>
    )
  }
)
