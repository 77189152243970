import { Box } from '@chakra-ui/react'
import React, { memo } from 'react'
import styled from 'styled-components'
import { BaseStatsBox } from './BaseStatsBox'
import { EffortValueBox } from './EffortValueBox'
import { IndivisualValueBox } from './IndivisualValueBox'
import { LabelBox } from './LabelBox'
import { NatureCorrectionBox } from './NatureCorrectionBox'
import { StatsBox } from './StatsBox'
import { StatStageBox } from './StatStageBox'

const Root = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  height: 20px;
`

export const StatsLineHeader = memo(() => {
  return (
    <Root>
      <LabelBox> </LabelBox>
      <StatsBox fontSize="14px" color="gray">
        実数値
      </StatsBox>
      <BaseStatsBox>
        <Box fontSize="14px" color="gray">
          種族値
        </Box>
      </BaseStatsBox>
      <IndivisualValueBox>
        <Box fontSize="14px" color="gray">
          個体値
        </Box>
      </IndivisualValueBox>
      <EffortValueBox>
        <Box fontSize="14px" color="gray">
          努力値
        </Box>
      </EffortValueBox>
      <NatureCorrectionBox>
        <Box fontSize="14px" color="gray">
          性格補正
        </Box>
      </NatureCorrectionBox>
      <StatStageBox>
        <Box fontSize="14px" color="gray">
          ランク
        </Box>
      </StatStageBox>
    </Root>
  )
})
