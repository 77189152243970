import { Select } from '@chakra-ui/react'
import { memo } from 'react'
import { WEATHERS } from '../../domain/Weather'

type WeatherPickerProps = {
  value: number
  onChange: (value: number) => void
}

export const WeatherPicker = memo((props: WeatherPickerProps) => {
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    props.onChange(Number(event.target.value))
  }
  return (
    <Select value={props.value} onChange={onChange}>
      {WEATHERS.map((weather) => {
        return (
          <option key={weather.id} value={weather.id}>
            {weather.name}
          </option>
        )
      })}
    </Select>
  )
})
