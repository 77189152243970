import { ChevronDownIcon } from '@chakra-ui/icons'
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { forwardRef, memo, useCallback } from 'react'

type SearchTextAreaProps = {
  value: string
  onChange: (value: string) => void
  onKeyDownArrowUp: () => void
  onKeyDownArrowDown: () => void
  onKeyDownEnter: () => void
  onClickArrow: () => void
  onFocus: () => void
  onBlur?: () => void
  onClick?: () => void
  placeholder?: string
}

const SearchTextAreaBase = forwardRef<HTMLInputElement, SearchTextAreaProps>(
  (
    {
      value,
      onChange,
      onKeyDownArrowUp,
      onKeyDownArrowDown,
      onKeyDownEnter,
      onClickArrow,
      onFocus,
      onBlur,
      onClick,
      placeholder,
    },
    ref
  ) => {
    const handleChangeText: React.ChangeEventHandler<HTMLInputElement> =
      useCallback(
        (e) => {
          onChange(e.target.value)
        },
        [onChange]
      )

    const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> =
      useCallback(
        (event) => {
          if (event.key === 'ArrowDown') {
            onKeyDownArrowDown()
          } else if (event.key === 'ArrowUp') {
            onKeyDownArrowUp()
          } else if (event.key === 'Enter' || event.key === 'Tab') {
            onKeyDownEnter()
          }
        },
        [onKeyDownArrowDown, onKeyDownArrowUp, onKeyDownEnter]
      )

    return (
      <InputGroup>
        <Input
          ref={ref}
          value={value}
          onClick={onClick}
          onChange={handleChangeText}
          onFocus={onFocus}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
        />
        <InputRightElement
          children={<ChevronDownIcon onClick={onClickArrow} />}
        />
      </InputGroup>
    )
  }
)

export const SearchTextArea = memo(SearchTextAreaBase)
