import { Box, Flex } from '@chakra-ui/react'

type BaseStatsSectionProps = {
  hp: number
  attack: number
  deffense: number
  specialAttack: number
  specialDeffense: number
  speed: number
  weight: number
}
export const BaseStatsSection = (props: BaseStatsSectionProps) => {
  return (
    <Flex gap="5px">
      <Box>H{props.hp}</Box>
      <Box>A{props.attack}</Box>
      <Box>B{props.deffense}</Box>
      <Box>C{props.specialAttack}</Box>
      <Box>D{props.specialDeffense}</Box>
      <Box>S{props.speed}</Box>
      <Box>W{props.weight.toFixed(1)}kg</Box>
    </Flex>
  )
}
