import { Box, Flex } from '@chakra-ui/react'
import { memo } from 'react'
import { TypeId, types } from '../../../domain/Type'

type TeraTypePickerProps = {
  value?: TypeId
  onChange: (value?: TypeId) => void
  dataTestIdPrefix?: string
}

export const TeraTypePicker = memo(
  ({ value, onChange, dataTestIdPrefix }: TeraTypePickerProps) => {
    return (
      <Box>
        <Box fontSize="12px" color="gray">
          テラスタル
        </Box>
        <Flex gap="8px" userSelect="none">
          {types.map((type) => {
            const onClick = () => {
              if (type.id === value) {
                onChange(undefined)
              } else {
                onChange(type.id)
              }
            }
            return (
              <Box
                key={type.id}
                fontSize="24px"
                color={value === type.id ? 'black' : 'LightGray'}
                onClick={onClick}
                cursor="pointer"
                data-testid={dataTestIdPrefix + type.shortName}
              >
                {type.shortName}
              </Box>
            )
          })}
        </Flex>
      </Box>
    )
  }
)
