import { Dispatch, memo, useCallback } from 'react'
import { NatureCorrection, NatureCorrections } from '../../../domain/Nature'

import { DefendersAction } from './useDefendersState'
import { StatStage, StatStages } from '../../../domain/StatStage'
import { Box } from '@chakra-ui/react'
import { BaseStats } from '../../../domain/Stat'
import { IndivisualValues } from '../../../domain/IndivisualValue'
import { EffortValues } from '../../../domain/EffortValue'
import { StatsForm } from '../common/StatsForm'

type DefenderStatsSectionProps = {
  id: number
  level: number
  baseStats: BaseStats
  indivisualValues: IndivisualValues
  effortValues: EffortValues
  natureCorrections: NatureCorrections
  statStages: StatStages
  isIncludeDefenderAttackReference: boolean
  dispatch: Dispatch<DefendersAction>
}

export const DefenderStatsSection = memo(
  ({
    id,
    level,
    baseStats,
    indivisualValues,
    effortValues,
    natureCorrections,
    statStages,
    isIncludeDefenderAttackReference,
    dispatch,
  }: DefenderStatsSectionProps) => {
    const handleChangeIndivisualValue = useCallback(
      (key: keyof IndivisualValues, value: number) => {
        dispatch({
          type: 'changeIv',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeEffortValue = useCallback(
      (key: keyof EffortValues, value: number) => {
        dispatch({
          type: 'changeEv',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeNatureCorrection = useCallback(
      (key: keyof NatureCorrections, value: NatureCorrection) => {
        dispatch({
          type: 'changeNatureCorrection',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeStatStages = useCallback(
      (key: keyof StatStages, value: StatStage) => {
        dispatch({
          type: 'changeStatStage',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )

    return (
      <Box data-testid={`defender-${id}-stats`}>
        <StatsForm
          display={{
            hp: true,
            attack: isIncludeDefenderAttackReference,
            defense: true,
            specialAttack: false,
            specialDefense: true,
            speed: false,
          }}
          level={level}
          baseStats={baseStats}
          indivisualValues={indivisualValues}
          effortValues={effortValues}
          natureCorrections={natureCorrections}
          statStages={statStages}
          onChangeIndivisualValue={handleChangeIndivisualValue}
          onChangeEffortValue={handleChangeEffortValue}
          onChangeNatureCorrection={handleChangeNatureCorrection}
          onChangeStatStage={handleChangeStatStages}
          dataTestIdPrefix={`defender-${id}-stats`}
        />
      </Box>
    )
  }
)
