import React, { useCallback, useMemo } from 'react'
import { moves } from '../../domain/BaseMove'
import { SearchablePicker } from './SearchablePicker'

type MovePickerProps = {
  value: number
  onChange(value: number): void
  dataTestId?: string
}

export const MovePicker = ({
  value,
  onChange,
  dataTestId,
}: MovePickerProps) => {
  const options = useMemo(
    () => moves.map((m) => ({ value: String(m.id), name: m.name })),
    []
  )
  const handleChange = useCallback(
    (value: string) => {
      onChange(Number(value))
    },
    [onChange]
  )
  return (
    <SearchablePicker
      value={String(value)}
      onChange={handleChange}
      options={options}
      dataTestId={dataTestId}
    />
  )
}
