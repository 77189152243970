import { Box } from '@chakra-ui/react'
import { memo, useEffect, useRef } from 'react'
import { BasicOptionCard } from './BasicOptionCard'
import { OptionBox } from './OptionBox'
import { OptionsBox } from './OptionsBox'
import { SearchTextArea } from './SearchTextArea'
import { useOptions } from './useCursor'
import { useModalState } from './useModalState'

export type SearchablePickerOption = {
  value: string
  name: string
}

export type SearchablePickerProps = {
  value: string
  onChange: (value: string) => void
  options: SearchablePickerOption[]
  emptyValue?: string
  placeholder?: string
  dataTestId?: string
}

export const SearchablePicker = memo(
  ({
    value,
    onChange,
    options,
    emptyValue,
    placeholder,
    dataTestId,
  }: SearchablePickerProps) => {
    const { ref, isOpen, open, close } = useModalState()
    const {
      searchText,
      cursor,
      cursorIndex,
      filteredOptions,
      next,
      prev,
      cancel,
      clear,
      change,
      update,
    } = useOptions({
      value,
      emptyValue,
      options,
    })
    const scrollRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleFocus = () => {
      clear()
      open()
    }

    const handleClickArrow = () => {
      inputRef.current?.focus()
    }

    const handleClickOption = (value: string) => {
      update(value)
      onChange(value)
      close()
    }
    const handleChange = (value: string) => {
      change(value)
      open()
    }

    const handleKeyDownEnter = () => {
      onChange(cursor)
      update(cursor)
      close()
    }

    useEffect(() => {
      scrollRef.current?.scroll({ top: cursorIndex * 35 - 40 })
    }, [cursorIndex])

    const handleBlur = () => {
      close()
      cancel()
    }

    return (
      <Box ref={ref} data-testid={dataTestId}>
        <SearchTextArea
          ref={inputRef}
          value={searchText}
          placeholder={placeholder}
          onChange={handleChange}
          onClickArrow={handleClickArrow}
          onKeyDownArrowUp={prev}
          onKeyDownArrowDown={next}
          onKeyDownEnter={handleKeyDownEnter}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <OptionsBox isOpen={isOpen} ref={scrollRef}>
          {filteredOptions.map((option) => {
            return (
              <OptionBox
                key={option.value}
                value={option.value}
                isHovered={option.value === cursor}
                isSelected={option.value === value}
                onClick={handleClickOption}
              >
                <BasicOptionCard value={option.value} name={option.name} />
              </OptionBox>
            )
          })}
        </OptionsBox>
      </Box>
    )
  }
)
