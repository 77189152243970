import React, { useMemo } from 'react'
import { pokemons } from '../../domain/data/Pokemons'
import { SearchablePicker } from './SearchablePicker'

type PokemonsPickerProps = {
  value: string
  onChange: (value: string) => void
  dataTestId?: string
}

export const PokemonsPicker = ({
  value,
  onChange,
  dataTestId,
}: PokemonsPickerProps) => {
  const options = useMemo(
    () => pokemons.map((p) => ({ value: p.id, name: p.name })),
    []
  )
  return (
    <SearchablePicker
      value={value}
      onChange={onChange}
      options={options}
      dataTestId={dataTestId}
    />
  )
}
