import { Box, Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'
import { Header } from '../components/disclaimer/Header'

export const DisclaimerPage = () => {
  return (
    <Box>
      <Header height="56px" />
      <Box padding="30px">
        <Card>
          <CardHeader>
            <Heading>免責事項</Heading>
          </CardHeader>
          <CardBody>
            <p>
              当サイトを利用した結果生じた損害に対する一切の責任を負いません。
            </p>
            <p>当サイトの正確性・安全性を一切保証しておりません。</p>
            <p>
              当サイトは個人が運営するポケットモンスター（ポケモン）のファンサイトであり、株式会社ポケモン他企業様とは一切関係ありません。
            </p>
            <p>
              「ポケットモンスター」「ポケモン」「Pokémon」は任天堂・クリーチャーズ・ゲームフリークの登録商標です。ポケットモンスター（ポケモン）に関する全ての著作権は任天堂株式会社や株式会社ポケモンなどが所有しております。
            </p>
            <p>
              当サイトは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、(
              <a href="https://twitter.com/ayataka0nk">
                当サイト製作者のTwitter:あやたか
              </a>
              )までご連絡ください。連絡者が公式の担当者である確認が取れた場合に限り、迅速に対応いたします。
            </p>
          </CardBody>
        </Card>
      </Box>
    </Box>
  )
}
