import {
  biteMoves,
  getMoveCategory,
  BaseMove,
  waveMoves,
  weightRefMoves,
  ignoreBurnDebuffMoves,
} from './BaseMove'
import {
  ダメージの補正値を計算する引数,
  威力の補正値を計算する引数,
  技の威力を計算する引数,
  攻撃の補正値を計算する引数,
  最終ダメージを計算する引数,
  最終威力を計算する引数,
  最終攻撃を計算する引数,
  最終防御を計算する引数,
  防御の補正値を計算する引数,
} from './DamageFormula'
import {
  Environment,
  BattlePokemon,
  AttackerProperty,
  DefenderProperty,
} from './BattlePokemon'
import { judgeTeraBlastCategory } from './TeraBlast'
import {
  countResistantAgainst,
  countStrongAgainst,
  countWeakAgainst,
  TypeId,
  typesId,
} from './Type'
import { WEATHERS_ID } from './Weather'
import { TERRAINS_ID } from './Terrain'
import { hasAnyStatusCondition } from './StatusCondition'
import { MoveCustomParams } from './BattleMove'
import { ignoreBurnDebuffAbilities } from './Ability'

export type AttackSituation = {
  attacker: BattlePokemon & Environment & AttackerProperty
  defender: BattlePokemon & Environment & DefenderProperty
  baseMove: BaseMove
  move: {
    typeId: TypeId
    category: string
    customParams: MoveCustomParams
  }
}
const onTheGround = (args: BattlePokemon) => {
  if (args.ability.name !== 'ふゆう') {
    return true
  }

  if (args.isTerastal) {
    return args.teraType !== typesId.FLYING
  } else {
    return !args.typeIds.includes(typesId.FLYING)
  }
}

export const 技の威力を計算する引数を生成する = (
  args: AttackSituation
): 技の威力を計算する引数 => {
  const value = {
    表記威力: args.baseMove.power,
    エラがみ: false,
    アクロバット: false,
    でんげきくちばし: false,
    ダメおし: false,
    たたりめ: false,
    アシストパワー: false,
    合計能力ランクアップ数: 0,
    ヘビーボンバー: false,
    ヒートスタンプ: false,
    くさむすび: false,
    攻撃側の重さ: args.attacker.weight,
    防御側の重さ: args.defender.weight,
    おはかまいり: false,
    倒された味方の数: 0,
    ふんどのこぶし: false,
    攻撃を受けた回数: 0,
    けたぐり: false,
    ゆきなだれ: false,
  }
  // TODO エラがみでんくちダメ押しに使う先制攻撃チェックを実装
  if (
    args.baseMove.name === 'アクロバット' &&
    args.move.customParams.acrobaticsBuff
  ) {
    value.アクロバット = true
  }
  if (
    args.baseMove.name === 'たたりめ' &&
    hasAnyStatusCondition(args.defender.statusConditions)
  ) {
    value.たたりめ = true
  }
  if (args.baseMove.name === 'アシストパワー') {
    value.合計能力ランクアップ数 = args.move.customParams.totalStatStageCount
    value.アシストパワー = true
  }
  if (weightRefMoves.includes(args.baseMove.name)) {
    value.ヘビーボンバー = true
    value.ヒートスタンプ = true
  }
  if (args.baseMove.name === 'くさむすび') {
    value.くさむすび = true
  }

  if (args.baseMove.name === 'けたぐり') {
    value.けたぐり = true
  }

  if (args.baseMove.name === 'おはかまいり') {
    value.おはかまいり = true
    value.倒された味方の数 = args.move.customParams.lastRespectsCount
  }
  if (args.baseMove.name === 'ふんどのこぶし') {
    value.ふんどのこぶし = true
    value.攻撃を受けた回数 = args.move.customParams.rageFistCount
  }
  if (
    args.baseMove.name === 'ゆきなだれ' &&
    args.move.customParams.avalancheBuff
  ) {
    value.ゆきなだれ = true
  }

  return value
}

export const 威力の補正値を計算する引数を生成する = (
  args: AttackSituation
): 威力の補正値を計算する引数 => {
  const value: 威力の補正値を計算する引数 = {
    フェアリーオーラブレイク: false,
    ダークオーラブレイク: false,
    とうそうしん弱化: false,
    そうだいしょう1: false,
    エレキスキン: false,
    スカイスキン: false,
    ノーマルスキン: false,
    フェアリースキン: false,
    フリーズスキン: false,
    てつのこぶし: false,
    すてみ: false,
    そうだいしょう2: false,
    とうそうしん強化: false,
    バッテリー: false,
    ちからずく: false,
    すなのちから: false,
    アナライズ: false,
    かたいツメ: false,
    そうだいしょう3: false,
    パンクロック: false,
    パワースポット: false,
    フェアリーオーラ: false,
    ダークオーラ: false,
    きれあじ: false,
    テクニシャン: false,
    ねつぼうそう: false,
    どくぼうそう: false,
    がんじょうあご: false,
    メガランチャー: false,
    はがねのせいしん: false,
    たいねつ: false,
    かんそうはだ: false,
    ちからのハチマキ: false,
    ものしりメガネ: false,
    パンチグローブ: false,
    タイプ強化系持ち物: false,
    こんごうだま: false,
    しらたま: false,
    はっきんだま: false,
    こころのしずく: false,
    ジュエル系持ち物: false,
    技の性質で天候による半減: false,
    さきどり: false,
    はたきおとす: false,
    てだすけ: false,
    重力下のGのちから: false,
    ワイドフォース: false,
    じゅうでん: false,
    からげんき: false,
    しおみず: false,
    ベノムショック: false,
    かたきうち: false,
    クロスサンダークロスフレイム: false,
    ライジングボルト: false,
    フィールド弱化: false,
    フィールド強化: false,
    みずあそび: false,
    どろあそび: false,
  }

  if (
    (args.attacker.ability.name === 'オーラブレイク' ||
      args.defender.ability.name === 'オーラブレイク') &&
    (args.attacker.ability.name === 'フェアリーオーラ' ||
      args.defender.ability.name === 'フェアリーオーラ') &&
    args.move.typeId === typesId.FAIRY
  ) {
    //オーラブレイクとフェアリーオーラの両方を含み、フェアリー技
    value.フェアリーオーラブレイク = true
  }

  if (
    (args.attacker.ability.name === 'オーラブレイク' ||
      args.defender.ability.name === 'オーラブレイク') &&
    (args.attacker.ability.name === 'ダークオーラ' ||
      args.defender.ability.name === 'ダークオーラ') &&
    args.move.typeId === typesId.DARK
  ) {
    // オーラブレイクとダークオーラの両方を含み、悪技
    value.ダークオーラブレイク = true
  }

  if (args.attacker.ability.name === 'とうそうしん弱化') {
    value.とうそうしん弱化 = true
  }

  if (args.attacker.ability.name === 'そうだいしょう1') {
    value.そうだいしょう1 = true
  } else if (args.attacker.ability.name === 'そうだいしょう2') {
    value.そうだいしょう2 = true
  } else if (args.attacker.ability.name === 'そうだいしょう3') {
    value.そうだいしょう3 = true
  }

  if (
    args.attacker.ability.name === 'エレキスキン' &&
    args.move.typeId === typesId.NORMAL
  ) {
    // ノーマル技を電気技に変更時、威力強化
    value.エレキスキン = true
  }
  if (
    args.attacker.ability.name === 'スカイスキン' &&
    args.move.typeId === typesId.NORMAL
  ) {
    // ノーマル技を飛行技に変更時、威力強化
    value.スカイスキン = true
  }

  if (args.attacker.ability.name === 'ノーマルスキン') {
    value.ノーマルスキン = true
  }

  if (
    args.attacker.ability.name === 'フェアリースキン' &&
    args.move.typeId === typesId.NORMAL
  ) {
    // ノーマル技をフェアリー技に変更時、威力強化
    value.フェアリースキン = true
  }

  if (
    args.attacker.ability.name === 'フリーズスキン' &&
    args.move.typeId === typesId.NORMAL
  ) {
    // ノーマル技を氷技に変更時、威力強化
    value.フリーズスキン = true
  }
  if (args.attacker.ability.name === 'てつのこぶし' && args.baseMove.isDirect) {
    value.てつのこぶし = true
  }

  if (
    args.attacker.ability.name === 'すてみ' &&
    args.baseMove.hasRecoilDamage
  ) {
    value.すてみ = true
  }

  if (args.attacker.ability.name === 'とうそうしん強化') {
    value.とうそうしん強化 = true
  }

  if (args.attacker.ability.name === 'バッテリー') {
    value.バッテリー = true
  }

  if (
    args.attacker.ability.name === 'ちからずく' &&
    args.baseMove.hasAdditionalEffect
  ) {
    value.ちからずく = true
  }

  if (
    args.attacker.ability.name === 'すなのちから' &&
    args.attacker.weatherId === WEATHERS_ID.SANDSTORM &&
    [typesId.GROUND, typesId.ROCK, typesId.STEEL].includes(args.move.typeId)
  ) {
    // 天候がすなあらしのとき、地面、岩、鋼タイプの威力アップ
    value.すなのちから = true
  }

  if (args.attacker.ability.name === 'アナライズ') {
    value.アナライズ = true
  }

  if (args.attacker.ability.name === 'かたいツメ' && args.baseMove.isDirect) {
    value.かたいツメ = true
  }

  if (args.attacker.ability.name === 'パンクロック' && args.baseMove.isSound) {
    value.パンクロック = true
  }

  if (args.attacker.ability.name === 'パワースポット') {
    value.パワースポット = true
  }

  if (
    args.attacker.ability.name !== 'オーラブレイク' &&
    args.defender.ability.name !== 'オーラブレイク' &&
    (args.attacker.ability.name === 'フェアリーオーラ' ||
      args.defender.ability.name === 'フェアリーオーラ') &&
    args.move.typeId === typesId.FAIRY
  ) {
    //オーラブレイクがなくフェアリーオーラがあり、フェアリー技
    value.フェアリーオーラ = true
  }

  if (
    args.attacker.ability.name !== 'オーラブレイク' &&
    args.defender.ability.name !== 'オーラブレイク' &&
    (args.attacker.ability.name === 'ダークオーラ' ||
      args.defender.ability.name === 'ダークオーラ') &&
    args.move.typeId === typesId.DARK
  ) {
    // オーラブレイクがなくダークオーラがあり、悪技
    value.ダークオーラ = true
  }

  if (args.attacker.ability.name === 'きれあじ' && args.baseMove.isSlashing) {
    value.きれあじ = true
  }

  if (
    args.attacker.ability.name === 'テクニシャン' &&
    args.baseMove.power <= 60
  ) {
    value.テクニシャン = true
  }

  if (
    args.attacker.ability.name === 'ねつぼうそう' &&
    args.attacker.statusConditions.burn
  ) {
    value.ねつぼうそう = true
  }

  if (
    args.attacker.ability.name === 'どくぼうそう' &&
    args.attacker.statusConditions.poison
  ) {
    value.どくぼうそう = true
  }

  if (
    args.attacker.ability.name === 'がんじょうあご' &&
    biteMoves.includes(args.baseMove.name)
  ) {
    value.がんじょうあご = true
  }

  if (
    args.attacker.ability.name === 'メガランチャー' &&
    waveMoves.includes(args.baseMove.name)
  ) {
    value.メガランチャー = true
  }

  if (
    args.attacker.ability.name === 'はがねのせいしん' &&
    args.move.typeId === typesId.STEEL
  ) {
    value.はがねのせいしん = true
  }

  if (
    args.defender.ability.name === 'たいねつ' &&
    args.move.typeId === typesId.FIRE
  ) {
    value.たいねつ = true
  }

  if (
    args.defender.ability.name === 'かんそうはだ' &&
    args.move.typeId === typesId.FIRE
  ) {
    value.かんそうはだ = true
  }

  if (
    args.attacker.heldItem.name === 'ちからのハチマキ' &&
    args.move.category === '物理'
  ) {
    value.ちからのハチマキ = true
  }

  if (
    args.attacker.heldItem.name === 'ものしりメガネ' &&
    args.move.category === '特殊'
  ) {
    value.ものしりメガネ = true
  }

  if (
    args.attacker.heldItem.name === 'パンチグローブ' &&
    args.baseMove.isPunch
  ) {
    value.パンチグローブ = true
  }

  if (args.attacker.heldItem.name === 'タイプ強化系(x1.2)') {
    value.タイプ強化系持ち物 = true
  }

  if (
    args.attacker.heldItem.name === 'こんごうだま' &&
    [typesId.DRAGON, typesId.STEEL].includes(args.move.typeId)
  ) {
    value.こんごうだま = true
  }

  if (
    args.attacker.heldItem.name === 'しらたま' &&
    [typesId.WATER, typesId.DRAGON].includes(args.move.typeId)
  ) {
    value.しらたま = true
  }

  if (
    args.attacker.heldItem.name === 'はっきんだま' &&
    [typesId.DRAGON, typesId.GHOST].includes(args.move.typeId)
  ) {
    value.はっきんだま = true
  }

  if (
    args.attacker.heldItem.name === 'こころのしずく' &&
    [typesId.PSYCHIC, typesId.DRAGON].includes(args.move.typeId)
  ) {
    value.こころのしずく = true
  }

  if (args.attacker.heldItem.name === 'ジュエル系(x1.3)') {
    value.ジュエル系持ち物 = true
  }

  if (
    [WEATHERS_ID.RAIN, WEATHERS_ID.SANDSTORM].includes(
      args.attacker.weatherId
    ) &&
    (args.baseMove.name === 'ソーラービーム' ||
      args.baseMove.name === 'ソーラーブレード')
  ) {
    value.技の性質で天候による半減 = true
  }

  if (
    args.baseMove.name === 'はたきおとす' &&
    args.move.customParams.knockOffBuff
  ) {
    value.はたきおとす = true
  }

  if (
    args.baseMove.name === 'ワイドフォース' &&
    args.attacker.terrainId === TERRAINS_ID.PSYCHIC_TERRAIN &&
    onTheGround(args.attacker)
  ) {
    value.ワイドフォース = true
  }

  if (
    args.attacker.isBatteryCharging &&
    args.move.typeId === typesId.ELECTRIC
  ) {
    value.じゅうでん = true
  }

  if (
    args.baseMove.name === 'からげんき' &&
    hasAnyStatusCondition(args.attacker.statusConditions)
  ) {
    value.からげんき = true
  }

  if (
    args.baseMove.name === 'ベノムショック' &&
    args.defender.statusConditions.poison
  ) {
    value.ベノムショック = true
  }

  if (args.baseMove.name === 'ライジングボルト' && onTheGround(args.defender)) {
    value.ライジングボルト = true
  }

  if (
    args.defender.terrainId === TERRAINS_ID.GRASSY_TERRAIN &&
    onTheGround(args.defender) &&
    ['じしん', 'じならし'].includes(args.baseMove.name)
  ) {
    value.フィールド弱化 = true
  }

  if (
    args.attacker.terrainId === TERRAINS_ID.ELECTRIC_TERRAIN &&
    onTheGround(args.attacker) &&
    args.move.typeId === typesId.ELECTRIC
  ) {
    value.フィールド強化 = true
  }

  if (
    args.attacker.terrainId === TERRAINS_ID.GRASSY_TERRAIN &&
    onTheGround(args.attacker) &&
    args.move.typeId === typesId.GRASS
  ) {
    value.フィールド強化 = true
  }

  if (
    args.attacker.terrainId === TERRAINS_ID.MISTY_TERRAIN &&
    onTheGround(args.attacker) &&
    args.move.typeId === typesId.FAIRY
  ) {
    value.フィールド強化 = true
  }

  if (
    args.attacker.terrainId === TERRAINS_ID.PSYCHIC_TERRAIN &&
    onTheGround(args.attacker) &&
    args.move.typeId === typesId.PSYCHIC
  ) {
    value.フィールド強化 = true
  }

  //TODO フォームも変更が必要なので未実装となっているリスト
  // さきどり
  // てだすけ
  // 重力下のGのちから
  // しおみず
  // クロスサンダークロスフレイム
  // みずあそび
  // どろあそび
  // かたきうち

  return value
}

export const 最終威力を計算する引数を生成する = (
  args: AttackSituation
): 最終威力を計算する引数 => {
  const value: 最終威力を計算する引数 = {
    v技の威力を計算する引数: 技の威力を計算する引数を生成する(args),
    v威力の補正値を計算する引数: 威力の補正値を計算する引数を生成する(args),
    連続技: false,
    先制技: false,
    テラスタイプと技のタイプが同じ: false,
  }

  if (args.attacker.isTerastal && args.attacker.teraType === args.move.typeId) {
    value.テラスタイプと技のタイプが同じ = true
  }

  //TODO 威力が低いときテラスタイプの威力が60になるアレのための変数設定を実装
  return value
}

export const 攻撃の補正値を計算する引数を生成する = (
  args: AttackSituation
): 攻撃の補正値を計算する引数 => {
  const value: 攻撃の補正値を計算する引数 = {
    スロースタート: false,
    よわき: false,
    わざわいのうつわ: false,
    わざわいのおふだ: false,
    クォークチャージ: false,
    こだいかっせい: false,
    ハドロンエンジン: false,
    ひひいろのこどう: false,
    フラワーギフト: false,
    こんじょう: false,
    しんりょく: false,
    もうか: false,
    げきりゅう: false,
    むしのしらせ: false,
    もらいび: false,
    サンパワー: false,
    プラス: false,
    マイナス: false,
    いわはこび: false,
    はがねつかい: false,
    ごりむちゅう: false,
    トランジスタ: false,
    りゅうのあぎと: false,
    ちからもち: false,
    ヨガパワー: false,
    すいほう水技: false,
    はりこみ: false,
    あついしぼう: false,
    すいほう炎技: false,
    きよめのしお: false,
    こだわりハチマキ: false,
    こだわりメガネ: false,
    ふといホネ: false,
    しんかいのキバ: false,
    でんきだま: false,
  }

  if (args.attacker.ability.name === 'スロースタート') {
    value.スロースタート = true
  }

  if (args.attacker.ability.name === 'よわき') {
    value.よわき = true
  }

  if (
    args.defender.ability.name === 'わざわいのうつわ' &&
    args.move.category === '特殊'
  ) {
    value.わざわいのうつわ = true
  }

  if (
    args.defender.ability.name === 'わざわいのおふだ' &&
    args.move.category === '物理'
  ) {
    value.わざわいのおふだ = true
  }

  if (
    (args.attacker.ability.name === 'クォークチャージ(攻撃)' &&
      args.move.category === '物理') ||
    (args.attacker.ability.name === 'クォークチャージ(特攻)' &&
      args.move.category === '特殊')
  ) {
    value.クォークチャージ = true
  }

  if (
    (args.attacker.ability.name === 'こだいかっせい(攻撃)' &&
      args.move.category === '物理') ||
    (args.attacker.ability.name === 'こだいかっせい(特攻)' &&
      args.move.category === '特殊')
  ) {
    value.こだいかっせい = true
  }

  if (
    args.attacker.ability.name === 'ハドロンエンジン' &&
    args.attacker.terrainId === TERRAINS_ID.ELECTRIC_TERRAIN &&
    args.move.category === '特殊'
  ) {
    value.ハドロンエンジン = true
  }

  if (
    args.attacker.ability.name === 'ひひいろのこどう' &&
    args.attacker.weatherId === WEATHERS_ID.HARSH_SUNLIGHT &&
    args.move.category === '物理'
  ) {
    value.ひひいろのこどう = true
  }

  if (
    args.attacker.ability.name === 'フラワーギフト' &&
    args.move.category === '物理'
  ) {
    value.フラワーギフト = true
  }

  if (
    args.attacker.ability.name === 'こんじょう' &&
    hasAnyStatusCondition(args.attacker.statusConditions) &&
    args.move.category === '物理'
  ) {
    value.こんじょう = true
  }

  if (
    args.attacker.ability.name === 'しんりょく' &&
    args.move.typeId === typesId.GRASS
  ) {
    value.しんりょく = true
  }

  if (
    args.attacker.ability.name === 'もうか' &&
    args.move.typeId === typesId.FIRE
  ) {
    value.もうか = true
  }

  if (
    args.attacker.ability.name === 'げきりゅう' &&
    args.move.typeId === typesId.WATER
  ) {
    value.げきりゅう = true
  }

  if (
    args.attacker.ability.name === 'むしのしらせ' &&
    args.move.typeId === typesId.BUG
  ) {
    value.むしのしらせ = true
  }

  if (
    args.attacker.ability.name === 'もらいび' &&
    args.move.typeId === typesId.FIRE
  ) {
    value.もらいび = true
  }

  if (
    args.attacker.ability.name === 'サンパワー' &&
    args.attacker.weatherId === WEATHERS_ID.HARSH_SUNLIGHT &&
    args.move.typeId === typesId.FIRE
  ) {
    value.サンパワー = true
  }

  if (
    args.attacker.ability.name === 'プラス' &&
    args.move.category === '特殊'
  ) {
    value.プラス = true
  }

  if (
    args.attacker.ability.name === 'マイナス' &&
    args.move.category === '特殊'
  ) {
    value.マイナス = true
  }

  if (
    args.attacker.ability.name === 'いわはこび' &&
    args.move.typeId === typesId.ROCK
  ) {
    value.いわはこび = true
  }

  if (
    args.attacker.ability.name === 'はがねつかい' &&
    args.move.typeId === typesId.STEEL
  ) {
    value.はがねつかい = true
  }

  if (
    args.attacker.ability.name === 'ごりむちゅう' &&
    args.move.category === '物理'
  ) {
    value.ごりむちゅう = true
  }

  if (
    args.attacker.ability.name === 'トランジスタ' &&
    args.move.typeId === typesId.ELECTRIC
  ) {
    value.トランジスタ = true
  }

  if (
    args.attacker.ability.name === 'りゅうのあぎと' &&
    args.move.typeId === typesId.DRAGON
  ) {
    value.りゅうのあぎと = true
  }

  if (
    args.attacker.ability.name === 'ちからもち' &&
    args.move.category === '物理'
  ) {
    value.ちからもち = true
  }

  if (
    args.attacker.ability.name === 'ヨガパワー' &&
    args.move.category === '物理'
  ) {
    value.ヨガパワー = true
  }

  if (
    args.attacker.ability.name === 'すいほう(攻撃側)' &&
    args.move.typeId === typesId.WATER
  ) {
    value.すいほう水技 = true
  }

  if (args.attacker.ability.name === 'はりこみ') {
    value.はりこみ = true
  }

  if (
    args.defender.ability.name === 'あついしぼう' &&
    [typesId.FIRE, typesId.ICE].includes(args.move.typeId)
  ) {
    value.あついしぼう = true
  }

  if (
    args.defender.ability.name === 'すいほう(防御側)' &&
    args.move.typeId === typesId.FIRE
  ) {
    value.すいほう炎技 = true
  }

  if (
    args.defender.ability.name === 'きよめのしお' &&
    args.move.typeId === typesId.GHOST
  ) {
    value.きよめのしお = true
  }

  if (
    args.attacker.heldItem.name === 'こだわりハチマキ' &&
    args.move.category === '物理'
  ) {
    value.こだわりハチマキ = true
  }

  if (
    args.attacker.heldItem.name === 'こだわりメガネ' &&
    args.move.category === '特殊'
  ) {
    value.こだわりメガネ = true
  }

  if (
    args.attacker.heldItem.name === 'ふといホネ' &&
    args.move.category === '物理'
  ) {
    value.ふといホネ = true
  }

  // 未実装 しんかいのキバ

  if (args.attacker.heldItem.name === 'でんきだま') {
    //TODO ピカチュウのみ効果があるようにする
    value.でんきだま = true
  }

  return value
}

export const 最終攻撃を計算する引数を生成する = (
  args: AttackSituation
): 最終攻撃を計算する引数 => {
  const value: 最終攻撃を計算する引数 = {
    参照値: 0,
    ランク: 0,
    はりきり: false,
    v攻撃の補正値を計算する引数: 攻撃の補正値を計算する引数を生成する(args),
  }
  if (args.baseMove.attackReference === 'attackerAttack') {
    value.参照値 = args.attacker.attack
    value.ランク = args.attacker.statStages.attack
  } else if (args.baseMove.attackReference === 'attackerSpecialAttack') {
    value.参照値 = args.attacker.specialAttack
    value.ランク = args.attacker.statStages.specialAttack
  } else if (args.baseMove.attackReference === 'attackerDefense') {
    value.参照値 = args.attacker.defense
    value.ランク = args.attacker.statStages.defense
  } else if (args.baseMove.attackReference === 'teraBlast') {
    const teraBlastCategory = judgeTeraBlastCategory({
      isTerastal: args.attacker.isTerastal,
      attackStat: args.attacker.attack,
      specialAttackStat: args.attacker.specialAttack,
      attackStage: args.attacker.statStages.attack,
      specialAttackStage: args.attacker.statStages.specialAttack,
    })
    if (teraBlastCategory === 'Physical') {
      value.参照値 = args.attacker.attack
      value.ランク = args.attacker.statStages.attack
    } else {
      value.参照値 = args.attacker.specialAttack
      value.ランク = args.attacker.statStages.specialAttack
    }
  } else if (args.baseMove.attackReference === 'defenderAttack') {
    value.参照値 = args.defender.attack
    value.ランク = args.defender.statStages.attack
  } else {
    throw new Error()
  }

  if (
    args.attacker.ability.name === 'はりきり' &&
    args.move.category === '物理'
  ) {
    value.はりきり = true
  }
  return value
}

export const 防御の補正値を計算する引数を生成する = (args: AttackSituation) => {
  const value: 防御の補正値を計算する引数 = {
    わざわいのたま: false,
    わざわいのつるぎ: false,
    クォークチャージ: false,
    こだいかっせい: false,
    フラワーギフト: false,
    ふしぎなうろこ: false,
    くさのけがわ: false,
    ファーコート: false,
    しんかのきせき: false,
    とつげきチョッキ: false,
    しんかいのウロコ: false,
    メタルパウダー: false,
  }
  if (
    args.attacker.ability.name === 'わざわいのたま' &&
    args.move.category === '特殊'
  ) {
    value.わざわいのたま = true
  }

  if (
    args.attacker.ability.name === 'わざわいのつるぎ' &&
    args.move.category === '物理'
  ) {
    value.わざわいのつるぎ = true
  }

  if (
    args.defender.ability.name === 'クォークチャージ(防御)' &&
    args.move.category === '物理'
  ) {
    value.クォークチャージ = true
  }

  if (
    args.defender.ability.name === 'クォークチャージ(特防)' &&
    args.move.category === '特殊'
  ) {
    value.クォークチャージ = true
  }

  if (
    args.defender.ability.name === 'こだいかっせい(防御)' &&
    args.move.category === '物理'
  ) {
    value.こだいかっせい = true
  }

  if (
    args.defender.ability.name === 'こだいかっせい(特防)' &&
    args.move.category === '特殊'
  ) {
    value.こだいかっせい = true
  }

  if (
    args.defender.ability.name === 'フラワーギフト' &&
    args.move.category === '特殊'
  ) {
    value.フラワーギフト = true
  }

  if (
    args.defender.ability.name === 'ふしぎなうろこ' &&
    hasAnyStatusCondition(args.defender.statusConditions) &&
    args.move.category === '物理'
  ) {
    value.ふしぎなうろこ = true
  }

  if (
    args.defender.ability.name === 'くさのけがわ' &&
    args.defender.terrainId === TERRAINS_ID.GRASSY_TERRAIN &&
    args.move.category === '物理'
  ) {
    value.くさのけがわ = true
  }

  if (
    args.defender.ability.name === 'ファーコート' &&
    args.move.category === '物理'
  ) {
    value.ファーコート = true
  }

  if (args.defender.heldItem.name === 'しんかのきせき') {
    value.しんかのきせき = true
  }

  if (
    args.defender.heldItem.name === 'とつげきチョッキ' &&
    args.move.category === '特殊'
  ) {
    value.とつげきチョッキ = true
  }

  // 未実装 しんかいのウロコ, メタルパウダー

  return value
}

export const 最終防御を計算する引数を生成する = (args: AttackSituation) => {
  const value: 最終防御を計算する引数 = {
    参照値: 0,
    ランク: 0,
    砂嵐による特防強化: false,
    雪による防御強化: false,
    v防御の補正値を計算する引数: 防御の補正値を計算する引数を生成する(args),
  }
  const teraBlastCategory = judgeTeraBlastCategory({
    isTerastal: args.attacker.isTerastal,
    attackStat: args.attacker.attack,
    specialAttackStat: args.attacker.specialAttack,
    attackStage: args.attacker.statStages.attack,
    specialAttackStage: args.attacker.statStages.specialAttack,
  })
  if (
    (args.baseMove.attackReference === 'teraBlast' &&
      teraBlastCategory === 'Physical') ||
    args.baseMove.defenseReference === 'defenderDefense'
  ) {
    value.参照値 = args.defender.defense
    value.ランク = args.defender.statStages.defense
  } else if (
    (args.baseMove.attackReference === 'teraBlast' &&
      teraBlastCategory === 'Special') ||
    args.baseMove.defenseReference === 'defenderSpecialDefense'
  ) {
    value.参照値 = args.defender.specialDefense
    value.ランク = args.defender.statStages.specialDefense
  } else {
    throw new Error()
  }

  if (
    args.defender.typeIds.includes(typesId.ROCK) &&
    args.defender.weatherId === WEATHERS_ID.SANDSTORM &&
    args.move.category === '特殊'
  ) {
    value.砂嵐による特防強化 = true
  }

  if (
    args.defender.typeIds.includes(typesId.ICE) &&
    args.defender.weatherId === WEATHERS_ID.SNOW &&
    args.move.category === '物理'
  ) {
    value.雪による防御強化 = true
  }
  return value
}

export const ダメージの補正値を計算する引数を生成する = (
  args: AttackSituation,
  moveType: TypeId,
  moveCategory: string
): ダメージの補正値を計算する引数 => {
  const value = {
    リフレクター: false,
    ひかりのかべ: false,
    オーロラベール: false,
    ブレインフォース: false,
    アクセルブレイク: false,
    イナズマドライブ: false,
    スナイパー: false,
    いろめがね: false,
    もふもふに炎技: false,
    マルチスケイル: false,
    ファントムガード: false,
    もふもふに直接攻撃: false,
    パンクロック: false,
    こおりのりんぷん: false,
    フレンドガード: false,
    ハードロック: false,
    フィルター: false,
    プリズムアーマー: false,
    メトロノーム2: false,
    メトロノーム3: false,
    メトロノーム4: false,
    メトロノーム5: false,
    メトロノーム6: false,
    たつじんのおび: false,
    いのちのたま: false,
    半減きのみ: false,
    ちいさくなる2倍: false,
  }

  if (args.defender.hasReflect && moveCategory === '物理') {
    value.リフレクター = true
  }

  if (args.defender.hasLightScreen && moveCategory === '特殊') {
    value.ひかりのかべ = true
  }

  if (args.defender.hasReflect && args.defender.hasLightScreen) {
    value.オーロラベール = true
  }
  const counts =
    countStrongAgainst(args.baseMove.name, moveType, args.defender.typeIds) -
    countWeakAgainst(args.baseMove.name, moveType, args.defender.typeIds)
  const isStrongAgainst = counts > 0
  const isWeakAgainst = counts < 0
  if (args.attacker.ability.name === 'ブレインフォース' && isStrongAgainst) {
    value.ブレインフォース = true
  }

  if (args.baseMove.name === 'アクセルブレイク' && isStrongAgainst) {
    value.アクセルブレイク = true
  }

  if (args.baseMove.name === 'イナズマドライブ' && isStrongAgainst) {
    value.イナズマドライブ = true
  }

  if (
    args.attacker.ability.name === 'スナイパー' &&
    args.attacker.criticalHitIsEnabled
  ) {
    value.スナイパー = true
  }

  if (args.attacker.ability.name === 'いろめがね' && isWeakAgainst) {
    value.いろめがね = true
  }

  if (args.defender.ability.name === 'もふもふ' && moveType === typesId.FIRE) {
    value.もふもふに炎技 = true
  }

  if (
    args.defender.ability.name === 'マルチスケイル' &&
    !args.defender.isDamaged
  ) {
    value.マルチスケイル = true
  }

  if (
    args.defender.ability.name === 'ファントムガード' &&
    !args.defender.isDamaged
  ) {
    value.ファントムガード = true
  }

  if (args.defender.ability.name === 'もふもふ' && moveType === typesId.FIRE) {
    value.もふもふに炎技 = true
  }

  if (args.defender.ability.name === 'もふもふ' && args.baseMove.isDirect) {
    value.もふもふに直接攻撃 = true
  }

  if (args.defender.ability.name === 'パンクロック' && args.baseMove.isSound) {
    value.パンクロック = true
  }

  if (
    args.defender.ability.name === 'こおりのりんぷん' &&
    moveCategory === '特殊'
  ) {
    value.こおりのりんぷん = true
  }

  // 未実装 フレンドガード

  if (args.defender.ability.name === 'ハードロック' && isStrongAgainst) {
    value.ハードロック = true
  }

  if (args.defender.ability.name === 'フィルター' && isStrongAgainst) {
    value.フィルター = true
  }

  if (args.defender.ability.name === 'プリズムアーマー' && isStrongAgainst) {
    value.プリズムアーマー = true
  }

  if (args.attacker.heldItem.name === 'メトロノーム2') {
    value.メトロノーム2 = true
  } else if (args.attacker.heldItem.name === 'メトロノーム3') {
    value.メトロノーム3 = true
  } else if (args.attacker.heldItem.name === 'メトロノーム4') {
    value.メトロノーム4 = true
  } else if (args.attacker.heldItem.name === 'メトロノーム5') {
    value.メトロノーム5 = true
  } else if (args.attacker.heldItem.name === 'メトロノーム6') {
    value.メトロノーム6 = true
  }

  if (args.attacker.heldItem.name === 'たつじんのおび' && isStrongAgainst) {
    value.たつじんのおび = true
  }

  if (args.attacker.heldItem.name === 'いのちのたま') {
    value.いのちのたま = true
  }

  if (args.defender.heldItem.name === '半減きのみ') {
    value.半減きのみ = true
  }

  //TODO ちいさくなる状態の実装

  return value
}

export const 最終ダメージを計算する引数を生成する = (
  args: AttackSituation
): 最終ダメージを計算する引数 => {
  const moveCategory = getMoveCategory({
    attacker: {
      level: args.attacker.level,
      stats: {
        hp: args.attacker.hp,
        attack: args.attacker.attack,
        defense: args.attacker.defense,
        specialAttack: args.attacker.specialAttack,
        specialDefense: args.attacker.specialDefense,
        speed: args.attacker.speed,
      },
      statStages: args.attacker.statStages,
      isTerastal: args.attacker.isTerastal,
      teraType: args.attacker.teraType,
      abilityId: args.attacker.ability.id,
      move: {
        attackReference: args.baseMove.attackReference,
        displayCategory: args.baseMove.displayCategory,
      },
    },
  })

  const value: 最終ダメージを計算する引数 = {
    レベル: args.attacker.level,
    v最終威力を計算する引数: 最終威力を計算する引数を生成する(args),
    v最終攻撃を計算する引数: 最終攻撃を計算する引数を生成する(args),
    v最終防御を計算する引数: 最終防御を計算する引数を生成する(args),
    vダメージの補正値を計算する引数: ダメージの補正値を計算する引数を生成する(
      args,
      args.move.typeId,
      moveCategory
    ),
    天気弱化: false,
    天気強化: false,
    きょけんとつげき被ダメ増加: false,
    急所: false,
    タイプ一致: false,
    適応力: false,
    タイプ一致テラスタル: false,
    適応力テラスタル: false,
    タイプ相性2倍の数: 0,
    タイプ相性半減の数: 0,
    タイプ相性無効: false,
    やけど: false,
  }

  if (
    args.attacker.isTerastal &&
    (args.attacker.typeIds.includes(args.move.typeId) ||
      args.attacker.ability.name === 'へんげんじざい') &&
    args.attacker.teraType === args.move.typeId
  ) {
    // テラスタルしてて、元々のタイプ一致に加えてテラスタイプも一致
    value.タイプ一致テラスタル = true
  }

  if (args.attacker.isTerastal && args.attacker.teraType === args.move.typeId) {
    // テラスタルしてて、テラスタイプが一致
    value.タイプ一致 = true
  }

  if (
    args.attacker.typeIds.includes(args.move.typeId) ||
    args.attacker.ability.name === 'へんげんじざい'
  ) {
    value.タイプ一致 = true
  }

  const resistantAgainstCount = countResistantAgainst(
    args.baseMove.name,
    args.move.typeId,
    args.defender.typeIds
  )
  if (resistantAgainstCount > 0) {
    value.タイプ相性無効 = true
  }
  value.タイプ相性半減の数 = countWeakAgainst(
    args.baseMove.name,
    args.move.typeId,
    args.defender.typeIds
  )
  value.タイプ相性2倍の数 = countStrongAgainst(
    args.baseMove.name,
    args.move.typeId,
    args.defender.typeIds
  )

  if (
    args.attacker.weatherId === WEATHERS_ID.HARSH_SUNLIGHT &&
    args.move.typeId === typesId.FIRE
  ) {
    value.天気強化 = true
  }

  if (
    args.attacker.weatherId === WEATHERS_ID.RAIN &&
    args.move.typeId === typesId.WATER
  ) {
    value.天気強化 = true
  }

  if (
    args.attacker.weatherId === WEATHERS_ID.HARSH_SUNLIGHT &&
    args.move.typeId === typesId.WATER
  ) {
    value.天気弱化 = true
  }

  if (
    args.attacker.weatherId === WEATHERS_ID.RAIN &&
    args.move.typeId === typesId.FIRE
  ) {
    value.天気弱化 = true
  }

  //未実装 きょけんとつげきによる被ダメ増加

  if (args.attacker.criticalHitIsEnabled) {
    value.急所 = true
  }

  if (
    args.attacker.statusConditions.burn &&
    moveCategory === '物理' &&
    !ignoreBurnDebuffMoves.includes(args.baseMove.name) &&
    !ignoreBurnDebuffAbilities.includes(args.attacker.ability.name)
  ) {
    value.やけど = true
  }

  return value
}
