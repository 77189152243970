import { Dispatch } from 'react'
import { AttackerDisplay } from '../AttackerDisplay/AttackerDisplay'
import { AttackerForm } from './AttackerForm'
import { useAttackerCalculatedValues } from './useAttackerCalculatedValues'
import { Attacker, AttackersAction } from './useAttackersState'

type AttackerCardProps = {
  removable: boolean
  id: number
  attacker: Attacker
  dispatch: Dispatch<AttackersAction>
}

export const AttackerCard = ({
  removable,
  id,
  attacker,
  dispatch,
}: AttackerCardProps) => {
  const {
    pokemon,
    move,
    baseStats,
    stats,
    ability,
    moveDisplayCategory,
    moveTypeId,
    movePower,
  } = useAttackerCalculatedValues({ attacker })

  const handleOpenClick = () => {
    dispatch({
      type: 'changeIsOpen',
      payload: { id, value: true },
    })
  }

  const handleCloseClick = () => {
    dispatch({
      type: 'changeIsOpen',
      payload: { id, value: false },
    })
  }

  if (attacker.isOpen) {
    return (
      <AttackerForm
        removable={removable}
        id={id}
        attacker={attacker}
        dispatch={dispatch}
        pokemon={pokemon}
        baseStats={baseStats}
        move={move}
        movePower={movePower}
        moveTypeId={moveTypeId}
        moveDisplayCategory={moveDisplayCategory}
        onCloseClick={handleCloseClick}
      />
    )
  } else {
    return (
      <AttackerDisplay
        attacker={attacker}
        pokemon={pokemon}
        ability={ability}
        move={move}
        movePower={movePower}
        moveDisplayCategory={moveDisplayCategory}
        stats={stats}
        onOpenClick={handleOpenClick}
      />
    )
  }
}
