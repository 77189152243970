import { useCallback, useEffect, useRef, useState } from 'react'

export const useModalState = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback(() => {
    setIsOpen(true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleOutsideClick = useCallback(
    (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        close()
      }
    },
    [close]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [handleOutsideClick])
  return { isOpen, open, close, ref }
}
