import { Box, Flex } from '@chakra-ui/react'
import { Dispatch } from 'react'
import { getBaseMove } from '../../domain/BaseMove'
import { 最終ダメージ計算結果 } from '../../domain/DamageFormula'

import { AttackersForm } from './AttackersForm/AttackersForm'
import {
  AttackersAction,
  AttackersState,
} from './AttackersForm/useAttackersState'
import { DefendersForm } from './DefendersForm/DefendersForm'
import {
  DefendersAction,
  DefendersState,
} from './DefendersForm/useDefendersState'

type CalculateSectionProps = {
  attackersState: AttackersState
  attackersDispatch: Dispatch<AttackersAction>
  defendersState: DefendersState
  defendersDispatch: Dispatch<DefendersAction>
  results: 最終ダメージ計算結果[]
}

export const CalculateSection = ({
  attackersState,
  attackersDispatch,
  defendersState,
  defendersDispatch,
  results,
}: CalculateSectionProps) => {
  let isIncludeDefenderAttackReference = false
  let isIncludeDefenderDefenseReference = false
  let isIncludeDefenderSpecialDefenseReference = false

  for (let attacker of attackersState) {
    const baseMove = getBaseMove(attacker.moveId)
    if (baseMove.attackReference === 'defenderAttack') {
      isIncludeDefenderAttackReference = true
    } else if (baseMove.attackReference === 'teraBlast') {
      isIncludeDefenderDefenseReference = true
      isIncludeDefenderSpecialDefenseReference = true
    } else if (baseMove.defenseReference === 'defenderDefense') {
      isIncludeDefenderDefenseReference = true
    } else if (baseMove.defenseReference === 'defenderSpecialDefense') {
      isIncludeDefenderSpecialDefenseReference = true
    }
  }

  return (
    <Box padding="20px">
      <Flex gap="20px">
        <Box width="620px">
          <AttackersForm state={attackersState} dispatch={attackersDispatch} />
        </Box>
        <Box whiteSpace="nowrap">{'->'}</Box>
        <Box width="620px">
          <DefendersForm
            isIncludeDefenderAttackReference={isIncludeDefenderAttackReference}
            isIncludeDefenderDefenseReference={
              isIncludeDefenderDefenseReference
            }
            isIncludeDefenderSpecialDefenseReference={
              isIncludeDefenderSpecialDefenseReference
            }
            state={defendersState}
            dispatch={defendersDispatch}
            results={results}
          />
        </Box>
      </Flex>
    </Box>
  )
}
