import { PokeMath } from './PokeMath'
import { IndivisualValues } from './IndivisualValue'
import { EffortValues } from './EffortValue'
import { Stats } from './Stat'
import { NatureCorrections, NatureCorrection } from './Nature'
import { Pokemon } from './Pokemon'

export const calcStats = ({
  level,
  pokemon,
  indivisualValues,
  effortValues,
  natureCorrections,
}: {
  level: number
  pokemon: Pokemon
  indivisualValues: IndivisualValues
  effortValues: EffortValues
  natureCorrections: NatureCorrections
}): Stats => {
  return {
    hp: calcHPStat({
      level: level,
      baseStat: pokemon.hp,
      indivisualValue: indivisualValues.hp,
      effortValue: effortValues.hp,
    }),
    attack: calcStat({
      level: level,
      baseStat: pokemon.attack,
      indivisualValue: indivisualValues.attack,
      effortValue: effortValues.attack,
      natureCorrection: natureCorrections.attack,
    }),
    defense: calcStat({
      level: level,
      baseStat: pokemon.defense,
      indivisualValue: indivisualValues.defense,
      effortValue: effortValues.defense,
      natureCorrection: natureCorrections.defense,
    }),
    specialAttack: calcStat({
      level: level,
      baseStat: pokemon.specialAttack,
      indivisualValue: indivisualValues.specialAttack,
      effortValue: effortValues.specialAttack,
      natureCorrection: natureCorrections.specialAttack,
    }),
    specialDefense: calcStat({
      level: level,
      baseStat: pokemon.specialDefense,
      indivisualValue: indivisualValues.specialDefense,
      effortValue: effortValues.specialDefense,
      natureCorrection: natureCorrections.specialDefense,
    }),
    speed: calcStat({
      level: level,
      baseStat: pokemon.speed,
      indivisualValue: indivisualValues.speed,
      effortValue: effortValues.speed,
      natureCorrection: natureCorrections.speed,
    }),
  }
}

export const calcHPStat = (args: {
  level: number
  baseStat: number
  indivisualValue: number
  effortValue: number
}) => {
  let value = PokeMath.floor(
    args.baseStat * 2 + args.indivisualValue + args.effortValue / 4
  )
  value = PokeMath.floor((value * args.level) / 100) + args.level + 10
  return value
}

export const calcStat = (args: {
  level: number
  baseStat: number
  indivisualValue: number
  effortValue: number
  natureCorrection: NatureCorrection
}) => {
  let value = PokeMath.floor(
    args.baseStat * 2 +
      args.indivisualValue +
      PokeMath.floor(args.effortValue / 4)
  )
  value = PokeMath.floor((value * args.level) / 100) + 5

  if (args.natureCorrection === '上昇') {
    value = PokeMath.floor(value * 1.1)
  } else if (args.natureCorrection === '下降') {
    value = PokeMath.floor(value * 0.9)
  }
  return value
}
