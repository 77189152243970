import { Box } from '@chakra-ui/react'

type NatureCorrectionBoxProps = {
  children: React.ReactNode
}
export const NatureCorrectionBox = (props: NatureCorrectionBoxProps) => {
  return (
    <Box width="180px" display="flex" justifyContent="center">
      {props.children}
    </Box>
  )
}
