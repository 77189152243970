import { Select } from '@chakra-ui/react'
import { memo } from 'react'
import { TERRAINS } from '../../domain/Terrain'

type TerrainPickerProps = {
  value: number
  onChange: (value: number) => void
}

export const TerrainPicker = memo((props: TerrainPickerProps) => {
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    props.onChange(Number(event.target.value))
  }
  return (
    <Select value={props.value} onChange={onChange}>
      {TERRAINS.map((terrain) => {
        return (
          <option key={terrain.id} value={terrain.id}>
            {terrain.name}
          </option>
        )
      })}
    </Select>
  )
})
