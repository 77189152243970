import { AttackerV2, CalcHistoryV2, DefenderV2 } from './fixHistoryV2'

type NatureCorrection = '上昇' | '無し' | '下降'

export type AttackerV1 = {
  id: number
  level: number
  heldItemId: number
  abilityId: number
  pokemonId: string
  indivisualValues: {
    hp: number
    attack: number
    defense: number
    specialAttack: number
    specialDefense: number
    speed: number
  }
  effortValues: {
    hp: number
    attack: number
    defense: number
    specialAttack: number
    specialDefense: number
    speed: number
  }
  natureCorrections: {
    attack: NatureCorrection
    defense: NatureCorrection
    specialAttack: NatureCorrection
    specialDefense: NatureCorrection
    speed: NatureCorrection
  }
  moveId: number
  attackTime: number
  statStages: {
    attack: number
    defense: number
    specialAttack: number
    specialDefense: number
    speed: number
  }
  criticalHitIsEnabled: boolean
  weatherId: number
  terrainId: number
  statusConditions: {
    burn: boolean
    freeze: boolean
    paralysis: boolean
    poison: boolean
  }
  teraType?: number
  isBatteryCharging: boolean
  moveCustomParams: {
    totalStatStageCount: number
    lastRespectsCount: number
    rageFistCount: number
    acrobaticsBuff: boolean
    knockOffBuff: boolean
    avalancheBuff: boolean
  }
  isOpen: boolean
}

export type DefenderV1 = {
  id: number
  level: number
  typeIds: number[]
  heldItemId: number
  abilityId: number
  pokemonId: string
  indivisualValues: {
    hp: number
    attack: number
    defense: number
    specialAttack: number
    specialDefense: number
    speed: number
  }
  effortValues: {
    hp: number
    attack: number
    defense: number
    specialAttack: number
    specialDefense: number
    speed: number
  }
  natureCorrections: {
    attack: NatureCorrection
    defense: NatureCorrection
    specialAttack: NatureCorrection
    specialDefense: NatureCorrection
    speed: NatureCorrection
  }
  statStages: {
    attack: number
    defense: number
    specialAttack: number
    specialDefense: number
    speed: number
  }
  weatherId: number
  terrainId: number
  hasReflect: boolean
  hasLightScreen: boolean
  statusConditions: {
    burn: boolean
    freeze: boolean
    paralysis: boolean
    poison: boolean
  }
  teraType?: number
  isOpen: boolean
}

export type CalcHistoryV1 = {
  id: number
  attackersState: AttackerV1[]
  defendersState: DefenderV1[]
}[]

const fixDefenderState = (defender: DefenderV1): DefenderV2 => {
  return {
    ...defender,
    constantDamages: {
      disguise: false,
    },
  }
}

const fixAttackerState = (attacker: AttackerV1): AttackerV2 => {
  return attacker
}

export const fixHistoryV1 = (history: CalcHistoryV1): CalcHistoryV2 => {
  return history.map((h) => ({
    id: h.id,
    attackersState: h.attackersState.map((a) => fixAttackerState(a)),
    defendersState: h.defendersState.map((d) => fixDefenderState(d)),
  }))
}
