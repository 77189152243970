export type StatusConditions = {
  burn: boolean
  freeze: boolean
  paralysis: boolean
  poison: boolean
}

export const hasAnyStatusCondition = (cond: StatusConditions) => {
  return cond.burn || cond.freeze || cond.paralysis || cond.poison
}
