import { Box } from '@chakra-ui/react'

type IndivisualValueBoxProps = {
  children: React.ReactNode
}
export const IndivisualValueBox = (props: IndivisualValueBoxProps) => {
  return (
    <Box width="70px" display="flex" justifyContent="center">
      {props.children}
    </Box>
  )
}
