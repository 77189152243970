import { Select } from '@chakra-ui/react'

type TotalStatStageCountInputProps = {
  value: number
  onChange: (value: number) => void
}

export const TotalStatStageCountInput = (
  props: TotalStatStageCountInputProps
) => {
  const options = [...Array(42)].map((_, i) => i)
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    props.onChange(Number(event.target.value))
  }
  return (
    <Select value={props.value} onChange={onChange}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}
