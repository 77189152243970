import { Stack } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { CalcHistoryRecordCard } from './CalcHistoryRecordCard'
import { CalcHistory } from './useCalcHistory'

type CalcHistorySectionProps = {
  selectedId?: number
  calcHistory: CalcHistory
  onClickCalcHistoryRecord: (id: number) => void
  onClickRemove: (id: number) => void
  onDragAndDrop: (sourceId: number, destId: number) => void
}

export const CalcHistorySection = ({
  selectedId,
  calcHistory,
  onClickCalcHistoryRecord,
  onClickRemove,
  onDragAndDrop,
}: CalcHistorySectionProps) => {
  const [draggingId, setDraggingId] = useState<number | undefined>()
  const handleDragStart = (id: number) => {
    setDraggingId(id)
  }
  const handleDrop = useCallback(
    (id: number) => {
      if (typeof draggingId === 'undefined') {
        return
      }
      onDragAndDrop(draggingId, id)
      setDraggingId(undefined)
    },
    [draggingId, onDragAndDrop]
  )
  return (
    <Stack spacing={2} padding="10px">
      {calcHistory.data.map((c) => {
        return (
          <CalcHistoryRecordCard
            selectedId={selectedId}
            key={c.id}
            record={c}
            onClickCard={onClickCalcHistoryRecord}
            onClickRemove={onClickRemove}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        )
      })}
    </Stack>
  )
}
