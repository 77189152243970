import { Dispatch, memo, useCallback } from 'react'
import { StatStage, StatStages } from '../../../domain/StatStage'
import { NatureCorrection, NatureCorrections } from '../../../domain/Nature'
import { AttackersAction } from './useAttackersState'
import { StatsForm } from '../common/StatsForm'
import { IndivisualValues } from '../../../domain/IndivisualValue'
import { EffortValues } from '../../../domain/EffortValue'
import { BaseStats } from '../../../domain/Stat'
import { AttackReference } from '../../../domain/BaseMove'
import { Box } from '@chakra-ui/react'

type AttackerStatsSectionProps = {
  id: number
  level: number
  baseStats: BaseStats
  indivisualValues: IndivisualValues
  effortValues: EffortValues
  natureCorrections: NatureCorrections
  statStages: StatStages
  attackReference: AttackReference
  dispatch: Dispatch<AttackersAction>
}

export const AttackerStatsSection = memo(
  ({
    id,
    level,
    baseStats,
    indivisualValues,
    effortValues,
    natureCorrections,
    statStages,
    attackReference,
    dispatch,
  }: AttackerStatsSectionProps) => {
    const handleChangeIndivisualValue = useCallback(
      (key: keyof IndivisualValues, value: number) => {
        dispatch({
          type: 'changeIv',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeEffortValue = useCallback(
      (key: keyof EffortValues, value: number) => {
        dispatch({
          type: 'changeEv',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeNatureCorrection = useCallback(
      (key: keyof NatureCorrections, value: NatureCorrection) => {
        dispatch({
          type: 'changeNatureCorrection',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )
    const handleChangeStatStages = useCallback(
      (key: keyof StatStages, value: StatStage) => {
        dispatch({
          type: 'changeStatStage',
          payload: { id, value, key },
        })
      },
      [dispatch, id]
    )

    if (attackReference === 'defenderAttack') {
      return <></>
    }
    return (
      <Box data-testid={`attacker-${id}-stats`}>
        <StatsForm
          display={{
            hp: false,
            attack:
              attackReference === 'attackerAttack' ||
              attackReference === 'teraBlast',
            defense: attackReference === 'attackerDefense',
            specialAttack:
              attackReference === 'attackerSpecialAttack' ||
              attackReference === 'teraBlast',
            specialDefense: false,
            speed: false,
          }}
          level={level}
          baseStats={baseStats}
          indivisualValues={indivisualValues}
          effortValues={effortValues}
          natureCorrections={natureCorrections}
          statStages={statStages}
          onChangeIndivisualValue={handleChangeIndivisualValue}
          onChangeEffortValue={handleChangeEffortValue}
          onChangeNatureCorrection={handleChangeNatureCorrection}
          onChangeStatStage={handleChangeStatStages}
          dataTestIdPrefix={`attacker-${id}-stats`}
        />
      </Box>
    )
  }
)
