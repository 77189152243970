import { Box, Card, CardBody, Flex, Stack } from '@chakra-ui/react'
import { useCallback } from 'react'
import { RemoveButton } from '../../common/RemoveButton'
import { AttackerSection } from './AttackerSection'
import { DefenderSection } from './DefenderSection'
import { CalcHistoryRecord } from './useCalcHistory'

type CalcHistoryRecordCardProps = {
  selectedId?: number
  record: CalcHistoryRecord
  onClickCard: (id: number) => void
  onClickRemove: (id: number) => void
  onDragStart: (id: number) => void
  onDrop: (id: number) => void
}

export const CalcHistoryRecordCard = ({
  selectedId,
  record,
  onClickCard,
  onClickRemove,
  onDragStart,
  onDrop,
}: CalcHistoryRecordCardProps) => {
  const handleClickCard = () => {
    onClickCard(record.id)
  }
  const handleClickRemove = () => {
    onClickRemove(record.id)
  }
  const handleDragStart = () => {
    onDragStart(record.id)
  }
  const handleDragOver: React.DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
    },
    []
  )
  const handleDrop: React.DragEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault()
      onDrop(record.id)
    },
    [onDrop, record.id]
  )

  return (
    <Card
      onClick={handleClickCard}
      cursor="pointer"
      backgroundColor={selectedId === record.id ? 'gainsboro' : 'white'}
      draggable
      onDragStart={handleDragStart}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      _active={{
        opacity: 1,
      }}
    >
      <CardBody paddingX={2} paddingY={1}>
        <Flex justifyContent="space-between">
          <Stack spacing={2}>
            <Flex alignItems="center">
              <Box>攻</Box>
              {record.attackersState.slice(0, 1).map((attacker) => (
                <AttackerSection key={attacker.id} attacker={attacker} />
              ))}
            </Flex>
            <Flex alignItems="center">
              <Box>防</Box>
              {record.defendersState.slice(0, 5).map((defender) => (
                <DefenderSection key={defender.id} defender={defender} />
              ))}
            </Flex>
          </Stack>
          <Box>
            <RemoveButton onClick={handleClickRemove} />
          </Box>
        </Flex>
      </CardBody>
    </Card>
  )
}
