import { Box, Flex } from '@chakra-ui/react'
import { NatureCorrection } from '../../../domain/Nature'
import { Stats } from '../../../domain/Stat'
import { Defender } from '../DefendersForm/useDefendersState'

const displayNatureCorrection = (natureCorrection: NatureCorrection) => {
  if (natureCorrection === '上昇') {
    return 'x1.1'
  } else if (natureCorrection === '下降') {
    return 'x0.9'
  } else {
    return 'x1.0'
  }
}

const StatDisplay = ({
  label,
  stat,
  effortValue,
  indivisualValue,
  natureCorrection,
  statStage,
}: {
  label: string
  stat: number
  effortValue: number
  indivisualValue: number
  natureCorrection: NatureCorrection
  statStage: number
}) => {
  return (
    <Flex gap="5px">
      <Box>
        {label}
        {stat}
      </Box>
      <Box>{`(努${effortValue}, 個${indivisualValue}, 性${displayNatureCorrection(
        natureCorrection
      )})`}</Box>
      <Box>能力ランク: {statStage}</Box>
    </Flex>
  )
}

const HPStatDisplay = ({
  label,
  stat,
  effortValue,
  indivisualValue,
}: {
  label: string
  stat: number
  effortValue: number
  indivisualValue: number
}) => {
  return (
    <Flex gap="5px">
      <Box>
        {label}
        {stat}
      </Box>
      <Box>{`(努${effortValue}, 個${indivisualValue})`}</Box>
    </Flex>
  )
}

type StatsDisplayProps = {
  stats: Stats
  defender: Defender
  isIncludeDefenderAttackReference: boolean
  isIncludeDefenderDefenseReference: boolean
  isIncludeDefenderSpecialDefenseReference: boolean
}
export const DefendersStatsDisplay = ({
  stats,
  defender,
  isIncludeDefenderAttackReference,
  isIncludeDefenderDefenseReference,
  isIncludeDefenderSpecialDefenseReference,
}: StatsDisplayProps) => {
  return (
    <>
      <HPStatDisplay
        label="HP"
        stat={stats.hp}
        effortValue={defender.effortValues.hp}
        indivisualValue={defender.indivisualValues.hp}
      />
      {isIncludeDefenderAttackReference && (
        <StatDisplay
          label="攻撃"
          stat={stats.attack}
          effortValue={defender.effortValues.attack}
          indivisualValue={defender.indivisualValues.attack}
          natureCorrection={defender.natureCorrections.attack}
          statStage={defender.statStages.attack}
        />
      )}
      {isIncludeDefenderDefenseReference && (
        <StatDisplay
          label="防御"
          stat={stats.defense}
          effortValue={defender.effortValues.defense}
          indivisualValue={defender.indivisualValues.defense}
          natureCorrection={defender.natureCorrections.defense}
          statStage={defender.statStages.defense}
        />
      )}
      {isIncludeDefenderSpecialDefenseReference && (
        <StatDisplay
          label="特防"
          stat={stats.specialDefense}
          effortValue={defender.effortValues.specialDefense}
          indivisualValue={defender.indivisualValues.specialDefense}
          natureCorrection={defender.natureCorrections.specialDefense}
          statStage={defender.statStages.specialDefense}
        />
      )}
    </>
  )
}
