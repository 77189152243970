import { Flex, Stack } from '@chakra-ui/react'
import { Dispatch } from 'react'
import { 最終ダメージ計算結果 } from '../../../domain/DamageFormula'
import { AddButton } from '../../common/AddButton'
import { DefenderCard } from './DefenderCard'
import { DefendersAction, DefendersState } from './useDefendersState'

type DefendersFormProps = {
  state: DefendersState
  dispatch: Dispatch<DefendersAction>
  isIncludeDefenderAttackReference: boolean
  isIncludeDefenderDefenseReference: boolean
  isIncludeDefenderSpecialDefenseReference: boolean
  results: 最終ダメージ計算結果[]
}

export const DefendersForm = ({
  state,
  dispatch,
  isIncludeDefenderAttackReference,
  isIncludeDefenderDefenseReference,
  isIncludeDefenderSpecialDefenseReference,
  results,
}: DefendersFormProps) => {
  const onClickAdd = () => {
    dispatch({ type: 'add' })
  }

  return (
    <Stack spacing={4}>
      <Flex justifyContent="center">防御側</Flex>
      {state.map((defender) => (
        <DefenderCard
          key={defender.id}
          removable={state.length > 1}
          id={defender.id}
          isIncludeDefenderAttackReference={isIncludeDefenderAttackReference}
          isIncludeDefenderDefenseReference={isIncludeDefenderDefenseReference}
          isIncludeDefenderSpecialDefenseReference={
            isIncludeDefenderSpecialDefenseReference
          }
          defender={defender}
          dispatch={dispatch}
          result={results[state.findIndex((d) => d.id === defender.id)]}
        />
      ))}
      <AddButton onClick={onClickAdd} />
    </Stack>
  )
}
