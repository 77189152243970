import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { 最終ダメージ計算結果 } from '../../../domain/DamageFormula'
import { DamageBar } from './DamageBar'

type DamageCalcResultProps = {
  result: 最終ダメージ計算結果
  deffenseHp: number
  dataTestIdMinDamage?: string
  dataTestIdMaxDamage?: string
  dataTestIdPrefix: string
}

export const DamageCalcResult = ({
  result,
  deffenseHp,
  dataTestIdMinDamage,
  dataTestIdMaxDamage,
  dataTestIdPrefix,
}: DamageCalcResultProps) => {
  const hp = deffenseHp
  const HighestDamageRatio = result.highestDamage / hp
  const lowestRemainingHpRatio = 1 - HighestDamageRatio
  const lowestDamageRatio = result.lowestDamage / hp
  const highestRemainingHpRatio = 1 - lowestDamageRatio
  const displayHighDamage = (HighestDamageRatio * 100).toFixed(1)
  const displayLowDamage = (lowestDamageRatio * 100).toFixed(1)
  return (
    <Box>
      <Flex gap="10px">
        <Box>
          <span data-testid={dataTestIdMinDamage}>{result.lowestDamage}</span>
          <span> ~ </span>
          <span data-testid={dataTestIdMaxDamage}>{result.highestDamage}</span>
        </Box>
        <Box>
          (
          <span data-testid={`${dataTestIdPrefix}-min-hp-ratio`}>
            {displayLowDamage}
          </span>{' '}
          ~{' '}
          <span data-testid={`${dataTestIdPrefix}-max-hp-ratio`}>
            {displayHighDamage}
          </span>{' '}
          %)
        </Box>
      </Flex>
      <Box height="15px">
        <DamageBar
          lowHpRatio={lowestRemainingHpRatio}
          highHpRatio={highestRemainingHpRatio}
        />
      </Box>
    </Box>
  )
}
