import { pokemons } from './data/Pokemons'

export type Pokemon = {
  id: string
  nationalNumber: number
  name: string
  hp: number
  attack: number
  defense: number
  specialAttack: number
  specialDefense: number
  speed: number
  weight: number
  types: number[]
  abilities: string[]
}

export const getPokemon = (pokemonId: string) => {
  const pokemon = pokemons.find((m) => m.id === pokemonId)
  if (typeof pokemon === 'undefined') {
    throw new Error()
  }
  return pokemon
}

//除外リスト
// const ignoreIds = [
//   '946f', // イッカネズミの形態違い
//   '960b', // イキリンコの色違い
//   '960y', // イキリンコの色違い
//   '960w', // イキリンコの色違い
//   '550f', // バスラオの色違い
//   '917f', // ノココッチの違い
//   '952t', // シャリタツの色違い
//   '952n', // シャリタツの色違い
// ]
