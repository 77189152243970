import { Box, Select } from '@chakra-ui/react'

type InputAttackTimesProps = {
  display: boolean
  value: number
  onChange: (value: number) => void
  options: number[]
}

export const InputAttackTimes = (props: InputAttackTimesProps) => {
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    props.onChange(Number(event.target.value))
  }
  return (
    <Box width="80px">
      {props.display && (
        <Select value={props.value} onChange={onChange}>
          {props.options.map((t) => (
            <option key={t} value={t}>
              {t}回
            </option>
          ))}
        </Select>
      )}
    </Box>
  )
}
