import { Attacker } from '../../AttackersForm/useAttackersState'
import { Defender } from '../../DefendersForm/useDefendersState'
import { CalcHistory } from '../useCalcHistory'
import { AttackerV1, DefenderV1 } from './fixHistoryV1'

export type AttackerV2 = AttackerV1

export type DefenderV2 = DefenderV1 & {
  constantDamages: {
    disguise: boolean
  }
}

export type CalcHistoryV2 = {
  id: number
  attackersState: AttackerV1[]
  defendersState: DefenderV2[]
}[]

const fixDefenderState = (defender: DefenderV2): Defender => {
  return {
    ...defender,
  }
}

const fixAttackerState = (attacker: AttackerV2): Attacker => {
  return attacker
}

export const fixHistoryV2 = (history: CalcHistoryV2): CalcHistory => {
  return {
    schema: 'v3',
    data: history.map((h) => ({
      id: h.id,
      attackersState: h.attackersState.map((a) => fixAttackerState(a)),
      defendersState: h.defendersState.map((d) => fixDefenderState(d)),
    })),
  }
}
