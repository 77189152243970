export type HeldItem = {
  id: number
  name: string
  offensive: boolean
  defensive: boolean
  isExistsInPaldia: boolean
}

export const getHeldItem = (id: number) => {
  const item = heldItems.find((h) => h.id === id)
  if (typeof item === 'undefined') {
    throw new Error()
  }
  return item
}

export const heldItems: HeldItem[] = [
  {
    id: 0,
    name: 'なし',
    offensive: true,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 18,
    name: 'いのちのたま',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 2,
    name: 'こだわりハチマキ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 3,
    name: 'こだわりメガネ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 17,
    name: 'たつじんのおび',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 4,
    name: 'ちからのハチマキ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 5,
    name: 'ものしりメガネ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },

  {
    id: 7,
    name: 'タイプ強化系(x1.2)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 8,
    name: 'ジュエル系(x1.3)',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 6,
    name: 'パンチグローブ',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 9,
    name: 'でんきだま',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 10,
    name: 'しんかのきせき',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 11,
    name: 'とつげきチョッキ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
  {
    id: 12,
    name: 'メトロノーム2',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 13,
    name: 'メトロノーム3',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 14,
    name: 'メトロノーム4',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 15,
    name: 'メトロノーム5',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 16,
    name: 'メトロノーム6',
    offensive: true,
    defensive: false,
    isExistsInPaldia: true,
  },
  {
    id: 19,
    name: '半減きのみ',
    offensive: false,
    defensive: true,
    isExistsInPaldia: true,
  },
]
