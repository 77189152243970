import { Box, Flex } from '@chakra-ui/react'
import { calcHPStat } from '../../../domain/StatsFormula'
import { NatureCorrection } from '../../../domain/Nature'
import { BaseStatsBox } from './BaseStatsBox'
import { EffortValueBox } from './EffortValueBox'
import { EffortValueSlider } from './EffortValueSlider'
import { IndivisualValueBox } from './IndivisualValueBox'
import { IndivisualValuePicker } from './IndivisualValuePicker'
import { LabelBox } from './LabelBox'
import { NatureCorrectionBox } from './NatureCorrectionBox'
import { StatsBox } from './StatsBox'
import { StatStageBox } from './StatStageBox'

type StatsLineHPProps = {
  label: string
  level: number
  baseStats: number
  indivisualValue: number
  effortValue: number
  natureCorrection?: NatureCorrection
  onChangeIndivisualValue: (indivisualValue: number) => void
  onChangeEffortValue: (effortValue: number) => void
  dataTestIdPrefix: string
}

export const StatsLineHP = ({
  label,
  level,
  baseStats,
  indivisualValue,
  effortValue,
  onChangeIndivisualValue,
  onChangeEffortValue,
  dataTestIdPrefix,
}: StatsLineHPProps) => {
  return (
    <Box>
      <Flex gap="10px" alignItems="center" height="50px">
        <LabelBox>{label}</LabelBox>
        <StatsBox data-testid={`${dataTestIdPrefix}-stat`}>
          {calcHPStat({
            level: level,
            baseStat: baseStats,
            indivisualValue: indivisualValue,
            effortValue: effortValue,
          })}
        </StatsBox>
        <BaseStatsBox>{baseStats}</BaseStatsBox>
        <IndivisualValueBox>
          <IndivisualValuePicker
            value={indivisualValue}
            onChange={onChangeIndivisualValue}
            dataTestId={`${dataTestIdPrefix}-iv`}
          />
        </IndivisualValueBox>
        <EffortValueBox>{effortValue}</EffortValueBox>
        <NatureCorrectionBox> </NatureCorrectionBox>
        <StatStageBox> </StatStageBox>
      </Flex>
      <Box>
        <EffortValueSlider
          value={effortValue}
          onChange={onChangeEffortValue}
          indivisualValue={indivisualValue}
          dataTestId={`${dataTestIdPrefix}-ev-slider`}
        />
      </Box>
    </Box>
  )
}
