import { useCallback, useEffect, useState } from 'react'
import { AttackersState } from '../AttackersForm/useAttackersState'
import { DefendersState } from '../DefendersForm/useDefendersState'
import { fixHistory } from './HistoryFixer/fixHistory'
import { SchemaVersion } from './HistoryFixer/SchemaVersion'

export type CalcHistoryRecord = {
  id: number
  attackersState: AttackersState
  defendersState: DefendersState
}
export type CalcHistory = {
  schema: SchemaVersion
  data: CalcHistoryRecord[]
}

export type AddCalcHistory = (calcCondition: {
  attackersState: AttackersState
  defendersState: DefendersState
}) => void

export type UpdateCalcHistory = (calcCondition: {
  id: number
  attackersState: AttackersState
  defendersState: DefendersState
}) => void

const storeCalcHistory = (calcHistory: CalcHistory): void => {
  localStorage.setItem('calcHistory', JSON.stringify(calcHistory))
}

const readCalcHistory = () => {
  const str = localStorage.getItem('calcHistory')
  if (str === null) {
    return []
  } else {
    return JSON.parse(str)
  }
}

const fixCalcHistory = () => {
  const history = readCalcHistory()
  const newHistory = fixHistory(history)
  storeCalcHistory(newHistory)
}

export const useCalcHistory = () => {
  const [calcHistory, setCalcHistory] = useState<CalcHistory>({
    schema: 'v3',
    data: [],
  })
  useEffect(() => {
    fixCalcHistory()
  }, [])

  const add: AddCalcHistory = useCallback(
    (calcCondition) => {
      let maxId = 0
      if (calcHistory.data.length !== 0) {
        maxId = Math.max(...calcHistory.data.map((r) => r.id))
      }
      const newRecord: CalcHistoryRecord = {
        attackersState: calcCondition.attackersState.map((r) => ({
          ...r,
          isOpen: false,
        })),
        defendersState: calcCondition.defendersState.map((r) => ({
          ...r,
          isOpen: false,
        })),
        id: maxId + 1,
      }
      const newHistory = {
        schema: calcHistory.schema,
        data: [...calcHistory.data, newRecord],
      }

      setCalcHistory(newHistory)
      storeCalcHistory(newHistory)
    },
    [calcHistory]
  )
  const update: UpdateCalcHistory = useCallback(
    ({ id, attackersState, defendersState }) => {
      const index = calcHistory.data.findIndex((r) => r.id === id)
      const newRecord: CalcHistoryRecord = {
        attackersState: attackersState.map((r) => ({
          ...r,
          isOpen: false,
        })),
        defendersState: defendersState.map((r) => ({
          ...r,
          isOpen: false,
        })),
        id: id,
      }
      const newHistory = {
        schema: calcHistory.schema,
        data: [
          ...calcHistory.data.slice(0, index),
          newRecord,
          ...calcHistory.data.slice(index + 1),
        ],
      }
      setCalcHistory(newHistory)
      storeCalcHistory(newHistory)
    },
    [calcHistory]
  )

  const remove = useCallback(
    (id: number) => {
      const index = calcHistory.data.findIndex((r) => r.id === id)
      const newArray = [
        ...calcHistory.data.slice(0, index),
        ...calcHistory.data.slice(index + 1),
      ]
      const newHistory = {
        schema: calcHistory.schema,
        data: newArray,
      }
      setCalcHistory(newHistory)
      storeCalcHistory(newHistory)
    },
    [calcHistory]
  )

  const move = (srcId: number, destId: number) => {
    setCalcHistory((prev) => {
      const srcIndex = prev.data.findIndex((r) => r.id === srcId)
      const destIndex = prev.data.findIndex((r) => r.id === destId)
      const newArray = prev.data.filter((r) => r.id !== srcId)
      newArray.splice(destIndex, 0, prev.data[srcIndex])
      return {
        schema: prev.schema,
        data: newArray,
      }
    })
  }

  useEffect(() => {
    setCalcHistory(readCalcHistory())
  }, [])

  return { calcHistory, add, update, remove, move }
}
