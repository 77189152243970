import { Select } from '@chakra-ui/react'

type NumberSelectProps = {
  value: number
  onChange: (value: number) => void
  min: number
  max: number
}

export const NumberSelect = ({
  value,
  onChange,
  min,
  max,
}: NumberSelectProps) => {
  const options = [...Array(max - min + 1)].map((_, i) => i + min)
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    onChange(Number(event.target.value))
  }
  return (
    <Select value={value} onChange={handleChange}>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Select>
  )
}
