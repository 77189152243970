export type Terrain = {
  id: number
  name: string
}

export const TERRAINS_ID = {
  NONE: 0,
  ELECTRIC_TERRAIN: 1,
  GRASSY_TERRAIN: 2,
  MISTY_TERRAIN: 3,
  PSYCHIC_TERRAIN: 4,
}

export const TERRAINS: Terrain[] = [
  {
    id: TERRAINS_ID.NONE,
    name: 'フィールドなし',
  },
  {
    id: TERRAINS_ID.ELECTRIC_TERRAIN,
    name: 'エレキフィールド',
  },
  {
    id: TERRAINS_ID.GRASSY_TERRAIN,
    name: 'グラスフィールド',
  },
  {
    id: TERRAINS_ID.MISTY_TERRAIN,
    name: 'ミストフィールド',
  },
  {
    id: TERRAINS_ID.PSYCHIC_TERRAIN,
    name: 'サイコフィールド',
  },
]

export const getTerrain = (id: number) => {
  const terrain = TERRAINS.find((t) => t.id === id)
  if (typeof terrain === 'undefined') {
    throw new Error()
  }
  return terrain
}
