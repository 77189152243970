import { Dispatch } from 'react'
import { 最終ダメージ計算結果 } from '../../../domain/DamageFormula'
import { DefenderDisplay } from '../DefenderDisplay/DefenderDisplay'
import { DefenderForm } from './DefenderForm'
import { useDefenderCalculatedValues } from './useDefenderCalculatedValues'
import { Defender, DefendersAction } from './useDefendersState'

type DefenderCardProps = {
  removable: boolean
  id: number
  isIncludeDefenderAttackReference: boolean
  isIncludeDefenderDefenseReference: boolean
  isIncludeDefenderSpecialDefenseReference: boolean
  defender: Defender
  dispatch: Dispatch<DefendersAction>
  result: 最終ダメージ計算結果
}

export const DefenderCard = ({
  removable,
  id,
  isIncludeDefenderAttackReference,
  isIncludeDefenderDefenseReference,
  isIncludeDefenderSpecialDefenseReference,
  defender,
  dispatch,
  result,
}: DefenderCardProps) => {
  const { pokemon, stats, ability, baseStats } = useDefenderCalculatedValues({
    defender,
  })

  const handleOpenClick = () => {
    dispatch({
      type: 'changeIsOpen',
      payload: { id, value: true },
    })
  }

  const handleCloseClick = () => {
    dispatch({
      type: 'changeIsOpen',
      payload: { id, value: false },
    })
  }

  if (defender.isOpen) {
    return (
      <DefenderForm
        removable={removable}
        id={id}
        isIncludeDefenderAttackReference={isIncludeDefenderAttackReference}
        defender={defender}
        dispatch={dispatch}
        result={result}
        pokemon={pokemon}
        baseStats={baseStats}
        onCloseClick={handleCloseClick}
      />
    )
  } else {
    return (
      <DefenderDisplay
        id={id}
        defender={defender}
        pokemon={pokemon}
        stats={stats}
        ability={ability}
        onOpenClick={handleOpenClick}
        isIncludeDefenderAttackReference={isIncludeDefenderAttackReference}
        isIncludeDefenderDefenseReference={isIncludeDefenderDefenseReference}
        isIncludeDefenderSpecialDefenseReference={
          isIncludeDefenderSpecialDefenseReference
        }
        result={result}
      />
    )
  }
}
