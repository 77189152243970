import { Box, HStack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { getBaseMove } from '../../../domain/BaseMove'
import { Attacker } from '../AttackersForm/useAttackersState'
import { PokemonWithHeldItemIcon } from './PokemonWithHeldItemIcon'

type AttackerSectionProps = {
  attacker: Attacker
}

export const AttackerSection = ({ attacker }: AttackerSectionProps) => {
  const move = useMemo(() => getBaseMove(attacker.moveId), [attacker.moveId])
  return (
    <HStack>
      <PokemonWithHeldItemIcon
        pokemonId={attacker.pokemonId}
        heldItemId={attacker.heldItemId}
      />
      <Box fontSize="12px">{move.name}</Box>
    </HStack>
  )
}
