import { Box } from '@chakra-ui/react'
import { HeldItemIcon } from '../../common/HeldItemIcon'
import { PokeIcon } from '../../common/PokeIcon'

type PokeIconProps = {
  pokemonId: string
  heldItemId: number
}
export const PokemonWithHeldItemIcon = ({
  pokemonId,
  heldItemId,
}: PokeIconProps) => {
  return (
    <Box position="relative">
      <PokeIcon pokemonId={pokemonId} width="70px" />
      <HeldItemIcon
        heldItemId={heldItemId}
        width="35px"
        position="absolute"
        top="35px"
        left="35px"
      />
    </Box>
  )
}
