import { TriangleDownIcon } from '@chakra-ui/icons'
import { Box, Card, CardBody, Flex, HStack, IconButton } from '@chakra-ui/react'
import { BaseMove } from '../../../domain/BaseMove'
import { Pokemon } from '../../../domain/Pokemon'
import { Stats } from '../../../domain/Stat'
import { WEATHERS_ID, getWeather } from '../../../domain/Weather'
import { Attacker } from '../AttackersForm/useAttackersState'
import { MoveDisplay } from './MoveDisplay'
import { PokemonWithHeldItemIcon } from '../DisplayParts/PokemonWithHeldItemIcon'
import { AttackerStatsDisplay } from './AttackerStatsDisplay'
import { Ability } from '../../../domain/Ability'
import { getType } from '../../../domain/Type'
import { getTerrain, TERRAINS_ID } from '../../../domain/Terrain'
import { memo } from 'react'

type AttackerDisplayProps = {
  attacker: Attacker
  pokemon: Pokemon
  ability: Ability
  move: BaseMove
  movePower: string
  moveDisplayCategory: string
  stats: Stats
  onOpenClick: () => void
}

export const AttackerDisplay = memo(
  ({
    attacker,
    pokemon,
    ability,
    move,
    movePower,
    moveDisplayCategory,
    stats,
    onOpenClick,
  }: AttackerDisplayProps) => {
    return (
      <Card>
        <CardBody>
          <Flex alignItems="center">
            <PokemonWithHeldItemIcon
              pokemonId={attacker.pokemonId}
              heldItemId={attacker.heldItemId}
            />
            <Box>
              <HStack>
                <Box>{pokemon.name}</Box>
                {ability.name !== 'なし' && <Box>特性:{ability.name}</Box>}
                {typeof attacker.teraType !== 'undefined' && (
                  <Box>{getType(attacker.teraType).name}テラスタル</Box>
                )}
              </HStack>
              <MoveDisplay
                move={move}
                movePower={movePower}
                moveDisplayCategory={moveDisplayCategory}
                attackTimes={attacker.attackTime}
              />
              <AttackerStatsDisplay
                stats={stats}
                move={move}
                attacker={attacker}
                moveDisplayCategory={moveDisplayCategory}
              />
              <HStack>
                {attacker.criticalHitIsEnabled && <Box>急所</Box>}
                {attacker.isBatteryCharging && <Box>充電</Box>}
                {attacker.statusConditions.burn && <Box>やけど</Box>}
                {attacker.statusConditions.poison && <Box>どく</Box>}
                {attacker.weatherId !== WEATHERS_ID.NONE && (
                  <Box>天候{getWeather(attacker.weatherId).name}</Box>
                )}
                {attacker.terrainId !== TERRAINS_ID.NONE && (
                  <Box>{getTerrain(attacker.terrainId).name}</Box>
                )}
              </HStack>
            </Box>
          </Flex>
          <IconButton
            onClick={onOpenClick}
            aria-label="close"
            width="100%"
            size="xs"
            icon={<TriangleDownIcon />}
          />
        </CardBody>
      </Card>
    )
  }
)
