import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton } from '@chakra-ui/react'
import { memo } from 'react'
import { StatStage } from '../../domain/StatStage'

type StatStagePickerProps = {
  value: StatStage
  onChange: (value: StatStage) => void
}

export const StatStagePicker = memo((props: StatStagePickerProps) => {
  const onClickUp = () => {
    if (props.value > 5) {
      return
    }
    props.onChange(props.value + 1)
  }
  const onClickDown = () => {
    if (props.value < -5) {
      return
    }
    props.onChange(props.value - 1)
  }
  return (
    <Flex alignItems="center" gap="5px">
      <Box width="20px" textAlign="right">
        {props.value > 0 ? `+${props.value}` : props.value}
      </Box>
      <Box>
        <IconButton
          size="sm"
          aria-label="rank-up"
          icon={<ChevronUpIcon boxSize={5} />}
          onClick={onClickUp}
        />
      </Box>
      <Box>
        <IconButton
          size="sm"
          aria-label="rank-down"
          icon={<ChevronDownIcon boxSize={5} />}
          onClick={onClickDown}
        />
      </Box>
    </Flex>
  )
})
