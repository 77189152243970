import { Image } from '@chakra-ui/react'

type PokeIconProps = {
  pokemonId: string
  width: string
}
export const PokeIcon = (props: PokeIconProps) => {
  const src = `/poke-icons/${props.pokemonId}.png`
  return <Image src={src} width={props.width} />
}
