import { AddIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'

type AddButtonProps = {
  onClick: () => void
}
export const AddButton = ({ onClick }: AddButtonProps) => {
  return (
    <IconButton
      aria-label="delete-button"
      icon={<AddIcon color="green.500" />}
      onClick={onClick}
    />
  )
}
