import { useMemo } from 'react'
import { getAbility } from '../../../domain/Ability'
import { getBaseMove } from '../../../domain/BaseMove'
import {
  calculateBattleMoveDisplayCategory,
  calculateBattleMoveType,
  calculateDisplayMovePower,
} from '../../../domain/BattleMove'
import { getPokemon } from '../../../domain/Pokemon'
import { BaseStats } from '../../../domain/Stat'
import { calcStats } from '../../../domain/StatsFormula'
import { typesId } from '../../../domain/Type'
import { Attacker } from './useAttackersState'

export const useAttackerCalculatedValues = ({
  attacker,
}: {
  attacker: Attacker
}) => {
  const pokemon = useMemo(
    () => getPokemon(attacker.pokemonId),
    [attacker.pokemonId]
  )
  const move = useMemo(() => getBaseMove(attacker.moveId), [attacker.moveId])
  const ability = useMemo(
    () => getAbility(attacker.abilityId),
    [attacker.abilityId]
  )
  const stats = useMemo(
    () =>
      calcStats({
        level: attacker.level,
        pokemon: pokemon,
        indivisualValues: attacker.indivisualValues,
        effortValues: attacker.effortValues,
        natureCorrections: attacker.natureCorrections,
      }),
    [
      attacker.effortValues,
      attacker.indivisualValues,
      attacker.level,
      attacker.natureCorrections,
      pokemon,
    ]
  )
  const baseStats: BaseStats = useMemo(
    () => ({
      hp: pokemon.hp,
      attack: pokemon.attack,
      defense: pokemon.defense,
      specialAttack: pokemon.specialAttack,
      specialDefense: pokemon.specialDefense,
      speed: pokemon.speed,
    }),
    [pokemon]
  )
  const moveDisplayCategory = useMemo(
    () =>
      calculateBattleMoveDisplayCategory({
        attackerIsTerastal: typeof attacker.teraType !== 'undefined',
        stats: stats,
        statStages: attacker.statStages,
        baseDisplayCategory: move.displayCategory,
        attackReference: move.attackReference,
      }),
    [
      attacker.statStages,
      attacker.teraType,
      move.attackReference,
      move.displayCategory,
      stats,
    ]
  )
  const moveTypeId = useMemo(
    () =>
      calculateBattleMoveType({
        attackerIsTerastal: typeof attacker.teraType !== 'undefined',
        attackerTeraType: attacker.teraType || typesId.NORMAL,
        attackerAbilityName: ability.name,
        baseMoveTypeId: move.type,
        baseMoveAttackReference: move.attackReference,
      }),
    [ability.name, attacker.teraType, move.attackReference, move.type]
  )
  const movePower = calculateDisplayMovePower({
    baseMove: move,
    moveCustomParams: attacker.moveCustomParams,
  })
  return {
    pokemon,
    baseStats,
    move,
    ability,
    stats,
    moveDisplayCategory,
    moveTypeId,
    movePower,
  }
}
