export type StatStage = number

export type StatStages = {
  attack: number
  defense: number
  specialAttack: number
  specialDefense: number
  speed: number
}

export const statStages: StatStage[] = [
  6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6,
]

export const isStatStage = (value: any): value is StatStage => {
  if (typeof value !== 'number') {
    throw new Error()
  }
  return [6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6].includes(value)
}

export const getRankCorrection = (statStage: number): number => {
  if (statStage === 0) {
    return 1
  } else if (statStage === 1) {
    return 3 / 2
  } else if (statStage === 2) {
    return 4 / 2
  } else if (statStage === 3) {
    return 5 / 2
  } else if (statStage === 4) {
    return 6 / 2
  } else if (statStage === 5) {
    return 7 / 2
  } else if (statStage === 6) {
    return 8 / 2
  } else if (statStage === -1) {
    return 2 / 3
  } else if (statStage === -2) {
    return 2 / 4
  } else if (statStage === -3) {
    return 2 / 5
  } else if (statStage === -4) {
    return 2 / 6
  } else if (statStage === -5) {
    return 2 / 7
  } else if (statStage === -6) {
    return 2 / 8
  } else {
    throw Error('予期しないランク値: ' + statStage)
  }
}
