import { Box } from '@chakra-ui/react'
import { memo } from 'react'

type BasicOptionCardProps = {
  value: string
  name: string
}

export const BasicOptionCard = memo(({ value, name }: BasicOptionCardProps) => {
  return <Box> {name}</Box>
})
