import { Flex } from '@chakra-ui/react'
import { Dispatch } from 'react'
import { ConstantDamages } from '../../../domain/ConstantDamage'
import { Pokemon } from '../../../domain/Pokemon'
import { Checkbox } from '../../common/Checkbox'
import { DefendersAction } from './useDefendersState'

type ConstantDamageSectionProps = {
  id: number
  constantDamages: ConstantDamages
  pokemon: Pokemon
  dispatch: Dispatch<DefendersAction>
}

export const ConstantDamageSection = ({
  id,
  constantDamages,
  pokemon,
  dispatch,
}: ConstantDamageSectionProps) => {
  const handleDisguiseChange = (value: boolean) => {
    dispatch({
      type: 'changeConstantDamage',
      payload: { id, value, key: 'disguise' },
    })
  }
  return (
    <Flex gap="20px">
      {pokemon.name === 'ミミッキュ' && (
        <Checkbox
          value={constantDamages.disguise}
          onChange={handleDisguiseChange}
          label="ばけのかわ(1/8)"
        />
      )}
    </Flex>
  )
}
