import { Flex, Stack } from '@chakra-ui/react'
import { Dispatch } from 'react'
import { AddButton } from '../../common/AddButton'
import { AttackerCard } from './AttackerCard'
import { AttackersAction, AttackersState } from './useAttackersState'

type AttackersFormProps = {
  state: AttackersState
  dispatch: Dispatch<AttackersAction>
}

export const AttackersForm = ({ state, dispatch }: AttackersFormProps) => {
  const onClickAdd = () => {
    dispatch({ type: 'add' })
  }
  return (
    <Stack spacing={4}>
      <Flex justifyContent="center">攻撃側</Flex>
      {state.map((attacker) => (
        <AttackerCard
          key={attacker.id}
          removable={state.length > 1}
          id={attacker.id}
          attacker={attacker}
          dispatch={dispatch}
        />
      ))}
      <AddButton onClick={onClickAdd} />
    </Stack>
  )
}
