import { useMemo } from 'react'
import { getAbility } from '../../../domain/Ability'
import { getPokemon } from '../../../domain/Pokemon'
import { BaseStats } from '../../../domain/Stat'
import { calcStats } from '../../../domain/StatsFormula'
import { Defender } from './useDefendersState'

export const useDefenderCalculatedValues = ({
  defender,
}: {
  defender: Defender
}) => {
  const pokemon = useMemo(
    () => getPokemon(defender.pokemonId),
    [defender.pokemonId]
  )
  const baseStats: BaseStats = useMemo(
    () => ({
      hp: pokemon.hp,
      attack: pokemon.attack,
      defense: pokemon.defense,
      specialAttack: pokemon.specialAttack,
      specialDefense: pokemon.specialDefense,
      speed: pokemon.speed,
    }),
    [pokemon]
  )

  const stats = useMemo(
    () =>
      calcStats({
        level: defender.level,
        pokemon: pokemon,
        indivisualValues: defender.indivisualValues,
        effortValues: defender.effortValues,
        natureCorrections: defender.natureCorrections,
      }),
    [
      defender.effortValues,
      defender.indivisualValues,
      defender.level,
      defender.natureCorrections,
      pokemon,
    ]
  )
  const ability = useMemo(
    () => getAbility(defender.abilityId),
    [defender.abilityId]
  )
  return { pokemon, baseStats, stats, ability }
}
