import { Grid } from '@chakra-ui/react'
import { memo } from 'react'
import { StatusConditions } from '../../../domain/StatusCondition'
import { Checkbox } from '../../common/Checkbox'

type StatusConditionSectionProps = {
  displayBurn?: boolean
  displayFreeze?: boolean
  displayParalysis?: boolean
  displayPoison?: boolean
  statusConditions: StatusConditions
  onChange: (key: keyof StatusConditions, value: boolean) => void
}

export const StatusConditionSection = memo(
  (props: StatusConditionSectionProps) => {
    const onChangeBurn = (value: boolean) => {
      props.onChange('burn', value)
    }
    const onChangeFreeze = (value: boolean) => {
      props.onChange('freeze', value)
    }
    const onChangeParalysis = (value: boolean) => {
      props.onChange('paralysis', value)
    }
    const onChangePoison = (value: boolean) => {
      props.onChange('poison', value)
    }
    return (
      <Grid templateColumns="repeat(4, 100px)">
        {props.displayBurn && (
          <Checkbox
            value={props.statusConditions.burn}
            onChange={onChangeBurn}
            label="やけど"
          />
        )}
        {props.displayFreeze && (
          <Checkbox
            value={props.statusConditions.freeze}
            onChange={onChangeFreeze}
            label="こおり"
          />
        )}
        {props.displayParalysis && (
          <Checkbox
            value={props.statusConditions.paralysis}
            onChange={onChangeParalysis}
            label="まひ"
          />
        )}
        {props.displayPoison && (
          <Checkbox
            value={props.statusConditions.poison}
            onChange={onChangePoison}
            label="どく"
          />
        )}
      </Grid>
    )
  }
)
