import { useReducer } from 'react'
import { StatStage, StatStages } from '../../../domain/StatStage'
import { NatureCorrection, NatureCorrections } from '../../../domain/Nature'
import { getPokemon } from '../../../domain/Pokemon'
import { calcNewEffortValue } from '../common/calcNewEffortValue'
import { IndivisualValues } from '../../../domain/IndivisualValue'
import { EffortValues } from '../../../domain/EffortValue'
import { TypeId, typesId } from '../../../domain/Type'
import { StatusConditions } from '../../../domain/StatusCondition'

type ConstantDamages = {
  disguise: boolean
}

export type Defender = {
  id: number
  level: number
  typeIds: TypeId[]
  heldItemId: number
  abilityId: number
  pokemonId: string
  indivisualValues: IndivisualValues
  effortValues: EffortValues
  natureCorrections: NatureCorrections
  statStages: StatStages
  weatherId: number
  terrainId: number
  hasReflect: boolean
  hasLightScreen: boolean
  statusConditions: StatusConditions
  teraType?: TypeId
  constantDamages: ConstantDamages
  isOpen: boolean
}

export type DefendersState = Defender[]

export type DefendersAction =
  | {
      type: 'init'
      payload: { defendersState: DefendersState }
    }
  | {
      type: 'default'
    }
  | {
      type: 'changeIv'
      payload: { id: number; value: number; key: keyof IndivisualValues }
    }
  | {
      type: 'changeEv'
      payload: { id: number; value: number; key: keyof EffortValues }
    }
  | {
      type: 'changeNatureCorrection'
      payload: {
        id: number
        value: NatureCorrection
        key: keyof NatureCorrections
      }
    }
  | {
      type: 'changeStatStage'
      payload: { id: number; value: StatStage; key: keyof StatStages }
    }
  | {
      type: 'changePokemonId'
      payload: { id: number; value: string }
    }
  | {
      type: 'add'
    }
  | {
      type: 'remove'
      payload: { id: number }
    }
  | {
      type: 'changeAbility'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeHeldItem'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeWeatherId'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeTerrainId'
      payload: { id: number; value: number }
    }
  | {
      type: 'changeHasReflect'
      payload: { id: number; value: boolean }
    }
  | {
      type: 'changeHasLightScreen'
      payload: { id: number; value: boolean }
    }
  | {
      type: 'changeTypeIds'
      payload: { id: number; value: TypeId[] }
    }
  | {
      type: 'changeStatusCondition'
      payload: { id: number; value: boolean; key: keyof StatusConditions }
    }
  | {
      type: 'changeTeraType'
      payload: { id: number; value?: TypeId }
    }
  | {
      type: 'changeIsOpen'
      payload: {
        id: number
        value: boolean
      }
    }
  | {
      type: 'changeConstantDamage'
      payload: {
        id: number
        value: boolean
        key: keyof ConstantDamages
      }
    }

const replaceState = (
  state: DefendersState,
  id: number,
  changeRecord: (record: Defender) => Defender
): DefendersState => {
  const index = state.findIndex((a) => a.id === id)
  return [
    ...state.slice(0, index),
    changeRecord(state[index]),
    ...state.slice(index + 1),
  ]
}

const reducer = (
  state: DefendersState,
  action: DefendersAction
): Defender[] => {
  if (action.type === 'init') {
    return action.payload.defendersState
  } else if (action.type === 'default') {
    return [makeInitial(1)]
  } else if (action.type === 'changeIv') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      indivisualValues: {
        ...record.indivisualValues,
        [key]: value,
      },
      effortValues: {
        ...record.effortValues,
        [key]: calcNewEffortValue(value, record.effortValues[key]),
      },
    }))
  } else if (action.type === 'changeEv') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      effortValues: {
        ...record.effortValues,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeNatureCorrection') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      natureCorrections: {
        ...record.natureCorrections,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeStatStage') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      statStages: {
        ...record.statStages,
        [key]: value,
      },
    }))
  } else if (action.type === 'changePokemonId') {
    const { id, value } = action.payload
    const pokemon = getPokemon(value)
    return replaceState(state, id, (record) => ({
      ...record,
      pokemonId: value,
      typeIds: pokemon.types,
      teraType: undefined,
      constantDamages: defaultConstantDamages,
    }))
  } else if (action.type === 'add') {
    const maxId = Math.max(...state.map((r) => r.id))
    const index = state.findIndex((r) => r.id === maxId)
    return [
      ...state.slice(0, index),
      { ...state[index], isOpen: false },
      makeInitial(maxId + 1),
      // deepCopyDefender({ ...state[index], isOpen: true }, maxId + 1),
    ]
  } else if (action.type === 'remove') {
    const { id } = action.payload
    const index = state.findIndex((a) => a.id === id)
    return [...state.slice(0, index), ...state.slice(index + 1)]
  } else if (action.type === 'changeAbility') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      abilityId: value,
    }))
  } else if (action.type === 'changeHeldItem') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      heldItemId: value,
    }))
  } else if (action.type === 'changeWeatherId') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      weatherId: value,
    }))
  } else if (action.type === 'changeTerrainId') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      terrainId: value,
    }))
  } else if (action.type === 'changeHasReflect') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      hasReflect: value,
    }))
  } else if (action.type === 'changeHasLightScreen') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      hasLightScreen: value,
    }))
  } else if (action.type === 'changeTypeIds') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      typeIds: value,
    }))
  } else if (action.type === 'changeStatusCondition') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      statusConditions: {
        ...record.statusConditions,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeTeraType') {
    const { id, value } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      teraType: value,
    }))
  } else if (action.type === 'changeConstantDamage') {
    const { id, value, key } = action.payload
    return replaceState(state, id, (record) => ({
      ...record,
      constantDamages: {
        ...record.constantDamages,
        [key]: value,
      },
    }))
  } else if (action.type === 'changeIsOpen') {
    const { id, value } = action.payload

    return replaceState(state, id, (record) => ({
      ...record,
      isOpen: value,
    }))
  } else {
    throw new Error()
  }
}

const makeInitial = (id: number): Defender => {
  return {
    id: id,
    level: 50,
    typeIds: [typesId.WATER],
    heldItemId: 0,
    abilityId: 0,
    pokemonId: '931',
    indivisualValues: {
      hp: 31,
      attack: 31,
      specialAttack: 31,
      defense: 31,
      specialDefense: 31,
      speed: 31,
    },
    effortValues: {
      hp: 0,
      attack: 252,
      defense: 0,
      specialAttack: 252,
      specialDefense: 0,
      speed: 0,
    },
    natureCorrections: {
      attack: '無し',
      defense: '無し',
      specialAttack: '無し',
      specialDefense: '無し',
      speed: '無し',
    },
    statStages: {
      attack: 0,
      defense: 0,
      specialAttack: 0,
      specialDefense: 0,
      speed: 0,
    },
    weatherId: 0,
    terrainId: 0,
    hasReflect: false,
    hasLightScreen: false,
    statusConditions: {
      burn: false,
      freeze: false,
      paralysis: false,
      poison: false,
    },
    teraType: undefined,
    isOpen: true,
    constantDamages: {
      disguise: false,
    },
  }
}

export const useDefenders = () => {
  const [state, dispatch] = useReducer(reducer, [makeInitial(1)])
  return { defendersState: state, defendersDispatch: dispatch }
}

const defaultConstantDamages: ConstantDamages = {
  disguise: false,
}
