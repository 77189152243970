import { Box } from '@chakra-ui/react'

type EffortValueBoxProps = {
  children: React.ReactNode
}
export const EffortValueBox = (props: EffortValueBoxProps) => {
  return (
    <Box width="50px" display="flex" justifyContent="center">
      {props.children}
    </Box>
  )
}
