import { getBaseMove, BaseMove } from '../../../domain/BaseMove'
import { calcHPStat, calcStats } from '../../../domain/StatsFormula'
import {
  randomNumbers,
  最終ダメージを計算する,
  最終ダメージ計算結果,
} from '../../../domain/DamageFormula'
import { 最終ダメージを計算する引数を生成する } from '../../../domain/AttackSituation'
import { Attacker } from '../AttackersForm/useAttackersState'
import { Defender } from '../DefendersForm/useDefendersState'
import { unifyDamageCalcResult } from './UnifyDamageCalcResult'
import { typesId } from '../../../domain/Type'
import { getAbility } from '../../../domain/Ability'
import { getPokemon } from '../../../domain/Pokemon'
import {
  calculateBattleMoveDisplayCategory,
  calculateBattleMoveType,
} from '../../../domain/BattleMove'
import { getHeldItem } from '../../../domain/HeldItem'
import { calcDisguiseDamage } from '../../../domain/ConstantDamage'

type FlattenAttacker = {
  pokemon: Attacker
  move: BaseMove
}

const flatAttacks = (attackers: Attacker[]): FlattenAttacker[] => {
  const conditions = []
  for (const attacker of attackers) {
    for (let i = 1; i < attacker.attackTime + 1; i++) {
      conditions.push({ pokemon: attacker, move: getBaseMove(attacker.moveId) })
    }
  }
  return conditions
}

const makeDamageCalcCondition = (args: {
  attack: FlattenAttacker
  defender: Defender
  defenderIsDamaged: boolean
}) => {
  const attacker = args.attack.pokemon
  const defender = args.defender
  const move = args.attack.move
  const attackerPokemon = getPokemon(attacker.pokemonId)
  const defenderPokemon = getPokemon(defender.pokemonId)
  const attackerStats = calcStats({
    level: 50,
    pokemon: attackerPokemon,
    indivisualValues: attacker.indivisualValues,
    effortValues: attacker.effortValues,
    natureCorrections: attacker.natureCorrections,
  })
  const defenderStats = calcStats({
    level: 50,
    pokemon: defenderPokemon,
    indivisualValues: defender.indivisualValues,
    effortValues: defender.effortValues,
    natureCorrections: defender.natureCorrections,
  })
  const attackerAbility = getAbility(attacker.abilityId)
  const defenderAbility = getAbility(defender.abilityId)
  const attackerIsTerastal = typeof attacker.teraType !== 'undefined'
  const attackerTeraType = attacker.teraType || typesId.NORMAL
  const moveType = calculateBattleMoveType({
    attackerIsTerastal: attackerIsTerastal,
    attackerTeraType: attackerTeraType,
    attackerAbilityName: attackerAbility.name,
    baseMoveTypeId: move.type,
    baseMoveAttackReference: move.attackReference,
  })
  const moveCategory = calculateBattleMoveDisplayCategory({
    attackerIsTerastal: typeof attacker.teraType !== 'undefined',
    stats: attackerStats,
    statStages: attacker.statStages,
    baseDisplayCategory: move.displayCategory,
    attackReference: move.attackReference,
  })
  return 最終ダメージを計算する引数を生成する({
    attacker: {
      level: 50,
      hp: attackerStats.hp,
      attack: attackerStats.attack,
      defense: attackerStats.defense,
      specialAttack: attackerStats.specialAttack,
      specialDefense: attackerStats.specialDefense,
      speed: attackerStats.speed,
      weight: attackerPokemon.weight,
      typeIds: attackerPokemon.types,
      heldItem: getHeldItem(attacker.heldItemId),
      ability: attackerAbility,
      weatherId: attacker.weatherId,
      statStages: attacker.statStages,
      isTerastal: attackerIsTerastal,
      teraType: attackerTeraType,
      statusConditions: attacker.statusConditions,
      terrainId: attacker.terrainId,
      isBatteryCharging: attacker.isBatteryCharging,
      criticalHitIsEnabled: attacker.criticalHitIsEnabled,
    },
    defender: {
      level: 50,
      hp: defenderStats.hp,
      attack: defenderStats.attack,
      defense: defenderStats.defense,
      specialAttack: defenderStats.specialAttack,
      specialDefense: defenderStats.specialDefense,
      speed: defenderStats.speed,
      weight: defenderPokemon.weight,
      typeIds:
        typeof defender.teraType !== 'undefined'
          ? [defender.teraType]
          : defender.typeIds,
      heldItem: getHeldItem(defender.heldItemId),
      ability: defenderAbility,
      weatherId: defender.weatherId,
      statStages: defender.statStages,
      teraType: typesId.NORMAL, // 現状計算に使われないのでダミー
      isTerastal: false, // 現状計算に使われないのでダミー
      statusConditions: defender.statusConditions,
      terrainId: defender.terrainId,
      hasReflect: defender.hasReflect,
      hasLightScreen: defender.hasLightScreen,
      isDamaged: args.defenderIsDamaged,
    },
    baseMove: move,
    move: {
      typeId: moveType,
      category: moveCategory,
      customParams: attacker.moveCustomParams,
    },
  })
}

export const calcDamage = (args: {
  attackers: Attacker[]
  defender: Defender
}) => {
  const attacks = flatAttacks(args.attackers)
  let defenderIsDamaged = false
  const results: 最終ダメージ計算結果[] = []

  //TODO ここパフォーマンスに悪いので要リファクタ
  const defenderPokemon = getPokemon(args.defender.pokemonId)
  const maxHp = calcHPStat({
    level: args.defender.level,
    baseStat: defenderPokemon.hp,
    indivisualValue: args.defender.indivisualValues.hp,
    effortValue: args.defender.effortValues.hp,
  })
  if (args.defender.constantDamages.disguise) {
    defenderIsDamaged = true
    const damage = calcDisguiseDamage(maxHp)
    results.push({
      damages: randomNumbers.map(() => damage),
      lowestDamage: damage,
      highestDamage: damage,
    })
  }
  for (const attack of attacks) {
    const cond = makeDamageCalcCondition({
      attack,
      defender: args.defender,
      defenderIsDamaged: defenderIsDamaged,
    })
    const result = 最終ダメージを計算する(cond)
    if (result.lowestDamage > 0) {
      defenderIsDamaged = true
    }
    results.push(result)
  }
  return unifyDamageCalcResult(results)
}

export const calcDamages = (args: {
  attackers: Attacker[]
  defenders: Defender[]
}) => {
  return args.defenders.map((defender, index) =>
    calcDamage({
      attackers: args.attackers,
      defender,
    })
  )
}
