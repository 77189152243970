export const specialRound = (num: number) => {
  if (num < 0) {
    throw new Error()
  }
  const target = num - Math.floor(num)
  if (target <= 0.5) {
    return Math.floor(num)
  } else {
    return Math.ceil(num)
  }
}

export const round = (num: number) => {
  if (num < 0) {
    throw new Error()
  }
  const target = num - Math.floor(num)
  if (target < 0.5) {
    return Math.floor(num)
  } else {
    return Math.ceil(num)
  }
}

export const floor = (num: number) => {
  return Math.floor(num)
}

export const ceil = (num: number) => {
  return Math.ceil(num)
}

export const PokeMath = {
  specialRound: specialRound,
  round: round,
  floor: floor,
  ceil: ceil,
}
