import { Select } from '@chakra-ui/react'
import { memo } from 'react'

type IndivisualValuePickerProps = {
  value: number
  onChange: (value: number) => void
  dataTestId?: string
}

const options = [31, ...[...Array(31)].map((_, i) => i)]

export const IndivisualValuePicker = memo(
  (props: IndivisualValuePickerProps) => {
    const onChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
      props.onChange(Number(event.target.value))
    }
    return (
      <Select
        value={props.value}
        onChange={onChange}
        data-testid={props.dataTestId}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    )
  }
)
