import { Divider, Flex, Heading } from '@chakra-ui/react'

type HeaderProps = {
  height: string
}
export const Header = ({ height }: HeaderProps) => {
  return (
    <>
      <Flex
        position="sticky"
        top="0"
        zIndex="2"
        backgroundColor="white"
        height={height}
        justifyContent="space-between"
        alignItems="center"
        padding="2"
      >
        <Heading size="sm" color="gray">
          ポケモンSV ダメージ計算 (PC専用) β版
        </Heading>
      </Flex>
      <Divider />
    </>
  )
}
