import { TypeId } from './Type'
import { moves } from './data/Moves'
import { judgeTeraBlastCategory } from './TeraBlast'
import { StatStages } from './StatStage'
import { Stats } from './Stat'

export type AttackReference =
  | 'attackerAttack'
  | 'attackerSpecialAttack'
  | 'teraBlast'
  | 'attackerDefense'
  | 'defenderAttack'

export type DefenseReference =
  | 'defenderDefense'
  | 'defenderSpecialDefense'
  | 'teraBlast'

export type BaseMove = {
  id: number
  name: string
  power: number
  type: TypeId
  attackTimes: number[]
  attackReference: AttackReference
  defenseReference: DefenseReference
  displayCategory: string
  isDirect: boolean
  isPunch: boolean
  hasRecoilDamage: boolean
  isSound: boolean
  hasAdditionalEffect: boolean
  isSlashing: boolean
}

export { moves }

export const getBaseMove = (id: number) => {
  const move = moves.find((m) => m.id === id)
  if (typeof move === 'undefined') {
    throw new Error()
  }
  return move
}

export const calcStoredPowerPower = (totalStatStageCount: number) => {
  return 20 + totalStatStageCount * 20
}

export const calcWeightPower = (
  attackerWeight: number,
  defenderWeight: number
) => {
  if (defenderWeight <= (attackerWeight * 1) / 5) {
    return 120
  } else if (defenderWeight <= (attackerWeight * 1) / 4) {
    return 100
  } else if (defenderWeight <= (attackerWeight * 1) / 3) {
    return 80
  } else if (defenderWeight <= (attackerWeight * 1) / 2) {
    return 60
  } else {
    return 40
  }
}

export const calcGrassKnot = (defenderWeight: number) => {
  if (defenderWeight < 10) {
    return 20
  } else if (defenderWeight < 25) {
    return 40
  } else if (defenderWeight < 50) {
    return 60
  } else if (defenderWeight < 100) {
    return 80
  } else if (defenderWeight < 200) {
    return 100
  } else {
    return 120
  }
}

export const calcLastRespectsPower = (lastRespectsCount: number) => {
  return 50 + lastRespectsCount * 50
}

export const calcRageFistPower = (rageFistCount: number) => {
  return 50 + rageFistCount * 50
}

export const calcAcrobaticsBuff = (acrobaticsBuff: boolean) => {
  if (acrobaticsBuff) {
    return 110
  } else {
    return 55
  }
}

export const calcLowKick = (defenderWeight: number) => {
  if (defenderWeight < 10) {
    return 20
  } else if (defenderWeight < 25) {
    return 40
  } else if (defenderWeight < 50) {
    return 60
  } else if (defenderWeight < 100) {
    return 80
  } else if (defenderWeight < 200) {
    return 100
  } else {
    return 120
  }
}

export const calcAvalancheBuff = (avalancheBuff: boolean) => {
  if (avalancheBuff) {
    return 120
  } else {
    return 60
  }
}

export const biteMoves = [
  'エラがみ',
  'かみくだく',
  'かみつく',
  'かみなりのキバ',
  'くらいつく',
  'こおりのキバ',
  'サイコファング',
  'どくどくのキバ',
  'ひっさつまえば',
  'ほのおのキバ',
]

export const waveMoves = [
  'あくのはどう',
  'はどうだん',
  'みずのはどう',
  'りゅうのはどう',
  'だいちのはどう',
  'こんげんのはどう',
]

export const strongAgainstMinimize = [
  'のしかかり',
  'ふみつけ',
  'ドラゴンダイブ',
  'ヘビーボンバー',
  'ヒートスタンプ',
  'フライングプレス',
  'ゴーストダイブ',
  'ハードローラー',
  'ハイパーダーククラッシャー',
]

export const weightRefMoves = ['ヒートスタンプ', 'ヘビーボンバー', 'くさむすび']

export const ignoreBurnDebuffMoves = ['からげんき']

export const getMoveCategory = ({
  attacker,
}: {
  attacker: {
    level: number
    stats: Stats
    statStages: StatStages
    isTerastal: boolean
    teraType: TypeId
    abilityId: number
    move: {
      attackReference: AttackReference
      displayCategory: string
    }
  }
}): string => {
  if (attacker.move.attackReference === 'teraBlast') {
    const categoryEng = judgeTeraBlastCategory({
      isTerastal: attacker.isTerastal,
      attackStat: attacker.stats.attack,
      specialAttackStat: attacker.stats.specialAttack,
      attackStage: attacker.statStages.attack,
      specialAttackStage: attacker.statStages.specialAttack,
    })
    if (categoryEng === 'Physical') {
      return '物理'
    } else {
      return '特殊'
    }
  } else {
    return attacker.move.displayCategory
  }
}
