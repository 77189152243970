import { memo, useCallback } from 'react'
import { EffortValues } from '../../../domain/EffortValue'
import { IndivisualValues } from '../../../domain/IndivisualValue'
import { NatureCorrection, NatureCorrections } from '../../../domain/Nature'
import { BaseStats } from '../../../domain/Stat'
import { StatStage, StatStages } from '../../../domain/StatStage'
import { StatsLine } from './StatsLine'
import { StatsLineHeader } from './StatsLineHeader'
import { StatsLineHP } from './StatsLineHP'

type StatsFormProps = {
  display: {
    hp: boolean
    attack: boolean
    defense: boolean
    specialAttack: boolean
    specialDefense: boolean
    speed: boolean
  }
  level: number
  baseStats: BaseStats
  indivisualValues: IndivisualValues
  effortValues: EffortValues
  natureCorrections: NatureCorrections
  statStages: StatStages
  onChangeIndivisualValue: (key: keyof IndivisualValues, value: number) => void
  onChangeEffortValue: (key: keyof EffortValues, value: number) => void
  onChangeNatureCorrection: (
    key: keyof NatureCorrections,
    value: NatureCorrection
  ) => void
  onChangeStatStage: (key: keyof StatStages, value: number) => void
  dataTestIdPrefix: string
}

export const StatsForm = memo(
  ({
    display,
    level,
    baseStats,
    indivisualValues,
    effortValues,
    natureCorrections,
    statStages,
    onChangeIndivisualValue,
    onChangeEffortValue,
    onChangeNatureCorrection,
    onChangeStatStage,
    dataTestIdPrefix,
  }: StatsFormProps) => {
    const handleChangeHpIv = useCallback(
      (value: number) => {
        onChangeIndivisualValue('hp', value)
      },
      [onChangeIndivisualValue]
    )
    const handleChangeAttackIv = useCallback(
      (value: number) => {
        onChangeIndivisualValue('attack', value)
      },
      [onChangeIndivisualValue]
    )
    const handleChangeDefenseIv = useCallback(
      (value: number) => {
        onChangeIndivisualValue('defense', value)
      },
      [onChangeIndivisualValue]
    )
    const handleChangeSpecialAttackIv = useCallback(
      (value: number) => {
        onChangeIndivisualValue('specialAttack', value)
      },
      [onChangeIndivisualValue]
    )
    const handleChangeSpecialDefenseIv = useCallback(
      (value: number) => {
        onChangeIndivisualValue('specialDefense', value)
      },
      [onChangeIndivisualValue]
    )
    const handleChangeSpeedIv = useCallback(
      (value: number) => {
        onChangeIndivisualValue('speed', value)
      },
      [onChangeIndivisualValue]
    )
    const handleChangeHpEv = useCallback(
      (value: number) => {
        onChangeEffortValue('hp', value)
      },
      [onChangeEffortValue]
    )
    const handleChangeAttackEv = useCallback(
      (value: number) => {
        onChangeEffortValue('attack', value)
      },
      [onChangeEffortValue]
    )
    const handleChangeDefenseEv = useCallback(
      (value: number) => {
        onChangeEffortValue('defense', value)
      },
      [onChangeEffortValue]
    )
    const handleChangeSpecialAttackEv = useCallback(
      (value: number) => {
        onChangeEffortValue('specialAttack', value)
      },
      [onChangeEffortValue]
    )
    const handleChangeSpecialDefenseEv = useCallback(
      (value: number) => {
        onChangeEffortValue('specialDefense', value)
      },
      [onChangeEffortValue]
    )
    const handleChangeSpeedEv = useCallback(
      (value: number) => {
        onChangeEffortValue('speed', value)
      },
      [onChangeEffortValue]
    )
    const handleChangeAttackNatureCorrection = useCallback(
      (value: NatureCorrection) => {
        onChangeNatureCorrection('attack', value)
      },
      [onChangeNatureCorrection]
    )
    const handleChangeDefenseNatureCorrection = useCallback(
      (value: NatureCorrection) => {
        onChangeNatureCorrection('defense', value)
      },
      [onChangeNatureCorrection]
    )
    const handleChangeSpecialAttackNatureCorrection = useCallback(
      (value: NatureCorrection) => {
        onChangeNatureCorrection('specialAttack', value)
      },
      [onChangeNatureCorrection]
    )
    const handleChangeSpecialDefenseNatureCorrection = useCallback(
      (value: NatureCorrection) => {
        onChangeNatureCorrection('specialDefense', value)
      },
      [onChangeNatureCorrection]
    )
    const handleChangeSpeedNatureCorrection = useCallback(
      (value: NatureCorrection) => {
        onChangeNatureCorrection('speed', value)
      },
      [onChangeNatureCorrection]
    )
    const handleChangeAttackStatStage = useCallback(
      (value: StatStage) => {
        onChangeStatStage('attack', value)
      },
      [onChangeStatStage]
    )
    const handleChangeDefenseStatStage = useCallback(
      (value: StatStage) => {
        onChangeStatStage('defense', value)
      },
      [onChangeStatStage]
    )
    const handleChangeSpecialAttackStatStage = useCallback(
      (value: StatStage) => {
        onChangeStatStage('specialAttack', value)
      },
      [onChangeStatStage]
    )
    const handleChangeSpecialDefenseStatStage = useCallback(
      (value: StatStage) => {
        onChangeStatStage('specialDefense', value)
      },
      [onChangeStatStage]
    )
    const handleChangeSpeedStatStage = useCallback(
      (value: StatStage) => {
        onChangeStatStage('speed', value)
      },
      [onChangeStatStage]
    )
    return (
      <>
        <StatsLineHeader />
        {display.hp && (
          <StatsLineHP
            label="HP"
            level={50}
            baseStats={baseStats.hp}
            indivisualValue={indivisualValues.hp}
            effortValue={effortValues.hp}
            onChangeIndivisualValue={handleChangeHpIv}
            onChangeEffortValue={handleChangeHpEv}
            dataTestIdPrefix={`${dataTestIdPrefix}-hp`}
          />
        )}
        {display.attack && (
          <StatsLine
            label="攻撃"
            level={level}
            baseStats={baseStats.attack}
            indivisualValue={indivisualValues.attack}
            effortValue={effortValues.attack}
            natureCorrection={natureCorrections.attack}
            statStage={statStages.attack}
            onChangeIndivisualValue={handleChangeAttackIv}
            onChangeEffortValue={handleChangeAttackEv}
            onChangeNatureCorrection={handleChangeAttackNatureCorrection}
            onChangeStatStage={handleChangeAttackStatStage}
            dataTestIdPrefix={`${dataTestIdPrefix}-attack`}
          />
        )}
        {display.defense && (
          <StatsLine
            label="防御"
            level={level}
            baseStats={baseStats.defense}
            indivisualValue={indivisualValues.defense}
            effortValue={effortValues.defense}
            natureCorrection={natureCorrections.defense}
            statStage={statStages.defense}
            onChangeIndivisualValue={handleChangeDefenseIv}
            onChangeEffortValue={handleChangeDefenseEv}
            onChangeNatureCorrection={handleChangeDefenseNatureCorrection}
            onChangeStatStage={handleChangeDefenseStatStage}
            dataTestIdPrefix={`${dataTestIdPrefix}-defense`}
          />
        )}
        {display.specialAttack && (
          <StatsLine
            label="特攻"
            level={level}
            baseStats={baseStats.specialAttack}
            indivisualValue={indivisualValues.specialAttack}
            effortValue={effortValues.specialAttack}
            natureCorrection={natureCorrections.specialAttack}
            statStage={statStages.specialAttack}
            onChangeIndivisualValue={handleChangeSpecialAttackIv}
            onChangeEffortValue={handleChangeSpecialAttackEv}
            onChangeNatureCorrection={handleChangeSpecialAttackNatureCorrection}
            onChangeStatStage={handleChangeSpecialAttackStatStage}
            dataTestIdPrefix={`${dataTestIdPrefix}-specialAttack`}
          />
        )}
        {display.specialDefense && (
          <StatsLine
            label="特防"
            level={level}
            baseStats={baseStats.specialDefense}
            indivisualValue={indivisualValues.specialDefense}
            effortValue={effortValues.specialDefense}
            natureCorrection={natureCorrections.specialDefense}
            statStage={statStages.specialDefense}
            onChangeIndivisualValue={handleChangeSpecialDefenseIv}
            onChangeEffortValue={handleChangeSpecialDefenseEv}
            onChangeNatureCorrection={
              handleChangeSpecialDefenseNatureCorrection
            }
            onChangeStatStage={handleChangeSpecialDefenseStatStage}
            dataTestIdPrefix={`${dataTestIdPrefix}-specialDefense`}
          />
        )}
        {display.speed && (
          <StatsLine
            label="素早"
            level={level}
            baseStats={baseStats.speed}
            indivisualValue={indivisualValues.speed}
            effortValue={effortValues.speed}
            natureCorrection={natureCorrections.speed}
            statStage={statStages.speed}
            onChangeIndivisualValue={handleChangeSpeedIv}
            onChangeEffortValue={handleChangeSpeedEv}
            onChangeNatureCorrection={handleChangeSpeedNatureCorrection}
            onChangeStatStage={handleChangeSpeedStatStage}
            dataTestIdPrefix={`${dataTestIdPrefix}-speed`}
          />
        )}
      </>
    )
  }
)
