import { CalcHistory } from '../useCalcHistory'
import { CalcHistoryV1, fixHistoryV1 } from './fixHistoryV1'
import { CalcHistoryV2, fixHistoryV2 } from './fixHistoryV2'

export const fixHistory = (
  history: CalcHistoryV1 | CalcHistoryV2 | CalcHistory
) => {
  if (Array.isArray(history)) {
    return fixHistoryV2(fixHistoryV1(history))
  } else if (history.schema === 'v3') {
    return history
  } else {
    throw new Error()
  }
}
