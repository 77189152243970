import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'
import { ChangeEventHandler, memo } from 'react'
type CheckboxProps = {
  value: boolean
  onChange: (value: boolean) => void
  label: string
}

export const Checkbox = memo(
  ({ value, onChange, label: lavel }: CheckboxProps) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      onChange(event.target.checked)
    }
    return (
      <ChakraCheckbox isChecked={value} onChange={handleChange}>
        {lavel}
      </ChakraCheckbox>
    )
  }
)
