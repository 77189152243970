import { Box, Flex } from '@chakra-ui/react'
import { BaseMove } from '../../../domain/BaseMove'
import { NatureCorrection } from '../../../domain/Nature'
import { Stats } from '../../../domain/Stat'
import { Attacker } from '../AttackersForm/useAttackersState'

const displayNatureCorrection = (natureCorrection: NatureCorrection) => {
  if (natureCorrection === '上昇') {
    return 'x1.1'
  } else if (natureCorrection === '下降') {
    return 'x0.9'
  } else {
    return 'x1.0'
  }
}

type StatsDisplayProps = {
  stats: Stats
  move: BaseMove
  attacker: Attacker
  moveDisplayCategory: string
}

const StatDisplay = ({
  label,
  stat,
  effortValue,
  indivisualValue,
  natureCorrection,
  statStage,
}: {
  label: string
  stat: number
  effortValue: number
  indivisualValue: number
  natureCorrection: NatureCorrection
  statStage: number
}) => {
  return (
    <Flex gap="5px">
      <Box>
        {label}
        {stat}
      </Box>
      <Box>{`(努${effortValue}, 個${indivisualValue}, 性${displayNatureCorrection(
        natureCorrection
      )})`}</Box>
      <Box>能力ランク: {statStage}</Box>
    </Flex>
  )
}

export const AttackerStatsDisplay = ({
  stats,
  move,
  attacker,
  moveDisplayCategory,
}: StatsDisplayProps) => {
  if (move.attackReference === 'defenderAttack') {
    return <Box>防御側の攻撃値参照</Box>
  } else if (move.attackReference === 'attackerDefense') {
    return (
      <StatDisplay
        label="防御"
        stat={stats.defense}
        effortValue={attacker.effortValues.defense}
        indivisualValue={attacker.indivisualValues.defense}
        natureCorrection={attacker.natureCorrections.defense}
        statStage={attacker.statStages.defense}
      />
    )
  } else if (
    move.attackReference === 'attackerAttack' ||
    (move.attackReference === 'teraBlast' && moveDisplayCategory === '物理')
  ) {
    return (
      <StatDisplay
        label="攻撃"
        stat={stats.attack}
        effortValue={attacker.effortValues.attack}
        indivisualValue={attacker.indivisualValues.attack}
        natureCorrection={attacker.natureCorrections.attack}
        statStage={attacker.statStages.attack}
      />
    )
  } else if (
    move.attackReference === 'attackerSpecialAttack' ||
    (move.attackReference === 'teraBlast' && moveDisplayCategory === '特殊')
  ) {
    return (
      <StatDisplay
        label="特攻"
        stat={stats.specialAttack}
        effortValue={attacker.effortValues.specialAttack}
        indivisualValue={attacker.indivisualValues.specialAttack}
        natureCorrection={attacker.natureCorrections.specialAttack}
        statStage={attacker.statStages.specialAttack}
      />
    )
  } else {
    throw new Error()
  }
}
