import { Box, Flex } from '@chakra-ui/react'
import { TypeId, types } from '../../domain/Type'

type TypesPickerProps = {
  value: TypeId[]
  onChange: (value: TypeId[]) => void
  multipleSelect?: boolean
}

export const TypesPicker = ({
  value,
  onChange,
  multipleSelect = true,
}: TypesPickerProps) => {
  return (
    <Flex gap="8px" userSelect="none">
      {types.map((type) => {
        const onClick = () => {
          if (value.includes(type.id)) {
            onChange(value.filter((v) => v !== type.id))
          } else {
            if (multipleSelect) {
              onChange([...value, type.id])
            } else {
              onChange([type.id])
            }
          }
        }
        return (
          <Box
            key={type.id}
            fontSize="24px"
            color={value.includes(type.id) ? 'black' : 'LightGray'}
            onClick={onClick}
            cursor="pointer"
          >
            {type.shortName}
          </Box>
        )
      })}
    </Flex>
  )
}
