import { useCallback, useEffect, useState } from 'react'
import { SearchablePickerOption } from './SearchablePicker'

export const useOptions = ({
  value,
  emptyValue,
  options,
}: {
  value: string
  emptyValue?: string
  options: SearchablePickerOption[]
}) => {
  const selectedIndex = options.findIndex((o) => o.value === value)
  const selectedOption = options[selectedIndex]
  const [searchText, setSearchText] = useState(
    emptyValue !== selectedOption.value ? selectedOption.name : ''
  )
  const [cursor, setCursor] = useState<string>(value)
  let filteredOptions = options.filter((o) => o.name.includes(searchText))
  const cursorIndex = filteredOptions.findIndex((o) => o.value === cursor)

  useEffect(() => {
    setSearchText(selectedOption.name)
  }, [selectedOption.name, value])

  const next = useCallback(() => {
    if (cursorIndex + 1 >= filteredOptions.length) {
      setCursor(filteredOptions[0].value)
    } else {
      setCursor(filteredOptions[cursorIndex + 1].value)
    }
  }, [cursorIndex, filteredOptions])

  const prev = useCallback(() => {
    if (cursorIndex - 1 < 0) {
      setCursor(filteredOptions[filteredOptions.length - 1].value)
    } else {
      setCursor(filteredOptions[cursorIndex - 1].value)
    }
  }, [cursorIndex, filteredOptions])

  const cancel = useCallback(() => {
    setSearchText(
      emptyValue !== selectedOption.value ? selectedOption.name : ''
    )
  }, [emptyValue, selectedOption.name, selectedOption.value])

  const clear = useCallback(() => {
    setSearchText('')
    setCursor(value)
  }, [value])

  const change = useCallback(
    (searchText: string) => {
      setSearchText(searchText)
      const newFilteredOptions = options.filter((o) =>
        o.name.includes(searchText)
      )
      setCursor(
        newFilteredOptions.length === 0 ? value : newFilteredOptions[0].value
      )
    },
    [options, value]
  )

  const update = useCallback(
    (value: string) => {
      const newSelectedOption = options.find((o) => o.value === value)
      if (typeof newSelectedOption === 'undefined') throw new Error()
      setSearchText(newSelectedOption.name)
    },
    [options]
  )

  return {
    searchText,
    cursor,
    cursorIndex,
    selectedIndex,
    selectedOption,
    filteredOptions,
    next,
    prev,
    cancel,
    clear,
    change,
    update,
  }
}
