import { Box } from '@chakra-ui/react'

type StatStageBoxProps = {
  children: React.ReactNode
}
export const StatStageBox = (props: StatStageBoxProps) => {
  return (
    <Box width="100px" display="flex" justifyContent="center">
      {props.children}
    </Box>
  )
}
