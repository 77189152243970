export type TypeId = number

export type Type = {
  id: number
  name: string
  shortName: string
  strongAgainst: TypeId[]
  weakAgainst: TypeId[]
  resistantAgainst: TypeId[]
}

const NORMAL = 1
const FIRE = 2
const WATER = 3
const ELECTRIC = 4
const GRASS = 5
const ICE = 6
const FIGHTING = 7
const POISON = 8
const GROUND = 9
const FLYING = 10
const PSYCHIC = 11
const BUG = 12
const ROCK = 13
const GHOST = 14
const DRAGON = 15
const DARK = 16
const STEEL = 17
const FAIRY = 18

export const typesId = {
  NORMAL,
  FIRE,
  WATER,
  ELECTRIC,
  GRASS,
  ICE,
  FIGHTING,
  POISON,
  GROUND,
  FLYING,
  PSYCHIC,
  BUG,
  ROCK,
  GHOST,
  DRAGON,
  DARK,
  STEEL,
  FAIRY,
}

export const types: Type[] = [
  {
    id: NORMAL,
    name: 'ノーマル',
    shortName: '無',
    strongAgainst: [],
    weakAgainst: [ROCK, GHOST, STEEL],
    resistantAgainst: [GHOST],
  },
  {
    id: FIRE,
    name: 'ほのお',
    shortName: '炎',
    strongAgainst: [GRASS, ICE, BUG, STEEL],
    weakAgainst: [FIRE, WATER, ROCK, DRAGON],
    resistantAgainst: [],
  },
  {
    id: WATER,
    name: 'みず',
    shortName: '水',
    strongAgainst: [FIRE, GROUND, ROCK],
    weakAgainst: [WATER, GRASS, DRAGON],
    resistantAgainst: [],
  },
  {
    id: ELECTRIC,
    name: 'でんき',
    shortName: '電',
    strongAgainst: [WATER, FLYING],
    weakAgainst: [ELECTRIC, GRASS, DRAGON],
    resistantAgainst: [GROUND],
  },
  {
    id: typesId.GRASS,
    name: 'くさ',
    shortName: '草',
    strongAgainst: [WATER, GROUND, ROCK],
    weakAgainst: [FIRE, GRASS, POISON, FLYING, BUG, DRAGON, STEEL],
    resistantAgainst: [],
  },
  {
    id: typesId.ICE,
    name: 'こおり',
    shortName: '氷',
    strongAgainst: [GRASS, GROUND, FLYING, DRAGON],
    weakAgainst: [FIRE, WATER, ICE, STEEL],
    resistantAgainst: [],
  },
  {
    id: typesId.FIGHTING,
    name: 'かくとう',
    shortName: '闘',
    strongAgainst: [NORMAL, ICE, ROCK, DARK, STEEL],
    weakAgainst: [POISON, FLYING, PSYCHIC, BUG, FAIRY],
    resistantAgainst: [GHOST],
  },
  {
    id: typesId.POISON,
    name: 'どく',
    shortName: '毒',
    strongAgainst: [GRASS, FAIRY],
    weakAgainst: [POISON, GROUND, ROCK, GHOST],
    resistantAgainst: [STEEL],
  },
  {
    id: typesId.GROUND,
    name: 'じめん',
    shortName: '地',
    strongAgainst: [FIRE, ELECTRIC, POISON, ROCK, STEEL],
    weakAgainst: [GRASS, BUG],
    resistantAgainst: [FLYING],
  },
  {
    id: typesId.FLYING,
    name: 'ひこう',
    shortName: '飛',
    strongAgainst: [GRASS, FIGHTING, BUG],
    weakAgainst: [ELECTRIC, ROCK, STEEL],
    resistantAgainst: [],
  },
  {
    id: typesId.PSYCHIC,
    name: 'エスパー',
    shortName: '超',
    strongAgainst: [FIGHTING, POISON],
    weakAgainst: [PSYCHIC, STEEL],
    resistantAgainst: [DARK],
  },
  {
    id: typesId.BUG,
    name: 'むし',
    shortName: '虫',
    strongAgainst: [GRASS, PSYCHIC, DARK],
    weakAgainst: [FIRE, FIGHTING, POISON, FLYING, GHOST, STEEL, FAIRY],
    resistantAgainst: [],
  },
  {
    id: typesId.ROCK,
    name: 'いわ',
    shortName: '岩',
    strongAgainst: [FIRE, ICE, FLYING, BUG],
    weakAgainst: [FIGHTING, GROUND, STEEL],
    resistantAgainst: [],
  },
  {
    id: typesId.GHOST,
    name: 'ゴースト',
    shortName: '霊',
    strongAgainst: [PSYCHIC, GHOST],
    weakAgainst: [DARK],
    resistantAgainst: [NORMAL],
  },
  {
    id: typesId.DRAGON,
    name: 'ドラゴン',
    shortName: '竜',
    strongAgainst: [DRAGON],
    weakAgainst: [STEEL],
    resistantAgainst: [FAIRY],
  },
  {
    id: typesId.DARK,
    name: 'あく',
    shortName: '悪',
    strongAgainst: [PSYCHIC, GHOST],
    weakAgainst: [FIGHTING, DARK, FAIRY],
    resistantAgainst: [],
  },
  {
    id: typesId.STEEL,
    name: 'はがね',
    shortName: '鋼',
    strongAgainst: [ICE, ROCK, FAIRY],
    weakAgainst: [FIRE, WATER, ELECTRIC, STEEL],
    resistantAgainst: [],
  },
  {
    id: typesId.FAIRY,
    name: 'フェアリー',
    shortName: '妖',
    strongAgainst: [FIGHTING, DRAGON, DARK],
    weakAgainst: [FIRE, POISON, STEEL],
    resistantAgainst: [],
  },
]

export const countStrongAgainst = (
  moveName: string,
  attackTypeId: TypeId,
  defenseTypeIds: TypeId[]
) => {
  const attackType = getType(attackTypeId)
  let counts = defenseTypeIds.reduce(
    (count, defenseType) =>
      attackType.strongAgainst.includes(defenseType) ? count + 1 : count,
    0
  )

  if (moveName === 'フリーズドライ' && defenseTypeIds.includes(typesId.WATER)) {
    counts = counts + 1
  }

  return counts
}

export const countWeakAgainst = (
  moveName: string,
  attackTypeId: TypeId,
  defenseTypeIds: TypeId[]
) => {
  const attackType = getType(attackTypeId)
  let counts = defenseTypeIds.reduce(
    (count, defenseType) =>
      attackType.weakAgainst.includes(defenseType) ? count + 1 : count,
    0
  )
  if (
    counts > 0 &&
    moveName === 'フリーズドライ' &&
    defenseTypeIds.includes(typesId.WATER)
  ) {
    // 本来氷は水に半減だが、フリーズドライに限っては半減カウントをしないため。
    counts = counts - 1
  }
  return counts
}

export const countResistantAgainst = (
  moveName: string,
  attackTypeId: TypeId,
  defenseTypeIds: TypeId[]
) => {
  const attackType = getType(attackTypeId)
  return defenseTypeIds.reduce(
    (count, defenseType) =>
      attackType.resistantAgainst.includes(defenseType) ? count + 1 : count,
    0
  )
}

export const getType = (id: number): Type => {
  const type = types.find((type) => type.id === id)
  if (typeof type === 'undefined') {
    throw new Error()
  }
  return type
}
