export const calcNewEffortValue = (
  indivisualValue: number,
  effortValue: number
) => {
  const isIndivisualValueOddNumber = indivisualValue % 2 !== 0
  if (isIndivisualValueOddNumber && effortValue >= 4 && effortValue % 8 === 0) {
    // 個体値が奇数なのに、努力値4以上かつ8で割れてしまったら、振りが足りないので+4
    return effortValue + 4
  } else if (
    !isIndivisualValueOddNumber &&
    effortValue > 0 &&
    effortValue % 8 !== 0
  ) {
    // 個体値が偶数なのに、努力値8で割れなかったら、無駄振りがあるので-4
    return effortValue - 4
  } else {
    return effortValue
  }
}
