import { AddIcon, EditIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, HStack } from '@chakra-ui/react'

type ToolBarProps = {
  selectedId?: number
  onAddClick: () => void
  onSaveClick: (id: number) => void
  onClearClick: () => void
  height: string
}

export const ToolBar = ({
  selectedId,
  onAddClick,
  onSaveClick,
  onClearClick,
  height,
}: ToolBarProps) => {
  const handleSaveClick = () => {
    if (typeof selectedId !== 'undefined') {
      onSaveClick(selectedId)
    }
  }
  return (
    <Box
      position="sticky"
      top="56px"
      backgroundColor="white"
      width="100%"
      height={height}
    >
      <Flex justifyContent="space-between" alignItems="center" padding="2">
        <HStack>
          <Box>
            <Button onClick={onClearClick} size="md">
              クリア
            </Button>
          </Box>
        </HStack>
        <HStack>
          {selectedId && (
            <Button
              leftIcon={<EditIcon color="green.500" />}
              onClick={handleSaveClick}
              size="md"
            >
              計算結果を更新
            </Button>
          )}
          <Button
            leftIcon={<AddIcon color="green.500" />}
            onClick={onAddClick}
            size="md"
          >
            計算結果を保存
          </Button>
        </HStack>
      </Flex>
    </Box>
  )
}
