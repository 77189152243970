import React, { memo, useMemo } from 'react'
import { heldItems } from '../../domain/HeldItem'
import { SearchablePicker } from './SearchablePicker'

type HeldItemPickerProps = {
  value: number
  onChange(value: number): void
  category: 'offensive' | 'defensive'
  dataTestId?: string
}

const getOptions = ({
  category,
}: {
  category: HeldItemPickerProps['category']
}) => {
  let options = heldItems.filter((r) => r.isExistsInPaldia)
  if (category === 'offensive') {
    options = options.filter((o) => o.offensive)
  } else if (category === 'defensive') {
    options = options.filter((o) => o.defensive)
  }
  return options.map((o) => ({ value: String(o.id), name: o.name }))
}

export const HeldItemPicker = memo(
  ({ value, onChange, category, dataTestId }: HeldItemPickerProps) => {
    const options = useMemo(
      () => getOptions({ category: category }),
      [category]
    )
    const handleChange = (value: string) => {
      onChange(Number(value))
    }

    return (
      <SearchablePicker
        value={String(value)}
        onChange={handleChange}
        options={options}
        placeholder="持ち物"
        emptyValue={String(0)}
        dataTestId={dataTestId}
      />
    )
  }
)
