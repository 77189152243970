import { Box, Flex } from '@chakra-ui/react'
import { StatStage } from '../../../domain/StatStage'
import { calcStat } from '../../../domain/StatsFormula'
import { NatureCorrection } from '../../../domain/Nature'
import { NatureCorrectionPicker } from '../../common/NatureCorrectionPicker'
import { StatStagePicker } from '../../common/StatStagePicker'
import { BaseStatsBox } from './BaseStatsBox'
import { EffortValueBox } from './EffortValueBox'
import { EffortValueSlider } from './EffortValueSlider'
import { IndivisualValueBox } from './IndivisualValueBox'
import { IndivisualValuePicker } from './IndivisualValuePicker'
import { LabelBox } from './LabelBox'
import { NatureCorrectionBox } from './NatureCorrectionBox'
import { StatsBox } from './StatsBox'
import { StatStageBox } from './StatStageBox'
import { memo } from 'react'

type StatsLineProps = {
  label: string
  level: number
  baseStats: number
  indivisualValue: number
  effortValue: number
  natureCorrection: NatureCorrection
  statStage: StatStage
  onChangeIndivisualValue: (indivisualValue: number) => void
  onChangeEffortValue: (effortValue: number) => void
  onChangeNatureCorrection: (value: NatureCorrection) => void
  onChangeStatStage: (value: StatStage) => void
  dataTestIdPrefix: string
}

export const StatsLine = memo(
  ({
    label,
    level,
    baseStats,
    indivisualValue,
    effortValue,
    natureCorrection,
    statStage,
    onChangeIndivisualValue,
    onChangeEffortValue,
    onChangeNatureCorrection,
    onChangeStatStage,
    dataTestIdPrefix,
  }: StatsLineProps) => {
    return (
      <Box data-testid={dataTestIdPrefix}>
        <Flex gap="10px" alignItems="center" height="50px">
          <LabelBox>{label}</LabelBox>
          <StatsBox
            fontSize="20px"
            fontWeight="bold"
            data-testid={`${dataTestIdPrefix}-stat`}
          >
            {calcStat({
              level: level,
              baseStat: baseStats,
              indivisualValue: indivisualValue,
              effortValue: effortValue,
              natureCorrection: natureCorrection || '無し',
            })}
          </StatsBox>
          <BaseStatsBox>{baseStats}</BaseStatsBox>
          <IndivisualValueBox>
            <IndivisualValuePicker
              value={indivisualValue}
              onChange={onChangeIndivisualValue}
              dataTestId={`${dataTestIdPrefix}-iv`}
            />
          </IndivisualValueBox>
          <EffortValueBox>{effortValue}</EffortValueBox>
          <NatureCorrectionBox>
            <NatureCorrectionPicker
              value={natureCorrection}
              onChange={onChangeNatureCorrection}
              dataTestIdPrefix={`${dataTestIdPrefix}-nature-correction`}
            />
          </NatureCorrectionBox>
          <StatStageBox>
            <StatStagePicker value={statStage} onChange={onChangeStatStage} />
          </StatStageBox>
        </Flex>
        <Box>
          <EffortValueSlider
            value={effortValue}
            onChange={onChangeEffortValue}
            indivisualValue={indivisualValue}
            dataTestId={`${dataTestIdPrefix}-ev-slider`}
          />
        </Box>
      </Box>
    )
  }
)
