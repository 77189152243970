import React, { memo, useMemo } from 'react'
import { abilities } from '../../domain/Ability'
import { SearchablePicker } from './SearchablePicker'

type AbilityPickerProps = {
  value: number
  onChange(value: number): void
  category?: 'offensive' | 'defensive'
  isExistsInPaldia?: boolean
  defaultAbilityNames?: string[]
  dataTestId?: string
}
const getAbilities = ({
  category,
  isExistsInPaldia,
  defaultAbilityNames,
}: {
  category: AbilityPickerProps['category']
  isExistsInPaldia: AbilityPickerProps['isExistsInPaldia']
  defaultAbilityNames?: string[]
}) => {
  let options = abilities.filter((a) => a.id !== 0)
  if (category && category === 'offensive') {
    options = options.filter((o) => o.offensive)
  } else if (category && category === 'defensive') {
    options = options.filter((o) => o.defensive)
  }

  if (isExistsInPaldia) {
    options = options.filter((o) => o.isExistsInPaldia)
  }
  options.sort((a, b) => a.name.localeCompare(b.name, 'ja'))
  options = [abilities[0], ...options]

  if (defaultAbilityNames) {
    options = [
      ...options.filter((r) => defaultAbilityNames.includes(r.name)),
      ...options.filter((r) => !defaultAbilityNames.includes(r.name)),
    ]
  }

  return options.map((o) => ({ value: String(o.id), name: o.name }))
}

export const AbilityPicker = memo(
  ({
    value,
    onChange,
    category,
    isExistsInPaldia = true,
    dataTestId,
    defaultAbilityNames,
  }: AbilityPickerProps) => {
    const options = useMemo(
      () => getAbilities({ category, isExistsInPaldia, defaultAbilityNames }),
      [category, defaultAbilityNames, isExistsInPaldia]
    )
    const handleChange = (value: string) => {
      onChange(Number(value))
    }

    return (
      <SearchablePicker
        value={String(value)}
        onChange={handleChange}
        options={options}
        placeholder="特性"
        emptyValue={String(0)}
        dataTestId={dataTestId}
      />
    )
  }
)
