import { ChakraProvider } from '@chakra-ui/react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DisclaimerPage } from './pages/DisclaimerPage'
import { SimpleCalcPage } from './pages/SimpleCalcPage'
import { theme } from './theme'

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<SimpleCalcPage />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
      </Routes>
    </ChakraProvider>
  )
}

export default App
